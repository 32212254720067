import { useState } from "react";
import { useSelector } from "react-redux";
import Select from "components/Form/components/Select";
import { RootState } from "shared/state/store";
import useActions from "Hooks/useActions";

const RecognitionDestinationSelector = () => {
  const {
    setDpmCountryError,
    setRecognitionStateError,
    setRecognitionDestination,
  } = useActions();
  const [placeholder, setPlaceholder] = useState("Select");
  const { recognitionDestination } = useSelector(
    (state: RootState) => state.staffUseOnly,
  );
  return (
    <div>
      <Select
        id="RecognitionDestination"
        label={"Send recognition to"}
        options={[
          { label: "Club President", value: "Club President" },
          { label: "Club Secretary", value: "Club Secretary" },
          { label: "Club Foundation Chair", value: "Club Foundation Chair" },
          { label: "Donor", value: "Donor" },
          { label: "Other", value: "Other" },
        ]}
        value={recognitionDestination?.value}
        onChange={(item) => {
          setPlaceholder(item.label);
          if (item.value === "Other") {
            setDpmCountryError(true, false);
          } else {
            setDpmCountryError(false, false);
            setRecognitionStateError(false, false);
          }
          setRecognitionDestination(item as { label: string; value: string });
        }}
        placeholder={placeholder}
      />
    </div>
  );
};

export default RecognitionDestinationSelector;
