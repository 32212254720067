// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PayInvoice Grid Styling Start */

.inv-flex-host {
  display: flex;
  flex-direction: column;
}

.inv-container .inv-header,
.inv-container .inv-footer {
  display: flex;
  padding: 10px;
  background-color: #f8f9fa;
  border-bottom: 0.5px solid #263B4C;
}

.inv-container .inv-header .col-heading,
.inv-container .inv-footer .col-heading {
  font-weight: bold;
}

.inv-container .inv-footer {
  margin-top: 14px;
  border-bottom: none;
  margin-bottom: -20px;
}

.inv-container .inv-row {
  display: flex;
  border-bottom: 0.5px solid #263B4C;
  padding: 10px;
  background: #FFFFFF;
}

.inv-container .inv-col:nth-child(1) {
  flex: 1 1;
  height: 20px;
}

.inv-container .inv-col:nth-child(1) .c12 {
  height: 24px;
  width: 20px;
}

.inv-container .inv-col:nth-child(1) .c1 {
  margin: 0;
}

.inv-container .inv-col:nth-child(5) {
  text-align: end;
}

.inv-container .inv-col {
  flex: 3 1;
}

/* PayInvoice Grid Styling End */
`, "",{"version":3,"sources":["webpack://./src/components/PayInvoice/components/PayInvoiceGrid/styles.css"],"names":[],"mappings":"AAAA,kCAAkC;;AAElC;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;;EAEE,aAAa;EACb,aAAa;EACb,yBAAyB;EACzB,kCAAkC;AACpC;;AAEA;;EAEE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,kCAAkC;EAClC,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,SAAO;EACP,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,SAAO;AACT;;AAEA,gCAAgC","sourcesContent":["/* PayInvoice Grid Styling Start */\n\n.inv-flex-host {\n  display: flex;\n  flex-direction: column;\n}\n\n.inv-container .inv-header,\n.inv-container .inv-footer {\n  display: flex;\n  padding: 10px;\n  background-color: #f8f9fa;\n  border-bottom: 0.5px solid #263B4C;\n}\n\n.inv-container .inv-header .col-heading,\n.inv-container .inv-footer .col-heading {\n  font-weight: bold;\n}\n\n.inv-container .inv-footer {\n  margin-top: 14px;\n  border-bottom: none;\n  margin-bottom: -20px;\n}\n\n.inv-container .inv-row {\n  display: flex;\n  border-bottom: 0.5px solid #263B4C;\n  padding: 10px;\n  background: #FFFFFF;\n}\n\n.inv-container .inv-col:nth-child(1) {\n  flex: 1;\n  height: 20px;\n}\n\n.inv-container .inv-col:nth-child(1) .c12 {\n  height: 24px;\n  width: 20px;\n}\n\n.inv-container .inv-col:nth-child(1) .c1 {\n  margin: 0;\n}\n\n.inv-container .inv-col:nth-child(5) {\n  text-align: end;\n}\n\n.inv-container .inv-col {\n  flex: 3;\n}\n\n/* PayInvoice Grid Styling End */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
