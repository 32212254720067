import { useEffect, useRef } from "react";
import { styled } from "@mui/system";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import moment from "moment";
import calendarIcon from "shared/assets/calendar.svg";
import closeIcon from "shared/assets/close.svg";

const CalendarIcon = styled("span")`
  width: 20px;
  background-size: 20px 20px;
  background-image: url(${calendarIcon});
  background-position: center top;
  background-repeat: no-repeat;
`;

const CloseIcon = styled("span")`
  width: 15px;
  background-size: 15px 15px;
  background-image: url(${closeIcon});
  background-position: center center;
  background-repeat: no-repeat;
`;

const DatePickerInput = styled("input")`
  width: calc(100% - 40px);
  border: none;
  margin-left: 1rem;
  &:focus {
    outline: none;
  }
  ::placeholder {
    color: #aaa;
  }
`;

const DatePickerLabel = styled("label")`
  font-weight: 700;
  display: block;
  margin-bottom: 12px;
`;

interface DatePickerProps {
  id: string;
  labelText: string;
  onDateChange: (date: string[] | null) => void;
}

const DatePicker = ({ id, labelText, onDateChange }: DatePickerProps) => {
  const dateRef = useRef(null);

  useEffect(() => {
    if (dateRef.current) {
      const datepicker = flatpickr(dateRef.current, {
        dateFormat: "d-M-Y",
        wrap: true,
        onChange: (selectedDates) => {
          if (selectedDates.length === 0) {
            onDateChange(null);
          } else {
            const formattedSelectedDates = selectedDates.map((sd) =>
              moment(sd).format("YYYY-MM-DD"),
            );
            onDateChange(formattedSelectedDates);
          }
        },
      });

      return () => {
        datepicker.destroy();
      };
    }
  }, []);

  return (
    <div>
      <div>
        <DatePickerLabel>{labelText}</DatePickerLabel>
      </div>
      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          justifyContent: "space-between",
          border: "1px solid black",
          padding: "0.65em",
        }}
        ref={dateRef}
      >
        <CalendarIcon data-toggle />
        <DatePickerInput placeholder={"Select"} data-input />
        <CloseIcon data-clear />
      </div>
    </div>
  );
};

export default DatePicker;
