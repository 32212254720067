import { ReactNode } from "react";
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import ModuleHeader from "components/ModuleHeader";
import { pxToRem, color } from "shared/styles";

const ContentWrapper = styled(Box)(({ theme }) => ({
  border: "1px solid black",
  marginLeft: pxToRem(150),
  marginRight: pxToRem(150),
  marginTop: pxToRem(20),
  marginBottom: pxToRem(70),
  [theme.breakpoints.down("sm")]: {
    marginLeft: pxToRem(30),
    marginRight: pxToRem(30),
  },
  [theme.breakpoints.down("xs")]: {
    border: "none",
    borderBottom: `1px solid ${color.midGrey}`,
  },
}));

interface ModuleContainerProps {
  children: ReactNode;
  headerText: string;
}

const ModuleContainer = ({ children, headerText }: ModuleContainerProps) => {
  return (
    <ContentWrapper>
      <ModuleHeader headerText={headerText} />
      <Box
        sx={{
          width: "75%",
          justifyContent: "center",
          margin: "auto",
          paddingTop: 5,
          paddingBottom: 5,
        }}
      >
        {children}
      </Box>
    </ContentWrapper>
  );
};

export default ModuleContainer;
