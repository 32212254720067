import React, { ChangeEvent } from "react";
import { styled } from "@mui/material/styles";
import { color } from "shared/styles";
import unselected from "shared/assets/unselected.svg";
import selectedBlue from "shared/assets/selected-blue.svg";
import disabled from "shared/assets/disabled.svg";

interface CheckBoxProps {
  id?: string;
  label: string;
  errorMsg?: string | null;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  outSideContainer?: boolean;
  checked: boolean;
}

interface InputLabelType {
  isDisabled?: boolean;
  checked?: boolean;
  outSideContainer?: boolean;
}

const Input = styled("input")({
  display: "none",
});
const InputLabel = styled("label")(
  ({ isDisabled, checked, outSideContainer }: InputLabelType) => ({
    backfaceVisibility: "hidden",
    backgroundColor: isDisabled ? color.offWhite : color.blueSkyLight,
    backgroundImage:
      isDisabled && !checked
        ? `url(${disabled})`
        : checked
          ? `url(${selectedBlue})`
          : `url(${unselected})`,
    backgroundPosition: `30px 30px`,
    backgroundRepeat: "no-repeat",
    backgroundSize: checked ? "24px 20px" : "20px 20px",
    border: `1px solid ${color.lightBlueGrey}`,
    color: checked ? color.blue : color.navy,
    cursor: `pointer`,
    display: `block`,
    lineHeight: "1.4em",
    padding: `30px 30px 30px 60px`,
    width: outSideContainer ? "72%" : "100%",
    margin: "auto",
    marginBottom: "50px",
    boxSizing: "border-box",
    "&:hover": {
      backgroundColor: isDisabled ? color.offWhite : color.blueSkyMid,
    },
  }),
);

const LabelText = styled("span")({});

const ErrorMessage = styled("div")({
  color: color.red,
});

const Checkbox: React.FC<CheckBoxProps> = ({
  id,
  label,
  errorMsg,
  onChange,
  checked,
  isDisabled,
  outSideContainer,
}) => {
  return (
    <InputLabel
      htmlFor={id}
      checked={checked}
      isDisabled={isDisabled}
      outSideContainer={outSideContainer}
    >
      <Input
        id={id}
        type="checkbox"
        defaultChecked={checked}
        onChange={onChange}
      />
      <LabelText>{label}</LabelText>
      {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
    </InputLabel>
  );
};

export default Checkbox;
