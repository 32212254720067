import { Amounts } from "shared/types";
// These constants map to hardcoded recurringFrequency IDs.
// They are not needed once this data gets served from Drupal.
const MONTHLY = "monthly";
const QUARTERLY = "quarterly";
const ANNUALLY = "annual";

const amounts: Amounts = {
  "US Dollar": {
    oneTime: {
      default: 250,
      options: [25, 100, 250, 1000],
    },
    recurring: {
      [MONTHLY]: {
        default: 100,
        options: [10, 50, 100, 200],
      },
      [QUARTERLY]: {
        default: 250,
        options: [25, 100, 250, 500],
      },
      [ANNUALLY]: {
        default: 1000,
        options: [100, 500, 1000, 1500],
      },
    },
  },
  "Brazilian Real": {
    oneTime: {
      default: 300,
      options: [30, 100, 300, 3500],
    },
    recurring: {
      [MONTHLY]: {
        default: 120,
        options: [30, 50, 120, 700],
      },
      [QUARTERLY]: {
        default: 300,
        options: [50, 100, 300, 1750],
      },
      [ANNUALLY]: {
        default: 1200,
        options: [120, 500, 1200, 7000],
      },
    },
  },
  "Australian Dollar": {
    oneTime: {
      default: 250,
      options: [50, 100, 250, 500],
    },
    recurring: {
      [MONTHLY]: {
        default: 100,
        options: [20, 50, 100, 115],
      },
      [QUARTERLY]: {
        default: 100,
        options: [50, 100, 250, 275],
      },
      [ANNUALLY]: {
        default: 100,
        options: [100, 500, 1000, 1400],
      },
    },
  },
  "New Zealand Dollar": {
    oneTime: {
      default: 250,
      options: [50, 100, 250, 500],
    },
    recurring: {
      [MONTHLY]: {
        default: 100,
        options: [20, 50, 100, 125],
      },
      [QUARTERLY]: {
        default: 250,
        options: [50, 100, 250, 365],
      },
      [ANNUALLY]: {
        default: 1000,
        options: [200, 500, 1000, 1450],
      },
    },
  },
  "Argentine Peso": {
    oneTime: {
      default: 250,
      options: [150, 200, 250, 300],
    },
    recurring: {
      [MONTHLY]: {
        default: 150,
        options: [60, 100, 150, 200],
      },
      [QUARTERLY]: {
        default: 500,
        options: [150, 300, 500, 1000],
      },
      [ANNUALLY]: {
        default: 3000,
        options: [1000, 2000, 3000, 4000],
      },
    },
  },
  Yen: {
    oneTime: {
      default: 10000,
      options: [5000, 10000, 30000, 50000],
    },
    recurring: {
      [MONTHLY]: {
        default: 10000,
        options: [2000, 5000, 10000, 12000],
      },
      [QUARTERLY]: {
        default: 30000,
        options: [5000, 10000, 30000, 25000],
      },
      [ANNUALLY]: {
        default: 120000,
        options: [20000, 50000, 120000, 200000],
      },
    },
  },
  "Indian Rupee": {
    oneTime: {
      default: 20000,
      options: [70000, 20000, 7500, 2000],
    },
    recurring: {
      [MONTHLY]: {
        default: 8000,
        options: [14000, 8000, 3750, 800],
      },
      [QUARTERLY]: {
        default: 20000,
        options: [35000, 20000, 7500, 2000],
      },
      [ANNUALLY]: {
        default: 80000,
        options: [140000, 80000, 37500, 8000],
      },
    },
  },
  "South Korean Won": {
    oneTime: {
      default: 600000,
      options: [30000, 120000, 600000, 1200000],
    },
    recurring: {
      [MONTHLY]: {
        default: 120000,
        options: [12000, 60000, 120000, 240000],
      },
      [QUARTERLY]: {
        default: 240000,
        options: [30000, 120000, 240000, 600000],
      },
      [ANNUALLY]: {
        default: 1200000,
        options: [120000, 600000, 1200000, 2400000],
      },
    },
  },
  Euro: {
    oneTime: {
      default: 235,
      options: [950, 235, 95, 25],
    },
    recurring: {
      [MONTHLY]: {
        default: 100,
        options: [200, 100, 50, 10],
      },
      [QUARTERLY]: {
        default: 250,
        options: [500, 250, 100, 25],
      },
      [ANNUALLY]: {
        default: 1000,
        options: [2000, 1000, 500, 100],
      },
    },
  },
  "Swiss Franc": {
    oneTime: {
      default: 250,
      options: [1000, 250, 100, 25],
    },
    recurring: {
      [MONTHLY]: {
        default: 100,
        options: [200, 100, 50, 10],
      },
      [QUARTERLY]: {
        default: 250,
        options: [500, 250, 100, 25],
      },
      [ANNUALLY]: {
        default: 1000,
        options: [2000, 1000, 500, 100],
      },
    },
  },
  "Danish Krone": {
    oneTime: {
      default: 700,
      options: [1750, 700, 350, 175],
    },
    recurring: {
      [MONTHLY]: {
        default: 280,
        options: [350, 280, 175, 70],
      },
      [QUARTERLY]: {
        default: 700,
        options: [875, 700, 350, 175],
      },
      [ANNUALLY]: {
        default: 2800,
        options: [3500, 2800, 1750, 700],
      },
    },
  },
  "Norwegian Krone": {
    oneTime: {
      default: 860,
      options: [2150, 860, 430, 215],
    },
    recurring: {
      [MONTHLY]: {
        default: 340,
        options: [430, 340, 225, 90],
      },
      [QUARTERLY]: {
        default: 860,
        options: [1000, 860, 430, 215],
      },
      [ANNUALLY]: {
        default: 3440,
        options: [4300, 3440, 2150, 860],
      },
    },
  },
  "Swedish Krona": {
    oneTime: {
      default: 880,
      options: [2200, 880, 440, 220],
    },
    recurring: {
      [MONTHLY]: {
        default: 352,
        options: [440, 352, 220, 88],
      },
      [QUARTERLY]: {
        default: 880,
        options: [1100, 880, 440, 220],
      },
      [ANNUALLY]: {
        default: 3520,
        options: [4400, 3520, 2200, 880],
      },
    },
  },
  Rand: {
    oneTime: {
      default: 1400,
      options: [3500, 1400, 700, 350],
    },
    recurring: {
      [MONTHLY]: {
        default: 560,
        options: [700, 560, 350, 140],
      },
      [QUARTERLY]: {
        default: 1400,
        options: [1750, 1400, 700, 350],
      },
      [ANNUALLY]: {
        default: 5600,
        options: [7000, 5600, 3500, 1400],
      },
    },
  },
  "Canadian Dollar": {
    oneTime: {
      default: 250,
      options: [25, 100, 250, 1000],
    },
    recurring: {
      [MONTHLY]: {
        default: 100,
        options: [10, 50, 100, 200],
      },
      [QUARTERLY]: {
        default: 250,
        options: [25, 100, 250, 500],
      },
      [ANNUALLY]: {
        default: 1000,
        options: [100, 500, 1000, 2000],
      },
    },
  },
  "Pound Sterling": {
    oneTime: {
      default: 250,
      options: [25, 100, 250, 750],
    },
    recurring: {
      [MONTHLY]: {
        default: 100,
        options: [10, 50, 100, 150],
      },
      [QUARTERLY]: {
        default: 250,
        options: [25, 100, 250, 375],
      },
      [ANNUALLY]: {
        default: 1000,
        options: [100, 500, 1000, 1500],
      },
    },
  },
};

export default amounts;
