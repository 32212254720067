import React, { ReactNode, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import TabBar from "components/FundCategory/TabBar";
import Grants from "components/FundCategory/Grants";
import PromotedFundList from "components/FundCategory/PromotedFundList";
import ModuleContainer from "components/ModuleContainer";
import DropdownFundList from "./DropdownFundList";
import TrackedGift from "./TrackedGift";
import NamedFunds from "components/FundCategory/NamedFunds";
import { RootState } from "shared/state/store";
import { pxToRem } from "shared/styles";
import useActions from "Hooks/useActions";
import {
  getDpmCategories,
  getFundById,
  getItemsFromLocalStorage,
} from "shared/utils";
import { Trans, useTranslation } from "react-i18next";
import i18n from "i18n";
import { Fund } from "shared/types";

interface TabBlurbProps {
  children: ReactNode;
}

const TabBlurb = ({ children }: TabBlurbProps) => (
  <Box
    className="tab__blurb"
    sx={{
      lineHeight: pxToRem(24),
      marginBottom: pxToRem(35),
      marginTop: pxToRem(35),
      marginLeft: pxToRem(20),
      marginRight: pxToRem(20),
    }}
  >
    {children}
  </Box>
);

interface AllowedTabContent {
  [key: string]: React.ComponentType<any>;
}

const FundCategory = () => {
  const {
    setGrantsError,
    setNamedFundsError,
    setTrackedGiftError,
    setDonationType,
    setNamedFundId,
    setGrantId,
    setTrackedGiftId,
    setTrackedGiftFundId,
    setTrackedGiftSubFundId,
    setTrackedGiftName,
    setTrackedGiftAlias,
    setActiveFundTab,
    setNamedFundAliasDescription,
    setSelectedFundUniqueId,
  } = useActions();
  const {
    config,
    namedFundId,
    trackedGiftId,
    grantId,
    activeFundTab,
    mode,
    dpmBtnMode,
  } = useSelector((state: RootState) => state.app);

  const { t } = useTranslation();

  const lang =
    i18n.language === "en"
      ? "en"
      : i18n.language === "es"
        ? "es"
        : i18n.language === "it"
          ? "it"
          : i18n.language === "fr"
            ? "fr"
            : i18n.language === "ko"
              ? "ko"
              : i18n.language === "pt"
                ? "pt"
                : i18n.language === "ja"
                  ? "ja"
                  : i18n.language === "de"
                    ? "de"
                    : "en";

  const tabs =
    mode === "dpm"
      ? config && getDpmCategories(config.categories, dpmBtnMode, mode)
      : config &&
        config.categories[lang].filter((cat) =>
          cat.content.filter((obj) => obj.mode === mode),
        );
  const user = { authenticated: true };

  const allowedTabContent: AllowedTabContent = {
    PromotedFundList,
    DropdownFundList,
    Grants,
    TrackedGift,
    NamedFunds,
  };
  const activeTabData =
    tabs?.find((tab) => tab.id === activeFundTab) || (tabs && tabs[0]);
  const tabContent = activeTabData?.content
    .filter((con) => con.mode === mode)
    .map((content, index) => {
      const TabComponent =
        allowedTabContent[content.component] ||
        allowedTabContent.UnknownTabContent;
      return (
        <TabComponent
          key={`${activeTabData.id}:${content.id}`}
          contentIndex={index}
          // userRoles={userRoles}
          config={config}
          // lang={lang}
          // {...rest}
          activeTab={activeTabData}
        />
      );
    });
  useEffect(() => {
    if (
      config &&
      activeTabData?.content.some(
        (tab) =>
          tab.component.includes("PromotedFundList") ||
          tab.component.includes("DropdownFundList"),
      )
    ) {
      // Todo
      const subscriptionEdit = false;
      if (subscriptionEdit === false) {
        const selectedFund: Fund = getItemsFromLocalStorage(
          "selectedFund",
          "json",
        );
        const fundData = getFundById(
          config.funds,
          selectedFund ? selectedFund.id : activeTabData.content[0].defaultFund,
        );
        setSelectedFundUniqueId(fundData?.id as number, config.funds);
        // setTransactionTypeAlias(fundData.alias);
      }
      setGrantsError(false, false);
      setTrackedGiftError(false, false);
      setNamedFundsError(false, false);
      if (grantId) {
        setGrantId(null);
      }
      if (namedFundId) {
        setNamedFundId(null);
      }
      if (trackedGiftId) {
        setTrackedGiftId("");
        setTrackedGiftFundId("");
        setTrackedGiftSubFundId("");
        setTrackedGiftAlias("");
        setTrackedGiftName("");
      }
    }

    if (
      config &&
      activeTabData?.content.some((tab) => tab.component.includes("Grants"))
    ) {
      setGrantsError(true, false);
      setDonationType(config.currencies, "one-time");
      setNamedFundsError(false, false);
      setTrackedGiftId("");
      setTrackedGiftFundId("");
      setTrackedGiftSubFundId("");
      setTrackedGiftAlias("");
      setTrackedGiftName("");
      setNamedFundAliasDescription("");
      setNamedFundId(null);
    }
    if (
      config &&
      activeTabData?.content.some((tab) => tab.component.includes("NamedFunds"))
    ) {
      setNamedFundsError(true, false);
      setDonationType(config.currencies, "one-time");
      setGrantsError(false, false);
    }
    if (
      config &&
      activeTabData?.content.some((tab) =>
        tab.component.includes("TrackedGift"),
      )
    ) {
      setTrackedGiftError(true, false);
      setDonationType(config.currencies, "one-time");
      setGrantsError(false, false);
      setNamedFundsError(false, false);
    }
  }, [activeTabData]);

  // setActiveFundTab(config?.categories.en[0].id as string);

  return (
    <ModuleContainer headerText={t("Choose what you would like to support")}>
      {config?.appConfigs.fundTabsShown && <TabBar tabs={tabs} />}
      {activeTabData?.blurb &&
        activeFundTab &&
        activeTabData.content[0].component !== "NamedFunds" && (
          <TabBlurb>{activeTabData.blurb}</TabBlurb>
        )}
      {activeTabData?.blurb &&
        activeFundTab &&
        activeTabData.content[0].component === "NamedFunds" &&
        !user.authenticated && <TabBlurb>{activeTabData.blurb}</TabBlurb>}
      {tabContent}
    </ModuleContainer>
  );
};

export default FundCategory;
