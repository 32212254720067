import { createSlice } from "@reduxjs/toolkit";
import { setDdoMemberId } from "shared/utils/metrics";

const storedGuestContinue = localStorage.getItem("guestContinue");
const initialGuestContinue = storedGuestContinue
  ? JSON.parse(storedGuestContinue)
  : false;
export interface UserState {
  user: {
    authenticated: boolean;
    roles: {
      [key: number]: string;
    };
    sec_groups: [];
    memberId?: string;
  };
  guestContinue: boolean;
}

export const initialStateUser = {
  user: {
    authenticated: false,
    roles: {
      1: "anonymous user",
    },
    sec_groups: [],
  },
  guestContinue: initialGuestContinue,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialStateUser,
  reducers: {
    setUser: (state, action) => {
      const { user, guestContinue } = action.payload;
      if (user.member_id) {
        setDdoMemberId(user.member_id);
      }
      state.user = user;
      localStorage.setItem("guestContinue", JSON.stringify(guestContinue));
      //  if ("guestContinue" in user) {
      state.guestContinue = guestContinue;
      // }
    },
  },
});

export const { setUser } = userSlice.actions;

export const createSetUserAction = (user = {}, guestContinue: boolean) => ({
  type: "user/setUser",
  payload: { user, guestContinue: guestContinue },
});

export default userSlice.reducer;
