import { useSelector } from "react-redux";
import useActions from "Hooks/useActions";
import { Funds, SelectOptions } from "shared/types";
import Select from "components/Form/components/Select";
import { RootState } from "shared/state/store";
import { getFundById, getFundTitle, getVisibleFunds } from "shared/utils";

interface DropdownFundListProps {
  contentIndex: number;
  label: string;
  funds: number[] | null;
}

const DropdownFundList = ({
  contentIndex,
  label,
  funds,
}: DropdownFundListProps) => {
  const { setSelectedFundUniqueId, setEndowmentName } = useActions();
  const { selectedFundUniqueId, config, endowmentName } = useSelector(
    (state: RootState) => state.app,
  );
  const isFirstContent = contentIndex === 0;
  const options =
    config &&
    getVisibleFunds(config.funds, funds, config.appConfigs.oneTimeEnabled).map(
      (fund) => {
        const fundData = config && getFundById(config.funds, fund);
        if (!fundData) {
          return null;
        }
        return {
          label: getFundTitle(
            {
              fundTitle: {
                en: fundData.fundTitle?.en || null,
                fr: fundData.fundTitle?.fr || null,
                de: fundData.fundTitle?.de || null,
                es: fundData.fundTitle?.es || null,
                it: fundData.fundTitle?.it || null,
                ja: fundData.fundTitle?.ja || null,
                ko: fundData.fundTitle?.ko || null,
                pt: fundData.fundTitle?.pt || null,
              },
              id: fundData.id,
            },
            "en",
          ),
          value: fundData.id,
          fund: fundData,
        };
      },
    );
  const setFund = (id: number, allFunds: Funds) => {
    setSelectedFundUniqueId(id, allFunds);
    const fundName = getFundById(allFunds, id)?.fundTitle?.en;
    fundName && setEndowmentName(fundName);
  };

  return (
    <div>
      <Select
        id="FundSelect"
        label={isFirstContent && label ? label : "Or choose a specific cause"}
        value={selectedFundUniqueId}
        options={options as SelectOptions[]}
        onChange={(event: SelectOptions) =>
          config && setFund(event.value as number, config.funds)
        }
        placeholder={endowmentName}
      />
    </div>
  );
};

export default DropdownFundList;
