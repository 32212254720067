import React, { useEffect, useMemo } from "react";
import { styled } from "@mui/system";
import {
  color,
  bp,
  stepTitle,
  media,
  pxToRem,
  donationSubheading,
  CustomButton,
} from "shared/styles";
import { useSelector } from "react-redux";
import { RootState } from "shared/state/store";
import {
  checkIsInvoiceFlow,
  checkIsRotaryDirectPage,
  formatAmountByCurrency,
  getFrequencyById,
  getFrequencyName,
  getFundById,
  getFundByKey,
  getFundTitle,
} from "shared/utils";
import { Config, Currency, Funds } from "shared/types";
import {
  Content,
  ContentHeading,
  ContentNote,
  ContentRow,
  ContentText,
} from "./styles";
import Address from "components/Address";
import useActions from "Hooks/useActions";
import { fetchEcardLinkAndStoreData } from "shared/utils/api";

interface WrapperProps {
  onClick: () => void;
}
const Wrapper = styled("div")<WrapperProps>`
  background-color: ${color.white};
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;

  ${bp("sm")`
    background-color: rgba(255, 255, 255, 0.9);
  `}
`;

const WindowContainer = styled("div")`
  height: 100vh;
  margin: 0 auto;
  max-width: 770px;

  ${bp("sm")`
    margin: 80px auto 0;
    height: auto;
  `}
`;

const Header = styled("div")`
  background-color: ${color.navy};
  color: ${color.white};
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;

  ${bp("sm")`
    height: 45px;
    position: relative;
  `}
`;
const CloseBtn = styled("div")(({ theme }) => ({
  alignItems: "center",
  cursor: "pointer",
  display: "flex",
  margin: "0 16px 0 auto",
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
    color: color.navy,
    margin: 0,
    position: "absolute",
    right: 0,
    top: "-32px",
    transition: "all 0.3s ease",
    "&:hover": {
      color: color.red,
      path: {
        fill: color.red,
      },
    },
  },
}));

const CloseText = styled("div")``;

const CloseGraphic = styled("div")(({ theme }) => ({
  height: "19px",
  margin: "0 0 0 8px",
  width: "19px",
  "& path": {
    fill: color.white,
    transition: "all 0.3s ease",
  },
  [theme.breakpoints.up("sm")]: {
    height: "20px",
    margin: "2px 0 0 10px",
    width: "20px",
    "& path": {
      fill: color.navy,
    },
  },
}));

const Main = styled("div")`
  background-color: ${color.white};
  height: calc(100vh - 39px);
  margin: 39px 0 0;
  overflow-y: scroll;
  padding: 0 5%;
  -webkit-overflow-scrolling: touch;

  ${bp("sm")`
    border: 1px solid;
    box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.42);
    height: auto;
    margin: 0 0 40px;
    max-height: calc(100vh - 255px);
    padding: 0 5rem;
  `}
`;

const Heading = styled("h1")`
  ${stepTitle()};
`;

const DonationGroup = styled("div")`
  &:last-child {
    margin-bottom: ${pxToRem(198)};

    ${media.xs_landscape_s} {
      margin-bottom: ${pxToRem(138)};
    }

    ${bp("sm")`
      margin-bottom: 3.125rem;
    `}
  }
`;

const Subheading = styled("p")`
  ${donationSubheading()};
`;

const Footer = styled("div")(({ theme }) => ({
  bottom: 0,
  padding: "0 5%",
  position: "fixed",
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
  },
  [theme.breakpoints.up("sm")]: {
    justifyContent: "center",
    padding: 0,
    position: "static",
  },
}));

const FooterInner = styled("div")`
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 1) 100%
  );
  padding: ${pxToRem(44)} 0;
  width: 100%;

  ${bp("sm")`
    background: transparent;
    padding: 0;
    width: auto;
  `}
`;

interface FooterBtnProps {
  primary?: boolean;
}
const FooterBtn = styled(CustomButton)<FooterBtnProps>`
  &:first-child {
    margin-bottom: 10px;
  }
  ${bp("xs")`
    min-width: auto;
    width: 100%;
  `}
  ${bp("xs_landscape_up")`
    width: calc(50% - 2.5%);
  `}
  ${media.xs_landscape_s} {
    &:first-child {
      margin: 0 5% 0 0;
    }
  }
  ${bp("sm")`
    min-width: 16.9rem;
    margin: 0 1rem;
    width: auto;
  `}
  ${media.xs_landscape_down} {
    &:first-child {
      margin-bottom: ${pxToRem(10)};
    }
  }
  ${media.s_down} {
    &:hover,
    &:focus {
      background-color: ${color.white};
      color: ${color.red};
    }
  }
`;

const DedicationImage = styled("img")`
  margin-bottom: ${pxToRem(7)};
  max-width: 100%;
`;

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 37.99988 38"
    aria-labelledby="title"
  >
    <title>Close modal</title>
    <path d="M19,0A19,19,0,1,0,37.99988,19,18.99992,18.99992,0,0,0,19,0Zm0,34A15,15,0,1,1,33.99988,19,15.00009,15.00009,0,0,1,19,34Zm2.82837-15,4.6568,4.65686L23.6568,26.48529l-4.65686-4.65686-4.65686,4.65686-2.82843-2.82843L16.17151,19l-4.65686-4.6568,2.82843-2.82849,4.65686,4.65686,4.65686-4.65686,2.82837,2.82849Z" />
  </svg>
);

const DonationDetails = () => {
  const {
    setDonationDetailsVisibility,
    setFirstName,
    setLastName,
    setPhone,
    setEmail,
    setStreet,
    setBillingState,
    setZip,
    setBillingCity,
    setInternationalProvince,
    setBillingCountry,
    setEcardLink,
  } = useActions();
  const {
    config,
    mode,
    dpmBtnMode,
    selectedFundUniqueId,
    donationType,
    grantId,
    trackedGiftAliasDescription,
    namedFundAliasDescription,
    currency,
    amount,
    recurringFrequencyId,
    recurringStartDateDetails,
  } = useSelector((state: RootState) => state.app);
  const {
    dedicationVisibility,
    dedicationName,
    dedicationType,
    dedicationNotificationStatus,
    donorName,
    notificationType,
    selectedDedicationImage,
    personalMessage,
    recipientEmail,
  } = useSelector((state: RootState) => state.dedication);
  const {
    paymentType,
    paymentAmount,
    multipleDonorObject,
    multipleDonorVisibility,
  } = useSelector((state: RootState) => state.staffUseOnly);
  const { grantsError, trackedGiftError, namedFundsError } = useSelector(
    (state: RootState) => state.error,
  );
  const {
    billingCity,
    billingCountry,
    billingState,
    internationalProvince,
    street,
    street2,
    street3,
    zip,
  } = useSelector((state: RootState) => state.billingAddress);
  const { firstName, lastName, phone, email } = useSelector(
    (state: RootState) => state.personalDetails,
  );
  const donationTypeDisplay = useMemo(() => {
    const fund = getFundById(
      config?.funds as Funds,
      selectedFundUniqueId as number,
    );
    const recurringAvailable =
      config?.appConfigs.recurringEnabled &&
      fund?.recurring &&
      // !behalfVisibility &&
      !dedicationVisibility;
    if (recurringAvailable && donationType === "one-time") {
      return "One-time donation";
    } else if (recurringAvailable && donationType === "recurring") {
      return "Recurring donation";
    }
  }, [selectedFundUniqueId, donationType, dedicationVisibility]);

  const fundTitle = useMemo(() => {
    const fund = getFundById(
      config?.funds as Funds,
      selectedFundUniqueId as number,
    );
    const grantDisplay = grantId ? grantId.replace("GG", "Global Grant ") : "";
    if (mode === "dpm" && dpmBtnMode === "addPayment") {
      const paymentFund =
        (paymentType && getFundById(config?.funds as Funds, paymentType)) ||
        null;
      return (paymentFund && paymentFund.rnwPurposeText) || "";
    } else if (checkIsInvoiceFlow(config as Config)) {
      const invoiceFund =
        getFundByKey(config?.funds as Funds, "typeName", "Invoice") || null;
      return (invoiceFund && invoiceFund.rnwPurposeText) || "";
    } else if (mode === "dpm" && dpmBtnMode === "addDonation") {
      return !grantDisplay
        ? fund &&
            getFundTitle(
              {
                fundTitle: {
                  en: fund.fundTitle?.en || null,
                  fr: fund.fundTitle?.fr || null,
                  de: fund.fundTitle?.de || null,
                  es: fund.fundTitle?.es || null,
                  it: fund.fundTitle?.it || null,
                  ja: fund.fundTitle?.ja || null,
                  ko: fund.fundTitle?.ko || null,
                  pt: fund.fundTitle?.pt || null,
                },
                id: fund.id,
              },
              "en",
            )
        : grantDisplay;
    } else if (!trackedGiftError.status && trackedGiftAliasDescription) {
      return trackedGiftAliasDescription;
    } else if (!namedFundsError.status && namedFundAliasDescription) {
      return namedFundAliasDescription;
    } else {
      return !grantDisplay
        ? fund &&
            getFundTitle(
              {
                fundTitle: {
                  en: fund.fundTitle?.en || null,
                  fr: fund.fundTitle?.fr || null,
                  de: fund.fundTitle?.de || null,
                  es: fund.fundTitle?.es || null,
                  it: fund.fundTitle?.it || null,
                  ja: fund.fundTitle?.ja || null,
                  ko: fund.fundTitle?.ko || null,
                  pt: fund.fundTitle?.pt || null,
                },
                id: fund.id,
              },
              "en",
            )
        : grantDisplay;
    }
  }, [selectedFundUniqueId, grantId, paymentType, dpmBtnMode]);

  const amountFormatted = useMemo(() => {
    if (mode === "dpm" && dpmBtnMode === "addPayment") {
      return (
        (paymentAmount &&
          formatAmountByCurrency(paymentAmount, currency as Currency)) ||
        ""
      );
    } else if (checkIsInvoiceFlow(config as Config)) {
      return (
        (paymentAmount &&
          formatAmountByCurrency(paymentAmount, currency as Currency)) ||
        ""
      );
    } else if (mode === "dpm" && dpmBtnMode === "addDonation") {
      if (multipleDonorVisibility && multipleDonorObject !== null) {
        let nonAutoInsertedRows = multipleDonorObject.filter(
          (row) => !row.isAutoInserted,
        );
        let noError = nonAutoInsertedRows.every(
          (value) => value.errorMessage.err_amount === undefined,
        );
        if (noError) {
          const amountTotalNum = nonAutoInsertedRows.reduce(
            (acc, cur) => acc + Number(cur.amount),
            0,
          );
          return (
            (amountTotalNum &&
              formatAmountByCurrency(amountTotalNum, currency as Currency)) ||
            ""
          );
        }
      } else {
        return (
          (amount && formatAmountByCurrency(amount, currency as Currency)) || ""
        );
      }
    } else {
      return (
        (amount && formatAmountByCurrency(amount, currency as Currency)) || ""
      );
    }
  }, [paymentAmount, amount, multipleDonorObject, currency, dpmBtnMode]);

  const frequencyDisplay = useMemo(() => {
    const frequency = getFrequencyById(recurringFrequencyId as string);
    return (frequency && getFrequencyName(frequency, "en")) || "";
  }, [recurringFrequencyId]);

  const startDate = useMemo(() => {
    return donationType === "recurring"
      ? recurringStartDateDetails.month
      : null;
  }, [donationType, recurringStartDateDetails]);

  const paymentSchedule = useMemo(() => {
    return donationType === "recurring"
      ? recurringStartDateDetails.description
      : null;
  }, [donationType, recurringStartDateDetails]);

  const isRecurring = useMemo(() => {
    const fund = getFundById(
      config?.funds as Funds,
      selectedFundUniqueId as number,
    );
    const recurringAvailable =
      config?.appConfigs.recurringEnabled &&
      fund?.recurring &&
      // !behalfVisibility &&
      !dedicationVisibility;
    return recurringAvailable && donationType === "recurring";
  }, [
    selectedFundUniqueId,
    dedicationVisibility,
    donationType,
    selectedFundUniqueId,
  ]);

  // To do: Make the following personal info data dynamic
  const propsAddress = {
    widgetUUID: "rotary-italy",
    street: street,
    street2: street2,
    street3: street3,
    state: billingState,
    zip: zip,
    city: billingCity,
    country: billingCountry,
    internationalProvince: internationalProvince,
  };

  const onClose = () => {
    setDonationDetailsVisibility(false);
  };

  const onEdit = () => {
    setDonationDetailsVisibility(false);
    const element = document.querySelector(".widget__container");
    const top = element && element.getBoundingClientRect().top + window.scrollY;
    top && window.scrollTo({ top, behavior: "smooth" });
  };

  const onSubmit = async () => {
    setDonationDetailsVisibility(false);
    // submitForm(); To do: Add submit form logic
    if ((window as any).rnw) {
      try {
        // Store the API promises in an array
        const apiPromises: Promise<any>[] = [];

        // Check if dedicationNotificationStatus is true before making the async call
        if (dedicationNotificationStatus) {
          // First API call: fetch eCard data
          const ecardPromise = fetchEcardLinkAndStoreData(
            dedicationType === "memory" ? 1 : 2,
            notificationType === "email" ? 1 : 2,
            dedicationName,
            donorName,
            Number(selectedDedicationImage.id),
          ).then((response) => {
            const baseURL = process.env.REACT_APP_BASE_URL;
            let ecardUrl = `${baseURL}/en/e-card/${response.data.ecardId}`;
            localStorage.setItem("eCardUrl", ecardUrl);
            setEcardLink(ecardUrl);
          });
          apiPromises.push(ecardPromise);
        }

        await Promise.all(apiPromises);

        return (
          window as any
        ).rnw.tamaro.instance.paymentForm.validateAndSend();
      } catch (error) {
        console.error("Error during form submission:", error);
      }
    }
  };

  useEffect(() => {
    const body = global.document.getElementsByTagName("body")[0];
    body.style.overflow = "hidden";
    if ((window as any).rnw) {
      const tamaroRNWidget = (window as any).rnw.tamaro.instance.paymentForm
        .data;
      setFirstName(tamaroRNWidget["stored_customer_firstname"]);
      setLastName(tamaroRNWidget["stored_customer_lastname"]);
      setPhone(tamaroRNWidget["stored_customer_phone"]);
      setEmail(tamaroRNWidget["stored_customer_email"]);

      setStreet(tamaroRNWidget["stored_customer_street_full"]);
      setStreet(tamaroRNWidget["stored_customer_street2"], 2);
      setStreet(tamaroRNWidget["stored_customer_street3"], 3);
      setBillingState(tamaroRNWidget["stored_customer_state"]);
      setZip(tamaroRNWidget["stored_customer_zip2"]);
      setBillingCity(tamaroRNWidget["stored_customer_city2"]);
      setInternationalProvince(
        tamaroRNWidget["stored_customer_international_province"],
      );
      setBillingCountry(tamaroRNWidget["stored_customer_country2"]);
    }
    return () => {
      body.style.overflow = "scroll";
    };
  }, []);
  return (
    <Wrapper onClick={onClose}>
      <WindowContainer onClick={(e) => e.stopPropagation()}>
        <Header>
          <Heading>
            {checkIsRotaryDirectPage(config as Config)
              ? "Enrollment Summary"
              : checkIsInvoiceFlow(config as Config) ||
                  (mode === "dpm" && dpmBtnMode === "addPayment")
                ? "Payment summary"
                : "Donation summary"}
          </Heading>
          <CloseBtn onClick={onClose}>
            <CloseText>Close</CloseText>
            <CloseGraphic>
              <CloseIcon />
            </CloseGraphic>
          </CloseBtn>
        </Header>
        <Main>
          <DonationGroup>
            <Subheading>
              {checkIsRotaryDirectPage(config as Config)
                ? "Enrollment"
                : checkIsInvoiceFlow(config as Config) ||
                    (mode === "dpm" && dpmBtnMode === "addPayment")
                  ? "Payment"
                  : "Donation"}
            </Subheading>
            <Content>
              {!grantsError.status &&
                !trackedGiftError.status &&
                !namedFundsError.status && (
                  <ContentRow>
                    <ContentHeading columns={true}>Support</ContentHeading>
                    <ContentText columns={true}>{fundTitle}</ContentText>
                  </ContentRow>
                )}

              <ContentRow>
                <ContentHeading columns={true}>Organisation</ContentHeading>
                <ContentText columns={true}>
                  The Rotary Foundation of Rotary International
                </ContentText>
              </ContentRow>

              {donationTypeDisplay && (
                <ContentRow>
                  <ContentHeading>Type</ContentHeading>
                  <ContentText>{donationTypeDisplay}</ContentText>
                </ContentRow>
              )}

              <ContentRow>
                <ContentHeading>Amount</ContentHeading>
                <ContentText>{`${amountFormatted} ${currency?.code.toUpperCase()}`}</ContentText>
              </ContentRow>

              {frequencyDisplay && (
                <ContentRow>
                  <ContentHeading>Frequency</ContentHeading>
                  <ContentText>{`${frequencyDisplay}`}</ContentText>
                </ContentRow>
              )}

              {startDate && (
                <ContentRow>
                  <ContentHeading>
                    {/* To do during subscription edit */}
                    {/* {(config.subscriptionEdit && 
                      Drupal.t("Next charge date")) ||
                      Drupal.t("Start date")} */}
                    Start Date
                  </ContentHeading>
                  <ContentText>{`${startDate}`}</ContentText>
                </ContentRow>
              )}

              {paymentSchedule && (
                <ContentRow>
                  <ContentHeading>Payment schedule</ContentHeading>
                  <ContentText
                    dangerouslySetInnerHTML={{
                      __html: paymentSchedule,
                    }}
                  />
                </ContentRow>
              )}
            </Content>
          </DonationGroup>
          {dedicationVisibility && dedicationName && (
            <DonationGroup>
              <Subheading>
                {dedicationType === "memory" ? "In memory of" : "In honor of"}
              </Subheading>
              <Content>
                <ContentRow>
                  <ContentHeading>
                    {dedicationType === "memory"
                      ? "Name of deceased"
                      : "Name of honoree"}
                  </ContentHeading>
                  <ContentText>{dedicationName}</ContentText>
                </ContentRow>

                {dedicationNotificationStatus && (
                  <Content>
                    {donorName && (
                      <ContentRow>
                        <ContentHeading>Name of donor</ContentHeading>
                        <ContentText>{donorName}</ContentText>
                      </ContentRow>
                    )}

                    {notificationType !== "letter" && (
                      <ContentRow>
                        <ContentHeading>Card design</ContentHeading>
                        <ContentText>
                          <DedicationImage
                            src={selectedDedicationImage.url}
                            alt={selectedDedicationImage.alt}
                          />
                        </ContentText>
                      </ContentRow>
                    )}

                    {notificationType !== "letter" && personalMessage && (
                      <ContentRow>
                        <ContentHeading>Personal message</ContentHeading>
                        <ContentText>{personalMessage}</ContentText>
                      </ContentRow>
                    )}

                    <ContentRow>
                      <ContentHeading>Notification</ContentHeading>
                      <ContentText>
                        {notificationType === "email"
                          ? "Email"
                          : notificationType === "letter"
                            ? "Mail letter"
                            : "Print and deliver it myself"}
                      </ContentText>
                    </ContentRow>

                    {notificationType === "email" && recipientEmail && (
                      <ContentRow>
                        <ContentHeading>
                          Recipient's email address
                        </ContentHeading>
                        <ContentText>{recipientEmail}</ContentText>
                      </ContentRow>
                    )}

                    {notificationType === "letter" ? (
                      ""
                    ) : notificationType === "print" ? (
                      <ContentNote>
                        You will be able to print and deliver your personalized
                        notification after you complete your donation
                      </ContentNote>
                    ) : (
                      <ContentNote>
                        Rotary will send the recipient an email with a link to
                        your personalized notification after your donation is
                        completed
                      </ContentNote>
                    )}
                  </Content>
                )}
              </Content>
            </DonationGroup>
          )}

          {/* To do: Behalf */}
          {/* {behalfVisibility && (
            <DonationGroup>
              <Subheading>{Drupal.t("Donation from")}</Subheading>
              <Content>
                {isDonatingOnBehalfOfClub && (
                  <ContentRow>
                    <ContentHeading>{Drupal.t("Club")}</ContentHeading>
                    {clubType == "Rotary Club" && (
                      <ContentText
                        dangerouslySetInnerHTML={{
                          __html: Drupal.t("Rotary Club of @location", {
                            "@location": clubName,
                          }),
                        }}
                      ></ContentText>
                    )}
                    {clubType == "Rotaract Club" && (
                      <ContentText
                        dangerouslySetInnerHTML={{
                          __html: Drupal.t("Rotaract Club of @location", {
                            "@location": clubName,
                          }),
                        }}
                      ></ContentText>
                    )}
                  </ContentRow>
                )}
                {isDonatingOnBehalfOfClubMembers && (
                  <ContentRow>
                    <ContentHeading>{Drupal.t("Club members")}</ContentHeading>
                    {clubType == "Rotary Club" && (
                      <ContentText
                        dangerouslySetInnerHTML={{
                          __html: Drupal.t("Rotary Club of @location", {
                            "@location": clubName,
                          }),
                        }}
                      ></ContentText>
                    )}
                    {clubType == "Rotaract Club" && (
                      <ContentText
                        dangerouslySetInnerHTML={{
                          __html: Drupal.t("Rotaract Club of @location", {
                            "@location": clubName,
                          }),
                        }}
                      ></ContentText>
                    )}
                  </ContentRow>
                )}
                {isDonatingOnBehalfOfClubMembers && !!clubMembers.length && (
                  <ContentRow table>
                    <ContentTable>
                      {clubMembers.map(({ first_name, last_name, amount }) => (
                        <ContentTableRow key={last_name + first_name}>
                          <ContentMember>
                            {first_name} {last_name}
                          </ContentMember>
                          <ContentMemberAmt>
                            {formatAmountByCurrency(amount, currency)}{" "}
                            {currencyCode}
                          </ContentMemberAmt>
                        </ContentTableRow>
                      ))}
                    </ContentTable>
                    <ContentTableRow>
                      <ContentMember>{Drupal.t("Total")}</ContentMember>
                      <ContentMemberAmt>
                        {amountFormatted} {currencyCode}
                      </ContentMemberAmt>
                    </ContentTableRow>
                  </ContentRow>
                )}
              </Content>
            </DonationGroup>
          )} */}

          <DonationGroup>
            <Subheading>Personal information</Subheading>
            <Content>
              <ContentRow>
                <ContentHeading>Name</ContentHeading>
                <ContentText>
                  {firstName} {lastName}
                </ContentText>
              </ContentRow>

              <ContentRow>
                <ContentHeading>Phone number</ContentHeading>
                <ContentText>{phone}</ContentText>
              </ContentRow>

              <ContentRow>
                <ContentHeading>Billing address</ContentHeading>
                <ContentText>
                  <Address {...propsAddress} />
                </ContentText>
              </ContentRow>

              <ContentRow>
                <ContentHeading>Email address</ContentHeading>
                <ContentText>{email}</ContentText>
              </ContentRow>
            </Content>

            {isRecurring && (
              <ContentNote>
                You may receive emails from Rotary International regarding your
                recurring gift arrangement.
              </ContentNote>
            )}
          </DonationGroup>
        </Main>
        <Footer>
          <FooterInner>
            <FooterBtn onClick={onEdit}>
              {/* To do: add Subscription edit logic */}
              {/* {(config.subscriptionEdit && Drupal.t("Edit my changes")) ||
                (checkIsRotaryDirectPage(config)
                  ? Drupal.t("Edit my enrollment")
                  : checkIsInvoiceFlow(config) ||
                      (config.dpmMode && config.dpm.btnMode == "addPayment")
                    ? Drupal.t("EDIT MY TRANSACTION")
                    : Drupal.t("Edit my donation"))} */}
              {checkIsRotaryDirectPage(config as Config)
                ? "Edit my enrollment"
                : checkIsInvoiceFlow(config as Config) ||
                    (mode === "dpm" && dpmBtnMode === "addPayment")
                  ? "EDIT MY TRANSACTION"
                  : "Edit my donation"}
            </FooterBtn>
            <FooterBtn onClick={onSubmit} primary>
              {/* {(config.subscriptionEdit && Drupal.t("Submit changes")) ||
                Drupal.t("Submit")} */}
              Submit
            </FooterBtn>
          </FooterInner>
        </Footer>
      </WindowContainer>
    </Wrapper>
  );
};

export default DonationDetails;
