import { css, styled } from "@mui/system";
import { bp, color, fontSize, pxToRem } from "shared/styles";

interface ContentProps {
  confirmation?: boolean;
  columns?: boolean;
}

export const Content = styled("div")<ContentProps>`
  margin-bottom: ${pxToRem(6)};

  ${bp("sm")`
    margin-bottom: 0;
  `}

  ${(props) =>
    props.confirmation &&
    css`
      ${bp("s_up_rn")`
      width: calc(100% / 3 * 2);
    `}
    `};

  ${(props) =>
    props.columns &&
    css`
      ${bp("xs")`
      display: flex;
      padding-bottom: 0.25rem;
    `}
    `};
`;

interface ContentRowProps {
  table?: boolean;
}

export const ContentRow = styled("div")(({ theme }) => ({
  borderBottom: `1px solid ${color.greyCobble}`,
  color: color.navy,
  padding: `${pxToRem(11)} 0`,
  "&:last-child": {
    borderBottom: 0,
  },
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    flexDirection: "row",
  },
  [theme.breakpoints.down("sm")]: {
    display: "block",
    padding: "0.438rem 0",
  },
}));

interface ContentHeadingProps {
  columns?: Boolean;
}
export const ContentHeading = styled("p")<ContentHeadingProps>`
  margin: 0;
  width: 100%;
  font-weight: 700;
  line-height: 1.5;
`;

interface ContentTextProps {
  columns?: Boolean;
}
export const ContentText = styled("div")<ContentTextProps>`
  width: 100%;
  font-weight: 400;
  color: ${color.black};
  line-height: 1.5;
  word-wrap: break-word;
`;

export const ContentNote = styled("div")`
  font-size: 0.875rem;
  line-height: 1.25rem;
  background-color: ${color.blueLight};
  padding: ${pxToRem(24)} ${pxToRem(32)};

  ${bp("sm")`
    font-size: 0.875rem;
    line-height: 1.5rem;
  `}
  span {
    color: ${color.navy};
    font-weight: bold;
  }
`;
