import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/system";
import useActions from "Hooks/useActions";
import recurringFrequencies from "shared/data/recurringFrequencies";
import Select from "components/Form/components/Select";
import { getFrequencyName } from "shared/utils";
import { RootState } from "shared/state/store";
import { Currencies, Frequency, SelectOptions } from "shared/types";

const DonationFrequencyWrapper = styled("div")`
  .field {
    margin-bottom: 0;
  }
`;

const DonationFrequency = () => {
  const { setFrequencyError, setRecurringFrequency, setRecurringInterval } =
    useActions();
  const [placeholder, setPlaceholder] = useState("Select");
  const { recurringFrequencyId, config } = useSelector(
    (state: RootState) => state.app,
  );
  const { frequencyError } = useSelector((state: RootState) => state.error);
  const selectedValue = recurringFrequencyId ? recurringFrequencyId : null;

  const setFrequency = (event: SelectOptions) => {
    setRecurringFrequency(
      config?.currencies as Currencies,
      event.frequency as Frequency,
    );

    if (typeof event.label === "string") {
      setPlaceholder(event.label);
    }

    setFrequencyError(false, false);
    setRecurringInterval(event.frequency?.interval);
  };

  const options = recurringFrequencies.map((frequency) => ({
    label: getFrequencyName(frequency, "en"),
    value: frequency.id,
    frequency: frequency,
  }));

  useEffect(() => {
    setFrequencyError(false, false);
    return function cleanup() {
      setFrequencyError(false, false);
    };
  }, []);

  return (
    <DonationFrequencyWrapper>
      <Select
        id="DonationFrequency"
        label="Choose frequency"
        value={recurringFrequencyId}
        placeholder={placeholder}
        options={options}
        onChange={(event) => {
          setFrequency(event);
        }}
        required
        error={frequencyError.visibility && frequencyError.status}
        errorMessages={["You must select a frequency."]}
      />
    </DonationFrequencyWrapper>
  );
};

export default DonationFrequency;
