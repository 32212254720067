import OverrideCreditOrgHeader from "./Header";
import OverrideCreditOrgGridRow from "./GridRow";
import "./styles.css";

const OverrideCreditOrgGrid = () => {
  return (
    <div className="oco-container flex-host">
      <OverrideCreditOrgHeader />
      <div className="oco-body">
        <OverrideCreditOrgGridRow />
      </div>
    </div>
  );
};

export default OverrideCreditOrgGrid;
