import reactSelect from "react-select";
import FormItem from "./FormItem";
import { SelectOptions } from "shared/types";

interface SelectProps {
  id: string;
  value?: string | null | number;
  onChange: (event: SelectOptions) => void;
  type?: string;
  label?: string;
  options: SelectOptions[] | null;
  placeholder?: string | null;
  required?: boolean;
  showActiveSelect?: boolean;
  error?: boolean;
  errorMessages?: string[];
}

const Select = ({
  id,
  value,
  options,
  onChange,
  placeholder,
  label = "",
  required = false,
  showActiveSelect = false,
  error = false,
  errorMessages = [],
  ...otherProps
}: SelectProps) => {
  const defaultOptions = {
    placeholder: placeholder,
    clearable: false,
    searchable: true,
  };
  const selectOptions = {
    ...defaultOptions,
    ...otherProps,
  };

  return (
    <FormItem
      FormElement={reactSelect}
      id={id}
      label={label}
      value={value}
      options={options}
      onChange={onChange}
      required={required}
      error={error}
      errorMessages={errorMessages}
      {...selectOptions}
    />
  );
};

export default Select;
