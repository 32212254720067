import { useState } from "react";
import TextInput from "components/Form/components/TextInput";
import Select from "components/Form/components/Select";
import { styled } from "@mui/system";
import throbber from "shared/assets/throbber.gif";
import {
  typeMappingWithId,
  typeOptions,
  validators,
} from "../../utils/utils.overrideCreditOrg";
import { pxToRem, errorIconStandalone } from "shared/styles";
import { useSelector } from "react-redux";
import { RootState } from "shared/state/store";
import "./styles.css";
import { fetchDonorNameByTypeAndMemberId } from "shared/utils/api";
import useActions from "Hooks/useActions";

const LoadingVisual = styled("div")`
  background: url(${throbber}) no-repeat;
  height: 30px;
  margin: 0rem auto -1.2rem;
  width: 30px;
`;

const ErrorIcon = styled("div")`
  ${errorIconStandalone}
  position: absolute;
  display: block;
  top: ${pxToRem(8)};
  right: -30px;
`;

interface GridData {
  id: string | null;
  name: string;
  selectedType: string;
  errorMessage: string;
  isLoading: boolean;
}

const OverrideCreditOrgGridRow = () => {
  const {
    setOverrideCreditOrgId,
    setOverrideCreditOrgType,
    setOverrideCreditOrgValidStatus,
    setOverrideCreditOrgError,
  } = useActions();
  const [placeholder, setPlaceholder] = useState("Select");
  const [gridData, setGridData] = useState<GridData>({
    id: null,
    name: "",
    selectedType: "",
    errorMessage: "",
    isLoading: false,
  });
  const { overrideCreditOrgError } = useSelector(
    (state: RootState) => state.error,
  );
  const setGlobalState = (
    id: string | null,
    type: number | null,
    validStatus: boolean,
  ) => {
    setOverrideCreditOrgId(id);
    setOverrideCreditOrgType(type);
    setOverrideCreditOrgValidStatus(validStatus);
  };
  const getDonorName = (type: string, id: string) => {
    if (type !== "" && id.length !== 0) {
      setGridData((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
    }
    fetchDonorNameByTypeAndMemberId(type, id)
      .then((data) => {
        if (data.status === 200) {
          setOverrideCreditOrgError(false, false);
          setGridData((prevState) => ({
            ...prevState,
            name: data.data.payload.Name,
            errorMessage: "",
            isLoading: false,
          }));
          setGlobalState(data.data.payload.id, typeMappingWithId[type], true);
        } else {
          setOverrideCreditOrgError(true, true);
          setGridData((prevState) => ({
            ...prevState,
            name: "",
            errorMessage: "No matching record found.",
            isLoading: false,
          }));
          setGlobalState(null, null, false);
        }
      })
      .catch((error) => {
        setGridData((prevState) => ({
          ...prevState,
          name: "",
          errorMessage: "No matching record found.",
          isLoading: false,
        }));
        setGlobalState(null, null, false);
        setOverrideCreditOrgError(true, true);
      });
  };
  return (
    <div className="row-container">
      <div
        className={
          "oco-row flex-host" +
          (overrideCreditOrgError.status && overrideCreditOrgError.visibility
            ? " oco-row-error"
            : "")
        }
      >
        <div
          className={
            "oco-col data" +
            (overrideCreditOrgError.status && overrideCreditOrgError.visibility
              ? " oco-type-col-error"
              : "")
          }
        >
          <Select
            id="OcoDonorType"
            placeholder={placeholder}
            options={typeOptions}
            value={gridData.selectedType}
            onChange={(event) => {
              setPlaceholder(event.label);
              setGridData((prevState) => ({
                ...prevState,
                selectedType: event.value as string,
              }));
              if (
                event.value !== undefined &&
                event.value.toString().length !== 0 &&
                validators.isValidId(gridData.id as string)
              ) {
                getDonorName(event.value as string, gridData.id as string);
              }
            }}
            error={
              overrideCreditOrgError.status && overrideCreditOrgError.visibility
            }
          />
        </div>
        <div
          className={
            "oco-col data" + (gridData.errorMessage ? " oco-id-col-error" : "")
          }
        >
          {gridData.selectedType ? (
            <div>
              <TextInput
                maxLength="12"
                value={gridData.id || ""}
                type="text"
                onChange={(event) => {
                  setGridData((prevState) => ({
                    ...prevState,
                    id: event.target.value as string,
                  }));
                  if (event.target.value !== undefined) {
                    const newId = event.target.value?.trim();
                    if (newId !== "" && validators.isValidId(newId as string)) {
                      setGridData((prevState) => ({
                        ...prevState,
                        errorMessage: "",
                        id: newId as string,
                      }));
                      setOverrideCreditOrgError(false, false);
                    } else {
                      setGridData((prevState) => ({
                        ...prevState,
                        name: "",
                        errorMessage:
                          "Please enter a numeric value that does not start with zero.",
                      }));
                      setGlobalState(null, null, false);
                      setOverrideCreditOrgError(true, true);
                      setOverrideCreditOrgError(true, true);
                    }
                  }
                }}
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                  if (event.target.value && gridData.errorMessage === "") {
                    getDonorName(
                      gridData.selectedType,
                      event.target.value.trim(),
                    );
                  }
                }}
              />
            </div>
          ) : (
            <label />
          )}
        </div>
        <div
          className={
            "oco-col data" +
            (overrideCreditOrgError.status && overrideCreditOrgError.visibility
              ? " oco-name-col-error"
              : "")
          }
          title={typeof gridData.name != "undefined" ? gridData.name : ""}
        >
          <label>
            {gridData.isLoading ? (
              <LoadingVisual />
            ) : typeof gridData.name != "undefined" ? (
              gridData.name
            ) : (
              ""
            )}
          </label>
        </div>
        {gridData.errorMessage && <ErrorIcon />}
      </div>

      {gridData.errorMessage && (
        <div className="oco-error">{gridData.errorMessage}</div>
      )}
    </div>
  );
};

export default OverrideCreditOrgGridRow;
