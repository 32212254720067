import { ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { styled, css } from "@mui/system";
import TextInput from "components/Form/components/TextInput";
import { bp, color, fieldLabel, fontSize } from "shared/styles";
import useActions from "Hooks/useActions";
import selected from "shared/assets/selected.svg";
import unselected from "shared/assets/unselected.svg";
import {
  checkIsInvoiceFlow,
  getAmountPlaceholder,
  getAmountsForCurrency,
  getCurrencyById,
  updateErrorInLocalStorage,
  validatorsForAmount,
} from "shared/utils";
import { Config, Currency, Currencies } from "shared/types";
import { RootState } from "shared/state/store";
import { useMemo } from "react";
import RecognitionAmount from "components/RecognitionAmount";
import { validateCustomAmount } from "shared/utils/inputValidation";

const StyledAmount = styled("div")``;

const FixedAmountLabel = styled("label")`
  ${fieldLabel()}
`;

const FixedAmountList = styled("div")(({ theme }) => ({
  fontWeight: "700",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginLeftop: "-10px",
  marginTop: "-10px",
  marginBottom: "30px",
  [theme.breakpoints.down("md")]: {
    justifyContent: "flex-start",
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: "-16px",
    marginTop: "-16px",
  },
}));

interface FixedAmountOptionProps {
  active: boolean;
}

const FixedAmountOption = styled("label")<FixedAmountOptionProps>`
  background: ${color.white};
  border: 1px solid ${color.navy};
  box-sizing: border-box;
  cursor: pointer;
  font-weight: inherit;
  display: block;
  margin: 20px 0 0 20px;
  position: relative;
  text-align: center;
  transition: all 0.3s ease;
  margin-right: 20px;

  ${bp("xs")`
    margin: 16px 0 0 16px;
  `} &:first-of-type:nth-last-of-type(4), &:first-of-type:nth-last-of-type(4) ~ label {
    ${fontSize(20)} width: calc(50% - 10px);

    ${bp("xs")`
      font-size: 1rem
      width: calc(24.98% - 16px);
    `} ${bp("sm")`
      font-size: 1.5rem
    `};
  }

  /* Five options */
  &:first-of-type:nth-last-of-type(5),
  &:first-of-type:nth-last-of-type(5) ~ label {
    ${fontSize(14)} width: calc(33% - 10px);
    margin: 20px 0 0 20px;
    ${bp("xs")`
      font-size: 1.25rem
      width: calc(33% - 16px);
    `} ${bp("sm")`
      width: calc(19.98% - 26px);
    `};
  }

  &:before {
    content: "";
    float: left;
    padding-top: 100%;
  }

  &:hover {
    background-color: ${color.blueSkyLight};
    @media (pointer: coarse) {
      background-color: transparent;
    }
  }

  ${(props) =>
    props.active &&
    css`
      color: ${color.white};
      background: ${color.blue};
      border: 1px solid ${color.blue};
      &:hover {
        background: ${color.blue};
        color: ${color.white};
      }
    `};
`;

const FixedAmountOptionFlex = styled("div")`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const FixedAmountCurrency = styled("span")`
  ${fontSize(12)}
  left: 3px;
  line-height: 20px;
  position: absolute;
  top: 0px;

  ${bp("xs")`
      font-szie: 0.875;
    `};
`;

const FixedAmountInput = styled("input")`
  display: none;
`;

interface AmountNumberProps {
  active: boolean;
}

const AmountNumber = styled("span")<AmountNumberProps>(({ theme, active }) => ({
  backgroundSize: active ? "24px 20px" : "20px 20px",
  backgroundImage: active ? `url(${selected})` : `url(${unselected})`,
  backgroundPosition: active ? "calc(50% + 2px) top" : "center top",
  backgroundRepeat: "no-repeat",
  color: active ? color.white : color.navy,
  lineHeight: "1em",
  marginTop: "10px",
  minWidth: "46px",
  padding: "20px 8px 0",
  [theme.breakpoints.up("xs")]: {
    padding: "30px 8px 0",
  },
}));

const Amount = () => {
  const {
    setAmountError,
    showCustomAmount,
    setFixedAmount,
    setCustomAmountValue,
    setPaymentAmount,
    setPaymentAmountError,
  } = useActions();
  const {
    currencyId,
    customAmountShown,
    customAmount,
    fixedAmount,
    recurringFrequencyId,
    config,
  } = useSelector((state: RootState) => state.app);
  const { amountError, paymentAmountError } = useSelector(
    (state: RootState) => state.error,
  );

  const { paymentAmount, payInvoiceBillingCurrency, payInvoiceExchangeRate } =
    useSelector((state: RootState) => state.staffUseOnly);

  const getDefaultAmounts = (currencyId: string) => {
    const amounts = getAmountsForCurrency(currencyId);
    return amounts.oneTime.options;
  };

  const getRecurringAmounts = (currency: Currency, frequencyId: string) => {
    const amounts = getAmountsForCurrency(currency.id);

    if (
      amounts &&
      amounts.recurring[frequencyId as keyof typeof amounts.recurring]
    ) {
      return amounts.recurring[frequencyId as keyof typeof amounts.recurring]
        .options;
    } else {
      // Handle the case where the frequencyId doesn't exist, e.g., return a default value or throw an error.
      throw new Error(
        "Recurring amounts not found for the specified frequencyId.",
      );
    }
  };

  const setCustomAmount = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const numericValue = parseFloat(inputValue);
    if (!validateCustomAmount(event.target.value)) {
      setAmountError(true, true);
    } else {
      setAmountError(false, false);
      updateErrorInLocalStorage("amountError", false, false);
    }
    const customAmountHasChanged = numericValue !== customAmount;
    if (customAmountHasChanged) {
      setCustomAmountValue(numericValue);
    }
  };

  const onCustomAmountBlur = () => {
    if (typeof localStorage.getItem("customAmount") === "string") {
      setAmountError(false, true);
    }
  };

  const currency = useMemo(
    () => config && getCurrencyById(config.currencies, currencyId),
    [currencyId],
  );

  const amounts = useMemo(() => {
    if (recurringFrequencyId) {
      return currency && getRecurringAmounts(currency, recurringFrequencyId);
    } else return getDefaultAmounts(currencyId);
  }, [currencyId, recurringFrequencyId]);

  let btnMode = "addDonation";
  if (
    config &&
    ((config.appConfigs.mode === "dpm" && btnMode == "addPayment") ||
      checkIsInvoiceFlow(config))
  ) {
    let wholeNum: string;
    const validateCommaAndDott = {
      removeCommaAndDott: (amount: string) => {
        if (/[.,]$/.test(amount) === true) {
          wholeNum = amount.replace(/[.,]$/, "");
          return wholeNum;
        }
      },
      addZeroAtEnd: (amount: string) => {
        if (/([.,]\d{1})$/.test(amount) === true) {
          wholeNum = amount.concat("0");
          return wholeNum;
        }
      },
    };
    const toCurrency = getCurrencyById(
      config?.currencies as Currencies,
      currencyId,
    );
    return (
      <StyledAmount>
        <TextInput
          id="paymentAmount"
          value={paymentAmount}
          onChange={({ target: { value } }) => {
            const acceptedVal =
              validatorsForAmount.regexTillTwoDecimalDigit(value);
            setPaymentAmount(acceptedVal);
            if (
              validatorsForAmount.regexZeroWithDecimalNum(acceptedVal) ===
                true ||
              validatorsForAmount.regexFloatingNum(acceptedVal) === true
            ) {
              if (validateCommaAndDott.removeCommaAndDott(acceptedVal)) {
                setPaymentAmount(wholeNum);
              } else if (validateCommaAndDott.addZeroAtEnd(acceptedVal)) {
                setPaymentAmount(wholeNum);
              }
              setPaymentAmountError(false, false);
              setPaymentAmount(acceptedVal);
            } else {
              setPaymentAmountError(true, true);
            }
          }}
          onBlur={({ target: { value } }) => {
            if (
              validatorsForAmount.regexZeroWithDecimalNum(value) === true ||
              validatorsForAmount.regexFloatingNum(value) === true
            ) {
              if (validateCommaAndDott.removeCommaAndDott(value)) {
                setPaymentAmount(wholeNum);
              } else if (validateCommaAndDott.addZeroAtEnd(value)) {
                setPaymentAmount(wholeNum);
              }
            }
          }}
          label={
            checkIsInvoiceFlow(config as Config)
              ? `Enter amount in ${toCurrency.code.toUpperCase()}`
              : "Enter amount"
          }
          required
          error={paymentAmountError.visibility && paymentAmountError.status}
          errorMessages={["Please enter a valid amount"]}
          placeholder={getAmountPlaceholder(toCurrency)}
        ></TextInput>
        {payInvoiceBillingCurrency !== toCurrency.code.toUpperCase() && (
          <RecognitionAmount
            currency={currency as Currency}
            showInfo
            currencies={config && config.currencies}
            rates={config && config.rates}
          />
        )}
      </StyledAmount>
    );
  } else {
    return (
      <StyledAmount>
        <FixedAmountLabel>Select an amount</FixedAmountLabel>
        <FixedAmountList>
          {amounts &&
            amounts.map((value) => (
              <FixedAmountOption key={value} active={fixedAmount === value}>
                <FixedAmountOptionFlex>
                  <FixedAmountInput
                    type="radio"
                    name="amount"
                    value={value}
                    checked={fixedAmount === value}
                    onChange={() => {
                      const fixedAmountHasChanged = value !== fixedAmount;
                      if (fixedAmountHasChanged) {
                        setFixedAmount(value);
                        setAmountError(false, false);
                        updateErrorInLocalStorage("amountError", false, false);
                      }
                    }}
                  />
                  <FixedAmountCurrency>
                    {currency && currency.code.toUpperCase()}
                  </FixedAmountCurrency>
                  <AmountNumber active={fixedAmount === value}>
                    {value}
                  </AmountNumber>
                </FixedAmountOptionFlex>
              </FixedAmountOption>
            ))}
          <FixedAmountOption active={customAmountShown}>
            <FixedAmountOptionFlex>
              <FixedAmountInput
                type="radio"
                name="amount"
                value={"Other"}
                checked={customAmountShown}
                onChange={() => {
                  showCustomAmount();
                  setAmountError(true, false);
                  updateErrorInLocalStorage("amountError", true, true);
                }}
              />
              <FixedAmountCurrency>
                {currency && currency.code.toUpperCase()}
              </FixedAmountCurrency>
              <AmountNumber active={customAmountShown}>{"Other"}</AmountNumber>
            </FixedAmountOptionFlex>
          </FixedAmountOption>
        </FixedAmountList>
        {customAmountShown && (
          <TextInput
            id="CustomAmount"
            type="text"
            value={customAmount || ""}
            onChange={setCustomAmount}
            onBlur={onCustomAmountBlur}
            label={"Enter other amount"}
            required
            error={amountError.visibility && amountError.status}
            errorMessages={[
              "Please enter a number without commas, decimals, or other characters. (For example, enter 125 but not 125.00.)",
            ]}
            // autoFocus
          />
        )}
        <RecognitionAmount
          currency={currency as Currency}
          showInfo
          currencies={config && config.currencies}
          rates={config && config.rates}
        />
      </StyledAmount>
    );
  }
};
export default Amount;
