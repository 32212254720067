import { css } from "@mui/system";

export const globalReset = () => css`
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
`;

export const resetBody = () => css`
  margin: 0;
`;

export const resetButton = () => css`
  background: none;
  background-color: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  &:hover,
  &:focus {
    background-color: inherit;
    outline: none;
  }
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`;

export const resetInput = () => css`
  box-shadow: none;
  margin: 0;
  outline: none;
  -webkit-appearance: none;
  &:focus {
    box-shadow: inherit;
    outline: none;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;
