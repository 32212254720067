import { ChangeEvent } from "react";
import { styled } from "@mui/system";

import { Checkbox } from "@mui/material";

const CustomCheckbox = styled(Checkbox)({
  "& .MuiSvgIcon-root": {
    color: "#3f51b5",
  },
});

interface CustomCheckboxProps {
  id?: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const CustomCheckboxComponent = (props: CustomCheckboxProps) => {
  return (
    <CustomCheckbox
      id={props.id}
      checked={props.checked}
      onChange={props.onChange}
      disableRipple={true}
    />
  );
};

export default CustomCheckboxComponent;
