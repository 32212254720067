import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      <button onClick={() => changeLanguage("en")}>English</button>
      <button onClick={() => changeLanguage("fr")}>Français</button>
      <button onClick={() => changeLanguage("de")}>German</button>
      <button onClick={() => changeLanguage("es")}>Spanish</button>
      <button onClick={() => changeLanguage("ko")}>Korea</button>
      <button onClick={() => changeLanguage("pt")}>Portuguese</button>
      <button onClick={() => changeLanguage("it")}>Italian</button>
      <button onClick={() => changeLanguage("ja")}>Japanese</button>
    </div>
  );
};

export default LanguageSwitcher;
