import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/system";
import useActions from "Hooks/useActions";
import { color, pxToRem } from "shared/styles";
import paymentSchedule from "shared/assets/payment-schedule.svg";
import { Frequency, SelectOptions } from "shared/types";
import Select from "components/Form/components/Select";
import { getIntervals } from "shared/recurring";
import { RootState } from "shared/state/store";
import { setItemsInLocalStorage, setValueFromLocalStorage } from "shared/utils";

const DonationStartWrapper = styled("div")`
  margin-bottom: ${pxToRem(30)};
  .field {
    margin-bottom: 0;
  }
  .field-label {
    margin-top: ${pxToRem(30)};
  }
`;

const DescriptionWrapper = styled("div")(({ theme }) => ({
  backgroundColor: color.white,
  border: `1px solid ${color.navy}`,
  padding: pxToRem(14),
  width: "100%",
  boxSizing: "border-box",
  [theme.breakpoints.up("xs")]: {
    display: "table",
    minHeight: pxToRem(97),
    padding: `${pxToRem(26)} ${pxToRem(30)}`,
    boxSizing: "border-box",
    "&: before": {
      backgroundColor: color.blue,
      backgroundImage: `url(${paymentSchedule})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "auto 22px",
      borderRadius: "22px",
      content: '""',
      float: "left",
      height: pxToRem(45),
      marginRight: pxToRem(20),
      width: pxToRem(45),
    },
  },
}));

const Description = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    display: "table-cell",
    lineHeight: "1.5rem",
    verticalAlign: "middle",
  },
}));

interface DonationStartProps {
  frequency: Frequency;
}

const DonationStart = ({ frequency }: DonationStartProps) => {
  const { setRecurringInterval, setRecurringStartDateDetails } = useActions();
  const [startDate, setStartDate] = useState<SelectOptions | null>(null);
  const [placeholder, setPlaceholder] = useState("Select");
  const [description, setDescription] = useState<string | null>(null);
  const { recurringFrequencyId, recurringInterval, config } = useSelector(
    (state: RootState) => state.app,
  );
  //For edit subscriptions uncomment this
  // const options = subscriptionEdit ?
  // getEditIntervals(frequency, raiseNowUtcOffset) :
  // getIntervals(frequency, raiseNowUtcOffset);
  const options = useMemo(() => {
    return (
      config && getIntervals(frequency, config.appConfigs.raiseNowUtcOffset)
    );
  }, [frequency]);
  const setStart = (event: SelectOptions) => {
    setItemsInLocalStorage("ruccuringStartDate", event, "json");
    setStartDate(event);
    setPlaceholder(event.label);
    setRecurringInterval(event.interval);
    setRecurringStartDateDetails(event.label, event.description as string);
    event.description && setDescription(event.description);
  };

  useEffect(() => {
    //To do post base work, for subscriptionedit
    if (!startDate && options) {
      // if (!subscriptionEdit) {
      // New subscriptions: Select the first available option.
      setStartDate(
        setValueFromLocalStorage("ruccuringStartDate", "json", options[0]),
      );
      setPlaceholder(
        setValueFromLocalStorage("ruccuringStartDate", "json", options[0])
          .label,
      );
      setDescription(
        setValueFromLocalStorage("ruccuringStartDate", "json", options[0])
          .description,
      );
      // }
      // else {
      //   // Existing subscription: pick a matching start date.

      //   // The interval is always the same.
      //   if (frequency.id === 'monthly') {
      //     setStart(options[0]);
      //     return;
      //   }

      //   // There is always an interval that includes the same set of months as
      //   // the existing subscription.
      //   if (frequency.id === 'quarterly') {
      //     const orderMonths = (cron) =>
      //       /(\d{1,2},?){4}/
      //       .exec(cron)
      //       .shift()
      //       .split(',')
      //       .sort((a, b) => a - b)
      //       .join(',');
      //     options.forEach((option, index) => {
      //       if (orderMonths(option.interval) === orderMonths(interval)) {
      //         setStart(options[index]);
      //       }
      //     });
      //   }

      //   // There is always an interval for the existing subscriptions annual
      //   // interval.
      //   if (frequency.id === 'annual') {
      //     const monthFromInterval = (cron) =>
      //       /\d (\d{1,2}) \*$/
      //         .exec(cron)[1];

      //     options.forEach((option, index) => {
      //       if (monthFromInterval(option.interval) === monthFromInterval(interval)) {
      //         setStart(options[index]);
      //       }
      //     });
      //   }
      // }
    } else {
      options && setStartDate(options[0]);
      options && setDescription(options[0].description);
      options && setPlaceholder(options[0].label);
    }
  }, [recurringFrequencyId]);

  return (
    <DonationStartWrapper>
      {frequency.id !== "monthly" && (
        <Select
          id="DonationStart"
          label="Choose start date" //for subscription edit, make it dynamic
          placeholder={placeholder}
          value={recurringInterval}
          options={options}
          onChange={(event: SelectOptions) => setStart(event)}
          required
        />
      )}
      <DescriptionWrapper>
        <Description
          dangerouslySetInnerHTML={{
            __html: description!,
          }}
        />
      </DescriptionWrapper>
    </DonationStartWrapper>
  );
};

export default DonationStart;
