import React from "react";

interface HeaderProps {
  currencyCode: string;
}

const Header = ({ currencyCode }: HeaderProps) => (
  <div className="mdg-header flex-host">
    <div className="mdg-col header">
      <label className="col-heading">Type</label>
    </div>
    <div className="mdg-col header">
      <label className="col-heading">ID</label>
    </div>
    <div className="mdg-col header">
      <label className="col-heading">Name</label>
    </div>
    <div className="mdg-col header">
      <label className="col-heading">
        Amount<span className="currency-code">({currencyCode})</span>
      </label>
    </div>
  </div>
);

export default Header;
