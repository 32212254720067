import { createSlice } from "@reduxjs/toolkit";
import { MultipleDonorObject, Rate } from "shared/types";

export interface StaffUseOnlyState {
  requestSource: string | null;
  privacyPolicyFlag: boolean;
  overrideDate: string[] | null;
  notes: string;
  qpqQuantity: string;
  recognitionDestination: { label: string; value: string } | null;
  recognitionDestinationName: string | null;
  recognitionAddressLine1: string | null;
  recognitionAddressLine2: string | null;
  recognitionAddressLine3: string | null;
  recognitionCity: string | null;
  recognitionStateProvince: string | null;
  recognitionPostalCode: string | null;
  recognitionCountry: string | null;
  recognitionPhone: string | null;
  recognitionNameOnCertificate: string | null;
  presentationDate: string[] | null;
  isAnonymous: boolean;
  overrideCreditOrgVisibility: boolean;
  overrideCreditOrgId: string | null;
  overrideCreditOrgType: number | null;
  overrideCreditOrgValidStatus: boolean;
  occasion: string | null;
  recipientName: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  addressLine3: string | null;
  city: string | null;
  province: string | null;
  postalCode: string | null;
  country: string | null;
  paymentType: number | null;
  listItemsObject: { [key: string]: string }[] | null;
  paymentAmount: number | null;
  payInvoiceBillingCurrency: string;
  payInvoiceExchangeRate: Rate[] | null;
  multipleDonorVisibility: boolean;
  multipleDonorObject: MultipleDonorObject[] | null;
  donorType: string | null;
}

const initialStateStaffUseOnly: StaffUseOnlyState = {
  requestSource: null,
  privacyPolicyFlag: false,
  overrideDate: null,
  notes: "",
  qpqQuantity: "",
  recognitionDestination: null,
  recognitionDestinationName: null,
  recognitionAddressLine1: null,
  recognitionAddressLine2: null,
  recognitionAddressLine3: null,
  recognitionCity: null,
  recognitionStateProvince: null,
  recognitionPostalCode: null,
  recognitionCountry: null,
  recognitionPhone: null,
  recognitionNameOnCertificate: null,
  presentationDate: null,
  isAnonymous: false,
  overrideCreditOrgVisibility: false,
  overrideCreditOrgId: null,
  overrideCreditOrgType: null,
  overrideCreditOrgValidStatus: false,
  occasion: null,
  recipientName: null,
  addressLine1: null,
  addressLine2: null,
  addressLine3: null,
  city: null,
  province: null,
  postalCode: null,
  country: null,
  paymentType: null,
  listItemsObject: null,
  paymentAmount: null,
  payInvoiceBillingCurrency: "",
  payInvoiceExchangeRate: null,
  multipleDonorVisibility: false,
  multipleDonorObject: null,
  donorType: null,
};

const staffUseOnlySlice = createSlice({
  name: "staffUseOnly",
  initialState: initialStateStaffUseOnly,
  reducers: {
    setRequestSource: (state, action) => {
      const { requestSource } = action.payload;
      state.requestSource = requestSource;
    },
    setPrivacyPolicyFlag: (state, action) => {
      const { privacyPolicyFlag } = action.payload;
      state.privacyPolicyFlag = privacyPolicyFlag;
    },
    setOverrideDate: (state, action) => {
      const { overrideDate } = action.payload;
      state.overrideDate = overrideDate;
    },
    setNotes: (state, action) => {
      const { notes } = action.payload;
      state.notes = notes;
    },
    setQpqQuantity: (state, action) => {
      const { qpqQuantity } = action.payload;
      state.qpqQuantity = qpqQuantity;
    },
    setRecognitionDestination: (state, action) => {
      const { recognitionDestination } = action.payload;
      state.recognitionDestination = recognitionDestination;
      state.recognitionDestinationName = null;
      state.recognitionAddressLine1 = null;
      state.recognitionAddressLine2 = null;
      state.recognitionAddressLine3 = null;
      state.recognitionCity = null;
      state.recognitionStateProvince = null;
      state.recognitionPostalCode = null;
      state.recognitionCountry = null;
      state.recognitionPhone = null;
      state.recognitionNameOnCertificate = null;
      state.presentationDate =
        recognitionDestination == null ? null : state.presentationDate;
    },
    setRecognitionDestinationName: (state, action) => {
      const { recognitionDestinationName } = action.payload;
      state.recognitionDestinationName = recognitionDestinationName;
    },
    setRecognitionCountry: (state, action) => {
      const { recognitionCountry } = action.payload;
      state.recognitionCountry = recognitionCountry;
    },
    setRecognitionStateProvince: (state, action) => {
      const { recognitionStateProvince } = action.payload;
      state.recognitionStateProvince = recognitionStateProvince;
    },
    setRecognitionAddressLine1: (state, action) => {
      const { recognitionAddressLine1 } = action.payload;
      state.recognitionAddressLine1 = recognitionAddressLine1;
    },

    setRecognitionAddressLine2: (state, action) => {
      const { recognitionAddressLine2 } = action.payload;
      state.recognitionAddressLine2 = recognitionAddressLine2;
    },

    setRecognitionAddressLine3: (state, action) => {
      const { recognitionAddressLine2 } = action.payload;
      state.recognitionAddressLine2 = recognitionAddressLine2;
    },
    setRecognitionCity: (state, action) => {
      const { recognitionCity } = action.payload;
      state.recognitionCity = recognitionCity;
    },
    setRecognitionPostalCode: (state, action) => {
      const { recognitionPostalCode } = action.payload;
      state.recognitionPostalCode = recognitionPostalCode;
    },
    setRecognitionPhone: (state, action) => {
      const { recognitionPhone } = action.payload;
      state.recognitionPhone = recognitionPhone;
    },
    setRecognitionNameOnCertificate: (state, action) => {
      const { recognitionNameOnCertificate } = action.payload;
      state.recognitionNameOnCertificate = recognitionNameOnCertificate;
    },
    setPresentationDate: (state, action) => {
      const { presentationDate } = action.payload;
      state.presentationDate = presentationDate;
    },
    setAnonymous: (state, action) => {
      const { isAnonymous } = action.payload;
      state.isAnonymous = isAnonymous;
    },
    setToggelOverrideCreditOrgVisibility: (state, action) => {
      const { overrideCreditOrgVisibility } = action.payload;
      state.overrideCreditOrgVisibility = overrideCreditOrgVisibility;
    },
    setOverrideCreditOrgId: (state, action) => {
      const { overrideCreditOrgId } = action.payload;
      state.overrideCreditOrgId = overrideCreditOrgId;
    },
    setOverrideCreditOrgType: (state, action) => {
      const { overrideCreditOrgType } = action.payload;
      state.overrideCreditOrgType = overrideCreditOrgType;
    },
    setPaymentType: (state, action) => {
      const { paymentType } = action.payload;
      state.paymentType = paymentType;
    },
    setOccasion: (state, action) => {
      const { occasion } = action.payload;
      state.occasion = occasion;
    },
    setRecipientName: (state, action) => {
      const { recipientName } = action.payload;
      state.recipientName = recipientName;
    },
    setAddressLine1: (state, action) => {
      const { addressLine1 } = action.payload;
      state.addressLine1 = addressLine1;
    },
    setAddressLine2: (state, action) => {
      const { addressLine2 } = action.payload;
      state.addressLine2 = addressLine2;
    },
    setAddressLine3: (state, action) => {
      const { addressLine3 } = action.payload;
      state.addressLine3 = addressLine3;
    },
    setCity: (state, action) => {
      const { city } = action.payload;
      state.city = city;
    },
    setProvince: (state, action) => {
      const { province } = action.payload;
      state.province = province;
    },
    setPostalCode: (state, action) => {
      const { postalCode } = action.payload;
      state.postalCode = postalCode;
    },
    setMailLetterCountry: (state, action) => {
      const { country } = action.payload;
      state.country = country;
    },
    setListItemsObject: (state, action) => {
      const { listItemsObject } = action.payload;
      state.listItemsObject = listItemsObject;
    },
    setPaymentAmount: (state, action) => {
      const { paymentAmount } = action.payload;
      state.paymentAmount = paymentAmount;
    },
    setPayInvoiceBillingCurrency: (state, action) => {
      const { payInvoiceBillingCurrency } = action.payload;
      state.payInvoiceBillingCurrency = payInvoiceBillingCurrency;
    },
    setPayInvoiceExchangeRate: (state, action) => {
      const { payInvoiceExchangeRate } = action.payload;
      state.payInvoiceExchangeRate = payInvoiceExchangeRate;
    },
    setToggleMultipleDonorVisibility: (state, action) => {
      const { multipleDonorVisibility = !state.multipleDonorVisibility } =
        action.payload;
      state.multipleDonorVisibility = multipleDonorVisibility;
    },
    setMultipleDonorObject: (state, action) => {
      const { multipleDonorObject = !state.multipleDonorObject } =
        action.payload;
      // To do
      // const multipleDonorListToPass = multipleDonorObject.map(values => {
      // if (values.selectedType && values.id && (values.first_name || values.last_name) && values.amount) {
      //   return {
      //     donor_type: values.selectedType,
      //     member_id: Number(values.id),
      //     first_name: values.first_name,
      //     last_name: values.last_name,
      //     amount: values.amount
      //   }
      // }
      // });
      // global.localStorage.setItem('multipleDonorMembersList', JSON.stringify(multipleDonorListToPass.filter(val => (val !== undefined) )));
      state.multipleDonorObject = multipleDonorObject;
    },
  },
});

export const createSetRequestSource = (requestSource: string) => ({
  type: "staffUseOnly/setRequestSource",
  payload: { requestSource },
});

export const createSetPrivacyPolicyFlag = (privacyPolicyFlag: boolean) => ({
  type: "staffUseOnly/setPrivacyPolicyFlag",
  payload: { privacyPolicyFlag },
});

export const createSetOcerrideDate = (overrideDate: string[] | null) => ({
  type: "staffUseOnly/setOverrideDate",
  payload: { overrideDate },
});

export const createSetNotes = (notes: string) => ({
  type: "staffUseOnly/setNotes",
  payload: { notes },
});

export const createSetQpqQuantity = (qpqQuantity: string) => ({
  type: "staffUseOnly/setQpqQuantity",
  payload: { qpqQuantity },
});

export const createSetRecognitionDestination = (recognitionDestination: {
  label: string;
  value: string;
}) => ({
  type: "staffUseOnly/setRecognitionDestination",
  payload: { recognitionDestination },
});

export const createSetRecognitionDestinationName = (
  recognitionDestinationName: string,
) => ({
  type: "staffUseOnly/setRecognitionDestinationName",
  payload: { recognitionDestinationName },
});

export const createSetRecognitionCountry = (recognitionCountry: string) => ({
  type: "staffUseOnly/setRecognitionCountry",
  payload: { recognitionCountry },
});

export const createSetRecognitionStateProvince = (
  recognitionStateProvince: string,
) => ({
  type: "staffUseOnly/setRecognitionStateProvince",
  payload: { recognitionStateProvince },
});

export const createSetRecognitionAddressLine1 = (
  recognitionAddressLine1: string,
) => ({
  type: "staffUseOnly/setRecognitionAddressLine1",
  payload: { recognitionAddressLine1 },
});

export const createSetRecognitionAddressLine2 = (
  recognitionAddressLine2: string,
) => ({
  type: "staffUseOnly/setRecognitionAddressLine2",
  payload: { recognitionAddressLine2 },
});

export const createSetRecognitionAddressLine3 = (
  recognitionAddressLine3: string,
) => ({
  type: "staffUseOnly/setRecognitionAddressLine3",
  payload: { recognitionAddressLine3 },
});

export const createSetRecognitionCity = (recognitionCity: string) => ({
  type: "staffUseOnly/setRecognitionCity",
  payload: { recognitionCity },
});

export const createSetRecognitionPostalCode = (
  recognitionPostalCode: string,
) => ({
  type: "staffUseOnly/setRecognitionPostalCode",
  payload: { recognitionPostalCode },
});

export const createSetRecognitionPhone = (recognitionPhone: string) => ({
  type: "staffUseOnly/setRecognitionPhone",
  payload: { recognitionPhone },
});

export const createSetRecognitionNameOnCertificate = (
  recognitionNameOnCertificate: string,
) => ({
  type: "staffUseOnly/setRecognitionNameOnCertificate",
  payload: { recognitionNameOnCertificate },
});

export const createSetPresentationDate = (
  presentationDate: string[] | null,
) => ({
  type: "staffUseOnly/setPresentationDate",
  payload: { presentationDate },
});

export const createSetAnonymous = (isAnonymous: boolean) => ({
  type: "staffUseOnly/setAnonymous",
  payload: { isAnonymous },
});

export const createSetToggelOverrideCreditOrgVisibility = (
  overrideCreditOrgVisibility: boolean,
) => ({
  type: "staffUseOnly/setToggelOverrideCreditOrgVisibility",
  payload: { overrideCreditOrgVisibility },
});

export const createSetOverrideCreditOrgId = (
  overrideCreditOrgId: string | null,
) => ({
  type: "staffUseOnly/setOverrideCreditOrgId",
  payload: { overrideCreditOrgId },
});

export const createSetOverrideCreditOrgType = (
  overrideCreditOrgType: number | null,
) => ({
  type: "staffUseOnly/setOverrideCreditOrgType",
  payload: { overrideCreditOrgType },
});

export const createSetOverrideCreditOrgValidStatus = (
  overrideCreditOrgValidStatus: boolean,
) => ({
  type: "staffUseOnly/setOverrideCreditOrgValidStatus",
  payload: { overrideCreditOrgValidStatus },
});

export const createSetPaymentType = (paymentType: number) => ({
  type: "staffUseOnly/setPaymentType",
  payload: { paymentType },
});

export const createSetOccasion = (occasion: string | null) => ({
  type: "staffUseOnly/setOccasion",
  payload: { occasion },
});

export const createSetRecipientName = (recipientName: string | null) => ({
  type: "staffUseOnly/setRecipientName",
  payload: { recipientName },
});

export const createSetAddressLine1 = (addressLine1: string | null) => ({
  type: "staffUseOnly/setAddressLine1",
  payload: { addressLine1 },
});

export const createSetAddressLine2 = (addressLine2: string | null) => ({
  type: "staffUseOnly/setAddressLine2",
  payload: { addressLine2 },
});

export const createSetAddressLine3 = (addressLine3: string | null) => ({
  type: "staffUseOnly/setAddressLine3",
  payload: { addressLine3 },
});

export const createSetCity = (city: string | null) => ({
  type: "staffUseOnly/setCity",
  payload: { city },
});

export const createSetProvince = (province: string | null) => ({
  type: "staffUseOnly/setProvince",
  payload: { province },
});

export const createSetPostalCode = (postalCode: string | null) => ({
  type: "staffUseOnly/setPostalCode",
  payload: { postalCode },
});

export const createSetMailLetterCountry = (country: string | null) => ({
  type: "staffUseOnly/setMailLetterCountry",
  payload: { country },
});

export const createSetListItemsObject = (
  listItemsObject: { [key: string]: string }[] | null,
) => ({
  type: "staffUseOnly/setListItemsObject",
  payload: { listItemsObject },
});

export const createSetPaymentAmount = (paymentAmount: string) => ({
  type: "staffUseOnly/setPaymentAmount",
  payload: { paymentAmount },
});

export const createSetPayInvoiceBillingCurrency = (
  payInvoiceBillingCurrency: string,
) => ({
  type: "staffUseOnly/setPayInvoiceBillingCurrency",
  payload: { payInvoiceBillingCurrency },
});

export const createSetPayInvoiceExchangeRate = (
  payInvoiceExchangeRate: Rate[],
) => ({
  type: "staffUseOnly/setPayInvoiceExchangeRate",
  payload: { payInvoiceExchangeRate },
});

export const createSetToggleMultipleDonorVisibility = () => ({
  type: "staffUseOnly/setToggleMultipleDonorVisibility",
  payload: {},
});

export const createSetMultipleDonorObject = (
  multipleDonorObject: MultipleDonorObject[],
) => ({
  type: "setstaffUseOnly/MultipleDonorObject",
  payload: { multipleDonorObject },
});

export default staffUseOnlySlice.reducer;
