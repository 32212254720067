import React, { ChangeEvent, FocusEvent } from "react";
import { styled } from "@mui/system";
import { inputStyling } from "shared/styles";
import FormItem from "components/Form/components/FormItem";

interface TextInputProps {
  id?: string;
  value: string | number | null;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  label?: string;
  required?: boolean;
  error?: boolean;
  errorMessages?: string[];
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  subText?: string;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  maxLength?: string;
  helpText?: string;
  placeholder?: string;
}

interface StyledInputProps {
  error: boolean;
}

const StyledInput = styled("input")<StyledInputProps>`
  ${(props) => inputStyling(props.error)};
  && {
    box-sizing: border-box;
  }
`;

const TextInput = ({
  id,
  value,
  onChange,
  type = "text",
  label,
  required = false,
  error = false,
  errorMessages = [],
  subText = "",
  onKeyPress,
  onKeyDown,
  onBlur,
  maxLength,
  placeholder,
  ...otherProps
}: TextInputProps) => {
  return (
    <FormItem
      FormElement={StyledInput}
      id={id}
      label={label}
      value={value}
      required={required}
      error={error}
      errorMessages={errorMessages}
      onChange={onChange}
      onKeyDown={onKeyDown}
      subText={subText}
      onBlur={onBlur}
      placeholder={placeholder}
      {...otherProps}
    />
  );
};

export default TextInput;
