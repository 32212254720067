import { useEffect, useRef, useState } from "react";
import { styled } from "@mui/system";
import { validationMessage } from "shared/styles";
import presetUtils from "./presetUtils";
import { useSelector } from "react-redux";
import { RootState } from "shared/state/store";
import {
  Config,
  Country,
  DedicationImages,
  Frequency,
  Fund,
  Funds,
} from "shared/types";
import {
  checkIsInvoiceFlow,
  getCountryName,
  getFrequencyById,
  getFrequencyName,
  getFundById,
  getFundByKey,
  getFundTitle,
  staffOnlyFieldsUtils,
} from "shared/utils";
import useActions from "Hooks/useActions";
import "./tamaro.css";

interface StatusMessageProps {
  type: string;
}

const StatusMessage = styled("p")<StatusMessageProps>(({ type }) =>
  validationMessage({ type }),
);

const RaiseNowWidgetTamaro = (props: any) => {
  // const [raiseNowWidgetIsLoaded, setRaiseNowWidgetIsLoaded] = useState(false);
  const {
    config,
    amount,
    currency,
    country,
    trackedGiftId,
    namedFundId,
    // selectedGrantId,
    grantId,
    fund,
    trackedGiftAliasDescription,
    trackedGiftSubFundId,
    trackedGiftFundId,
    //   mode,
    dpmBtnMode,
    donationType,
    recurringFrequencyId,
    recurringInterval,
    //   paymentWorkflowRedirect,
    clubCreditClubIDNumber,
    clubCreditClubType,
  } = useSelector((state: RootState) => state.app);
  const {
    dedicationVisibility,
    dedicationName,
    dedicationType,
    ecardLink,
    notificationType,
    dedicationNotificationStatus,
    recipientEmail,
    donorName,
    personalMessage,
    selectedDedicationImage,
    personalMessageMaxLength,
    dedicationImages,
  } = useSelector((state: RootState) => state.dedication);
  const {
    paymentAmount,
    paymentType,
    notes,
    occasion,
    recipientName,
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    province,
    postalCode,
    country: staffUseOnlyCountry,
    requestSource,
    overrideDate,
    isAnonymous,
    qpqQuantity,
    recognitionDestination,
    recognitionDestinationName,
    recognitionAddressLine1,
    recognitionAddressLine2,
    recognitionAddressLine3,
    recognitionCity,
    recognitionStateProvince,
    recognitionCountry,
    recognitionPhone,
    recognitionPostalCode,
    recognitionNameOnCertificate,
    presentationDate,
    overrideCreditOrgId,
    overrideCreditOrgValidStatus,
    overrideCreditOrgType,
    overrideCreditOrgVisibility,
    multipleDonorObject,
    multipleDonorVisibility,
    listItemsObject,
    donorType,
  } = useSelector((state: RootState) => state.staffUseOnly);
  const { guestContinue, user: userState } = useSelector(
    (state: RootState) => state.user,
  );

  const latestStateRef = useRef({
    fund,
  });

  const {
    setTransactionData,
    setAlert,
    setActiveFundTab,
    setGrantId,
    setSelectedFundUniqueId,
    setDedicationVisibility,
    setDedicationType,
    setDedicationName,
    setNotificationType,
    setRecipientEmail,
    setSelectedDedicationImage,
    setDonorName,
    setPersonalMessage,
    handleToggleDedicationNotificationStatus,
    setRaiseNowWidgetIsLoaded,
    setRaiseNowWidgetIsLoading,
    setUser,
  } = useActions();
  let preferredLang = "it";
  let lang = "it";
  const loadCSS = (href: string) => {
    const link = document.createElement("link");
    link.href = href;
    link.rel = "stylesheet";
    document.head.appendChild(link);
  };
  const configureScript = (props: any) => {
    if (!(window as any).rnw || !(window as any).rnw.tamaro) {
      return;
    }
    if (
      (window as any).rnw &&
      (window as any).rnw.tamaro &&
      (window as any).rnw.tamaro.instance &&
      (window as any).rnw.tamaro.instance.paymentForm
    ) {
      const tamaroRaiseNowWidget = (window as any).rnw.tamaro.instance
        .paymentForm;
      const date = new Date();
      tamaroRaiseNowWidget.data["stored_timezone_offset"] =
        date.getTimezoneOffset();
      /* eslint-disable no-restricted-globals */
      tamaroRaiseNowWidget.data["stored_screen_height"] = screen.height;
      tamaroRaiseNowWidget.data["stored_screen_width"] = screen.width;
      /* eslint-enable no-restricted-globals */
      if (
        props.props.createdBy &&
        (config?.appConfigs.mode === "dpm" || props.props.dmiMode)
      ) {
        if (
          props.props.dmiMode ||
          (config?.appConfigs.mode === "dpm" &&
            dpmBtnMode === "addSubscription")
        ) {
          tamaroRaiseNowWidget.data["stored_subscription_created_by"] =
            props.props.createdBy;
        } else {
          tamaroRaiseNowWidget.data["stored_transaction_created_by"] =
            props.props.createdBy;
        }
      }
      if (
        props.props.foundation &&
        props.props.foundation.bankAltName !== null
      ) {
        tamaroRaiseNowWidget.data["stored_bank_id"] =
          props.props.foundation.bankAltName;
      }

      if (config?.appConfigs.mode === "dpm" && dpmBtnMode === "addPayment") {
        const paymentFund = paymentType
          ? getFundById(config.funds, paymentType)
          : fund;
        // introduced one new field OG-3258
        tamaroRaiseNowWidget.data["stored_transaction_type_name"] = "Payment";
        tamaroRaiseNowWidget.data["stored_campaign_id"] = paymentFund?.fundId;
        tamaroRaiseNowWidget.data["stored_campaign_subid"] =
          paymentFund?.fundSubId;
        tamaroRaiseNowWidget.data["stored_customer_fund_id"] =
          paymentFund?.fundId;
        tamaroRaiseNowWidget.data["stored_customer_subfund_id"] =
          paymentFund?.fundSubId;
        // RaiseNow expects the amount to be in subunits
        tamaroRaiseNowWidget.data["amount"] = paymentAmount;
        if (notes) {
          tamaroRaiseNowWidget.data["stored_staff_notes"] = notes;
        } else {
          tamaroRaiseNowWidget.data["stored_staff_notes"] = null;
        }
      } else {
        // RaiseNow expects the amount to be in subunits
        // tamaroRaiseNowWidget.data['amount'] = amount;
        tamaroRaiseNowWidget.data["amount"] = amount;
        // Fund ID, passed to Rotary CRM
        tamaroRaiseNowWidget.data["stored_campaign_id"] = fund?.fundId;
        // Sub Fund ID, passed to Rotary CRM
        tamaroRaiseNowWidget.data["stored_campaign_subid"] = fund?.fundSubId;
        // Duplicate campaign parameters to fix main parameter values missing bug
        tamaroRaiseNowWidget.data["stored_customer_fund_id"] = fund?.fundId;
        tamaroRaiseNowWidget.data["stored_customer_subfund_id"] =
          fund?.fundSubId;
      }
      tamaroRaiseNowWidget.data["currency"] = currency?.code;

      // To do: GoB
      const isGivingOnBehalfOfClub =
        props.props.behalfVisibility &&
        props.props.behalfType === "club" &&
        props.props.club_id &&
        props.props.club_type === "Rotary Club";
      const isGivingOnBehalfOfRotaractClub =
        props.props.behalfVisibility &&
        props.props.behalfType === "club" &&
        props.props.club_id &&
        props.props.club_type === "Rotaract Club";
      // Value represents an individual donation--gets sent for all cases, including club.
      if (isGivingOnBehalfOfClub) {
        tamaroRaiseNowWidget.data["stored_customer_donor_type"] = "3";
      } else if (isGivingOnBehalfOfRotaractClub) {
        tamaroRaiseNowWidget.data["stored_customer_donor_type"] = "4";
      } else if (
        (config?.appConfigs.mode === "dpm" && donorType) ||
        checkIsInvoiceFlow(config as Config)
      ) {
        tamaroRaiseNowWidget.data["stored_customer_donor_type"] = donorType;
        if (checkIsInvoiceFlow(config as Config)) {
          // Set these values for invoice mode only.[Not for dpm]
          tamaroRaiseNowWidget.data["stored_customer_member_id"] =
            props.props.user.organization.id;
          tamaroRaiseNowWidget.data["stored_rotary_club"] =
            props.props.user.organization.id;
          tamaroRaiseNowWidget.data["stored_rotary_credorg_id"] =
            props.props.user.organization.id;
        }
      } else {
        tamaroRaiseNowWidget.data["stored_customer_donor_type"] = "1";
      }
      const isDedicating = dedicationVisibility && dedicationName;
      if (isDedicating) {
        tamaroRaiseNowWidget.data["stored_mth_recipient_occasion"] =
          dedicationType;
        tamaroRaiseNowWidget.data["stored_mth_recipient_name"] = dedicationName;
        tamaroRaiseNowWidget.data["stored_mth_ecard_url"] =
          localStorage.getItem("eCardUrl");
        if (dedicationNotificationStatus && notificationType) {
          tamaroRaiseNowWidget.data["stored_mth_delivery_method"] =
            notificationType;
        } else {
          tamaroRaiseNowWidget.data["stored_mth_delivery_method"] = null;
        }
        if (notificationType == "email") {
          tamaroRaiseNowWidget.data["stored_mth_recipient_email"] =
            recipientEmail;
        } else {
          tamaroRaiseNowWidget.data["stored_mth_recipient_email"] = null;
        }
        if (notificationType == "letter") {
          tamaroRaiseNowWidget.data["stored_mth_delivery_method"] = "letter";
          tamaroRaiseNowWidget.data["stored_mth_recipient_occassion2"] =
            occasion;
          tamaroRaiseNowWidget.data["stored_mth_recipient_name2"] =
            recipientName;
          tamaroRaiseNowWidget.data["stored_mth_recipient_street1"] =
            addressLine1;
          tamaroRaiseNowWidget.data["stored_mth_recipient_street2"] =
            addressLine2;
          tamaroRaiseNowWidget.data["stored_mth_recipient_street3"] =
            addressLine3;
          tamaroRaiseNowWidget.data["stored_mth_recipient_city"] = city;
          tamaroRaiseNowWidget.data["stored_mth_recipient_state"] = province;
          tamaroRaiseNowWidget.data["stored_mth_recipient_zip_code"] =
            postalCode;
          tamaroRaiseNowWidget.data["stored_mth_recipient_country"] =
            staffUseOnlyCountry
              ? staffUseOnlyCountry.toUpperCase()
              : staffUseOnlyCountry;
          tamaroRaiseNowWidget.data["stored_mth_ecard_url"] = null;
        }
        tamaroRaiseNowWidget.data["stored_mth_sender_name"] = donorName;
        tamaroRaiseNowWidget.data["stored_mth_recipient_message"] =
          personalMessage;
        tamaroRaiseNowWidget.data["stored_dedication_image_id"] =
          selectedDedicationImage.id;
      } else {
        tamaroRaiseNowWidget.data["stored_mth_recipient_occasion"] = null;
        tamaroRaiseNowWidget.data["stored_mth_recipient_name"] = null;
        tamaroRaiseNowWidget.data["stored_mth_delivery_method"] = null;
        tamaroRaiseNowWidget.data["stored_mth_recipient_email"] = null;
        tamaroRaiseNowWidget.data["stored_mth_sender_name"] = null;
        tamaroRaiseNowWidget.data["stored_mth_recipient_message"] = null;
        tamaroRaiseNowWidget.data["stored_mth_ecard_url"] = null;
        tamaroRaiseNowWidget.data["stored_dedication_image_id"] = null;
      }

      if (
        isDedicating &&
        dedicationNotificationStatus &&
        notificationType === "email"
      ) {
        tamaroRaiseNowWidget.data["stored_mth_suppress_notification"] = false;
      } else {
        tamaroRaiseNowWidget.data["stored_mth_suppress_notification"] = true;
      }
      // To do
      if (isGivingOnBehalfOfClub) {
        tamaroRaiseNowWidget.data["stored_rotary_club"] =
          props.props.behalf.club.club_id;
        tamaroRaiseNowWidget.data["stored_rotary_credorg_type"] = 3;
        tamaroRaiseNowWidget.data["stored_rotary_credorg_id"] =
          props.props.behalf.club.club_id;
      } else if (isGivingOnBehalfOfRotaractClub) {
        tamaroRaiseNowWidget.data["stored_rotary_credorg_type"] = 4;
        tamaroRaiseNowWidget.data["stored_rotary_credorg_id"] =
          props.props.behalf.club.club_id;
      } else if (!checkIsInvoiceFlow(config as Config)) {
        tamaroRaiseNowWidget.data["stored_rotary_club"] = null;
        tamaroRaiseNowWidget.data["stored_rotary_credorg_type"] = null;
        tamaroRaiseNowWidget.data["stored_rotary_credorg_id"] = null;
      }

      //Club Information for Club Credit (optional)
      if (!props.props.user.authenticated) {
        if (
          clubCreditClubType === "Rotary Club" &&
          clubCreditClubIDNumber.length != 0
        ) {
          tamaroRaiseNowWidget.data["stored_rotary_credorg_type"] = 3;
          tamaroRaiseNowWidget.data["stored_rotary_credorg_id"] =
            clubCreditClubIDNumber;
        } else if (
          clubCreditClubType === "Rotaract Club" &&
          clubCreditClubIDNumber.length != 0
        ) {
          tamaroRaiseNowWidget.data["stored_rotary_credorg_type"] = 4;
          tamaroRaiseNowWidget.data["stored_rotary_credorg_id"] =
            clubCreditClubIDNumber;
        }
      }

      // Global Grants
      if (grantId) {
        tamaroRaiseNowWidget.data["stored_grant_id"] = grantId;
        tamaroRaiseNowWidget.data["stored_campaign_id"] = "GRNT_GG";
        tamaroRaiseNowWidget.data["stored_campaign_subid"] = "GG_FLOWTHR";
        // RI-587
        // following backup fields - stored_customer_fund_id &
        // stored_customer_subfund_id should be kept in sync with
        // stored_campaign_id & stored_campaign_subid respectively
        tamaroRaiseNowWidget.data["stored_customer_fund_id"] = "GRNT_GG";
        tamaroRaiseNowWidget.data["stored_customer_subfund_id"] = "GG_FLOWTHR";
      } else {
        tamaroRaiseNowWidget.data["stored_grant_id"] = null;
      }

      if (namedFundId) {
        tamaroRaiseNowWidget.data["stored_tracked_gift_id"] = namedFundId;
        tamaroRaiseNowWidget.data["stored_campaign_id"] = trackedGiftFundId;
        tamaroRaiseNowWidget.data["stored_campaign_subid"] =
          trackedGiftSubFundId;
        tamaroRaiseNowWidget.data["stored_customer_fund_id"] =
          trackedGiftFundId;
        tamaroRaiseNowWidget.data["stored_customer_subfund_id"] =
          trackedGiftSubFundId;
      } else {
        tamaroRaiseNowWidget.data["stored_tracked_gift_id"] = null;
      }

      // Recurring giving
      if (donationType === "one-time" || !recurringFrequencyId) {
        // Disable recurring
        tamaroRaiseNowWidget.data["stored_recurring"] = "false";
        tamaroRaiseNowWidget.data["payment_type"] = "onetime";
        tamaroRaiseNowWidget.data["stored_donation_type"] = "onetime";
        tamaroRaiseNowWidget.data["stored_suppress_cancel_email"] = null;
        tamaroRaiseNowWidget.data["recurring_interval"] = null;
        tamaroRaiseNowWidget.data["stored_recurring_interval_name"] = null;
        tamaroRaiseNowWidget.data["stored_translated_recurring_interval"] =
          null;
        tamaroRaiseNowWidget.data["stored_transaction_creation_source"] =
          requestSource;
        tamaroRaiseNowWidget.data["stored_subscription_creation_source"] = null;
        // Send the moto flag only if it is DMI or DPM mode.
        if (props.props.dmiMode || config?.appConfigs.mode === "dpm") {
          tamaroRaiseNowWidget.data["stored_moto"] = "true";
        }
      } else {
        // Enable recurring
        const frequency = getFrequencyById(recurringFrequencyId);
        tamaroRaiseNowWidget.data["stored_recurring"] = "true";
        tamaroRaiseNowWidget.data["payment_type"] = "recurring";
        tamaroRaiseNowWidget.data["stored_donation_type"] = "recurring";
        tamaroRaiseNowWidget.data["stored_suppress_cancel_email"] = "false";

        // Schedule the charges should follow
        tamaroRaiseNowWidget.data["recurring_interval"] = recurringInterval;
        // Human readable name for interval
        tamaroRaiseNowWidget.data["stored_recurring_interval_name"] =
          getFrequencyName(frequency as Frequency, "und");
        // Translated interval name, used in emails
        let transFrequencyName = "";
        if (preferredLang) {
          transFrequencyName = getFrequencyName(
            frequency as Frequency,
            preferredLang,
          );
        } else {
          // transFrequencyName = Drupal.t(
          //   getFrequencyName(frequency as Frequency, "und"),
          //   {},
          //   { context: "OG transactional email: interval" }
          // );
          transFrequencyName = getFrequencyName(frequency as Frequency, "und");
        }
        tamaroRaiseNowWidget.data["stored_translated_recurring_interval"] =
          transFrequencyName;
        tamaroRaiseNowWidget.data["stored_transaction_creation_source"] = null;
        tamaroRaiseNowWidget.data["stored_subscription_creation_source"] =
          requestSource;
        if (props.props.dmiMode || config?.appConfigs.mode === "dpm") {
          tamaroRaiseNowWidget.data["stored_moto"] = "true";
        }
      }
      if (config?.appConfigs.mode === "invoice") {
        tamaroRaiseNowWidget.data["stored_transaction_creation_source"] = "web";
      }
      // User fields
      // The ISO2 code of the country (uppercased)
      tamaroRaiseNowWidget.data["stored_customer_country"] =
        country?.iso2.toUpperCase();
      // The country identifier to match with DIS
      tamaroRaiseNowWidget.data["stored_customer_country_en"] = getCountryName(
        country as Country,
        "und",
      );
      // Country name, translated
      tamaroRaiseNowWidget.data["stored_customer_country2"] = getCountryName(
        country as Country,
        lang,
      );
      // To do User
      if (props.props.user.authenticated) {
        if (isGivingOnBehalfOfClub) {
          tamaroRaiseNowWidget.data["stored_customer_member_id"] =
            props.props.behalf.club.club_id;
        } else if (isGivingOnBehalfOfRotaractClub) {
          tamaroRaiseNowWidget.data["stored_customer_member_id"] =
            props.props.behalf.club.club_id;
        } else {
          if (!checkIsInvoiceFlow(config as Config)) {
            tamaroRaiseNowWidget.data["stored_customer_member_id"] =
              props.props.user.member_id;
          }
        }
        // Set the member_id which gets donation credit.
        tamaroRaiseNowWidget.data["stored_merchant_customer_id"] =
          props.props.user.member_id;
      }

      // Data when editing an existing subscription
      if (props.props.raiseNowSubscriptionId) {
        tamaroRaiseNowWidget.data["stored_old_subscription"] =
          props.props.raiseNowSubscriptionId;
      }
      if (props.props.raiseNowCustomerToken) {
        tamaroRaiseNowWidget.data["stored_customer_token"] =
          props.props.raiseNowCustomerToken;
      }
      if (preferredLang) {
        tamaroRaiseNowWidget.data["language"] = preferredLang;
      }

      // DPM and Add Donation Mode
      if (config?.appConfigs.mode === "dpm" && dpmBtnMode === "addDonation") {
        if (staffOnlyFieldsUtils.isValidDate(overrideDate as string[])) {
          let readableDate = staffOnlyFieldsUtils.getReadableDate(
            overrideDate as string[],
          );
          tamaroRaiseNowWidget.data["stored_override_date"] = readableDate;
        } else {
          tamaroRaiseNowWidget.data["stored_override_date"] = null;
        }
        tamaroRaiseNowWidget.data["stored_anonymous_donation"] = isAnonymous;
        if (qpqQuantity) {
          tamaroRaiseNowWidget.data["stored_qpq_amount"] = +qpqQuantity * 25;
        } else {
          tamaroRaiseNowWidget.data["stored_qpq_amount"] = null;
        }

        if (notes) {
          tamaroRaiseNowWidget.data["stored_staff_notes"] = notes;
        } else {
          tamaroRaiseNowWidget.data["stored_staff_notes"] = null;
        }

        if (recognitionDestination) {
          tamaroRaiseNowWidget.data["stored_recognition_send_to"] =
            recognitionDestination.value;
          if (recognitionDestination.value === "Other") {
            tamaroRaiseNowWidget.data["stored_recognition_recipient_name"] =
              recognitionDestinationName;
            tamaroRaiseNowWidget.data["stored_recognition_street1"] =
              recognitionAddressLine1;
            tamaroRaiseNowWidget.data["stored_recognition_street2"] =
              recognitionAddressLine2;
            tamaroRaiseNowWidget.data["stored_recognition_street3"] =
              recognitionAddressLine3;
            tamaroRaiseNowWidget.data["stored_recognition_city"] =
              recognitionCity;
            tamaroRaiseNowWidget.data["stored_recognition_state"] =
              recognitionStateProvince;
            tamaroRaiseNowWidget.data["stored_recognition_country"] =
              recognitionCountry
                ? recognitionCountry?.toUpperCase()
                : recognitionCountry;
            tamaroRaiseNowWidget.data["stored_recognition_phone"] =
              recognitionPhone;
            tamaroRaiseNowWidget.data["stored_recognition_zip_code"] =
              recognitionPostalCode;
          }
          tamaroRaiseNowWidget.data["stored_recognition_name_certificate"] =
            recognitionNameOnCertificate;
          if (staffOnlyFieldsUtils.isValidDate(presentationDate as string[])) {
            let readableDate = staffOnlyFieldsUtils.getReadableDate(
              presentationDate as string[],
            );
            tamaroRaiseNowWidget.data["stored_recognition_presentation_date"] =
              readableDate;
          } else {
            tamaroRaiseNowWidget.data["stored_recognition_presentation_date"] =
              null;
          }
        } else {
          tamaroRaiseNowWidget.data["stored_recognition_send_to"] = null;
          tamaroRaiseNowWidget.data["stored_recognition_presentation_date"] =
            null;
        }

        if (overrideCreditOrgVisibility && overrideCreditOrgValidStatus) {
          tamaroRaiseNowWidget.data["stored_rotary_credorg_type"] =
            overrideCreditOrgType;
          tamaroRaiseNowWidget.data["stored_rotary_credorg_id"] =
            overrideCreditOrgId;
        }

        // Tracked Gift
        if (trackedGiftId) {
          tamaroRaiseNowWidget.data["stored_tracked_gift_id"] = trackedGiftId;
          tamaroRaiseNowWidget.data["stored_campaign_id"] = trackedGiftFundId;
          tamaroRaiseNowWidget.data["stored_campaign_subid"] =
            trackedGiftSubFundId;
          tamaroRaiseNowWidget.data["stored_customer_fund_id"] =
            trackedGiftFundId;
          tamaroRaiseNowWidget.data["stored_customer_subfund_id"] =
            trackedGiftSubFundId;
        } else {
          tamaroRaiseNowWidget.data["stored_tracked_gift_id"] = null;
        }

        let amountTotalNum = 0;
        if (multipleDonorVisibility === true && multipleDonorObject !== null) {
          let nonAutoInsertedRows = multipleDonorObject.filter(
            (row: any) => !row.isAutoInserted,
          );
          let noError = nonAutoInsertedRows.every(
            (value: any) => value.errorMessage.err_amount === undefined,
          );
          if (noError) {
            amountTotalNum = nonAutoInsertedRows.reduce(
              (acc: any, cur: any) => acc + Number(cur.amount),
              0,
            );
            tamaroRaiseNowWidget.data["amount"] = amountTotalNum;
          }
        } else {
          tamaroRaiseNowWidget.data["amount"] = amount;
        }
      }

      //DPM and Pay Invoice Transaction
      if (checkIsInvoiceFlow(config as Config)) {
        const payInvoiceFund = getFundByKey(
          config?.funds as Funds,
          "typeName",
          "Invoice",
        );
        tamaroRaiseNowWidget.data["stored_transaction_type_name"] = "Invoice";
        tamaroRaiseNowWidget.data["amount"] = paymentAmount;
        tamaroRaiseNowWidget.data["stored_campaign_id"] =
          payInvoiceFund?.fundId;
        tamaroRaiseNowWidget.data["stored_campaign_subid"] =
          payInvoiceFund?.fundSubId;
        tamaroRaiseNowWidget.data["stored_customer_fund_id"] =
          payInvoiceFund?.fundId;
        tamaroRaiseNowWidget.data["stored_customer_subfund_id"] =
          payInvoiceFund?.fundSubId;
        if (notes) {
          tamaroRaiseNowWidget.data["stored_staff_notes"] = notes;
        } else {
          tamaroRaiseNowWidget.data["stored_staff_notes"] = null;
        }
      }
      // OG-978
      // tamaroRaiseNowWidget.data['stored_subscription_creation_source'] = selectedRequestSource;
      /* eslint-disable no-restricted-globals */
      if (
        !props.props.dmiMode &&
        !props.props.dpmMode &&
        config?.appConfigs.webAppBaseUrl &&
        location.hostname.indexOf("endpolio") == -1
      ) {
        let raisenow_source_url = `${config?.appConfigs.webAppBaseUrl}${location.pathname}?embed=true`;
        tamaroRaiseNowWidget.data["stored_rnw_source_url"] =
          raisenow_source_url;
      }
      /* eslint-enable no-restricted-globals */
    }
  };
  const loadScript = () => {
    setRaiseNowWidgetIsLoading(true);
    let script = document.createElement("script");
    script.src = "https://tamaro.raisenow.com/rotary-italy/latest/widget.js";
    script.async = true;

    loadCSS(
      "https://tamaro.raisenow.com/tamaro-core/WEB-1911/210-640d3ee8715f8b0d.css",
    );
    loadCSS(
      "https://tamaro.raisenow.com/rotary-italy/latest/873-334342d832ed9028.css",
    );

    document.body.appendChild(script);

    const user = {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      line1: "",
      line2: "",
      line3: "",
      zip: "",
      city: "",
    };

    if (props.props.user && props.props.user.authenticated) {
      user.first_name = props.props.user.first_name || "";
      user.last_name = props.props.user.last_name || "";
      user.phone = props.props.user.phone || "";
      user.email = props.props.user.email || "";
      if (props.props.user.address) {
        user.line1 = props.props.user.address.line1 || "";
        user.line2 = props.props.user.address.line2 || "";
        user.line3 = props.props.user.address.line3 || "";
        user.zip = props.props.user.address.zip || "";
        user.city = props.props.user.address.city || "";
      }
    }

    const translations = presetUtils.getAllPurpose(config?.funds as Funds);

    let rnWidgetconfig = {
      error_logging: true,
      show_submit_button: false,
      show_footer: false,
      payment_form_prefill: {
        stored_customer_firstname: user.first_name,
        stored_customer_lastname: user.last_name,
        stored_customer_phone: user.phone,
        stored_customer_email: user.email,
        stored_customer_fiscal_code: "",
        stored_customer_street_full: user.line1,
        stored_customer_street2: user.line2,
        stored_customer_street3: user.line3,
        stored_customer_zip2: user.zip,
        stored_customer_city2: user.city,
        // amount: this.props.amount,
        amount: amount,
        // currency: this.props.props.currency.code,
        currency: currency?.code,
        stored_customer_international_province: "",
      },
      epp_stage: {
        success_url: "https://myuat.rotary.org/en/donate",
      },
      //   language: this.props.props.lang,
      language: "en",
      testMode: config?.appConfigs.test_mode,
      debug: false,
      translations,
    };

    script.onload = () => {
      (window as any).rnw.tamaro.runWidget(
        ".rnw-tamaro-widget",
        rnWidgetconfig,
      );
      if (
        props.props.user?.authenticated &&
        currency?.code.toLowerCase() == "eur" &&
        country?.iso2.toLowerCase() == "it"
      ) {
        // fetchFiscalCodeStatus(
        //   props.props.config.api_url,
        //   props.props.user.member_id
        // ).then((data) => {
        //   window.stored_customer_gin_present = data.FISCALCODE;
        //   global.localStorage.setItem(
        //     "customerGinPresentFiscalCode",
        //     data.FISCALCODE
        //   );
        // });
      }

      const afterLoadCallBack = () => {
        setRaiseNowWidgetIsLoaded(true);
      };

      (window as any).rnw.tamaro.events.afterLoad.subscribe(afterLoadCallBack);

      const afterPaymentValidateCallBack = (event: any) => {
        const tamaroRaiseNowWidget = event.data.api.paymentForm;
        configureScript(props);
        /*----- adding Css for payment section of tamaro widget-----*/
        if (
          (window as any).rnw &&
          (window as any).rnw.tamaro &&
          (window as any).rnw.tamaro.instance &&
          (window as any).rnw.tamaro.instance.config
        ) {
          (window as any).rnw.tamaro.instance.config.customCssUrl =
            "./tamaro.css";
        }
        let preferredLang = "it";
        let lang = "it";
        // const {
        //   trackedGiftId,
        //   namedFundId,
        //   selectedGrantId,
        //   fund,
        //   preferredLang,
        //   lang,
        //   trackedGiftAliasDescription,
        // } = this.props;
        let stored_rnw_purpose_text;

        stored_rnw_purpose_text = !(namedFundId || trackedGiftId)
          ? !grantId
            ? latestStateRef.current.fund &&
              (preferredLang
                ? getFundTitle(
                    {
                      fundTitle: {
                        en: latestStateRef.current.fund.fundTitle?.en || null,
                        fr: latestStateRef.current.fund.fundTitle?.fr || null,
                        de: latestStateRef.current.fund.fundTitle?.de || null,
                        es: latestStateRef.current.fund.fundTitle?.es || null,
                        it: latestStateRef.current.fund.fundTitle?.it || null,
                        ja: latestStateRef.current.fund.fundTitle?.ja || null,
                        ko: latestStateRef.current.fund.fundTitle?.ko || null,
                        pt: latestStateRef.current.fund.fundTitle?.pt || null,
                      },
                      id: latestStateRef.current.fund.id,
                    },
                    preferredLang,
                  )
                : getFundTitle(
                    {
                      fundTitle: {
                        en: latestStateRef.current.fund.fundTitle?.en || null,
                        fr: latestStateRef.current.fund.fundTitle?.fr || null,
                        de: latestStateRef.current.fund.fundTitle?.de || null,
                        es: latestStateRef.current.fund.fundTitle?.es || null,
                        it: latestStateRef.current.fund.fundTitle?.it || null,
                        ja: latestStateRef.current.fund.fundTitle?.ja || null,
                        ko: latestStateRef.current.fund.fundTitle?.ko || null,
                        pt: latestStateRef.current.fund.fundTitle?.pt || null,
                      },
                      id: latestStateRef.current.fund.id,
                    },
                    lang,
                  ))
            : grantId.replace("GG", "Global Grant ")
          : trackedGiftAliasDescription;
        tamaroRaiseNowWidget.data["stored_rnw_purpose_text"] =
          stored_rnw_purpose_text;

        const getFundTitleWithLang = (fund: any, language: string) => {
          return getFundTitle(
            {
              fundTitle: {
                en: fund.fundTitle?.en || null,
                fr: fund.fundTitle?.fr || null,
                de: fund.fundTitle?.de || null,
                es: fund.fundTitle?.es || null,
                it: fund.fundTitle?.it || null,
                ja: fund.fundTitle?.ja || null,
                ko: fund.fundTitle?.ko || null,
                pt: fund.fundTitle?.pt || null,
              },
              id: fund.id,
            },
            language,
          );
        };

        if (!(props.namedFundId || props.trackedGiftId)) {
          if (!grantId) {
            if (latestStateRef.current.fund) {
              stored_rnw_purpose_text = preferredLang
                ? getFundTitleWithLang(
                    latestStateRef.current.fund,
                    preferredLang,
                  )
                : getFundTitleWithLang(latestStateRef.current.fund, lang);
            }
          } else {
            stored_rnw_purpose_text = grantId.replace("GG", "Global Grant ");
          }
        } else {
          stored_rnw_purpose_text = trackedGiftAliasDescription;
        }

        tamaroRaiseNowWidget.data["stored_rnw_purpose_text"] =
          stored_rnw_purpose_text;

        let purposeId = presetUtils.getPurposeByText(
          translations,
          preferredLang ? preferredLang : lang,
          stored_rnw_purpose_text,
        );

        if (
          (window as any).rnw &&
          (window as any).rnw.tamaro &&
          (window as any).rnw.tamaro.instance &&
          (window as any).rnw.tamaro.instance.config
        ) {
          const currentPurposeCount = Object.keys(
            (window as any).rnw.tamaro.instance.config.translations[
              `${preferredLang ? preferredLang : lang}`
            ].purposes,
          ).length;
          if (!purposeId) {
            (window as any).rnw.tamaro.instance.config.translations[
              `${props.preferredLang ? props.preferredLang : lang}`
            ].purposes[`p${currentPurposeCount}`] = stored_rnw_purpose_text;
            if (props.preferredLang && lang) {
              (window as any).rnw.tamaro.instance.config.translations[
                `${lang}`
              ].purposes[`p${currentPurposeCount}`] = stored_rnw_purpose_text;
            }
            purposeId = `p${currentPurposeCount}`;
          }
        }
        tamaroRaiseNowWidget.setPurpose(purposeId);
        tamaroRaiseNowWidget.data["stored_purpose"] = purposeId;
      };

      (window as any).rnw.tamaro.events.afterPaymentValidate.subscribe(
        afterPaymentValidateCallBack,
      );

      const paymentValidateSuccessCallBack = () => {};

      (window as any).rnw.tamaro.events.paymentValidateSuccess.subscribe(
        paymentValidateSuccessCallBack,
      );

      const paymentValidateErrorCallBack = () => {};

      (window as any).rnw.tamaro.events.paymentValidateError.subscribe(
        paymentValidateErrorCallBack,
      );

      const beforePaymentSendCallBack = async (event: any) => {
        configureScript(props);
        const tamaroRaiseNowWidget = event.data.api.paymentForm;
        const isDonatingOnBehalfOfClubMembers =
          (multipleDonorVisibility && multipleDonorObject) ||
          listItemsObject?.length
            ? true
            : props.props.behalf.behalfVisibility &&
              props.props.behalf.behalfType === "member";
        const clubMemberData = new FormData();
        clubMemberData.append(
          "clubMembers",
          JSON.stringify(
            props.props.behalf.clubMembers
              .map((obj: any) => ({ ...obj, donor_type: "1" }))
              .filter(
                ({ amount }: { amount: string }) => parseInt(amount, 10) > 0,
              ),
          ),
        );
        clubMemberData.append("type", "give-on-behalf");
        const getMultipleDonorListFromLocalStorage = localStorage.getItem(
          "multipleDonorMembersList",
        );
        const multipleDonorList =
          config?.appConfigs.mode === "dpm" &&
          multipleDonorVisibility &&
          multipleDonorObject
            ? getMultipleDonorListFromLocalStorage &&
              JSON.parse(getMultipleDonorListFromLocalStorage)
            : [{}];

        const multipleDonorData = new FormData();
        multipleDonorData.append(
          "clubMembers",
          JSON.stringify(multipleDonorList),
        );
        multipleDonorData.append("type", "give-on-behalf");
        const openItemsData = new FormData();
        openItemsData.append("clubMembers", JSON.stringify(listItemsObject));
        openItemsData.append("type", "invoice-payments");
        const memberListData =
          config?.appConfigs.mode === "dpm" && dpmBtnMode == "addDonation"
            ? multipleDonorData
            : checkIsInvoiceFlow(config as Config)
              ? openItemsData
              : clubMemberData; //Need to do
        if (isDonatingOnBehalfOfClubMembers) {
          // To do
          // const storedListID = await fetchIdAndStoreClubMembersForTamaro(
          //   this.props.config.api_url,
          //   memberListData
          // );
          // tamaroRaiseNowWidget.data["stored_list_id"] = storedListID;
        }
        const panPlain = tamaroRaiseNowWidget.data["stored_customer_pan"];
        const panEncrypted =
          tamaroRaiseNowWidget.data["stored_customer_pan_encrypted"];
        if (panPlain && panEncrypted) {
          tamaroRaiseNowWidget.data["stored_customer_pan"] = panEncrypted;
          tamaroRaiseNowWidget.data["stored_customer_pan_encrypted"] = null;
        }
        const fiscalPlain =
          tamaroRaiseNowWidget.data["stored_customer_fiscal_code"];
        const fiscalEncrypted =
          tamaroRaiseNowWidget.data["stored_customer_fiscal_code_encrypted"];
        if (fiscalPlain && fiscalEncrypted) {
          tamaroRaiseNowWidget.data["stored_customer_fiscal_code"] =
            fiscalEncrypted;
          tamaroRaiseNowWidget.data["stored_customer_fiscal_code_encrypted"] =
            null;
        }
      };
      (window as any).rnw.tamaro.events.beforePaymentSend.subscribe(
        beforePaymentSendCallBack,
      );

      const paymentCompleteCallBack = (event: any) => {
        const transactionInfo = event.data.api.transactionInfo;
        const tamaroRaiseNowWidget = event.data.api.paymentForm;
        if (transactionInfo.epayment_status == "success") {
          setTransactionData({
            payment: {
              hmac: transactionInfo["hmac"],
              further_rnw_interaction:
                transactionInfo["further_rnw_interaction"],
              mobile_mode: transactionInfo["mobile_mode"],
              test_mode: transactionInfo["test_mode"],
              language: transactionInfo["language"],
              return_parameters: transactionInfo["return_parameters"],
              expm: transactionInfo["expm"],
              expy: transactionInfo["expy"],
              ingcollect_api_key: transactionInfo["ingcollect_api_key"],
              ingcollect_merchant_id: transactionInfo["ingcollect_merchant_id"],
              country_code: transactionInfo["country_code"],
              ingenico_collect_transaction_id:
                transactionInfo["ingenico_collect_transaction_id"],
              internal_return_executions:
                transactionInfo["internal_return_executions"],
              amount: transactionInfo["amount"],
              currency: transactionInfo["currency"],
              epp_transaction_id: transactionInfo["epp_transaction_id"],
              payment_method: transactionInfo["card_type"],
              payment_provider: transactionInfo["payment_provider"],
              no_dd_validation: "true",
              meta_recurring: "true",
              stored_rnw_product_name:
                transactionInfo["stored_rnw_product_name"],
              stored_rnw_product_version:
                transactionInfo["stored_rnw_product_version"],
              stored_rnw_source_url: transactionInfo["stored_rnw_source_url"],
              stored_campaign_id: transactionInfo["stored_campaign_id"],
              stored_campaign_subid: transactionInfo["stored_campaign_subid"],
              stored_rnw_purpose_text:
                transactionInfo["stored_rnw_purpose_text"],
              stored_rnw_hide_address_fields: "false",
              stored_purpose: transactionInfo["stored_purpose"],
              stored_customer_firstname:
                transactionInfo["stored_customer_firstname"],
              stored_customer_lastname:
                transactionInfo["stored_customer_lastname"],
              stored_customer_phone: transactionInfo["stored_customer_phone"],
              stored_customer_email: transactionInfo["stored_customer_email"],
              stored_customer_street_full:
                transactionInfo["stored_customer_street_full"],
              stored_customer_street: transactionInfo["stored_customer_street"],
              stored_customer_zip_code:
                transactionInfo["stored_customer_zip_code"],
              stored_customer_city: transactionInfo["stored_customer_city"],
              stored_customer_city2: transactionInfo["stored_customer_city2"],
              stored_customer_state: transactionInfo["stored_customer_state"],
              stored_customer_zip2: transactionInfo["stored_customer_zip2"],
              stored_customer_country2:
                transactionInfo["stored_customer_country2"],
              stored_customer_country:
                transactionInfo["stored_customer_country"],
              stored_customer_country_en:
                transactionInfo["stored_customer_country_en"],
              stored_mth_suppress_notification:
                transactionInfo["stored_mth_suppress_notification"],
              stored_customer_donor_type:
                transactionInfo["stored_customer_donor_type"],
              stored_customer_fund_id:
                transactionInfo["stored_customer_fund_id"],
              stored_customer_subfund_id:
                transactionInfo["stored_customer_subfund_id"],
              stored_transaction_creation_source:
                transactionInfo["stored_transaction_creation_source"],
              stored_bank_id: transactionInfo["stored_bank_id"],
              stored_timezone_offset: transactionInfo["stored_timezone_offset"],
              stored_screen_height: transactionInfo["stored_screen_height"],
              stored_screen_width: transactionInfo["stored_screen_width"],
              stored_transaction_type_name:
                transactionInfo["stored_transaction_type_name"],
              stored_customer_online_donation:
                transactionInfo["stored_customer_online_donation"],
              stored_amount_superunit:
                transactionInfo["stored_amount_superunit"],
              stored_amount_subunit: transactionInfo["stored_amount_subunit"],
              stored_rnw_widget_uuid: transactionInfo["stored_rnw_widget_uuid"],
              stored_rnw_widget_instance_id:
                transactionInfo["stored_rnw_widget_uuid"],
              delete_data_after_submit: "true",
              widget_uuid: transactionInfo["stored_rnw_widget_uuid"],
              crmc_url: "https://lema.raisenow.com",
              http_base_url_endpoint: "https://widget-api.rnw.io",
              api_key: (window as any).rnw.tamaro.instance.epik.config[
                "eppApiKey"
              ],
              epayment_status: transactionInfo["epayment_status"],
              response_hmac: transactionInfo["response_hmac"],
              donation_type: transactionInfo["stored_donation_type"],
            },
            widget: {
              widgetName: transactionInfo["stored_rnw_product_name"],
              widgetUUID: transactionInfo["stored_rnw_widget_uuid"],
            },
          });
          if (!userState.authenticated) {
            let user = {
              authenticated: false,
              roles: {
                1: "anonymous user",
              },
            };
            setUser(user, false);
          }
          setAlert("");
        }
        if (
          transactionInfo.epayment_status === "error" &&
          props.paymentWorkflowRedirect
        ) {
          tamaroRaiseNowWidget.data["stored_customer_fiscal_code"] = null;
          transactionInfo["stored_customer_fiscal_code"] = null;

          if (transactionInfo["stored_cover_transaction_fee"]) {
            transactionInfo["default_selected_amount"] =
              transactionInfo["amount"] -
              transactionInfo["stored_rnw_cover_fee_amount"] / 100;
          } else {
            transactionInfo["default_selected_amount"] =
              transactionInfo["stored_amount_superunit"];
          }

          const widgetData = presetUtils.bootstrapWidgetPreset(
            transactionInfo,
            props.config,
          );

          if (widgetData.selectedFundTab !== null) {
            setActiveFundTab(widgetData.selectedFundTab.id);
          }
          if (widgetData.selectedFund !== null) {
            if (widgetData.selectedFund.isGrant === true) {
              setGrantId(widgetData.selectedFund.grantId);
            } else {
              setSelectedFundUniqueId(
                widgetData.selectedFund,
                props.config.funds,
              );
            }
          }

          //  let dedicationHandlers = dedicationHandlers();
          if (
            ["honor", "memory"].indexOf(
              widgetData.dedicationDetails.dedicationType,
            ) != -1
          ) {
            setDedicationVisibility(dedicationImages);
            setDedicationType(
              dedicationImages,
              widgetData.dedicationDetails.dedicationType,
            );
            setDedicationName(widgetData.dedicationDetails.dedicationName);
            if (
              ["print", "email"].indexOf(
                widgetData.dedicationDetails.notificationType,
              ) != -1
            ) {
              setNotificationType(
                widgetData.dedicationDetails.notificationType,
              );
              if (widgetData.dedicationDetails.notificationType == "email") {
                setRecipientEmail(widgetData.dedicationDetails.recipientEmail);
              }
              setDedicationName(widgetData.dedicationDetails.dedicationName);
              if (widgetData.dedicationDetails.selectedDedicationImage) {
                setSelectedDedicationImage(
                  dedicationImages,
                  widgetData.dedicationDetails.selectedDedicationImage,
                );
              }
              setDonorName(widgetData.dedicationDetails.donorName);
              setPersonalMessage(
                widgetData.dedicationDetails.personalMessage,
                personalMessageMaxLength -
                  widgetData.dedicationDetails.personalMessage.length,
              );
            } else {
              handleToggleDedicationNotificationStatus(dedicationImages);
            }
          }
          // To do
          // let behalfHandlers = this.props.behalfHandlers();
          // if (widgetData.behalfDetails.behalfVisibility) {
          //   behalfHandlers.toggleBehalfVisibility();
          //   behalfHandlers.setBehalfType(widgetData.behalfDetails.behalfType);
          // }

          // let amountHandlers = this.props.amountHandlers();
          // if (widgetData.amountDetails.isCustomAmount) {
          //   amountHandlers.setCustomAmount(widgetData.amountDetails.amount);
          // } else {
          //   amountHandlers.setFixedAmount(widgetData.amountDetails.amount);
          // }

          // scrollTo(".tamaro-widget");
          setAlert("");
        }
      };

      (window as any).rnw.tamaro.events.paymentComplete.subscribe(
        paymentCompleteCallBack,
      );
    };
  };

  useEffect(() => {
    if ((window as any).rnwWidget) {
      (window as any).rnwWidget = null;
    }
    // configureScript();
    loadScript();

    return () => {};
  }, []);

  useEffect(() => {
    configureScript(props);
    latestStateRef.current = { fund };
  }, [
    amount,
    country,
    currency,
    fund,
    namedFundId,
    trackedGiftId,
    trackedGiftFundId,
    trackedGiftSubFundId,
    grantId,
    dedicationVisibility,
    dedicationName,
    // ecardLink,
    localStorage.getItem("eCardUrl"),
    dedicationNotificationStatus,
    recipientEmail,
    donorName,
    personalMessage,
    selectedDedicationImage,
    // personalMessageMaxLength,
    dedicationImages,
    config,
    dpmBtnMode,
    requestSource,
    notes,
    notificationType,
    dedicationType,
    country,
    // ecardLink,
  ]);

  if (props.hasError) {
    return (
      <StatusMessage
        id="rnWidget"
        className="dds-widget-container"
        type="error"
      >
        We're sorry, our system is experiencing a temporary problem. Your
        contribution is important to us. Please try again soon.
      </StatusMessage>
    );
  }

  return <div className="rnw-tamaro-widget"></div>;
};

export default RaiseNowWidgetTamaro;
