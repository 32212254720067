import React, { ChangeEvent } from "react";
import { styled, css } from "@mui/system";
import { Box } from "@mui/material";
import Error from "shared/assets/error";
import { color, fieldWrapper, fieldLabel, fontSize } from "shared/styles";
import { SelectOptions } from "shared/types";

const ItemWrapper = styled("div")`
  ${fieldWrapper()}
`;

const ErrorIconWrapper = styled(Box)(() => ({
  top: "11px",
  right: "-40px",
  position: "absolute",
}));

interface LabelProps {
  htmlFor: string;
  error?: boolean;
}

const Label = styled("label")<LabelProps>`
  ${fieldLabel()}
`;

const SubText = styled("span")(() => ({
  fontWeight: "normal",
}));

interface ElementWrapperProps {
  error?: boolean;
}

const ElementWrapper = styled("div")<ElementWrapperProps>(() => ({
  position: "relative",
  // marginTop: pxToRem(10),
}));

const HelpText = styled("div")`
  ${fontSize(14, 20)}
  padding-top: 6px;
`;

interface ErrorMessagesProps {
  error?: boolean;
}

const ErrorMessages = styled("div")<ErrorMessagesProps>`
  line-height: 1.2em;
  padding-top: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ${fontSize(14)}

  ${(props) =>
    props.error &&
    css`
      color: ${color.red};
    `}
`;

const ErrorMessage = styled("div")(() => ({}));

interface FormItemProps {
  FormElement: React.ElementType;
  id?: string;
  label?: string;
  subText?: string;
  error?: boolean;
  errorMessages?: string[];
  helpText?: string;
  placeholder?: string | null;
  value?: string | number | null;
  required?: boolean;
  options?: SelectOptions[] | null;
  maxLength?: number;
  onChange?:
    | ((event: ChangeEvent<HTMLInputElement>) => void)
    | ((event: SelectOptions) => void);
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
}

const FormItem: React.FC<FormItemProps> = ({
  FormElement,
  id,
  label,
  subText,
  error,
  errorMessages = [],
  helpText,
  placeholder,
  ...props
}) => {
  const classes = [`field--${id}`, error ? "field--error" : ""];

  return (
    <ItemWrapper className={classes.join(" ")}>
      {label && (
        <Label htmlFor={id || ""} error={error}>
          {label}
          {subText && <SubText> ({subText})</SubText>}
        </Label>
      )}
      <ElementWrapper error={error}>
        <FormElement
          id={id}
          error={error}
          placeholder={placeholder}
          {...props}
        />
        {error && (
          <ErrorIconWrapper>
            <Error height={28} width={28} />
          </ErrorIconWrapper>
        )}
      </ElementWrapper>
      {error && (
        <ErrorMessages error={error}>
          {errorMessages.map((message) => (
            <ErrorMessage key={message}>{message}</ErrorMessage>
          ))}
        </ErrorMessages>
      )}
      {helpText && <HelpText>{helpText}</HelpText>}
    </ItemWrapper>
  );
};

export default FormItem;
