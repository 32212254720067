import Header from "./Header";
import "./styles.css";
import { MultipleDonorMemberListGridData } from "shared/types";
import MultipleDonorGridRow from "./GridRow";

interface MultipleDonorGridProps {
  currencyCode: string;
  multipleDonorConfig: { minRows: number };
  gridData: MultipleDonorMemberListGridData;
  onChange: (params: {
    index: number;
    change: { selectedType?: string; id?: string; amount?: string };
  }) => void;
  onBlur: (params: { index: number; data: { donorId: string } }) => void;
  onDelete: (params: { index: number }) => void;
  onClear: (params: { index: number }) => void;
  multipleDonorError: { visibility: boolean; status: boolean };
}

const MultipleDonorGrid = ({
  currencyCode,
  multipleDonorConfig,
  gridData,
  onChange,
  onBlur,
  onDelete,
  onClear,
}: MultipleDonorGridProps) => {
  return (
    <div className="mdg-container flex-host">
      <Header currencyCode={currencyCode} />
      <div className="mdg-body">
        {gridData.map((gd, i) => (
          <MultipleDonorGridRow
            key={i}
            multipleDonorConfig={multipleDonorConfig}
            rowsCount={gridData.length}
            gridRowData={gd}
            onChange={(change) => {
              onChange({ index: i, change });
            }}
            onClear={() => {
              onClear({ index: i });
            }}
            onDelete={() => {
              onDelete({ index: i });
            }}
            onBlur={(data) => {
              onBlur({ index: i, data });
            }}
            rowIndex={i}
          />
        ))}
      </div>
    </div>
  );
};

export default MultipleDonorGrid;
