import React from "react";

interface PayInvoiceGridFooterProps {
  totalBalance: number;
}

const PayInvoiceGridFooter: React.FC<PayInvoiceGridFooterProps> = ({
  totalBalance,
}) => {
  const total =
    totalBalance < 0
      ? `(${totalBalance.toString().replace(/-/g, "")})`
      : totalBalance.toFixed(2);
  return (
    <div className="inv-footer">
      <div className="inv-col"></div>
      <div className="inv-col"></div>
      <div className="inv-col"></div>
      <div className="inv-col">
        <label className="col-heading">{`${"Balance"}`}</label>
      </div>
      <div className="inv-col">
        <label className="col-heading">{total}</label>
      </div>
    </div>
  );
};

export default PayInvoiceGridFooter;
