import { bindActionCreators } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import {
  createSetActiveFundTab,
  createSetAmountToDefault,
  createSetClubCreditClubType,
  createSetClubIDNumber,
  createSetConfig,
  createSetCountryId,
  createSetCurrencyId,
  createSetCustomAmount,
  createSetDedicationStateProvinceList,
  createSetDonationType,
  createSetEndowmentName,
  createSetFixedAmount,
  createSetGrantId,
  createSetLoading,
  createSetNamedFundAliasDescription,
  createSetNamedFundId,
  createSetPaymentWorkflowRedirectAction,
  createSetRecognitionStateProvinceList,
  createSetRecurringFrequency,
  createSetRecurringInterval,
  createSetRecurringStartDateDetails,
  createSetSelectedFundUniqueId,
  createSetShowCustomAmount,
  createSetTrackedGiftAlias,
  createSetTrackedGiftAliasDescription,
  createSetTrackedGiftFundId,
  createSetTrackedGiftId,
  createSetTrackedGiftName,
  createSetTrackedGiftSubFundId,
} from "shared/state/app";
import {
  fetchConfig,
  fetchPaymentProfile,
  fetchPanStatus,
  fetchUserInfo,
} from "shared/state/app/thunks";
import {
  createSetBillingCity,
  createSetBillingCountry,
  createSetBillingState,
  createSetInternationalProvince,
  createSetStreet,
  createSetZip,
} from "shared/state/billingAddress";
import {
  createSetDedicationName,
  createSetDedicationPreviewVisibility,
  createSetDedicationType,
  createSetDedicationVisibility,
  createSetDefaultDedicationImages,
  createSetDonorName,
  createSetEcardLink,
  createSetNotificationType,
  createSetPersonalMessage,
  createSetRecipientEmail,
  createSetSelectedDedicationImage,
  createSetToggleNotificationStatus,
} from "shared/state/dedication";
import {
  createSetAmountError,
  createSetClubIDError,
  createSetCountryError,
  createSetDedicationErrorsVisibility,
  createSetDedicationNameError,
  createSetDedicationVisibilityError,
  createSetDonorNameError,
  createSetDpmCountryError,
  createSetFrequencyError,
  createSetGrantsError,
  createSetMultipleDonorError,
  createSetNamedFundError,
  createSetOverrideCreditOrgError,
  createSetPaymentTypeError,
  createSetQpqQuantityError,
  createSetRcgfStateProvinceError,
  createSetRecipientEmailError,
  createSetRequestSourceError,
  createSetStateProvinceError,
  createSetTrackedGiftError,
  createSetOpenItemsError,
  createSetPaymentAmountError,
} from "shared/state/error";
import {
  createSetEmail,
  createSetFirstName,
  createSetLastName,
  createSetPhone,
} from "shared/state/personalDetails";
import {
  createSetRaiseNowWidgetIsLoaded,
  createSetRaiseNowWidgetIsLoading,
} from "shared/state/raiseNow";
import {
  createSetAddressLine1,
  createSetAddressLine2,
  createSetAddressLine3,
  createSetAnonymous,
  createSetCity,
  createSetMailLetterCountry,
  createSetNotes,
  createSetOccasion,
  createSetOcerrideDate,
  createSetOverrideCreditOrgId,
  createSetOverrideCreditOrgType,
  createSetOverrideCreditOrgValidStatus,
  createSetPaymentType,
  createSetPostalCode,
  createSetPresentationDate,
  createSetPrivacyPolicyFlag,
  createSetProvince,
  createSetQpqQuantity,
  createSetRecipientName,
  createSetRecognitionAddressLine1,
  createSetRecognitionAddressLine2,
  createSetRecognitionAddressLine3,
  createSetRecognitionCity,
  createSetRecognitionCountry,
  createSetRecognitionDestination,
  createSetRecognitionDestinationName,
  createSetRecognitionNameOnCertificate,
  createSetRecognitionPhone,
  createSetRecognitionPostalCode,
  createSetRecognitionStateProvince,
  createSetRequestSource,
  createSetToggelOverrideCreditOrgVisibility,
  createSetListItemsObject,
  createSetPaymentAmount,
  createSetPayInvoiceBillingCurrency,
  createSetPayInvoiceExchangeRate,
  createSetToggleMultipleDonorVisibility,
  createSetMultipleDonorObject,
} from "shared/state/staffUseOnly";
import {
  createSetTransactionData,
  createSetTransactionFlow,
  createSetCustomerType,
} from "shared/state/transaction";
import {
  createSetAlert,
  createSetConfirmationDetailsVisibility,
  createSetDonationDetailsVisibility,
} from "shared/state/ui";
import { createSetUserAction } from "shared/state/user";

const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      setActiveFundTab: createSetActiveFundTab,
      setConfig: createSetConfig,
      setLoading: createSetLoading,
      setDedicationVisibility: createSetDedicationVisibility,
      setDedicationVisibilityError: createSetDedicationVisibilityError,
      setDedicationType: createSetDedicationType,
      setDefaultDedicationImages: createSetDefaultDedicationImages,
      setDonorName: createSetDonorName,
      setDonorNameError: createSetDonorNameError,
      setDedicationName: createSetDedicationName,
      setDedicationNameError: createSetDedicationNameError,
      setRecipientEmail: createSetRecipientEmail,
      setRecipientEmailError: createSetRecipientEmailError,
      setNotificationType: createSetNotificationType,
      handleToggleDedicationNotificationStatus:
        createSetToggleNotificationStatus,
      setSelectedDedicationImage: createSetSelectedDedicationImage,
      setEcardLink: createSetEcardLink,
      setCountryError: createSetCountryError,
      setPersonalMessage: createSetPersonalMessage,
      setOccasion: createSetOccasion,
      setRecipientName: createSetRecipientName,
      setAddressLine1: createSetAddressLine1,
      setAddressLine2: createSetAddressLine2,
      setAddressLine3: createSetAddressLine3,
      setCity: createSetCity,
      setProvince: createSetProvince,
      setMailLetterCountry: createSetMailLetterCountry,
      setPostalCode: createSetPostalCode,
      setStateProvinceError: createSetStateProvinceError,
      setDedicationStateProvinceList: createSetDedicationStateProvinceList,
      setDedicationErrorsVisibility: createSetDedicationErrorsVisibility,
      setDedicationPreviewVisibility: createSetDedicationPreviewVisibility,
      setAmountError: createSetAmountError,
      showCustomAmount: createSetShowCustomAmount,
      setFixedAmount: createSetFixedAmount,
      setCustomAmountValue: createSetCustomAmount,
      setClubIDError: createSetClubIDError,
      setClubCreditClubType: createSetClubCreditClubType,
      setClubIDNumber: createSetClubIDNumber,
      setCountryId: createSetCountryId,
      setCurrencyId: createSetCurrencyId,
      setAmountToDefault: createSetAmountToDefault,
      setFrequencyError: createSetFrequencyError,
      setRecurringFrequency: createSetRecurringFrequency,
      setRecurringInterval: createSetRecurringInterval,
      setRecurringStartDateDetails: createSetRecurringStartDateDetails,
      setDonationType: createSetDonationType,
      setSelectedFundUniqueId: createSetSelectedFundUniqueId,
      setEndowmentName: createSetEndowmentName,
      setGrantsError: createSetGrantsError,
      setGrantId: createSetGrantId,
      setNamedFundId: createSetNamedFundId,
      setTrackedGiftFundId: createSetTrackedGiftFundId,
      setTrackedGiftSubFundId: createSetTrackedGiftSubFundId,
      setTrackedGiftName: createSetTrackedGiftName,
      setTrackedGiftAliasDescription: createSetTrackedGiftAliasDescription,
      setNamedFundAliasDescription: createSetNamedFundAliasDescription,
      setNamedFundsError: createSetNamedFundError,
      setTrackedGiftId: createSetTrackedGiftId,
      setTrackedGiftAlias: createSetTrackedGiftAlias,
      setTrackedGiftError: createSetTrackedGiftError,
      setOverrideDate: createSetOcerrideDate,
      setQpqQuantity: createSetQpqQuantity,
      setQpqQuantityError: createSetQpqQuantityError,
      setNotes: createSetNotes,
      setAnonymous: createSetAnonymous,
      toggelOverrideCreditOrgVisibility:
        createSetToggelOverrideCreditOrgVisibility,
      setOverrideCreditOrgId: createSetOverrideCreditOrgId,
      setOverrideCreditOrgType: createSetOverrideCreditOrgType,
      setOverrideCreditOrgError: createSetOverrideCreditOrgError,
      setOverrideCreditOrgValidStatus: createSetOverrideCreditOrgValidStatus,
      setPaymentType: createSetPaymentType,
      setPaymentTypeError: createSetPaymentTypeError,
      setRecognitionDestination: createSetRecognitionDestination,
      setRecognitionDestinationName: createSetRecognitionDestinationName,
      setRecognitionAddressLine1: createSetRecognitionAddressLine1,
      setRecognitionAddressLine2: createSetRecognitionAddressLine2,
      setRecognitionAddressLine3: createSetRecognitionAddressLine3,
      setRecognitionCity: createSetRecognitionCity,
      setRecognitionStateProvince: createSetRecognitionStateProvince,
      setRecognitionPostalCode: createSetRecognitionPostalCode,
      setRecognitionPhone: createSetRecognitionPhone,
      setRecognitionNameOnCertificate: createSetRecognitionNameOnCertificate,
      setPresentationDate: createSetPresentationDate,
      setDpmCountryError: createSetDpmCountryError,
      setRecognitionCountry: createSetRecognitionCountry,
      setRecognitionStateError: createSetRcgfStateProvinceError,
      setRecognitionState: createSetRecognitionStateProvince,
      setRecognitionStateList: createSetRecognitionStateProvinceList,
      setRequestSource: createSetRequestSource,
      setRequestSourceError: createSetRequestSourceError,
      setPrivacyPolicyFlag: createSetPrivacyPolicyFlag,
      setListItemsObject: createSetListItemsObject,
      setPaymentAmount: createSetPaymentAmount,
      setPayInvoiceBillingCurrency: createSetPayInvoiceBillingCurrency,
      setPayInvoiceExchangeRate: createSetPayInvoiceExchangeRate,
      setPaymentAmountError: createSetPaymentAmountError,
      setOpenItemsError: createSetOpenItemsError,
      toggleMultipleDonorVisibility: createSetToggleMultipleDonorVisibility,
      setMultipleDonorError: createSetMultipleDonorError,
      setMultipleDonorObject: createSetMultipleDonorObject,
      fetchConfig: fetchConfig,
      fetchPaymentProfile: fetchPaymentProfile,
      fetchPanStatus: fetchPanStatus,
      fetchUserInfo: fetchUserInfo,
      setDonationDetailsVisibility: createSetDonationDetailsVisibility,
      setTransactionData: createSetTransactionData,
      setTransactionFlow: createSetTransactionFlow,
      setCustomerType: createSetCustomerType,
      setAlert: createSetAlert,
      setPaymentWorkflowRedirect: createSetPaymentWorkflowRedirectAction,
      setFirstName: createSetFirstName,
      setLastName: createSetLastName,
      setPhone: createSetPhone,
      setEmail: createSetEmail,
      setStreet: createSetStreet,
      setBillingState: createSetBillingState,
      setZip: createSetZip,
      setBillingCity: createSetBillingCity,
      setInternationalProvince: createSetInternationalProvince,
      setBillingCountry: createSetBillingCountry,
      setUser: createSetUserAction,
      setRaiseNowWidgetIsLoaded: createSetRaiseNowWidgetIsLoaded,
      setRaiseNowWidgetIsLoading: createSetRaiseNowWidgetIsLoading,
      setConfirmationDetailsVisibility: createSetConfirmationDetailsVisibility,
    },
    dispatch,
  );
};

export default useActions;
