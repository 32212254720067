import { createSlice } from "@reduxjs/toolkit";

export interface BillingAddressState {
  street: string | null;
  street2: string | null;
  street3: string | null;
  billingState: string | null;
  zip: string | null;
  billingCity: string | null;
  internationalProvince: string | null;
  billingCountry: string | null;
}

const initialStateBillingAddress: BillingAddressState = {
  street: null,
  street2: null,
  street3: null,
  billingState: null,
  zip: null,
  billingCity: null,
  internationalProvince: null,
  billingCountry: null,
};

const billingAddressSlice = createSlice({
  name: "billingAddress",
  initialState: initialStateBillingAddress,
  reducers: {
    setStreet: (state, action) => {
      const { street, fieldNum = "" } = action.payload;
      const key = `street${fieldNum}` as keyof BillingAddressState;
      state[key] = street;
    },
    setBillingState: (state, action) => {
      state.billingState = action.payload.billingState;
    },
    setZip: (state, action) => {
      state.zip = action.payload.zip;
    },
    setBillingCity: (state, action) => {
      state.billingCity = action.payload.city;
    },
    setInternationalProvince: (state, action) => {
      state.internationalProvince = action.payload.internationalProvince;
    },
    setBillingCountry: (state, action) => {
      state.billingCountry = action.payload.billingCountry;
    },
  },
});

export const {
  setStreet,
  setBillingState,
  setZip,
  setBillingCity,
  setInternationalProvince,
  setBillingCountry,
} = billingAddressSlice.actions;

type SetStreetPayload =
  | null
  | undefined
  | { street: string; fieldNum?: number };

export const createSetStreet = (street: string | null, fieldNum?: number) => ({
  type: "billingAddress/setStreet",
  payload: { street: street, fieldNum: fieldNum },
});

export const createSetBillingState = (billingState: string | null) => ({
  type: "billingAddress/setBillingState",
  payload: { billingState },
});

export const createSetZip = (zip: string | null) => ({
  type: "billingAddress/setZip",
  payload: { zip },
});

export const createSetBillingCity = (city: string | null) => ({
  type: "billingAddress/setBillingCity",
  payload: { city },
});

export const createSetInternationalProvince = (
  internationalProvince: string | null,
) => ({
  type: "billingAddress/setInternationalProvince",
  payload: { internationalProvince },
});

export const createSetBillingCountry = (billingCountry: string | null) => ({
  type: "billingAddress/setBillingCountry",
  payload: { billingCountry },
});

export default billingAddressSlice.reducer;
