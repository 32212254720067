import { useSelector } from "react-redux";
import { styled } from "@mui/system";
import RecognitionInfo from "components/RecognitionInfo";
import { pxToRem, universalEmphasis } from "shared/styles";
import { Config, Currencies, Currency, Rates } from "shared/types";
import {
  amountInDollars,
  checkIsInvoiceFlow,
  getExchangeRate,
  getExchangeRateForPayInvoice,
} from "shared/utils";
import { RootState } from "shared/state/store";
import { useMemo } from "react";

const StyledRecognitionAmount = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexFlow: "row nowrap",
  fontSize: pxToRem(14),
  [theme.breakpoints.up("sm")]: {
    fontSize: pxToRem(16),
  },
  "> div:first-of-type": {
    [theme.breakpoints.up("sm")]: {
      order: 2,
    },
  },
}));

const RecognitionText = styled("div")`
  display: inline-block;
`;

const RecognitionAmountLabel = styled("span")`
  ${universalEmphasis()}
`;

const DollarAmount = styled("span")`
  font-weight: 700;
  margin: 0 0 0 ${pxToRem(5)};
`;

const ConfirmationDollarAmount = styled("span")`
  font-style: italic;
  margin: 0 0 0 ${pxToRem(5)};
`;

interface RecognitionAmountProps {
  currencies: Currencies | null;
  rates: Rates | null;
  currency: Currency;
  showInfo?: boolean;
  isConfirmation?: boolean;
}

const RecognitionAmount = ({
  currencies,
  rates,
  currency,
  showInfo = false,
  isConfirmation = false,
}: RecognitionAmountProps) => {
  const { fixedAmount, customAmount, config } = useSelector(
    (state: RootState) => state.app,
  );
  const { confirmationDetailsVisibility } = useSelector(
    (state: RootState) => state.ui,
  );
  const { payInvoiceBillingCurrency, payInvoiceExchangeRate, paymentAmount } =
    useSelector((state: RootState) => state.staffUseOnly);
  const amount = useMemo(
    () =>
      confirmationDetailsVisibility
        ? Number(localStorage.getItem("fixedAmount")) ||
          Number(localStorage.getItem("customAmount")) ||
          0
        : fixedAmount || customAmount || 0,
    [fixedAmount, customAmount, confirmationDetailsVisibility],
  );
  const invoiceExchangeRate =
    payInvoiceExchangeRate !== null ? payInvoiceExchangeRate : rates;

  const renderAmount = (isConfirmation: boolean, currency: Currency) => {
    const exchangeRate = checkIsInvoiceFlow(config as Config)
      ? getExchangeRateForPayInvoice(
          currencies,
          invoiceExchangeRate as Rates,
          currency,
          payInvoiceBillingCurrency,
        )
      : getExchangeRate(currencies, rates, currency);

    const amountValue = checkIsInvoiceFlow(config as Config)
      ? amountInDollars(exchangeRate, Number(paymentAmount), currency)
      : amountInDollars(exchangeRate, amount, currency);
    return isConfirmation ? (
      <ConfirmationDollarAmount>{amountValue}</ConfirmationDollarAmount>
    ) : (
      <DollarAmount>{amountValue}</DollarAmount>
    );
  };

  return (
    <div>
      {((payInvoiceBillingCurrency &&
        getExchangeRateForPayInvoice(
          currencies,
          invoiceExchangeRate as Rates,
          currency,
          payInvoiceBillingCurrency,
        )) ||
        (currency.code !== "usd" &&
          getExchangeRate(currencies, rates, currency))) && (
        <StyledRecognitionAmount>
          {showInfo && <RecognitionInfo />}
          <RecognitionText>
            <RecognitionAmountLabel>
              {checkIsInvoiceFlow(config as Config) ? (
                <>Amount in {payInvoiceBillingCurrency}</>
              ) : config?.appConfigs.mode === "dpm" ? (
                <>Amount in USD: </>
              ) : (
                <>Recognition amount in USD:</>
              )}
            </RecognitionAmountLabel>
            {renderAmount(isConfirmation, currency)}
          </RecognitionText>
        </StyledRecognitionAmount>
      )}
    </div>
  );
};

export default RecognitionAmount;
