import { useSelector } from "react-redux";
import { RootState } from "shared/state/store";
import TextArea from "components/Form/components/TextArea";
import useActions from "Hooks/useActions";

const DPMInvoiceOnly = () => {
  const { setNotes } = useActions();
  const { notes } = useSelector((state: RootState) => state.staffUseOnly);

  return (
    <div>
      <TextArea
        label="Notes"
        id="StaffNotes"
        value={notes}
        characterLimit={250}
        charactersRemaining={notes ? 250 - notes.length : 250}
        onChange={(e) => {
          setNotes(e.target.value);
        }}
        subText=""
        maxLength={250}
      />
    </div>
  );
};

export default DPMInvoiceOnly;
