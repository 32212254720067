import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { color, pxToRem } from "shared/styles";
import {
  addFullNameToClubList,
  getDefaultRows,
  validators,
} from "./utils/utils.mulitpleDonor";
import MemberListCheckbox from "./components/MemberListCheckbox";
import { useSelector } from "react-redux";
import { RootState } from "shared/state/store";
import MultipleDonorGrid from "./components/MultipleDonorGrid";
import MultipleDonorGridFooter from "./components/MultipleDonorGrid/Footer";
import useActions from "Hooks/useActions";
import {
  getTypeOptionsLabelByValue,
  sortArrayByKey,
} from "./utils/utils.mulitpleDonor";
import {
  fetchClubMembersByClubIdandType,
  fetchDonorNameByTypeAndMemberId,
} from "shared/utils/api";
const Container = styled("div")``;

const MultipleDonorHeading = styled("div")`
  border-bottom: 1px solid ${color.navy};
  color: ${color.navy};
  font-size: ${pxToRem(24)};
  font-weight: 300;
  line-height: 90%;
  margin-bottom: 30px;
  padding-bottom: 6px;
  padding-top: 10px;
`;

const MultipleDonor = () => {
  const [gridData, setGridData] = useState(getDefaultRows(1));
  const [multipleDonorConfig, setMultipleDonorConfig] = useState({
    minRows: 1,
  });
  const [memberListVisibility, setMemberListVisibility] = useState(false);
  const [disableMemberListCheckbox, setDisableMemberListCheckbox] =
    useState(false);

  const { multipleDonorError } = useSelector((state: RootState) => state.error);
  const { currency } = useSelector((state: RootState) => state.app);
  const { setMultipleDonorError, setMultipleDonorObject } = useActions();

  const isAtLeastOneRowComplete = () => {
    return (
      gridData.find(
        (gdr) =>
          gdr.id &&
          validators.isValidId(gdr.id) &&
          gdr.selectedType &&
          (gdr.first_name || gdr.last_name) &&
          gdr.amount &&
          validators.isValidAmount(gdr.amount) &&
          !gdr.isLoading &&
          !gdr.isAutoInserted,
      ) !== undefined
    );
  };

  const isAtLeastOneRowIncomplete = () => {
    return (
      gridData.find((gdr) => Object.keys(gdr.errorMessage).length > 0) !==
      undefined
    );
  };
  useEffect(() => {
    setMultipleDonorError(true, false);
  }, []);

  /** Get Member Lists By Club_Id */
  const toggleMemberListVisibility = () => {
    getDonorList();
    setMemberListVisibility(!memberListVisibility);
  };
  const getDonorList = () => {
    if (memberListVisibility) {
      return;
    }
    let clubId = "90488";
    let clubType = "Rotary Club"; //hardcoded for now
    if (clubId !== undefined && clubId.length !== 0) {
      fetchClubMembersByClubIdandType(clubType, clubId)
        .then((data) => {
          if (
            data.data.payload !== undefined &&
            data.data.payload.length !== 0
          ) {
            setDisableMemberListCheckbox(true);
            const data_with_fullname = addFullNameToClubList(data.data.payload);
            const sortedData_with_fullname = data_with_fullname.sort((a, b) =>
              (a.full_name ?? "").localeCompare(b.full_name ?? ""),
            );
            sortArrayByKey(data_with_fullname, "desc", "full_name");
            const updatedGridData = sortedData_with_fullname.map((gd) => ({
              id: gd.member_id as number,
              first_name: gd.first_name,
              last_name: gd.last_name,
              amount: "",
              selectedType: "1",
              isAutoInserted: false,
              errorMessage: {},
              isLoading: false,
            }));
            setGridData([...updatedGridData, ...gridData]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const getDonorName = (type: string, id: string, index: number) => {
    if (type !== "" && id.length !== 0) {
      gridData[index].isLoading = true;
      setGridData([...gridData]);
      fetchDonorNameByTypeAndMemberId(type, id)
        .then((data) => {
          if (data.status === 200) {
            gridData[index].first_name = data.data.payload.Name;
            delete gridData[index].errorMessage.err_id;
          } else {
            gridData[index].errorMessage["err_id"] = "No matching record found";
            gridData[index].first_name = "";
            gridData[index].last_name = "";
            gridData[index].isLoading = false;
            setGridData([...gridData]);
          }
        })
        .catch((error) => {
          gridData[index].errorMessage["err_id"] = "No matching record found";
          gridData[index].first_name = "";
          gridData[index].last_name = "";
        })
        .finally(() => {
          gridData[index].isLoading = false;
          setGridData([...gridData]);
          if (!isAtLeastOneRowComplete() || isAtLeastOneRowIncomplete()) {
            setMultipleDonorError(true, true);
          } else {
            setMultipleDonorError(false, true);
          }
        });
    }
  };

  // To do - fetchDonorNameByTypeAndMemberId
  //const api_url = config.api_url;
  //const currencyCode = currency.code && currency.code.toUpperCase();
  return (
    <Container
      className="multipledonorgrid__container"
      id="multiple-donor-grid"
    >
      <MultipleDonorHeading>Multiple donors</MultipleDonorHeading>
      {/* {((individual.type == "Individual" || organization.type == "Rotary Club") && (clubName != "Rotary International" && clubId != 1)) && */}
      <MemberListCheckbox
        memberListVisibility={memberListVisibility}
        toggleMemberListVisibility={toggleMemberListVisibility}
        clubName={"Arecibo"} //To do make dynamic
        disableMemberListCheckbox={disableMemberListCheckbox}
      />
      {/* } */}
      {!isAtLeastOneRowComplete() &&
        multipleDonorError.status &&
        multipleDonorError.visibility && (
          <div className="mdg-empty-row-error-msg">
            <label>Please complete at least one row.</label>
          </div>
        )}

      <MultipleDonorGrid
        currencyCode={currency?.code.toUpperCase() as string}
        multipleDonorConfig={multipleDonorConfig}
        gridData={gridData}
        onChange={({ index, change }) => {
          setMultipleDonorObject(gridData);
          gridData[index] = {
            ...gridData[index],
            ...change,
          };
          if (change.id !== undefined) {
            gridData[index].first_name = "";
            gridData[index].last_name = "";
            if (validators.isValidId(change.id)) {
              delete gridData[index].errorMessage.err_id;
            } else {
              gridData[index].errorMessage["err_id"] =
                "Please enter a numeric value that does not start with zero.";
            }
          } else if (change.amount !== undefined) {
            if (
              change.amount === "" ||
              validators.isValidAmount(change.amount)
            ) {
              delete gridData[index].errorMessage.err_amount;
            } else {
              gridData[index].errorMessage["err_amount"] =
                "For amount field, please enter a numeric value that does not start with zero.";
            }
          }

          if (gridData[index].isAutoInserted === true) {
            delete gridData[index].isAutoInserted;
          }
          let last = gridData[gridData.length - 1];
          if (last.isAutoInserted !== true) {
            gridData.push({
              ...getDefaultRows(1)[0],
            });
          }
          setGridData((prevGridData) => {
            const updatedGridData = [...prevGridData];
            return updatedGridData;
          });
          if (!isAtLeastOneRowComplete() || isAtLeastOneRowIncomplete()) {
            setMultipleDonorError(true, true);
          } else {
            setMultipleDonorError(false, true);
          }
          if (
            change.selectedType !== undefined &&
            change.selectedType.length !== 0 &&
            validators.isValidId(gridData[index].id)
          ) {
            let type = getTypeOptionsLabelByValue(gridData[index].selectedType);
            // to do - fetchDonorNameByTypeAndMemberId api call
            if (type.length > 0)
              getDonorName(type[0].label, gridData[index].id, index);
          }
        }}
        onClear={({ index }) => {
          gridData[index] = getDefaultRows(1)[0];
          setGridData(gridData);
        }}
        onDelete={({ index }) => {
          const remainingRows = gridData.filter((td, i) => i !== index);
          setMultipleDonorObject(remainingRows);
          setGridData(remainingRows);
          if (!isAtLeastOneRowComplete() || isAtLeastOneRowIncomplete()) {
            setMultipleDonorError(true, true);
          } else {
            setMultipleDonorError(false, true);
          }
        }}
        onBlur={({ index, data }) => {
          if (
            data.donorId &&
            gridData[index].errorMessage.err_id === undefined
          ) {
            let type = getTypeOptionsLabelByValue(gridData[index].selectedType);
            // to do - fetchDonorNameByTypeAndMemberId api call
            if (type.length > 0)
              getDonorName(type[0].label, data.donorId, index);
          }
        }}
        multipleDonorError={multipleDonorError}
      />

      <MultipleDonorGridFooter
        currencyCode={currency?.code.toUpperCase() as string}
        amount={""}
        rows={gridData}
      />
    </Container>
  );
};

export default MultipleDonor;
