import { MultipleDonoGridDataItem } from "shared/types";

export const getDefaultRows = (count: number) => {
  const arr = new Array(count);
  return arr.fill({
    id: "",
    first_name: "",
    last_name: "",
    amount: "",
    selectedType: "",
    isAutoInserted: true,
    errorMessage: {},
    isLoading: false,
  });
};

export const validators = {
  isValidId: (id: string) => {
    return /^[1-9][0-9]{0,11}$/g.test(id) === true;
  },
  isValidAmount: (amount: string) => {
    return /^[1-9][0-9]{0,}$/g.test(amount) === true;
  },
};

export const typeOptions = [
  { label: "Alumni Association", value: "18" },
  { label: "Associate Foundation", value: "17" },
  { label: "Business", value: "2" },
  { label: "Charitable Organization", value: "14" },
  { label: "Club & District Foundation", value: "15" },
  { label: "District", value: "7" },
  { label: "Educational", value: "10" },
  { label: "GB&I", value: "11" },
  { label: "Individual", value: "1" },
  { label: "Interact Club", value: "5" },
  { label: "Multi-District", value: "16" },
  { label: "Official Licensee", value: "20" },
  { label: "RCC", value: "6" },
  { label: "RI", value: "9" },
  { label: "Rotaract Club", value: "4" },
  { label: "Rotary Club", value: "3" },
  { label: "Zone", value: "8" },
];

export const getTypeOptionsLabelByValue = (selectedType: string) => {
  return typeOptions.filter((options) => options.value === selectedType);
};

export const addFullNameToClubList = (data: MultipleDonoGridDataItem[]) => {
  return data.map((row) => ({
    ...row,
    full_name: row.last_name + " " + row.first_name,
  }));
};

export const sortArrayByKey = <T extends Record<string, any>>(
  collection: T[],
  sortOrder: string,
  key: keyof T,
) => {
  if (sortOrder === "desc") {
    return collection.sort((a, b) => b[key].localeCompare(a[key]));
  }
  return collection.sort((a, b) => a[key].localeCompare(b[key]));
};
