import { styled } from "@mui/system";
import { bp, color, fontSize } from "shared/styles";
import secure from "shared/assets/secure.svg";

const SecurityLockWrapper = styled("div")`
  background: url(${secure}) top center no-repeat;
  background-size: 12px 18px;
  margin: 1rem 0;
  padding-top: 28px;
  width: 50%;
  text-align: center;
  color: #263b4d !important;
  width: 90%;
  margin: auto;
  ${bp("sm")`
    width: 90%;
    margin: auto;
  `}
  div {
    ${fontSize(14, 22.4)}
    text-transform: none;
    a {
      color: ${color.navy} !important;
      text-decoration: underline !important;
    }
  }
  hr {
    height: 1px !important;
    margin: 20px 0 !important;
    background: none !important;
    background-color: #263b4d !important;
  }
`;

const PrivacyPolicyText = styled("div")`
  text-align: left;
`;

const SecurityLock = () => {
  const htmlToRender = `Your privacy is important to Rotary. The personal data you share with Rotary will only be used for official Rotary business. This means that the personal data you provide will primarily be used for financial processing, supporting The Rotary Foundation, communicating key organizational messages and responding to your inquiries. Personal data collected on this form is subject to <a href="https://my.rotary.org/en/privacy-policy" target="_blank">Rotary’s Privacy Policy</a>.`;

  return (
    <SecurityLockWrapper>
      <div>Your data is safe.</div>
      <hr />
      <PrivacyPolicyText
        dangerouslySetInnerHTML={{
          __html: htmlToRender,
        }}
      />
    </SecurityLockWrapper>
  );
};

export default SecurityLock;
