import React, { ChangeEvent, useState, MouseEvent, useEffect } from "react";
import { styled, css } from "@mui/system";
import { CustomButton, color, fontSize } from "shared/styles";
import LoadingThrobber from "components/LoadingThrobber";
import TextInput from "components/Form/components/TextInput";
import { useSelector } from "react-redux";
import { RootState } from "shared/state/store";
import { validateTrackedGiftId } from "shared/utils/inputValidation";
import { Box } from "@mui/material";
import useActions from "Hooks/useActions";
import { fetchTrackedGiftInfo } from "shared/utils/api";

interface SearchButtonProps {
  id: string;
  color: string;
  onClick: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  primary: boolean;
  shouldReset: boolean;
}

const dynamicStyle = (props: SearchButtonProps) => css`
  color: ${props.color};
`;

const TrackedGiftContainer = styled(Box)({
  boxSizing: "border-box",
  marginLeft: "18px",
  marginRight: "18px",
  marginTop: "20px",
});

const TrackedGiftDetails = styled("div")`
  margin-bottom: 30px;
`;

const TrackedGiftName = styled("div")`
  border-bottom: 1px solid ${color.navy};
  color: ${color.navy};
  ${fontSize(24, 30)}
  font-weight: 300;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-top: 15px;
`;

const TrackedGiftDetailsContainer = styled("div")`
  margin-bottom: 5px;
  label {
    font-weight: 700;
    display: inline;
  }
`;
const TrackedGiftDetailsRow = styled("div")`
  display: block;
`;

const ActualData = styled("div")`
  display: inline;
`;

const SearchButton = styled(CustomButton)<SearchButtonProps>(({ theme }) => ({
  marginBottom: "40px",
  [theme.breakpoints.up("xs")]: {
    minWidth: "170px",
  },
  ...dynamicStyle,
}));

const TrackedGift = () => {
  const {
    setTrackedGiftId,
    setTrackedGiftFundId,
    setTrackedGiftSubFundId,
    setTrackedGiftName,
    setTrackedGiftAlias,
    setTrackedGiftAliasDescription,
    setTrackedGiftError,
    setSelectedFundUniqueId,
    setDedicationVisibilityError,
  } = useActions();
  const [trackedGiftIdInput, setTrackedGiftIdInput] = useState("");
  const [trackedGiftErrorMessage, setTrackedGiftErrorMessage] = useState("");
  const [trackedGiftNameDisplay, setTrackedGiftNameDisplay] = useState("");
  const [trackedGiftAliasDisplay, setTrackedGiftAliasDisplay] = useState("");
  const [trackedGiftDesignation, setTrackedGiftDesignation] = useState("");
  const [trackedGiftLoading, setTrackedGiftLoading] = useState(false);
  const { trackedGiftError } = useSelector((state: RootState) => state.error);
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTrackedGiftIdInput(event.target.value);
  };

  const setCommonState = (
    id = "",
    fund = "",
    subFund = "",
    giftName = "",
    aliasDescriptionTranslation = "",
    alias = "",
    designation = "",
    aliasDescription = "",
  ) => {
    setTrackedGiftId(id);
    setTrackedGiftFundId(fund);
    setTrackedGiftSubFundId(subFund);
    setTrackedGiftName(giftName);
    setTrackedGiftNameDisplay(giftName);
    setTrackedGiftAlias(aliasDescriptionTranslation);
    setTrackedGiftAliasDisplay(alias);
    setTrackedGiftDesignation(designation);
    setTrackedGiftAliasDescription(aliasDescription);
  };

  const onSearchTrackedGifId = (trackedGiftId: string) => {
    setTrackedGiftLoading(true);
    const trackedGiftIdInputTrimmed = trackedGiftId.trim().toUpperCase();
    if (validateTrackedGiftId(trackedGiftIdInputTrimmed)) {
      //To do get user details for API
      fetchTrackedGiftInfo(trackedGiftIdInputTrimmed)
        .then((response) => {
          setTrackedGiftLoading(false);
          if (response) {
            const trackedGiftData = response?.data.payload;
            switch (trackedGiftData.status.toLowerCase()) {
              case "active":
                setTrackedGiftErrorMessage("");
                setCommonState(
                  trackedGiftData.Id,
                  trackedGiftData.Fund,
                  trackedGiftData.SubFund,
                  trackedGiftData.GiftName,
                  trackedGiftData.AliasDescriptionTranslation,
                  trackedGiftData.Alias,
                  trackedGiftData.Designation,
                  trackedGiftData.AliasDescription,
                );
                setTrackedGiftError(false, false);
                //   const paymentProfileData = {
                //     country: country,
                //     currency: currency,
                //     transactionTypeAlias: trackedGiftData[0].Alias,
                //     transactionFlow,
                //     customerType,
                //     api_url,
                //   };
                //   setTransactionTypeAlias(trackedGiftData[0].Alias);
                //   setPaymentProfileState(api_url, paymentProfileData, setPaymentProfile, setFoundation, 'TrackedGift');
                break;
              case "ineligible":
                setCommonState();
                setTrackedGiftError(true, true);
                setTrackedGiftErrorMessage(
                  "This donor does not have permission to give to this tracked gift. Please confirm tracked gift ID or select a donor with this permission.",
                );
                break;
              case "pending":
                setCommonState();
                setTrackedGiftError(true, true);
                setTrackedGiftErrorMessage(
                  "This tracked gift is not ready to accept funds. Please check back later.",
                );
                break;
              case "closed":
                setCommonState();
                setTrackedGiftError(true, true);
                setTrackedGiftErrorMessage(
                  "This tracked gift is no longer accepting funds.",
                );
                break;
              case "invalid":
                setCommonState();
                setTrackedGiftError(true, true);
                setTrackedGiftErrorMessage(
                  "This tracked gift was not found. Please enter a valid tracked gift ID.",
                );
                break;
              default:
                setCommonState();
                setTrackedGiftError(true, true);
                setTrackedGiftErrorMessage(
                  "There was an issue with your request. Status: " +
                    "" +
                    trackedGiftData.status,
                );
            }
          } else {
            setCommonState();
            setTrackedGiftError(true, true);
            setTrackedGiftErrorMessage("Please enter a valid tracked gift ID.");
          }
        })
        .catch((error) => {
          setCommonState();
          setTrackedGiftError(true, true);
          setTrackedGiftErrorMessage("Please enter a valid tracked gift ID.");
          setTrackedGiftLoading(false);
        });
    } else {
      setCommonState();
      setTrackedGiftError(true, true);
      setTrackedGiftErrorMessage("Please enter a valid tracked gift ID.");
      setTrackedGiftLoading(false);
    }
  };
  useEffect(() => {
    setSelectedFundUniqueId(null, null);
    setDedicationVisibilityError(false, false);
  }, []);
  return (
    <TrackedGiftContainer>
      <TextInput
        id="TrackedGift"
        label="Enter Tracked Gift ID"
        onChange={onInputChange}
        value={trackedGiftIdInput}
        required
        error={trackedGiftError.visibility && trackedGiftError.status}
        errorMessages={[trackedGiftErrorMessage]}
      />
      <SearchButton
        id="SearchButton"
        onClick={(e) => {
          e.preventDefault();
          onSearchTrackedGifId(trackedGiftIdInput);
        }}
        primary
        color="primary"
        shouldReset
      >
        Search
      </SearchButton>
      {trackedGiftLoading && (
        <LoadingThrobber forPurpose={"Search"}></LoadingThrobber>
      )}
      {trackedGiftNameDisplay && (
        <TrackedGiftDetails>
          <TrackedGiftName>{trackedGiftNameDisplay}</TrackedGiftName>
          <TrackedGiftDetailsContainer>
            <TrackedGiftDetailsRow>
              <label htmlFor="Alias">{"Alias"}: </label>
              <ActualData id="Alias">{trackedGiftAliasDisplay}</ActualData>
            </TrackedGiftDetailsRow>
            <TrackedGiftDetailsRow>
              <label htmlFor="Designation">{"Designation"}: </label>
              <ActualData id="Designation">{trackedGiftDesignation}</ActualData>
            </TrackedGiftDetailsRow>
          </TrackedGiftDetailsContainer>
        </TrackedGiftDetails>
      )}
    </TrackedGiftContainer>
  );
};

export default TrackedGift;
