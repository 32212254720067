import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "shared/state/store";
import { styled } from "@mui/system";
import { pxToRem, errorIconStandalone } from "shared/styles";
import ModuleContainer from "components/ModuleContainer";
import PayInvoiceGrid from "./components/PayInvoiceGrid";
import useActions from "Hooks/useActions";

import {
  calculateBalanceAmount,
  openItemNumberObject,
  createInvoiceExchangeRateObject,
  exchangedAmount,
  getPayInvoiceOfficialLicenseeList,
} from "./utils/utils.payInvoice";
import {
  Config,
  Currency,
  Rates,
  AmountConfig,
  SelectOptions,
  CountryCurrencies,
  Currencies,
} from "shared/types";
import { InvoiceGridDataItem, Rate } from "shared/types";
import {
  getAmountPlaceholder,
  getCurrencyByCode,
  getCurrencyById,
} from "shared/utils";
import Amount from "components/Amount";
import { fetchInvoiceListByClubTypeAndClubId } from "shared/utils/api";

const PayInvoiceContainer = styled("div")`
backgroundColor: "#EDF4F8",
padding: "1.875rem 7vw",
border: "1px solid #ccc",
fontColor: "#263b4c",
marginLeft: pxToRem(150),
marginRight: pxToRem(150),
marginTop: pxToRem(20),
marginBottom: pxToRem(70),
[theme.breakpoints.down("sm")]: {
  marginLeft: pxToRem(30),
  marginRight: pxToRem(30),
`;

const PayInvoiceTitle = styled("div")`
  left: 1px;
  font-size: 1.5rem;
  padding-bottom: 6px;
  font-weight: 300;
  margin-bottom: 1rem;
  border-bottom: 1px solid #333333;
`;

const InvoiceError = styled("div")`
  color: #c10042;
`;

const AmountContainer = styled("div")`
  padding: 3.125rem 5vw !important;
`;

const ErrorBlockMsg = styled("div")`
  color: #c10042;
  font:
    14px "Open Sans",
    Arial,
    sans-serif;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 30px;
  padding: 8px 10px 8px 15px;
  border: 1px solid #c10042;
  background-color: #f9e5ec;
  position: relative;
  &::after {
    ${errorIconStandalone}
    position: absolute;
    display: block;
    top: ${pxToRem(8)};
    right: -30px;
    content: "";
  }
`;

const PayInvoice = () => {
  const {
    setListItemsObject,
    setPaymentAmount,
    setPayInvoiceBillingCurrency,
    setPayInvoiceExchangeRate,
    setPaymentAmountError,
    setOpenItemsError,
  } = useActions();
  const {
    listItemsObject,
    paymentAmount,
    payInvoiceBillingCurrency,
    payInvoiceExchangeRate,
  } = useSelector((state: RootState) => state.staffUseOnly);
  const { openItemsError } = useSelector((state: RootState) => state.error);
  const { config, currencyId } = useSelector((state: RootState) => state.app);

  const [gridData, setGridData] = useState<InvoiceGridDataItem[] | []>([]);
  const [openItemsAmountSum, setOpenItemsAmountSum] = useState(0);
  const [currency, setCurrency] = useState("");
  const [invoiceDataError, setInvoiceDataError] = useState(false);
  const toCurrency = getCurrencyById(
    config?.currencies as Currencies,
    currencyId,
  );
  let customerType = "Rotary Club";
  // let customerType = 'Official Licensee';

  useEffect(() => {
    fetchInvoiceListByClubTypeAndClubId("Rotary Club", "84138").then((data) => {
      let invoiceListResponse = data.data.payload;
      if (invoiceListResponse.list && invoiceListResponse.list.length > 0) {
        setInvoiceDataError(false);
        let invoiceList = invoiceListResponse.list;
        let currency = "";
        if (customerType == "Official Licensee") {
          currency = "USD";
          setPayInvoiceBillingCurrency(currency);
          invoiceList = getPayInvoiceOfficialLicenseeList(
            invoiceListResponse.list,
            invoiceListResponse.billingToUsd,
          );
          setGridData(invoiceList);
          let { listArray, sum } = openItemNumberObject(invoiceList);
          setListItemsObject(listArray);
          setOpenItemsAmountSum(parseFloat(sum.toFixed(2)));
          const usdCurrencyObject = getCurrencyByCode(
            config?.currencies as Currencies,
            "usd",
          );
          let currencyAmount: number = sum;
          if (sum.toString() === "" || sum < 0) {
            if (usdCurrencyObject !== null) {
              currencyAmount = parseFloat(
                getAmountPlaceholder(usdCurrencyObject),
              );
            } else {
              currencyAmount = 0.0; // Default value
            }
          }
          if (typeof currencyAmount === "number" && !isNaN(currencyAmount)) {
            setPaymentAmount(currencyAmount.toFixed(2));
            if (currencyAmount > 0) {
              setPaymentAmountError(false, false);
            }
          } else {
            setPaymentAmount("0"); // Default value
          }
        } else {
          setCurrency(invoiceListResponse.currency);
          setPayInvoiceBillingCurrency(invoiceListResponse.currency);
          setGridData(invoiceListResponse.list);
          let { listArray, sum } = openItemNumberObject(
            invoiceListResponse.list,
          );
          setListItemsObject(listArray);
          setOpenItemsAmountSum(parseFloat(sum.toFixed(2)));
          const invoiceExchangeRateObject = createInvoiceExchangeRateObject(
            invoiceListResponse.billingToUsd,
            invoiceListResponse.usdToBilling,
            invoiceListResponse.currency.toUpperCase(),
          );
          setPayInvoiceExchangeRate(invoiceExchangeRateObject);
          let exchangedSumAmount = exchangedAmount(
            config?.currencies as Currencies,
            invoiceExchangeRateObject,
            invoiceListResponse.currency,
            toCurrency,
            parseFloat(sum.toFixed(2)),
          );
          if (exchangedSumAmount == "") {
            if (toCurrency.code.toUpperCase() == "INR") {
              exchangedSumAmount = "0.00";
            } else {
              exchangedSumAmount = getAmountPlaceholder(toCurrency);
            }
          }
          setPaymentAmount(exchangedSumAmount);
          if (parseFloat(exchangedSumAmount) > 0) {
            setPaymentAmountError(false, false);
          }
        }
      }
    });
  }, []);

  const invoiceExchangeRate =
    payInvoiceExchangeRate !== null
      ? payInvoiceExchangeRate
      : (config?.rates as Rates);
  const updatePaymentAmount = (sumAmount: number) => {
    if (customerType == "Official Licensee") {
      const usdCurrencyObject = getCurrencyByCode(
        config?.currencies as Currencies,
        "usd",
      );
      let currencyAmount: number = sumAmount;
      if (sumAmount < 0) {
        if (usdCurrencyObject !== null) {
          currencyAmount = parseFloat(getAmountPlaceholder(usdCurrencyObject));
        } else {
          currencyAmount = 0.0; // Default value
        }
      }
      if (typeof currencyAmount === "number" && !isNaN(currencyAmount)) {
        setPaymentAmount(currencyAmount.toFixed(2));
        if (currencyAmount > 0) {
          setPaymentAmountError(false, false);
        }
      } else {
        setPaymentAmount("0.0"); // Default value
      }
    } else {
      let exchangedSumAmount = exchangedAmount(
        config?.currencies as Currencies,
        invoiceExchangeRate,
        payInvoiceBillingCurrency,
        toCurrency,
        sumAmount,
      );
      if (exchangedSumAmount == "") {
        if (toCurrency.code.toUpperCase() == "INR") {
          exchangedSumAmount = "0.00";
        } else {
          exchangedSumAmount = getAmountPlaceholder(toCurrency);
        }
      }
      if (parseFloat(exchangedSumAmount) > 0) {
        setPaymentAmountError(false, false);
      }
      setPaymentAmount(exchangedSumAmount);
      setOpenItemsAmountSum(sumAmount);
    }
    setOpenItemsAmountSum(sumAmount);
  };
  return (
    <PayInvoiceContainer>
      <label htmlFor="InvoiceOpenItems"></label>
      <ModuleContainer headerText="Payment">
        <PayInvoiceTitle>{"Open items"}</PayInvoiceTitle>
        {openItemsError.visibility &&
          openItemsError.status &&
          !invoiceDataError && (
            <ErrorBlockMsg>
              Please check atleast one invoice to be paid.
            </ErrorBlockMsg>
          )}
        {gridData.length > 0 && (
          <PayInvoiceGrid
            currencyCode={toCurrency.code.toUpperCase()}
            totalBalance={Number(calculateBalanceAmount(gridData))}
            gridData={gridData}
            openItemsError={openItemsError}
            onChange={({ index, change }) => {
              if (change.checked !== undefined) {
                const updatedGridData = [...gridData];
                updatedGridData[index] = {
                  ...updatedGridData[index],
                  ...change,
                };
                setGridData(updatedGridData);
              }

              let listArray = Array.isArray(listItemsObject)
                ? listItemsObject.slice()
                : [];
              let position = -1;
              for (let i = 0; i < listArray.length; ++i) {
                if (
                  listArray[i]["Item number"] === gridData[index].itemNumber
                ) {
                  position = i;
                  break;
                }
              }
              let sumAmount;
              if (position > -1) {
                sumAmount = parseFloat(
                  (
                    parseFloat(openItemsAmountSum.toFixed(2)) -
                    parseFloat(gridData[index].amount)
                  ).toFixed(2),
                );
                updatePaymentAmount(sumAmount);
                listArray.splice(position, 1);
              } else {
                sumAmount = parseFloat(
                  (
                    parseFloat(openItemsAmountSum.toFixed(2)) +
                    parseFloat(gridData[index].amount)
                  ).toFixed(2),
                );
                updatePaymentAmount(sumAmount);
                listArray.push({ "Item number": gridData[index].itemNumber });
              }

              setListItemsObject(listArray);
              if (listArray.length) {
                setOpenItemsError(false, false);
              } else {
                setOpenItemsError(true, true);
              }
            }}
            mode={"dpm"}
          />
        )}
        <AmountContainer>
          <Amount></Amount>
        </AmountContainer>
        {gridData.length === 0 && invoiceDataError && (
          <InvoiceError>There are no open items.</InvoiceError>
        )}
      </ModuleContainer>
    </PayInvoiceContainer>
  );
};

export default PayInvoice;
