import React, { useEffect } from "react";
import {
  CloseBtn,
  Content,
  ContentDedication,
  ContentHeading,
  ContentNoteHeaderPopUp,
  ContentNotePopUp,
  ContentRow,
  ContentText,
  ContentYourDonation,
  DedicationImage,
  DonationGroup,
  Header,
  Heading,
  LinkDiv,
  Main,
  MainContentSection,
  Print,
  PrintIcon,
  PrintLink,
  Subheading,
  TableFormat,
  ViewNotificationDiv,
  Wrapper,
} from "./styles";
import useActions from "Hooks/useActions";
import {
  decodeAmount,
  displayDonorList,
  formatAmountByCurrency,
  formatEmail,
  getCurrencyByCode,
  getFundTitle,
  imgSize,
  printElement,
} from "shared/utils";
import { useSelector } from "react-redux";
import { RootState } from "shared/state/store";
import { Currencies, Currency } from "shared/types";
import RecognitionAmount from "components/RecognitionAmount";
import Address from "components/Address";

interface InfoBoxProps {
  title: string;
  children: React.ReactNode;
}

const InfoBox = ({ title, children }: InfoBoxProps) => (
  <DonationGroup>
    <Subheading confirmation>{title}</Subheading>
    <Content confirmation>{children}</Content>
  </DonationGroup>
);

const PrinterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 29"
    aria-labelledby="title"
  >
    <title id="title">Print</title>
    <path
      d="M28,5V0H7v5H0v15h7v9h21v-9h7V5H28z M9,2h17v3H9V2z M26,27H9V14h17V27z M33,18h-5v-6H7v6H2V7h31V18z M24,17v2
  H11v-2H24z M24,22v2H11v-2H24z"
    />
  </svg>
);

const CloseIcon = () => (
  <svg
    fill="#878D90"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="30px"
    height="30px"
  >
    <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" />
  </svg>
);

const RightArrow = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="13.000000pt"
    height="13.000000pt"
    viewBox="0 0 20.000000 20.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,20.000000) scale(0.100000,-0.100000)"
      fill="#19519C"
      stroke="none"
    >
      <path
        d="M75 159 c-4 -6 3 -21 16 -35 l22 -24 -23 -25 c-19 -20 -21 -28 -12
-37 10 -10 20 -4 49 25 l37 37 -34 35 c-36 37 -45 41 -55 24z"
      />
    </g>
  </svg>
);

const PrintOnPopup = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.000000pt"
    height="13.000000pt"
    viewBox="0 0 42.000000 32.000000"
    aria-labelledby="title"
    preserveAspectRatio="xMidYMid meet"
  >
    <title id="title">Print</title>
    <g fill="#19519C" stroke="none">
      <path
        d="M28,5V0H7v5H0v15h7v9h21v-9h7V5H28z M9,2h17v3H9V2z M26,27H9V14h17V27z M33,18h-5v-6H7v6H2V7h31V18z M24,17v2
  H11v-2H24z M24,22v2H11v-2H24z"
      />
    </g>
  </svg>
);

const DonationPreviewPopUp = () => {
  const { setConfirmationDetailsVisibility } = useActions();
  const {
    config,
    fund,
    namedFundAliasDescription,
    trackedGiftAliasDescription,
    donationType,
  } = useSelector((state: RootState) => state.app);
  const { namedFundsError, trackedGiftError } = useSelector(
    (state: RootState) => state.error,
  );
  const { dedicationVisibility } = useSelector(
    (state: RootState) => state.dedication,
  );
  const lang = "en";
  //   useEffect(() => {
  const isDedication = !!(window as any).rnw.tamaro.instance.transactionInfo[
    "stored_mth_recipient_name"
  ];
  const isDedicationNotification = !!(window as any).rnw.tamaro.instance
    .transactionInfo["stored_mth_ecard_url"];
  const behalfVisibility = false;
  const behalfType = "member";
  const isDonatingOnBehalfOfClubMembers =
    behalfVisibility && behalfType === "member";
  const currency = getCurrencyByCode(
    config?.currencies as Currencies,
    (window as any).rnw.tamaro.instance.transactionInfo["currency"],
  );
  const currencyCode = currency?.code.toUpperCase();
  const amount =
    (window as any).rnw.tamaro.instance.transactionInfo[
      "stored_amount_superunit"
    ] ||
    decodeAmount(
      (window as any).rnw.tamaro.instance.transactionInfo["amount"],
      currency as Currency,
    );
  const amountFormatted = formatAmountByCurrency(amount, currency as Currency);
  const confirmationNumber = (window as any).rnw.tamaro.instance
    .transactionInfo["epp_transaction_id"];
  const taxId = "97-952320154";
  //   },[])
  const foundation = localStorage.getItem("foundationName");
  const transactionFundMatchesState =
    fund?.fundId ===
      (window as any).rnw.tamaro.instance.transactionInfo[
        "stored_campaign_id"
      ] &&
    fund?.fundSubId ===
      (window as any).rnw.tamaro.instance.transactionInfo[
        "stored_campaign_subid"
      ];
  let fundTitle = transactionFundMatchesState
    ? getFundTitle(
        {
          fundTitle: {
            en: fund?.fundTitle?.en || null,
            fr: fund?.fundTitle?.fr || null,
            de: fund?.fundTitle?.de || null,
            es: fund?.fundTitle?.es || null,
            it: fund?.fundTitle?.it || null,
            ja: fund?.fundTitle?.ja || null,
            ko: fund?.fundTitle?.ko || null,
            pt: fund?.fundTitle?.pt || null,
          },
          id: fund?.id as number,
        },
        lang,
      )
    : (window as any).rnw.tamaro.instance.transactionInfo[
        "stored_rnw_purpose_text"
      ];
  if (!namedFundsError.status && namedFundAliasDescription) {
    fundTitle = namedFundAliasDescription;
  }
  if (!trackedGiftError.status && trackedGiftAliasDescription) {
    fundTitle = trackedGiftAliasDescription;
  }
  const recurringAvailable =
    config?.appConfigs.recurringEnabled &&
    fund?.recurring &&
    !behalfVisibility &&
    !(window as any).rnw.tamaro.instance.transactionInfo[
      "stored_mth_recipient_name"
    ];
  let donationTypeDisplay = "";
  let isDedicationDisplay = !!(window as any).rnw.tamaro.instance
    .transactionInfo["stored_mth_recipient_name"];
  if (
    (window as any).rnw.tamaro.instance.transactionInfo[
      "stored_donation_type"
    ] === "onetime"
  ) {
    donationTypeDisplay = "One-time donation";
  } else if (
    (window as any).rnw.tamaro.instance.transactionInfo[
      "stored_donation_type"
    ] === "recurring"
  ) {
    donationTypeDisplay = "Recurring donation";
  }
  const isRecurringTransaction =
    (window as any).rnw.tamaro.instance.transactionInfo[
      "stored_donation_type"
    ] == "recurring";
  const frequency = localStorage.getItem("frequency");
  const frequencyDisplay = frequency && JSON.parse(frequency).name["en"];
  const recurringStartDateDetails = localStorage.getItem(
    "recurringStartDateDetails",
  );
  const startDate =
    recurringStartDateDetails && JSON.parse(recurringStartDateDetails).month;
  const paymentSchedule =
    recurringStartDateDetails &&
    JSON.parse(recurringStartDateDetails).description;
  const fullName =
    (window as any).rnw.tamaro.instance.transactionInfo[
      "stored_localized_full_name"
    ] ||
    `${(window as any).rnw.tamaro.instance.transactionInfo["stored_customer_firstname"]} ${(window as any).rnw.tamaro.instance.transactionInfo["stored_customer_lastname"]}`;
  const phone = (window as any).rnw.tamaro.instance.transactionInfo[
    "stored_customer_phone"
  ];
  const emailFormatted = formatEmail(
    (window as any).rnw.tamaro.instance.transactionInfo[
      "stored_customer_email"
    ],
  );
  const propsAddress = {
    city: (window as any).rnw.tamaro.instance.transactionInfo[
      "stored_customer_city"
    ],
    country: (window as any).rnw.tamaro.instance.transactionInfo[
      "stored_customer_country2"
    ],
    internationalProvince: (window as any).rnw.tamaro.instance.transactionInfo[
      "stored_customer_international_province"
    ],
    state: (window as any).rnw.tamaro.instance.transactionInfo[
      "stored_customer_state"
    ],
    street: (window as any).rnw.tamaro.instance.transactionInfo[
      "stored_customer_street"
    ],
    street2: (window as any).rnw.tamaro.instance.transactionInfo[
      "stored_customer_street2"
    ],
    street3: (window as any).rnw.tamaro.instance.transactionInfo[
      "stored_customer_street3"
    ],
    zip: (window as any).rnw.tamaro.instance.transactionInfo[
      "stored_customer_zip_code"
    ],
    widgetUUID: "rotary-italy",
  };
  const dedicationType = (window as any).rnw.tamaro.instance.transactionInfo[
    "stored_mth_recipient_occasion"
  ];
  const dedicationName = (window as any).rnw.tamaro.instance.transactionInfo[
    "stored_mth_recipient_name"
  ];

  const donorName = (window as any).rnw.tamaro.instance.transactionInfo[
    "stored_mth_sender_name"
  ];

  const notificationType = (window as any).rnw.tamaro.instance.transactionInfo[
    "stored_mth_delivery_method"
  ];

  const recipientEmail = (window as any).rnw.tamaro.instance.transactionInfo[
    "stored_mth_recipient_email"
  ];
  const ecardLink =
    (window as any).rnw.tamaro.instance.transactionInfo[
      "stored_mth_delivery_method"
    ] === "print"
      ? `${(window as any).rnw.tamaro.instance.transactionInfo["stored_mth_ecard_url"]}?print=1`
      : (window as any).rnw.tamaro.instance.transactionInfo[
          "stored_mth_ecard_url"
        ];
  const dedicationImgLocalStorage = localStorage.getItem(
    "selectedDedicationImage",
  );
  const selectedDedicationImage =
    dedicationImgLocalStorage && JSON.parse(dedicationImgLocalStorage);

  const personalMessage = (window as any).rnw.tamaro.instance.transactionInfo[
    "stored_mth_recipient_message"
  ];
  useEffect(() => {
    const body = global.document.getElementsByTagName("body")[0];
    body.style.overflow = "hidden";
    return () => {
      body.style.overflow = "scroll";
    };
  }, []);
  return (
    <Wrapper>
      <Header>
        <Heading>Donation details</Heading>
        <CloseBtn onClick={() => setConfirmationDetailsVisibility(false)}>
          <CloseIcon />
        </CloseBtn>
      </Header>
      <PrintIcon>
        <Print
          href="/"
          onClick={(e) => {
            if (isDedication) {
              const image = document.getElementById("dedication_img");
              imgSize(image as HTMLImageElement);
            }
            if (isDonatingOnBehalfOfClubMembers) {
              let clubMembers;
              displayDonorList(
                clubMembers,
                amountFormatted,
                currencyCode as string,
                currency as Currency,
              );
            }

            let heading = "Donation details";
            printElement(
              document.getElementById("donation_previewdiv"),
              heading,
              document.getElementById("table_format"),
              isDonatingOnBehalfOfClubMembers,
              document.getElementById("your_donation_content"),
              document.getElementById("dedication_content"),
              isDedication,
            );
            e.preventDefault();
            return false;
          }}
        >
          <PrinterIcon />
          <span>Print this page</span>
        </Print>
      </PrintIcon>
      <TableFormat className="table_format" id="table_format"></TableFormat>
      <Main className="modal__main" id="donation_previewdiv">
        <MainContentSection>
          <InfoBox title="Your Donation">
            <ContentYourDonation
              className="modal__content_donation"
              id="your_donation_content"
            >
              <ContentRow>
                <ContentHeading>Confirmation number</ContentHeading>
                <ContentText>{confirmationNumber}</ContentText>
              </ContentRow>
              {foundation && (
                <ContentRow>
                  <ContentHeading>Organization</ContentHeading>
                  <ContentText>{foundation}</ContentText>
                </ContentRow>
              )}
              {taxId && (
                <ContentRow>
                  <ContentHeading>Rotary tax ID</ContentHeading>
                  <ContentText>{taxId}</ContentText>
                </ContentRow>
              )}
              {config?.appConfigs.fundSelectable && (
                <ContentRow>
                  <ContentHeading>Support</ContentHeading>
                  <ContentText>{fundTitle}</ContentText>
                </ContentRow>
              )}
              {donationTypeDisplay && (
                <ContentRow>
                  <ContentHeading>Type</ContentHeading>
                  <ContentText>{donationTypeDisplay}</ContentText>
                </ContentRow>
              )}
              <ContentRow>
                <ContentHeading>Amount</ContentHeading>
                <ContentText>
                  {amountFormatted} {currencyCode}
                  <RecognitionAmount
                    isConfirmation
                    currency={currency as Currency}
                    currencies={config && config.currencies}
                    rates={config && config.rates}
                  />
                </ContentText>
              </ContentRow>
              {isRecurringTransaction && frequencyDisplay && (
                <ContentRow>
                  <ContentHeading>Frequency</ContentHeading>
                  <ContentText>{frequencyDisplay}</ContentText>
                </ContentRow>
              )}
              {isRecurringTransaction && startDate && (
                <ContentRow>
                  <ContentHeading>
                    {/* {(subscriptionEdit && Drupal.t("Next charge date")) || */}
                    Start date
                  </ContentHeading>
                  <ContentText>{startDate}</ContentText>
                </ContentRow>
              )}
              {isRecurringTransaction && paymentSchedule && (
                <ContentRow>
                  <ContentHeading>Payment schedule</ContentHeading>
                  <ContentText
                    dangerouslySetInnerHTML={{
                      __html: paymentSchedule,
                    }}
                  />
                </ContentRow>
              )}
              <ContentRow>
                <ContentHeading>Name</ContentHeading>
                <ContentText>{fullName}</ContentText>
              </ContentRow>
              <ContentRow>
                <ContentHeading>Phone number</ContentHeading>
                <ContentText>{phone}</ContentText>
              </ContentRow>
              <ContentRow>
                <ContentHeading>Email address</ContentHeading>
                <ContentText
                  dangerouslySetInnerHTML={{
                    __html: emailFormatted,
                  }}
                />
              </ContentRow>
              <ContentRow>
                <ContentHeading>Billing address</ContentHeading>
                <ContentText>
                  <Address {...propsAddress} />
                </ContentText>
              </ContentRow>
            </ContentYourDonation>
          </InfoBox>
          {isDedication && (
            <InfoBox
              title={
                dedicationType === "memory" ? "In memory of" : "In honor of"
              }
            >
              <ContentDedication
                className="content_dedication"
                id="dedication_content"
              >
                <ContentRow>
                  <ContentHeading>
                    {dedicationType === "memory"
                      ? "Name of deceased"
                      : "Name of honoree"}
                  </ContentHeading>
                  <ContentText>{dedicationName}</ContentText>
                </ContentRow>
                {isDedicationNotification && (
                  <Content>
                    <ContentRow>
                      <ContentHeading>Name of donor</ContentHeading>
                      <ContentText>{donorName}</ContentText>
                    </ContentRow>
                    <ContentNoteHeaderPopUp>
                      Personalized notification
                    </ContentNoteHeaderPopUp>
                    {notificationType === "letter" ? (
                      ""
                    ) : notificationType === "print" ? (
                      <ContentNotePopUp>
                        To print your personalized notification, click the print
                        link below
                      </ContentNotePopUp>
                    ) : (
                      <ContentNotePopUp
                        dangerouslySetInnerHTML={{
                          __html: `An email with a link to your personalized notification has been sent to: ${recipientEmail}`,
                        }}
                      />
                    )}
                    {notificationType !== "letter" && (
                      <ViewNotificationDiv>
                        <LinkDiv href={ecardLink} target="_blank">
                          {notificationType === "print" ? (
                            <PrintOnPopup></PrintOnPopup>
                          ) : (
                            ""
                          )}
                          <PrintLink className="options__reset-password">
                            {notificationType === "print"
                              ? "Print your personalized notification"
                              : "View personalized notification"}
                          </PrintLink>
                          <RightArrow></RightArrow>
                        </LinkDiv>
                      </ViewNotificationDiv>
                    )}
                    {notificationType !== "letter" && (
                      <ContentRow>
                        <ContentHeading>Design</ContentHeading>
                        <ContentText>
                          <DedicationImage
                            className="ecard-image"
                            id="dedication_img"
                            src={selectedDedicationImage.url}
                            alt={selectedDedicationImage.alt}
                          />
                        </ContentText>
                      </ContentRow>
                    )}
                    {notificationType !== "letter" && personalMessage && (
                      <ContentRow>
                        <ContentHeading>Personal message</ContentHeading>
                        <ContentText>
                          {personalMessage.replace(/\r?\n|\r/g, " ")}
                        </ContentText>
                      </ContentRow>
                    )}
                    <ContentRow>
                      <ContentHeading>Notification method</ContentHeading>
                      <ContentText>
                        {notificationType === "email"
                          ? "Email"
                          : notificationType === "letter"
                            ? "Mail letter"
                            : "Print and deliver it myself"}
                      </ContentText>
                    </ContentRow>
                    {recipientEmail && (
                      <ContentRow>
                        <ContentHeading>
                          Recipient's email address
                        </ContentHeading>
                        <ContentText>{recipientEmail}</ContentText>
                      </ContentRow>
                    )}
                  </Content>
                )}
              </ContentDedication>
            </InfoBox>
          )}
          {/* {behalfVisibility && (
            <InfoBox title={Drupal.t("Donation from")}>
              {isDonatingOnBehalfOfClub && (
                <ContentRow>
                  <ContentHeading>{Drupal.t("Club")}</ContentHeading>
                  {club_type == "Rotary Club" && (
                    <ContentText
                      dangerouslySetInnerHTML={{
                        __html: Drupal.t("Rotary Club of @location", {
                          "@location": clubName,
                        }),
                      }}
                    ></ContentText>
                  )}
                  {club_type == "Rotaract Club" && (
                    <ContentText
                      dangerouslySetInnerHTML={{
                        __html: Drupal.t("Rotaract Club of @location", {
                          "@location": clubName,
                        }),
                      }}
                    ></ContentText>
                  )}
                </ContentRow>
              )}
              {isDonatingOnBehalfOfClubMembers && !!clubMembers.length && (
                <ContentRow table>
                  <HeaderRow>
                    <FooterTotal>{Drupal.t("Donor")}</FooterTotal>
                    <ContentMemberAmtTotal>
                      {Drupal.t("Amount")}
                    </ContentMemberAmtTotal>
                  </HeaderRow>
                  <ContentTable>
                    {clubMembers.map(({ first_name, last_name, amount }) => (
                      <ContentTableRow key={last_name + first_name}>
                        <ContentMember>
                          {first_name} {last_name}
                        </ContentMember>
                        <ContentMemberAmt>
                          {formatAmountByCurrency(amount, currency)}{" "}
                          {currencyCode}
                        </ContentMemberAmt>
                      </ContentTableRow>
                    ))}
                  </ContentTable>
                  <ContentTableRow>
                    <FooterTotal>{Drupal.t("Total")}</FooterTotal>
                    <ContentMemberAmtTotal>
                      {amountFormatted} {currencyCode}
                    </ContentMemberAmtTotal>
                  </ContentTableRow>
                </ContentRow>
              )}
            </InfoBox>
          )} */}
        </MainContentSection>
      </Main>
    </Wrapper>
  );
};

export default DonationPreviewPopUp;
