import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getDedicationErrorKeys } from "shared/utils";

export interface ErrorState {
  [key: string]: {
    status: boolean;
    visibility: boolean;
  };
}

const initialStateError: ErrorState = {
  amountError: {
    status: false,
    visibility: false,
  },
  namedFundsError: {
    status: false,
    visibility: false,
  },
  frequencyError: {
    status: false,
    visibility: false,
  },
  dedicationVisibilityError: {
    status: false,
    visibility: false,
  },
  dedicationNameError: {
    status: false,
    visibility: false,
  },
  recipientEmailError: {
    status: false,
    visibility: false,
  },
  donorNameError: {
    status: false,
    visibility: false,
  },
  grantsError: {
    status: false,
    visibility: false,
  },
  requestSourceError: {
    status: false,
    visibility: false,
  },
  qpqQuantityError: {
    status: false,
    visibility: false,
  },
  paymentTypeError: {
    status: false,
    visibility: false,
  },
  paymentAmountError: {
    status: false,
    visibility: false,
  },
  overrideCreditOrgError: {
    status: false,
    visibility: false,
  },
  multipleDonorError: {
    status: false,
    visibility: false,
  },
  trackedGiftError: {
    status: false,
    visibility: false,
  },
  openItemsError: {
    status: false,
    visibility: false,
  },
  privacyPolicyError: {
    status: false,
    visibility: false,
  },
  clubIDError: {
    status: false,
    visibility: false,
  },
  dpmCountryError: {
    status: false,
    visibility: false,
  },
  rcgfStateProvinceError: {
    status: false,
    visibility: false,
  },
  countryError: {
    status: false,
    visibility: false,
  },
  stateProvinceError: {
    status: false,
    visibility: false,
  },
};

const errorSlice = createSlice({
  name: "error",
  initialState: initialStateError,
  reducers: {
    setErrorKey: (
      state,
      action: PayloadAction<{
        status?: boolean;
        visibility?: boolean;
        key: string;
      }>,
    ) => {
      const { status, visibility, key } = action.payload;
      state[key] = {
        status: status !== undefined ? status : state[key].status,
        visibility:
          visibility !== undefined ? visibility : state[key].visibility,
      };
    },
    setAllErrorsVisibility: (
      state,
      action: PayloadAction<{ visibility: boolean }>,
    ) => {
      const { visibility } = action.payload;
      Object.keys(state).forEach((key) => {
        if (state[key].visibility !== undefined) {
          state[key].visibility = visibility;
        }
      });
    },
    setDedicationErrorsVisibility: (
      state,
      action: PayloadAction<{ visibility: boolean }>,
    ) => {
      const { visibility } = action.payload;
      const dedicationErrorKeys = getDedicationErrorKeys();
      Object.keys(state).forEach((key) => {
        if (
          dedicationErrorKeys.includes(key) &&
          state[key].visibility !== undefined
        ) {
          state[key].visibility = visibility;
        }
      });
    },
  },
});

export const {
  setErrorKey,
  setAllErrorsVisibility,
  setDedicationErrorsVisibility,
} = errorSlice.actions;

export const createSetGrantsError = (status: boolean, visibility: boolean) => {
  return {
    type: "error/setErrorKey",
    payload: {
      status,
      visibility,
      key: "grantsError",
    },
  };
};
export const createSetDedicationNameError = (
  status: boolean,
  visibility: boolean,
) => {
  return {
    type: "error/setErrorKey",
    payload: {
      status,
      visibility,
      key: "dedicationNameError",
    },
  };
};

export const createSetDonorNameError = (
  status: boolean,
  visibility: boolean,
) => {
  return {
    type: "error/setErrorKey",
    payload: {
      status,
      visibility,
      key: "donorNameError",
    },
  };
};

export const createSetRecipientEmailError = (
  status: boolean,
  visibility: boolean,
) => {
  return {
    type: "error/setErrorKey",
    payload: {
      status,
      visibility,
      key: "recipientEmailError",
    },
  };
};

export const createSetDedicationErrorsVisibility = (visibility: boolean) => {
  return {
    type: "errors/setDedicationErrorsVisibility",
    payload: { visibility },
  };
};

export const createSetAmountError = (status: boolean, visibility: boolean) => {
  return {
    type: "error/setErrorKey",
    payload: {
      status,
      visibility,
      key: "amountError",
    },
  };
};

export const createSetFrequencyError = (
  status: boolean,
  visibility: boolean,
) => {
  return {
    type: "error/setErrorKey",
    payload: {
      status,
      visibility,
      key: "frequencyError",
    },
  };
};

export const createSetDedicationVisibilityError = (
  status: boolean,
  visibility: boolean,
) => {
  return {
    type: "error/setErrorKey",
    payload: {
      status,
      visibility,
      key: "dedicationVisibilityError",
    },
  };
};

export const createSetClubIDError = (status: boolean, visibility: boolean) => {
  return {
    type: "error/setErrorKey",
    payload: {
      status,
      visibility,
      key: "clubIDError",
    },
  };
};

export const createSetTrackedGiftError = (
  status: boolean,
  visibility: boolean,
) => {
  return {
    type: "error/setErrorKey",
    payload: {
      status,
      visibility,
      key: "trackedGiftError",
    },
  };
};

export const createSetNamedFundError = (
  status: boolean,
  visibility: boolean,
) => {
  return {
    type: "error/setErrorKey",
    payload: {
      status,
      visibility,
      key: "namedFundsError",
    },
  };
};

export const createSetRequestSourceError = (
  status: boolean,
  visibility: boolean,
) => {
  return {
    type: "error/setErrorKey",
    payload: {
      status,
      visibility,
      key: "requestSourceError",
    },
  };
};

export const createSetQpqQuantityError = (
  status: boolean,
  visibility: boolean,
) => {
  return {
    type: "error/setErrorKey",
    payload: {
      status,
      visibility,
      key: "qpqQuantityError",
    },
  };
};

export const createSetDpmCountryError = (
  status: boolean,
  visibility: boolean,
) => {
  return {
    type: "error/setErrorKey",
    payload: {
      status,
      visibility,
      key: "dpmCountryError",
    },
  };
};

export const createSetRcgfStateProvinceError = (
  status: boolean,
  visibility: boolean,
) => {
  return {
    type: "error/setErrorKey",
    payload: {
      status,
      visibility,
      key: "rcgfStateProvinceError",
    },
  };
};

export const createSetStateProvinceError = (
  status: boolean,
  visibility: boolean,
) => {
  return {
    type: "error/setErrorKey",
    payload: {
      status,
      visibility,
      key: "stateProvinceError",
    },
  };
};

export const createSetOverrideCreditOrgError = (
  status: boolean,
  visibility: boolean,
) => {
  return {
    type: "error/setErrorKey",
    payload: {
      status,
      visibility,
      key: "overrideCreditOrgError",
    },
  };
};

export const createSetPaymentTypeError = (
  status: boolean,
  visibility: boolean,
) => {
  return {
    type: "error/setErrorKey",
    payload: {
      status,
      visibility,
      key: "paymentTypeError",
    },
  };
};

export const createSetCountryError = (status: boolean, visibility: boolean) => {
  return {
    type: "error/setErrorKey",
    payload: {
      status,
      visibility,
      key: "countryError",
    },
  };
};
export const createSetMultipleDonorError = (
  status: boolean,
  visibility: boolean,
) => {
  return {
    type: "error/setErrorKey",
    payload: {
      status,
      visibility,
      key: "multipleDonorError",
    },
  };
};

export const createSetPaymentAmountError = (
  status: boolean,
  visibility: boolean,
) => {
  return {
    type: "error/setErrorKey",
    payload: {
      status,
      visibility,
      key: "paymentAmountError",
    },
  };
};

export const createSetOpenItemsError = (
  status: boolean,
  visibility: boolean,
) => {
  return {
    type: "error/setErrorKey",
    payload: {
      status,
      visibility,
      key: "openItemsError",
    },
  };
};
export default errorSlice.reducer;
