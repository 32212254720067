import React, { useState } from "react";
import { styled } from "@mui/system";
import Checkbox from "components/Form/components/Checkbox";

const MemberListCheckboxWrapper = styled("div")``;

interface MemberListCheckboxProps {
  memberListVisibility: boolean;
  disableMemberListCheckbox: boolean;
  clubName: string;
  toggleMemberListVisibility: () => void;
}

const MemberListCheckbox = ({
  memberListVisibility,
  disableMemberListCheckbox,
  clubName,
  toggleMemberListVisibility,
}: MemberListCheckboxProps) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disableMemberListCheckbox) {
      setShowErrorMessage(true);
    } else {
      toggleMemberListVisibility();
    }
  };

  return (
    <MemberListCheckboxWrapper className="memberlist-checkbox-wrapper">
      <Checkbox
        id="MemberList"
        label={`Add Rotary club of ${clubName} member list`}
        checked={memberListVisibility}
        isDisabled={disableMemberListCheckbox}
        onChange={handleCheckboxChange}
        errorMsg={
          showErrorMessage
            ? 'To reset the table, deselect, "This donation is from multiple donors."'
            : undefined
        }
      />
    </MemberListCheckboxWrapper>
  );
};

export default MemberListCheckbox;
