import { useSelector } from "react-redux";
import { styled } from "@mui/system";
import ModuleContainer from "components/ModuleContainer";
import Country from "components/Country";
import { pxToRem } from "shared/styles";
import Currency from "components/Currency";
import DonationType from "components/DonationType";
import Amount from "components/Amount";
import { RootState } from "shared/state/store";
import {
  checkIsInvoiceFlow,
  checkIsRotaryDirectPage,
  getFrequencyById,
  getFundById,
} from "shared/utils";
import DonationFrequency from "components/DonationFrequency";
import { useMemo } from "react";
import DonationStart from "components/DonationStart";
import MultipleDonor from "components/MultipleDonor";
import { Config } from "shared/types";
import MyDonationLink from "components/MyDonationLink";

const CountryCurrencyWrapper = styled("div")`
  margin-bottom: ${pxToRem(0)};
`;

const Donation = () => {
  const {
    currencyRecurringEnabled,
    selectedFundUniqueId,
    donationType,
    recurringFrequencyId,
    config,
    grantId,
    namedFundId,
    trackedGiftId,
    mode,
    dpmBtnMode,
  } = useSelector((state: RootState) => state.app);
  const { dedicationVisibility } = useSelector(
    (state: RootState) => state.dedication,
  );
  const { grantsError } = useSelector((state: RootState) => state.error);
  const { multipleDonorVisibility } = useSelector(
    (state: RootState) => state.staffUseOnly,
  );
  const fund =
    config &&
    selectedFundUniqueId &&
    getFundById(config.funds, selectedFundUniqueId);

  const frequency = useMemo(() => {
    return recurringFrequencyId ? getFrequencyById(recurringFrequencyId) : null;
  }, [recurringFrequencyId]);
  return (
    <ModuleContainer
      headerText={
        checkIsRotaryDirectPage(config as Config)
          ? "Enrollment details"
          : checkIsInvoiceFlow(config as Config) ||
              (mode === "dpm" && dpmBtnMode == "addPayment")
            ? "Payment"
            : "Donation"
      }
    >
      <CountryCurrencyWrapper>
        <Country />
        <Currency />
      </CountryCurrencyWrapper>
      <MyDonationLink />

      {config &&
        config.appConfigs.recurringEnabled &&
        currencyRecurringEnabled &&
        fund &&
        fund.recurring &&
        !dedicationVisibility &&
        !grantsError.status &&
        grantId === null &&
        namedFundId === null &&
        mode !== "invoice" && ( // Add grants and named fund conditions
          <>
            {config.appConfigs.oneTimeEnabled &&
              config.appConfigs.recurringEnabled && <DonationType />}
            {donationType === "recurring" && <DonationFrequency />}
            {donationType === "recurring" && frequency && (
              <DonationStart frequency={frequency} />
            )}
          </>
        )}
      {
        // behalf.behalfType !== 'member' && todo
        !multipleDonorVisibility && <Amount />
      }
      {multipleDonorVisibility && <MultipleDonor />}
      {/* to do */}
      {/* <CountrySpecificNote>
            {((!config.dpmMode) || (config.dpmMode === true && config.dpm.btnMode !== 'payInvoice')) &&
              getCountrySpecificNote(
                country,
                foundation,
                lang,
                currency,
                fund.id,
                getEndowmentFunds(config),
                behalf,
                isGrantNumberSearched(grantsError.visibility, selectedGrantId),
                config
              )}
          </CountrySpecificNote>
          <CurrencySpecificNote>
            {getCurrencySpecificNote(currency,config)}
          </CurrencySpecificNote>
        {(!config.dpmMode) && (checkIsRotaryDirectPage(config) == true) &&
          <MyDonationLink
            webAppBaseUrl={config.webAppBaseUrl}
            isAuthenticated={user.authenticated}
            api_url={config.api_url}
            id={user.member_id}
            lang={lang}
            donationType={donationType}
            subscriptionEdit={config.subscriptionEdit}
          ></MyDonationLink>
        }
          {behalf.behalfVisibility &&
          <UserClubsWrapper>
            {(behalf.behalfVisibility && behalf.clubs.length > 1) &&
              <UserClubs
                clubs={behalf.clubs}
                club={club}
                type={behalf.behalfType}
                setSelectedClub={setSelectedClub}
                user={user}
                country={country}
                currency={currency}
                setCustomerType={setCustomerType}
                transactionTypeAliasValue={transactionTypeAliasValue}
                transactionFlow={transactionFlow}
                setPaymentProfile={setPaymentProfile}
                setFoundation={setFoundation}
                api_url={config.api_url}
                clubOnChange={clubOnChange}
                behalfHandlers={behalfHandlers}
              />}
            <DonationBehalf
              currency={currency}
              behalf={behalf}
              behalfHandlers={behalfHandlers}
              user={user}
              country={country}
              setCustomerType={setCustomerType}
              transactionTypeAliasValue={transactionTypeAliasValue}
              transactionFlow={transactionFlow}
              setPaymentProfile={setPaymentProfile}
              setFoundation={setFoundation}
              clubType={behalf.club.club_type}
            />
            </UserClubsWrapper>
          }
          {recurringEnabled &&
          currencyRecurringEnabled &&
           fund.recurring &&
            !behalf.behalfVisibility &&
            !dedication.dedicationVisibility &&
            !selectedGrantId &&
            !grantsError.status &&
            config.mode !== 'invoice' &&
            <DonationTypeWrapper>
              {oneTimeEnabled && recurringEnabled &&
              <DonationType
                config={config}
                type={donationType}
                setType={setDonationType}
                oneTimeEnabled={oneTimeEnabled}
                setTransactionFlow={setTransactionFlow}
                btnMode={(config && config.dpm && config.mode === 'dpm') ? config.dpm.btnMode : ''}
                transactionFlow={transactionFlow}
                customerType={customerType}
                transactionTypeAliasValue={transactionTypeAliasValue}
                country={country}
                currency={currency}
                setPaymentProfile={setPaymentProfile}
                fund={fund}
                setFoundation={setFoundation}
              />
              }
          {(!config.dpmMode) && (!checkIsRotaryDirectPage(config)) &&
            <MyDonationLink
              webAppBaseUrl={config.webAppBaseUrl}
              isAuthenticated={user.authenticated}
              api_url={config.api_url}
              id={user.member_id}
              lang={lang}
              donationType={donationType}
              subscriptionEdit={config.subscriptionEdit}
            ></MyDonationLink>
          }
              {donationType === 'recurring' &&
                <DonationFrequency
                  currencies={config.currencies}
                  frequency={frequency}
                  setFrequency={setFrequency}
                  setFrequencyError={setFrequencyError}
                  frequencyError={reducerError.frequencyError}
                  setInterval={setInterval}
                />}
              {donationType === 'recurring' &&
                frequency &&
                <DonationStart
                  key={frequency.id}
                  frequency={frequency}
                  interval={interval}
                  setInterval={setInterval}
                  setStartDateDetails={setStartDateDetails}
                  label={config.subscriptionEdit &&
                    Drupal.t('Choose next charge date') ||
                    Drupal.t('Choose start date')}
                />}
            </DonationTypeWrapper>} */}
    </ModuleContainer>
  );
};

export default Donation;
