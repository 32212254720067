import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material";
import "./index.css";
import App from "App";
import { store } from "shared/state/store";
import { defaultTheme } from "shared/styles";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const body = global.document.getElementsByTagName("body")[0];
body.style.margin = "0";
body.style.backgroundColor = "#eee";

const root = document.getElementById("root") as HTMLElement;

render(
  <I18nextProvider i18n={i18n}>
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={defaultTheme}>
          <App />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  </I18nextProvider>,
  root,
);
