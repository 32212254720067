import { useState, useEffect } from "react";
import { styled, css } from "@mui/system";
import {
  visuallyHidden,
  popup,
  popupCloseButton,
  iconButton,
  iconButtonActive,
  bp,
  pxToRem,
  defaultTheme,
} from "shared/styles";

const StyledRecognitionInfo = styled("div")(({ theme }) => ({
  display: "inline-block",
  lineHeight: 0,
  position: "relative",
  [theme.breakpoints.up("sm")]: {
    marginTop: pxToRem(2),
  },
}));

interface RecognitionInfoIconProps {
  active: boolean;
}

const RecognitionInfoIcon = styled("button")<RecognitionInfoIconProps>`
  ${iconButton()}
  margin: 0 10px 0 0;
  ${(props) =>
    props.active &&
    css`
      ${iconButtonActive()}
    `}

  ${bp("sm")`
      margin: 0 10px;
    `}
  
    &:hover {
    ${iconButtonActive()}
  }
`;

const InfoIconLabel = styled("span")`
  ${visuallyHidden()}
`;

const RecognitionInfoPopup = styled("div")`
  ${popup()}
  position: absolute;
  z-index: 1000;
  top: -6rem;
  left: -0.5rem;
`;

const PopupCloseButton = styled("button")`
  ${popupCloseButton()}
`;

const CloseButtonLabel = styled("span")`
  ${visuallyHidden()}
`;

const RecognitionInfo = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  useEffect(() => {
    const popup = document.querySelectorAll(
      "#RecognitionInfoIcon ~ div",
    )[0] as HTMLElement;
    const windowWidth = window.innerWidth;

    if (popup) {
      popup.style.top = `-${popup.offsetHeight + 20}px`;
      if (windowWidth > defaultTheme.breakpoints.values.sm) {
        popup.style.left = `-${popup.offsetWidth / 2 - 18}px`;
      }
    }
  }, []);

  return (
    <StyledRecognitionInfo onClick={togglePopup}>
      <RecognitionInfoIcon
        type="button"
        id="RecognitionInfoIcon"
        active={isPopupVisible}
      >
        <InfoIconLabel>More information</InfoIconLabel>
      </RecognitionInfoIcon>
      {isPopupVisible && (
        <RecognitionInfoPopup>
          'The amount that will be recorded to your donor account.'
          <PopupCloseButton type="button" onClick={togglePopup}>
            <CloseButtonLabel>'Close'</CloseButtonLabel>
          </PopupCloseButton>
        </RecognitionInfoPopup>
      )}
    </StyledRecognitionInfo>
  );
};

export default RecognitionInfo;
