import RecognitionDestinationSelector from "./RecognitionDestinationSelector";
import { useSelector } from "react-redux";
import { RootState } from "shared/state/store";
import TextInput from "components/Form/components/TextInput";
import CountrySelector from "./CountrySelector";
import StateProvinceSelector from "./StateProvinceSelector";
import { SelectOptions } from "shared/types";
import DatePicker from "../DatePicker";
import useActions from "Hooks/useActions";

const RecognitionFields = () => {
  const {
    setRecognitionDestinationName,
    setRecognitionAddressLine1,
    setRecognitionAddressLine2,
    setRecognitionCity,
    setRecognitionStateProvince,
    setRecognitionPostalCode,
    setRecognitionPhone,
    setRecognitionNameOnCertificate,
    setPresentationDate,
    setDpmCountryError,
    setRecognitionCountry,
    setRecognitionState,
    setRecognitionStateError,
    setRecognitionStateList,
  } = useActions();
  const {
    recognitionCountry,
    recognitionDestination,
    recognitionDestinationName,
    recognitionAddressLine1,
    recognitionAddressLine2,
    recognitionStateProvince,
    recognitionCity,
    recognitionPhone,
    recognitionPostalCode,
    recognitionNameOnCertificate,
  } = useSelector((state: RootState) => state.staffUseOnly);
  const { recognitionStateProvinceList } = useSelector(
    (state: RootState) => state.app,
  );
  const { rcgfStateProvinceError, dpmCountryError } = useSelector(
    (state: RootState) => state.error,
  );

  return (
    <div>
      <RecognitionDestinationSelector />
      {recognitionDestination && recognitionDestination.value === "Other" && (
        <div>
          <TextInput
            id="rcgfSendToName"
            value={recognitionDestinationName}
            onChange={(e) => {
              setRecognitionDestinationName(e.target.value);
            }}
            label={"Send to name"}
          />
          <CountrySelector
            label="Country"
            id="rcgfCountry"
            setDpmCountryError={setDpmCountryError}
            setDpmCountry={setRecognitionCountry}
            setStateError={setRecognitionStateError}
            setStateValue={setRecognitionState}
            setStateList={setRecognitionStateList}
            countryError={dpmCountryError}
          />
          <TextInput
            id="rcgfAddressLine1"
            value={recognitionAddressLine1}
            onChange={(e) => {
              setRecognitionAddressLine1(e.target.value);
            }}
            label={"Address line 1"}
          />
          <TextInput
            id="rcgfAddressLine2"
            value={recognitionAddressLine2}
            onChange={(e) => {
              setRecognitionAddressLine2(e.target.value);
            }}
            label={"Address line 2"}
          />
          <TextInput
            id="rcgfCity"
            value={recognitionCity}
            onChange={(e) => {
              setRecognitionCity(e.target.value);
            }}
            label={"City"}
          />
          {(recognitionCountry && recognitionCountry.toLowerCase() === "us") ||
          (recognitionCountry && recognitionCountry.toLowerCase() === "ca") ? (
            <StateProvinceSelector
              stateProvinceValue={recognitionStateProvince}
              label="State/Province"
              id="rcgfStateProvince"
              selectOptions={recognitionStateProvinceList as SelectOptions[]}
              setValue={setRecognitionState}
              setStateError={setRecognitionStateError}
              stateError={rcgfStateProvinceError}
            />
          ) : (
            <TextInput
              id="rcgfStateProvince"
              value={recognitionStateProvince}
              onChange={(e) => {
                setRecognitionStateProvince(e.target.value);
              }}
              label={"State/Province"}
              required
              error={
                rcgfStateProvinceError.visibility &&
                rcgfStateProvinceError.status
              }
              errorMessages={["This field is required."]}
            />
          )}
          <TextInput
            id="rcgfPostalCode"
            value={recognitionPostalCode}
            onChange={(e) => {
              setRecognitionPostalCode(e.target.value);
            }}
            label={"Postal code"}
          />
          <TextInput
            id="rcgfPhone"
            value={recognitionPhone}
            onChange={(e) => {
              setRecognitionPhone(e.target.value);
            }}
            label={"Phone"}
          />
        </div>
      )}
      {recognitionDestination && (
        <div>
          <TextInput
            id="rcgfNameOnCertificate"
            value={recognitionNameOnCertificate}
            onChange={(e) => {
              setRecognitionNameOnCertificate(e.target.value);
            }}
            label={"Name on certificate"}
          />
          <DatePicker
            id="PresentationDate"
            labelText="Presentation date"
            onDateChange={setPresentationDate}
          />
        </div>
      )}
    </div>
  );
};

export default RecognitionFields;
