export default [
  {
    id: "monthly",
    interval: "3 * *",
    name: {
      und: "Monthly",
      en: "Monthly",
      de: "Monatlich",
      es: "Mensual",
      fr: "Mensuel",
      it: "Mensile",
      ja: "月に一度",
      ko: "매월",
      pt: "Mensal",
    },
  },
  {
    id: "quarterly",
    interval: "3 1,4,7,10 *",
    name: {
      und: "Quarterly",
      en: "Quarterly",
      de: "Vierteljährlich",
      es: "Trimestral",
      fr: "Trimestriel",
      it: "Trimestrale",
      ja: "四半期に一度",
      ko: "매분기",
      pt: "Trimestral",
    },
  },
  {
    id: "annual",
    interval: "3 1 *",
    name: {
      und: "Annually",
      en: "Annually",
      de: "Jährlich",
      es: "Anual",
      fr: "Annuel",
      it: "Annuale",
      ja: "毎年",
      ko: "매년",
      pt: "Anual",
    },
  },
];
