const OverrideCreditOrgHeader = () => (
  <div className="oco-header flex-host">
    <div className="oco-col header">
      <label className="col-heading">Type</label>
    </div>
    <div className="oco-col header">
      <label className="col-heading">ID</label>
    </div>
    <div className="oco-col header">
      <label className="col-heading">Name</label>
    </div>
  </div>
);

export default OverrideCreditOrgHeader;
