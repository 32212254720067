import React from "react";
import PayInvoiceGridRow from "./GridRow";
import Header from "./Header";
import Footer from "../PayInvoiceGrid/Footer";
import { InvoiceGridDataItem } from "shared/types";
import { useSelector } from "react-redux";
import { RootState } from "shared/state/store";
import "./styles.css";

interface PayInvoiceGridProps {
  currencyCode: string;
  totalBalance: number;
  gridData: InvoiceGridDataItem[];
  onChange: (params: { index: number; change: { checked: boolean } }) => void;
  openItemsError: { visibility: boolean; status: boolean };
  mode: string;
}

const PayInvoiceGrid = ({
  currencyCode,
  totalBalance,
  gridData,
  onChange,
  mode,
}: PayInvoiceGridProps) => {
  const { payInvoiceBillingCurrency } = useSelector(
    (state: RootState) => state.staffUseOnly,
  );
  return (
    <div className="inv-container inv-flex-host">
      <Header currencyCode={payInvoiceBillingCurrency} />
      {gridData.map((gd, i) => (
        <PayInvoiceGridRow
          key={i}
          gridRowData={gd}
          onChange={(change) => {
            onChange({ index: i, change });
          }}
          mode={mode}
        />
      ))}
      <Footer totalBalance={totalBalance} />
    </div>
  );
};

export default PayInvoiceGrid;
