import { createSlice } from "@reduxjs/toolkit";

export interface PersonalDetailsState {
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  email: string | null;
}

const initialStatePersonalDetails: PersonalDetailsState = {
  firstName: null,
  lastName: null,
  phone: null,
  email: null,
};

const personalDetailsSlice = createSlice({
  name: "personalDetails",
  initialState: initialStatePersonalDetails,
  reducers: {
    setFirstName: (state, action) => {
      const { firstName } = action.payload;
      state.firstName = firstName;
    },
    setLastName: (state, action) => {
      const { lastName } = action.payload;
      state.lastName = lastName;
    },
    setPhone: (state, action) => {
      const { phone } = action.payload;
      state.phone = phone;
    },
    setEmail: (state, action) => {
      const { email } = action.payload;
      state.email = email;
    },
  },
});

export const { setFirstName, setLastName, setPhone, setEmail } =
  personalDetailsSlice.actions;

export const createSetFirstName = (firstName: string) => ({
  type: "personalDetails/setFirstName",
  payload: { firstName },
});

export const createSetLastName = (lastName: string) => ({
  type: "personalDetails/setLastName",
  payload: { lastName },
});

export const createSetPhone = (phone: string) => ({
  type: "personalDetails/setPhone",
  payload: { phone },
});

export const createSetEmail = (email: string) => ({
  type: "personalDetails/setEmail",
  payload: { email },
});

export default personalDetailsSlice.reducer;
