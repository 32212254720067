import moment from "moment";
import { DateConfig, Frequency } from "./types";

/**
 * Calculate date specific config based on rules for setting the correct
 * RaiseNow interval.
 *
 * @param {Date} now - The users current datetime
 * @returns {Object}
 */
export const getRecurringDateConfig = (
  raiseNowUtcOffset: number,
  now = new Date(),
) => {
  // Current time according to RaiseNow (CET/CEST timezone)
  const nowRN = moment.utc(now).utcOffset(raiseNowUtcOffset);

  // Define a grace period where it's possible to incidentally charge a
  // recurring donation immediately instead of at the next recurring interval
  const beginGracePeriod = nowRN.clone().startOf("month").date(3).hour(21);
  const endGracePeriod = nowRN.clone().startOf("month").date(4).hour(3);

  // Determine if the current time is in the grace period
  const beforeGracePeriod = nowRN.isBefore(beginGracePeriod);
  const duringGracePeriod =
    nowRN.isSameOrAfter(beginGracePeriod) && nowRN.isBefore(endGracePeriod);
  const afterGracePeriod = nowRN.isSameOrAfter(endGracePeriod);

  // Take into account the grace period when determining the start of the next
  // recurring interval
  const firstChargeMonth = beforeGracePeriod
    ? nowRN.month()
    : nowRN.clone().add(1, "months").month();
  const firstChargeDay = duringGracePeriod ? 2 : 3;
  const firstStartDateTime = nowRN
    .clone()
    .startOf("month")
    .month(firstChargeMonth)
    .date(firstChargeDay);

  return {
    baseDateTime: nowRN.toDate(),
    firstStartDateTime: firstStartDateTime.toDate(),
    firstChargeDay,
    firstChargeMonth,
    beforeGracePeriod,
    duringGracePeriod,
    afterGracePeriod,
  };
};

const getMonthlyIntervals = (dateConfig: DateConfig, limit = 1) => {
  const { firstStartDateTime, firstChargeDay } = dateConfig;

  const options = [];
  for (let i = 0; i < limit; i++) {
    const date = new Date(firstStartDateTime);
    date.setMonth(date.getMonth() + i);
    const month = date.toLocaleString("default", { month: "long" });

    options.push({
      label: month,
      value: date.getTime() / 1000, // Convert to Unix timestamp
      interval: `${firstChargeDay} * *`,
      description: `Your monthly donations will start the first week of ${month}.`,
    });
  }

  return options;
};

const getQuarterlyIntervals = (dateConfig: DateConfig, limit = 3) => {
  const { firstStartDateTime, firstChargeDay } = dateConfig;

  const options = [];
  for (let i = 0; i < limit; i++) {
    const date = new Date(firstStartDateTime);
    date.setMonth(date.getMonth() + i * 3);

    const firstQuarter = new Date(date);
    const secondQuarter = new Date(date);
    const thirdQuarter = new Date(date);
    const fourthQuarter = new Date(date);

    firstQuarter.setMonth(date.getMonth());
    secondQuarter.setMonth(date.getMonth() + 3);
    thirdQuarter.setMonth(date.getMonth() + 6);
    fourthQuarter.setMonth(date.getMonth() + 9);

    const quartersTranslated = {
      month1: firstQuarter.toLocaleString("default", { month: "long" }),
      month2: secondQuarter.toLocaleString("default", { month: "long" }),
      month3: thirdQuarter.toLocaleString("default", { month: "long" }),
      month4: fourthQuarter.toLocaleString("default", { month: "long" }),
    };

    options.push({
      label: date.toLocaleString("default", { month: "long" }),
      value: date.getTime() / 1000, // Convert to Unix timestamp
      interval: `${firstChargeDay} ${firstQuarter.getMonth() + 1},${
        secondQuarter.getMonth() + 1
      },${thirdQuarter.getMonth() + 1},${fourthQuarter.getMonth() + 1} *`,
      description: `Your quarterly donations will occur the first week of ${quartersTranslated.month1}, ${quartersTranslated.month2}, ${quartersTranslated.month3}, and ${quartersTranslated.month4}.`,
    });
  }

  return options;
};

const getAnnualIntervals = (dateConfig: DateConfig, limit = 11) => {
  const { firstStartDateTime, firstChargeDay } = dateConfig;

  const options = [];
  for (let i = 0; i < limit; i++) {
    const date = new Date(firstStartDateTime);
    date.setMonth(date.getMonth() + i);

    const monthTranslated = date.toLocaleString("default", { month: "long" });

    options.push({
      label: date.toLocaleString("default", { month: "long" }),
      value: date.getTime() / 1000, // Convert to Unix timestamp
      interval: `${firstChargeDay} ${date.getMonth() + 1} *`,
      description: `Your annual donations will start the first week of ${monthTranslated}.`,
    });
  }

  return options;
};

export const getIntervals = (
  frequency: Frequency,
  raiseNowUtcOffset: number,
  limit?: number,
) => {
  const dateConfig: DateConfig = getRecurringDateConfig(raiseNowUtcOffset);

  switch (frequency.id) {
    case "monthly":
      return getMonthlyIntervals(dateConfig, limit);
    case "quarterly":
      return getQuarterlyIntervals(dateConfig, limit);
    case "annual":
      return getAnnualIntervals(dateConfig, limit);
    default:
      return [];
  }
};
