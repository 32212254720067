import { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/system";
import useActions from "Hooks/useActions";
import {
  getVisibleFunds,
  getFundTitle,
  getFundSubtitle,
  getFundById,
} from "shared/utils";
import CalloutBtn from "components/Form/components/CalloutBtn";
import DropdownFundList from "../DropdownFundList";
import { Category } from "shared/types";
import { RootState } from "shared/state/store";

const Container = styled("div")({});

const Row = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "10px",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

interface PromotedFundListProps {
  activeTab: Category;
  contentIndex: number;
}

const PromotedFundList = ({
  activeTab,
  contentIndex,
}: PromotedFundListProps) => {
  const { setSelectedFundUniqueId, setEndowmentName } = useActions();
  const [subtitleVisibleId, setSubtitleVisibleId] = useState<number | null>(
    null,
  );
  const { selectedFundUniqueId, config, mode, dpmBtnMode } = useSelector(
    (state: RootState) => state.app,
  );
  useEffect(() => {
    config &&
      setSelectedFundUniqueId(activeTab.content[0].defaultFund, config.funds);
  }, [activeTab]);
  let listedFunds: number[] | null = null;
  if (activeTab && activeTab.content && activeTab.content.length > 0) {
    listedFunds = activeTab.content[0]?.config?.funds || [];
  }
  const funds = getVisibleFunds(
    config && config.funds,
    listedFunds,
    config && config.appConfigs.oneTimeEnabled,
  ).map((fundId) => {
    const fundData = config && getFundById(config.funds, fundId);
    if (!fundData) {
      return null;
    }
    return {
      id: fundData.id,
      isSelected: fundId === selectedFundUniqueId,
      title: getFundTitle(
        {
          fundTitle: {
            en: fundData.fundTitle?.en || null,
            fr: fundData.fundTitle?.fr || null,
            de: fundData.fundTitle?.de || null,
            es: fundData.fundTitle?.es || null,
            it: fundData.fundTitle?.it || null,
            ja: fundData.fundTitle?.ja || null,
            ko: fundData.fundTitle?.ko || null,
            pt: fundData.fundTitle?.pt || null,
          },
          id: fundData.id,
        },
        "en",
      ),
      subtitle: getFundSubtitle(
        {
          fundSubTitle: fundData.fundSubtitle
            ? {
                en: fundData.fundSubtitle?.en || undefined,
                fr: fundData.fundSubtitle?.fr || undefined,
                de: fundData.fundSubtitle?.de || undefined,
                es: fundData.fundSubtitle?.es || undefined,
                it: fundData.fundSubtitle?.it || undefined,
                ja: fundData.fundSubtitle?.ja || undefined,
                ko: fundData.fundSubtitle?.ko || undefined,
                pt: fundData.fundSubtitle?.pt || undefined,
              }
            : null,
          id: fundData.id,
        },
        "en",
      ),
      isSubtitleVisible: subtitleVisibleId === fundId,
      isRecurring: fundData.recurring || false,
      onClickFundCalloutBtn: function () {
        config && setSelectedFundUniqueId(fundData.id, config.funds);
        setEndowmentName("Select");
      },
    };
  });
  const fundsMapped = funds.filter((fund) => fund?.title && fund.subtitle);
  const otherFundsAndEndowment =
    config &&
    config.funds
      .filter((configFunds) =>
        funds.some(
          (endowmentsFunds) =>
            configFunds.id === endowmentsFunds?.id && !configFunds.fundSubtitle,
        ),
      )
      .map((fund) => fund.id);

  const onClickSubtitleHandler = useCallback(
    (subtitleId: number, event: React.MouseEvent<HTMLInputElement>) => {
      if (event) {
        event.stopPropagation();
      }
      const isOpeningSubtitle = subtitleVisibleId !== subtitleId;
      setSubtitleVisibleId(isOpeningSubtitle ? subtitleId : null);
    },
    [subtitleVisibleId],
  );

  const numRows = Math.ceil(fundsMapped.length / 2);
  const lastFundIndex = fundsMapped.length - 1;

  // const mode = 'dpm'

  return (
    <Container>
      {Array.from({ length: numRows || 0 }, (_, rowIndex) => (
        <Row key={rowIndex}>
          {Array.from({ length: 2 }, (_, colIndex) => {
            const itemIndex = rowIndex * 2 + colIndex;
            const isLastItem = itemIndex === lastFundIndex;
            const fund = fundsMapped[itemIndex];
            if (!fund) {
              return null;
            }
            const middle =
              fundsMapped.length % 2 !== 0 &&
              fundsMapped.indexOf(fundsMapped[fundsMapped.length - 1]) ===
                fundsMapped.length - 1;
            return (
              <CalloutBtn
                key={fund.id}
                isSelected={fund.isSelected}
                onClickCalloutBtn={fund.onClickFundCalloutBtn}
                title={fund.title}
                subtitle={fund.subtitle}
                isSubtitleVisible={fund.isSubtitleVisible}
                onClickSubtitle={(event: React.MouseEvent<HTMLInputElement>) =>
                  onClickSubtitleHandler(fund.id, event)
                }
                isLastItem={isLastItem}
                isMiddleBottom={middle}
              />
            );
          })}
        </Row>
      ))}
      {mode === "dpm" &&
        dpmBtnMode === "addDonation" &&
        activeTab.title === "Endowment" && (
          <DropdownFundList
            label="Other funds and endowments"
            contentIndex={contentIndex}
            funds={otherFundsAndEndowment}
          />
        )}
    </Container>
  );
};

export default PromotedFundList;
