import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "shared/state/store";
import { SelectOptions } from "shared/types";
import Select from "components/Form/components/Select";
import TextArea from "components/Form/components/TextArea";
import useActions from "Hooks/useActions";

const DpmPaymentComponent = () => {
  const { setPaymentType, setPaymentTypeError, setNotes } = useActions();
  const [placeholder, setPlaceholder] = useState("Select");
  const { config } = useSelector((state: RootState) => state.app);
  const { paymentType, notes } = useSelector(
    (state: RootState) => state.staffUseOnly,
  );
  const { paymentTypeError } = useSelector((state: RootState) => state.error);
  const paymentTypeOptions =
    config &&
    config.funds
      .filter((f) => f.typeName === "Payment")
      .map((pt) => ({
        label: pt?.fundTitle?.["en"] || pt.rnwPurposeText,
        value: pt.id,
      }));

  useEffect(() => {
    if (!paymentType) {
      setPaymentTypeError(true, false);
    } else {
      setPaymentTypeError(false, false);
    }
    //To do once Payment PRofile is ready
    // Also check logic
    // const selectedFundId = paymentType;
    // if (selectedFundId && staffUseOnly.paymentType !== prevProps.staffUseOnly.paymentType) {
    //   const selectedFund = getFundById(funds, selectedFundId);
    //   if (selectedFund) {
    //     const transactionTypeAliasValue = (selectedFund && selectedFund.alias) ? selectedFund.alias : fund.alias;
    //     setTransactionTypeAlias(transactionTypeAliasValue);
    //     const paymentProfileData = {
    //       country: country,
    //       currency: currency,
    //       transactionTypeAlias: transactionTypeAliasValue,
    //       transactionFlow: transactionFlow,
    //       customerType: customerType,
    //     };
    //     setPaymentProfileState(api_url, paymentProfileData, setPaymentProfile, setFoundation);
    //   }
    // }
    return () => {
      setPaymentTypeError(false, false);
    };
  }, [paymentType]);

  return (
    <div>
      <Select
        required
        id="PaymentType"
        label="Payment type"
        placeholder={placeholder}
        options={paymentTypeOptions as SelectOptions[]}
        value={paymentType}
        onChange={(paymentType) => {
          setPaymentType(paymentType.value as number);
          setPlaceholder(paymentType.label);
          if (paymentType && !paymentType.value) {
            setPaymentTypeError(true, true);
          } else {
            setPaymentTypeError(false, false);
          }
        }}
        error={paymentTypeError.visibility && paymentTypeError.status}
        errorMessages={["This field is required."]}
      />
      <TextArea
        label="Notes"
        id="StaffNotes"
        value={notes}
        characterLimit={250}
        charactersRemaining={notes ? 250 - notes.length : 250}
        onChange={(e) => {
          setNotes(e.target.value);
        }}
        subText=""
        maxLength={250}
      />
    </div>
  );
};

export default DpmPaymentComponent;
