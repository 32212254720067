import { useState } from "react";
import { styled, css } from "@mui/system";
import { useSelector } from "react-redux";
import useActions from "Hooks/useActions";
import { fontSize, color } from "shared/styles";
import ModuleContainer from "components/ModuleContainer";
import { RootState } from "shared/state/store";
import { CustomButton } from "shared/styles";
import TextInput from "components/Form/components/TextInput";
import { fetchClubName } from "shared/utils/api";

const SubHeading = styled("div")`
  color:#000000;  
  font: 16px "Open Sans", Arial,sans-serif;
  display: inline-block;
  line-height: 22px;
  font-weight: 400;
  a {
    ${fontSize(14)}
    color: ${color.blue};
    text-decoration: underline!important;
    &:focus,
    &:hover {
      text-decoration: none!important;
    }
`;

const ClubTypeDiv = styled("div")`
  padding-top: 20px;
`;

const FlexDiv = styled("div")`
  display: inline-flex;
  vertical-align: middle !important;
`;

const ClubTypeLabel = styled("label")`
  display: inline-flex !important;
  span {
    padding-right: 30px;
    padding-left: 10px;
    color: black;
    margin-top: 2px !important;
  }
`;

const ClubTypeInput = styled("input")`
  width: 20px;
  height: 20px;
  vertical-align: middle !important;
  &:active,
  &:focus {
    outline: 0;
    border: none;
    box-shadow: none;
  }
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 3px !important;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #bbc3d1;
  background-color: #f8f9fa;
  border-radius: 50%;
  &:checked {
    border: 1px solid #bbc3d1;
    background-color: #20bbe6;
  }
  &:checked + span {
    font-weight: bold;
  }
`;

const DivClubID = styled("div")`
  display: block;
`;

const ClubIDLabel = styled("div")`
  line-height: 28px;
  color: black;
  padding-top: 30px;
  span {
    font-weight: bold;
  }
`;

const SubHeadingClubID = styled("div")`
  color:#000000;  
  display: inline-block;
  font-weight: 400;
  padding-bottom:10px;
  a {
    ${fontSize(14)}
    color: ${color.darkBlue};
    text-decoration: underline!important;
    &:focus,
    &:hover {
      text-decoration: none!important;
    }
`;

interface SearchButtonProps {
  color?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  primary: boolean;
  shouldReset?: boolean;
}

const dynamicStyle = (props: SearchButtonProps) => css`
  color: ${props.color};
`;
const SearchButton = styled(CustomButton)<SearchButtonProps>(({ theme }) => ({
  marginBottom: "40px",
  [theme.breakpoints.up("xs")]: {
    minWidth: "170px",
  },
  ...dynamicStyle,
}));

const ClubDetails = styled("div")``;

const ClubNameField = styled("div")`
  color: ${color.navy};
  ${fontSize(20, 24)}
  font-weight: 300;
  padding-bottom: 10px;
  padding-top: 15px;
  span {
    font-weight: bold;
  }
`;

const ClubInfoClubCredit = () => {
  const { setClubIDError, setClubCreditClubType, setClubIDNumber } =
    useActions();
  const { clubCreditClubType, clubCreditClubIDNumber, config } = useSelector(
    (state: RootState) => state.app,
  );
  const { clubIDError } = useSelector((state: RootState) => state.error);
  const [creditClubName, setCreditClubName] = useState("");
  const clubTypeOptions = ["None", "Rotary Club", "Rotaract Club"];

  const onChangeClubType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClubCreditClubType(event.target.value);
    if (event.target.value === "None") {
      setClubIDError(false, false);
    } else {
      setClubIDError(true, false);
    }
    setClubIDNumber("");
    setCreditClubName("");
  };

  const onClubIDNumberInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.value.length > 12) {
      setClubIDError(true, true);
    } else {
      setClubIDError(false, false);
    }
    if (
      (clubCreditClubType === "Rotary Club" ||
        clubCreditClubType === "Rotaract Club") &&
      event.target.value.length === 0
    ) {
      setClubIDError(true, false);
    }
    if (
      (clubCreditClubType === "Rotary Club" ||
        clubCreditClubType === "Rotaract Club") &&
      event.target.value.length > 0 &&
      !creditClubName
    ) {
      setClubIDError(true, false);
    }
    setClubIDNumber(numericValue);
  };

  const onSearchClubName = () => {
    if (
      clubCreditClubIDNumber.length > 12 ||
      clubCreditClubIDNumber.length === 0
    ) {
      setClubIDError(true, true);
      setCreditClubName("");
    } else if (
      (clubCreditClubType === "Rotary Club" ||
        clubCreditClubType === "Rotaract Club") &&
      clubCreditClubIDNumber.length === 0
    ) {
      setClubIDError(true, false);
      setCreditClubName("");
    } else {
      fetchClubName(clubCreditClubIDNumber, clubCreditClubType)
        .then((result) => {
          if (result?.data.payload.ClubName) {
            setCreditClubName(result?.data.payload.ClubName);
            setClubIDError(false, false);
          }
        })
        .catch(() => {
          setClubIDError(true, true);
          setCreditClubName("");
        });
    }
  };

  return (
    <ModuleContainer headerText="Club Information for Club Credit (optional)">
      <SubHeading>
        Please provide your club type and club ID number to ensure your club
        will be credited for your contribution.
      </SubHeading>
      <ClubTypeDiv>
        {clubTypeOptions.map((value) => (
          <FlexDiv key={value}>
            <ClubTypeLabel>
              <ClubTypeInput
                type="radio"
                id="rdbClubtype"
                name="clubTypeRdb"
                checked={clubCreditClubType === value}
                value={value}
                onChange={(event) => onChangeClubType(event)}
              />
              <span> {value}</span>
            </ClubTypeLabel>
          </FlexDiv>
        ))}
        {!(clubCreditClubType === "None") && (
          <DivClubID>
            <ClubIDLabel>
              <span>Club ID number</span>
            </ClubIDLabel>
            <SubHeadingClubID
              dangerouslySetInnerHTML={{
                __html: `If you do not know your club ID number, you can search for it on <a href="${
                  config && config.appConfigs.webAppBaseUrl
                }/en/club-search" target="_blank">Club Finder</a>. The club ID number is different from your member ID.`,
              }}
            ></SubHeadingClubID>
            <TextInput
              placeholder="Club ID number"
              id="ClubIDNumber"
              error={clubIDError.status && clubIDError.visibility}
              type="number"
              onChange={onClubIDNumberInputChange}
              value={clubCreditClubIDNumber}
              errorMessages={[
                'Please enter a valid club ID number or select "None" for club type.',
              ]}
            />
            <SearchButton
              id="SearchButton"
              onClick={(e) => {
                e.preventDefault();
                onSearchClubName();
              }}
              primary
            >
              Search
            </SearchButton>
            {creditClubName.length > 0 && (
              <ClubDetails>
                <ClubNameField>
                  <span>Club: </span>
                  {creditClubName}
                </ClubNameField>
              </ClubDetails>
            )}
          </DivClubID>
        )}
      </ClubTypeDiv>
    </ModuleContainer>
  );
};

export default ClubInfoClubCredit;
