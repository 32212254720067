import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "components/Form/components/Select";
import { SelectOptions } from "shared/types";
import { RootState } from "shared/state/store";

interface StateProvinceSelectorProps {
  id: string;
  label: string;
  selectOptions: SelectOptions[];
  stateProvinceValue: string | null;
  setValue: (state: string) => void;
  setStateError: (status: boolean, visibility: boolean) => void;
  stateError: { status: boolean; visibility: boolean };
}

const StateProvinceSelector = ({
  id,
  label,
  selectOptions,
  stateProvinceValue,
  setValue,
  setStateError,
  stateError,
}: StateProvinceSelectorProps) => {
  const [placeholder, setPlaceholder] = useState("Select");
  const { recognitionCountry } = useSelector(
    (state: RootState) => state.staffUseOnly,
  );
  useEffect(() => {
    setPlaceholder("Select");
  }, [recognitionCountry]);
  return (
    <div>
      <Select
        id={id}
        label={label}
        options={selectOptions}
        value={stateProvinceValue}
        onChange={(item) => {
          setValue(item.value as string);
          setStateError(false, false);
          setPlaceholder(item.label);
        }}
        required
        error={stateError.visibility && stateError.status}
        errorMessages={["This field is required."]}
        placeholder={placeholder}
      />
    </div>
  );
};

export default StateProvinceSelector;
