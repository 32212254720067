import { SvgIcon } from "@mui/material";
import React from "react";

interface ErrorProps {
  height: number;
  width: number;
}

const Error: React.FC<ErrorProps> = ({ height, width }) => {
  return (
    <SvgIcon sx={{ height: `${height}px`, width: `${width}px` }}>
      <path
        d="M7.5,0A7.5,7.5,0,1,0,15,7.5,7.49,7.49,0,0,0,7.5,0ZM6.4,3H8.6V8.9H6.4V3Zm1.1,9.5a1.2,1.2,0,0,1,0-2.4,1.16,1.16,0,0,1,1.2,1.2A1.29,1.29,0,0,1,7.5,12.5Z"
        fill="#c10042"
      />
    </SvgIcon>
  );
};

export default Error;
