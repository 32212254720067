import { configureStore } from "@reduxjs/toolkit";
import appReducer, { AppState } from "shared/state/app";
import errorReducer, { ErrorState } from "shared/state/error";
import uiReducer, { UiState } from "shared/state/ui";
import dedicationReducer from "shared/state/dedication";
import { DedicationState } from "shared/state/dedication";
import staffUseOnlyReducer, { StaffUseOnlyState } from "../staffUseOnly";
import transactionReducer, { TransactionState } from "shared/state/transaction";
import personalDetailsReducer, {
  PersonalDetailsState,
} from "../personalDetails";
import billingAddressReducer, { BillingAddressState } from "../billingAddress";
import userReducer, { UserState } from "../user";
import raiseNowReducer, { RaiseNowState } from "../raiseNow";

export interface RootState {
  app: AppState;
  error: ErrorState;
  ui: UiState;
  dedication: DedicationState;
  staffUseOnly: StaffUseOnlyState;
  transaction: TransactionState;
  personalDetails: PersonalDetailsState;
  billingAddress: BillingAddressState;
  user: UserState;
  raiseNow: RaiseNowState;
}

const reducer = {
  app: appReducer,
  error: errorReducer,
  ui: uiReducer,
  dedication: dedicationReducer,
  staffUseOnly: staffUseOnlyReducer,
  transaction: transactionReducer,
  personalDetails: personalDetailsReducer,
  billingAddress: billingAddressReducer,
  user: userReducer,
  raiseNow: raiseNowReducer,
};

export const store = configureStore({
  reducer,
});
