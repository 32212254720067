import { orange } from "@mui/material/colors";
import { styled, css } from "@mui/system";
import {
  bp,
  btnconfirmation,
  color,
  confirmationTitle,
  ctaLink,
  CustomButton,
  donationSubheading,
  fixGridUnit,
  flexGridUnit,
  MainWrapper,
  otherWaysHeading,
  otherWaysSubheading,
  pxToRem,
  rightboxSubheading,
} from "shared/styles";

interface ContentProps {
  confirmation?: boolean;
  columns?: boolean;
}

export const Content = styled("div")<ContentProps>(
  ({ theme, confirmation, columns }) => ({
    marginBottom: pxToRem(6),

    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },

    ...(confirmation && {
      [theme.breakpoints.up("sm")]: {
        width: "calc(100% / 3 * 2)",
      },
    }),

    ...(columns && {
      [theme.breakpoints.up("xs")]: {
        display: "flex",
        paddingBottom: pxToRem(4),
      },
    }),
  }),
);

interface ContentRowProps {
  table?: boolean;
}

export const ContentRow = styled("div")<ContentRowProps>(
  ({ theme, table }) => ({
    fontSize: "14px",
    lineHeight: "20px",
    borderBottom: `1px solid ${color.greyCobble}`,
    color: color.navy,
    display: "flex",
    flexDirection: "column",
    padding: `${pxToRem(11)} 0`,

    "&:last-child": {
      borderBottom: "0 none",
    },

    [theme.breakpoints.up("xs")]: {
      alignItems: "flex-start",
      flexDirection: "row",
      padding: `${pxToRem(7)} 0`,
    },

    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
      lineHeight: "22px",
    },

    ...(table && {
      display: "block",
    }),
  }),
);

interface ContentHeadingProps {
  columns?: boolean;
}

export const ContentHeading = styled("h3")<ContentHeadingProps>(
  ({ theme, columns }) => ({
    fontWeight: 700,
    lineHeight: 1.5,

    [theme.breakpoints.up("xs")]: {
      marginRight: `${pxToRem(24)}`,
      width: "calc(50% - 12px)",
    },

    ...(columns && {
      width: "100% !important",

      [theme.breakpoints.up("xs")]: {
        borderBottom: `1px solid ${color.greyCobble}`,
        marginBottom: `${pxToRem(8)}`,
        padding: `${pxToRem(7)} 0`,
      },
    }),
  }),
);

interface ContentTextProps {
  columns?: boolean;
}

export const ContentText = styled("div")<ContentTextProps>(
  ({ theme, columns }) => ({
    fontWeight: 400,
    color: color.black,
    lineHeight: 1.5,
    wordWrap: "break-word",

    [theme.breakpoints.up("xs")]: {
      width: "calc(50% - 12px)",
    },

    img: {
      display: "block",
      padding: `${pxToRem(6)} 0 0`,

      [theme.breakpoints.up("xs")]: {
        padding: `${pxToRem(6)} 0`,
      },
    },

    ...(columns && {
      width: "100% !important",

      [theme.breakpoints.up("xs")]: {
        margin: `${pxToRem(5)} 0`,
      },
    }),
  }),
);

export const ContentNote = styled("div")(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "20px",
  backgroundColor: color.blueLight,
  padding: `${pxToRem(24)} ${pxToRem(32)}`,

  [theme.breakpoints.up("sm")]: {
    fontSize: "14px",
    lineHeight: "24px",
  },

  span: {
    color: color.navy,
    fontWeight: "bold",
  },
}));

export const Column = styled("div")(({ theme }) => ({
  // Box shadow styles
  WebkitBoxShadow: "0 1px 4px rgba(0, 0, 0, 0.25)",
  MozBoxShadow: "0 1px 4px rgba(0, 0, 0, 0.25)",
  boxShadow: "0 1px 4px rgba(0, 0, 0, 0.25)",
  textAlign: "center",

  [theme.breakpoints.up("sm")]: {
    display: "flex",
    flex: "1",
    flexFlow: "row wrap",
  },

  "&:first-child": {
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
      margin: 0,
      marginRight: `${pxToRem(30)}`,
    },
  },

  img: {
    height: "252px",
    objectFit: "cover",
  },
}));

export const OtherWaysContentInfo = styled("div")(({ theme }) => ({
  marginBottom: "0 !important",
  fontSize: "14px",
  lineHeight: 1.5,
  padding: `${pxToRem(11)} !important`,
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
  },

  "&:last-child": {
    borderBottom: "0 none",

    [theme.breakpoints.up("s_up_rn")]: {
      padding: `${pxToRem(11)} 0 ${pxToRem(11)}`,
    },
  },
}));

export const ConfirmationPreviewBtn = styled("div")`
  padding-top: 20px;
  padding-bottom: 36px;
  color: #004894;
  text-decoration: none !important;
  text-transform: none !important;
`;

export const ConfirmationTools = styled("div")`
  display: flex;
`;

export const Print = styled("a")(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "19px",
  backgroundColor: color.white,
  color: "currentColor",
  margin: "0 auto 0 5% !important",
  padding: `${pxToRem(6)} ${pxToRem(20)} !important`,
  textDecoration: "none",

  [theme.breakpoints.up("sm")]: {
    margin: "0 5% 0 auto !important",
  },

  [theme.breakpoints.up("md")]: {
    margin: `0 ${flexGridUnit()} 0 auto !important`,
  },

  [theme.breakpoints.up("xxl_up")]: {
    margin: `0 ${fixGridUnit()} 0 auto !important`,
  },

  "&:hover, &:focus": {
    color: "inherit",
    textDecoration: "underline",
  },

  svg: {
    boxSizing: "content-box",
    fill: "currentColor",
    height: "1em",
    paddingRight: "0.8em",
    width: "1.2em",
  },
}));

export const DonationWrapper = styled(MainWrapper)(({ theme }) => ({
  "@media (max-width: 30em)": {
    padding: "5%",
  },
}));

export const MainText = styled("p")(({ theme }) => ({
  fontSize: "14px",
  color: color.black,
  lineHeight: 1.8,
  margin: `0 0 ${pxToRem(24)} 0`,

  [theme.breakpoints.up("xs")]: {
    fontSize: "16px",
    lineHeight: 1.5,
  },

  "&:first-child": {
    [theme.breakpoints.up("md")]: {
      marginTop: "-18px",
    },
  },

  "&:last-child": {
    [theme.breakpoints.up("s_up_rn")]: {
      margin: `0 0 ${pxToRem(34)} 0`,
    },
  },

  "&:first-child:last-child": {
    [theme.breakpoints.up("s_up_rn")]: {
      margin: `-18px 0 ${pxToRem(34)} 0`,
    },
  },

  span: {
    fontWeight: 700,
    color: color.navy,
    wordWrap: "break-word",
  },
}));

export const MainContentSection = styled("div")``;

export const DonationGroup = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("s_up_rn")]: {
    backgroundColor: color.offWhite,
    borderTop: `1px solid ${color.navy}`,
    display: "flex",
    flexWrap: "nowrap",
    padding: `${pxToRem(16)} ${pxToRem(24)}`,

    "&:first-child": {
      backgroundColor: color.blueLight,
    },
  },
}));

export const HeadingWithLink = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("s_up_rn")]: {
    padding: `0 ${pxToRem(24)} 0 0`,
    width: "calc(100% / 3)",
  },
}));

interface SubheadingProps {
  confirmation?: boolean;
  columns?: boolean;
}

export const Subheading = styled("h2")<SubheadingProps>`
  ${donationSubheading()};
`;

export const SubheadingLink = styled("div")(({ theme }) => ({
  "& a": {
    fontSize: "14px",
    color: color.blue,
    lineHeight: 1.5,
    textDecoration: "underline !important",

    "&:focus, &:hover": {
      color: color.black,
    },

    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
    },
  },
}));

export const InfoText = styled("p")(({ theme }) => ({
  fontSize: "14px",
  backgroundColor: color.offWhite,
  lineHeight: 1.5,
  textAlign: "center",
  padding: `${pxToRem(52)} 5%`,

  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
  },

  [theme.breakpoints.up("s_up_rn")]: {
    backgroundColor: "transparent",
  },

  "&:last-child": {
    borderBottom: `1px solid ${color.greyCobble}`,
  },

  "& a": {
    color: color.blue,
    position: "relative",
    textDecoration: "underline !important",

    "&:focus, &:hover": {
      color: color.black,
    },
  },
}));

export const ContentColumn = styled("div")(({ theme }) => ({
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  marginTop: pxToRem(10),
  padding: `${pxToRem(11)} 0 0`,

  [theme.breakpoints.up("xs")]: {
    borderBottom: "0 none",
    padding: "0",
    width: `calc(50% - 12px)`,

    "&:first-child": {
      marginRight: pxToRem(24),
    },
  },

  [theme.breakpoints.up("sm")]: {
    fontSize: "1rem",
    lineHeight: "1.375rem",
  },

  [theme.breakpoints.up("s_up_rn")]: {
    marginTop: "0",
  },
}));

export const AccountOptionsWrapper = styled("div")`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  padding-top: 2rem;
  padding-left: 4.3%;
  padding-right: 4.3%;
  background-color: ${color.white};
`;

export const AccountOptionsContentInfo = styled("p")(({ theme }) => ({
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  maxWidth: "550px",
  padding: `0 0 2.5rem`,
  textAlign: "center",

  [theme.breakpoints.up("sm")]: {
    fontSize: "1rem",
    lineHeight: "1.375rem",
  },
}));

export const AccountButtons = styled("div")`
  text-align: center;
  ${bp("s_up")`
    display: flex;
    justify-content: center;
  `}
`;

interface CreateAccountProps {
  primary?: boolean;
  target: string;
}

export const CreateAccount = styled(CustomButton)<CreateAccountProps>`
  line-height: ${pxToRem(22)};
  margin-bottom: 1.5rem;
  padding-left: 1em;
  padding-right: 1em;
  display: table;
  ${bp("s_up")`
    margin: 0 auto;
  `}
`;

export const SignInResetWrapper = styled("div")`
  text-align: center;
`;

export const SignIn = styled(CustomButton)`
  display: block;
  line-height: ${pxToRem(22)};
  margin-bottom: 1.5rem;
  ${bp("s_up")`
    margin: 0 1rem 0.75rem;
  `}
`;

export const SignOut = styled(CustomButton)`
  margin: 0 0 1.5rem;
`;

export const ResetPassword = styled("a")`
  ${ctaLink()}
  margin-bottom: 1.5rem;
`;

export const DedicationImage = styled("img")`
  margin-bottom: ${pxToRem(7)};
  max-width: 100%;
`;

interface EcardLinkProps {
  shouldReset: boolean;
  textTransform: string;
}

export const EcardLink = styled(CustomButton)<EcardLinkProps>`
  margin-bottom: ${pxToRem(7)};
  margin-top: ${pxToRem(23)};
  ${bp("xs_up")`
    margin-left: auto;
    min-width: auto;
    width: calc(50% - 12px);
  `};
`;

export const LargeTitle = styled("p")`
  margin-bottom: 0 !important;
  ${confirmationTitle()};
`;

export const ConfirmationWrapper = styled("div")`
  padding: 4.3% 4.3% 0;
  padding: 0 4.3% 0;
  background-color: white;
  box-sizing: border-box @media (max-width: 30em) {
    padding: 2% 2% 0;
  }
  @media (max-width: 60em) {
    padding: 3% 3% 0;
    padding: 0 3% 0;
  }
  @media (max-width: 90em) {
    padding: 4% 4% 0;
    padding: 0 4% 0;
  }
`;

export const DonationParagraphBox = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "row wrap",

  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

export const ParaColumn = styled("div")(({ theme }) => ({
  flex: "1 1 11em",

  [theme.breakpoints.up("sm")]: {
    display: "flex",
    flex: "1 1 13em",
    flexFlow: "row wrap",

    "&:first-child": {
      justifyContent: "flex-start",
      margin: "0",
      height: "200px",
      marginRight: pxToRem(30),
    },
  },
}));

export const ParaColumn2 = styled("div")(({ theme }) => ({
  flex: "1 1 5em",
  flexFlow: "column nowrap",
  justifyContent: "center",
  alignItems: "center",
  width: "90%",

  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flex: "1 1 5em",
    flexFlow: "row wrap",

    "&:first-child": {
      margin: "0",
    },
  },
}));

export const ParaContentInfo = styled("p")(({ theme }) => ({
  marginBottom: "0!important",
  margin: "0!important",
  padding: `${pxToRem(11)} 0!important`,
  fontSize: "14px",
  lineHeight: "1.5",
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
  },

  [theme.breakpoints.up("s_up_rn")]: {
    padding: `${pxToRem(11)} 0`,
  },

  "&:last-child": {
    borderBottom: "0 none",

    [theme.breakpoints.up("s_up_rn")]: {
      padding: `${pxToRem(11)} 0 ${pxToRem(11)}`,
    },
  },

  "& a": {
    color: color.blue,
    position: "relative",
    textDecoration: "underline!important",
    "&:focus, &:hover": {
      color: color.black,
    },
  },

  "& span": {
    fontWeight: 700,
    color: color.navy,
    wordWrap: "break-word",
  },
}));

export const RightDonationBox = styled("div")`
  box-sizing: border-box;
  position: relative;
  display: flex;
  background: #e7f2f7;
  border: 1px solid #cad5dc;
  width: 97%;
`;

export const RightInnerBox = styled("div")`
  margin-left: ${pxToRem(34)};
  margin-top: ${pxToRem(23)};
`;

export const RightBoxSubheading = styled("div")`
  ${rightboxSubheading()};
`;

export const RightDonationBoxText = styled("p")(({ theme }) => ({
  marginBottom: "0!important",
  margin: "0!important",
  padding: `${pxToRem(6)} 0`,
  fontSize: "14px",
  lineHeight: "1",
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
  },

  [theme.breakpoints.up("s_up_rn")]: {
    padding: `${pxToRem(6)} 0`,
  },

  "&:last-child": {
    borderBottom: "0 none",
    textAlign: "center",
    // [theme.breakpoints.up("s_up_rn")]: {
    padding: `${pxToRem(6)} 0 ${pxToRem(8)}`,
    // },
  },

  "& span": {
    fontWeight: 700,
    color: color.navy,
    wordWrap: "break-word",
  },
}));

// add id='preview-donation'
export const RightDonationBoxUrl = styled("a")`
  text-decoration: underline !important;
  color: #004894;
  cursor: pointer;
  a {
    color: #004894;
    position: relative;
    cursor: pointer;
    &:focus,
    &:hover {
      color: ${color.black};
      cursor: pointer !important;
    }
  }
  span {
    font-weight: 900;
    color: #19519c;
  }
`;

export const RightDonationSecondBoxText = styled("div")`
  font-size: 16px;
  line-height: ${pxToRem(26)};
  text-align: center;
  padding-top: ${pxToRem(47)};
  margin: 0 auto;
`;

export const DonateButtonBox = styled("div")`
  font-size: 16px;
  line-height: ${pxToRem(26)};
  text-align: center;
  padding-top: ${pxToRem(30)};
  padding-bottom: ${pxToRem(12)};
  margin: 0 auto;
`;

export const FlexIcons = styled("div")`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  img {
    max-width: ${pxToRem(55)};
  }
`;

export const IconsBox = styled("div")`
  padding: ${pxToRem(10)};
`;

interface CTALinkProps {
  shouldReset?: boolean;
  href?: string;
  target?: string;
}

export const CTALink = styled(btnconfirmation)<CTALinkProps>`
  margin-bottom: ${pxToRem(25)};
`;

export const CTADiv = styled("div")`
  margin-top: ${pxToRem(5)};
  margin: auto;
`;

export const OtheyWaySubHeading = styled("h2")`
  ${otherWaysSubheading()};
  margin: 0 auto;
  ${bp("s_up_rn")`
    margin: 0 auto;
  `}
`;

export const OtherWaysContentBox = styled("div")`
  font-size: 16px;
  line-height: ${pxToRem(26)};
  text-align: center;
  display: inline-block !important;
  padding-top: ${pxToRem(1)};
  margin: 0 auto;
  padding-bottom: ${pxToRem(15)};
  min-width: 3em !important;

  a {
    display: inline-block !important;
    margin-top: ${pxToRem(20)};
    min-width: 15em !important;
  }
`;

export const OtherWaysHeading = styled("h2")`
  ${otherWaysHeading()};
`;

export const OtherwayParagraph = styled("div")`
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: ${pxToRem(90)};
  ${bp("s_up")`
    margin-left:10em;
    margin-right:10em;
    margin-top:3.5em; 
  `}
`;

export const ContentInfo = styled("p")(({ theme }) => ({
  fontSize: "14px",
  borderBottom: `1px solid ${color.greyCobble}`,
  margin: "0",
  padding: `${pxToRem(11)} 0`,
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
  },

  [theme.breakpoints.up("s_up_rn")]: {
    padding: `${pxToRem(24)} 0`,
  },

  "&:last-child": {
    borderBottom: "0 none",

    [theme.breakpoints.up("s_up_rn")]: {
      padding: `${pxToRem(24)} 0 ${pxToRem(8)}`,
    },
  },
  "& a": {
    color: color.blue,
    position: "relative",
    textDecoration: "underline!important",

    "&:focus, &:hover": {
      color: color.black,
    },
  },
}));

export const ConfirmationWrapperDPM = styled("div")``;

export const ViewNotificationDiv = styled("div")`
  display: flex !important;
  line-height: 18px !important;
  font-size: 16px !important;
  padding-top: 20px !important;
  padding-bottom: 25px !important;
`;

export const LinkDiv = styled("a")`
  display: flex !important;
  text-decoration: none !important;
  outline: none !important;
  pointer: cursor !important;
  &:hover,
  &:focus,
  &:active,
  &:link {
    text-decoration: none;
  }
`;

export const PrintLink = styled("a")`
  text-decoration: underline !important;
  margin-left: 5px !important;
  color: #0050a2 !important;
  &:focus,
  &:hover,
  &:visited,
  &:active {
    color: #0050a2 !important;
    pointer: cursor !important;
    text-decoration: none !important;
  }
`;

export const SocialIconsBox = styled("a")`
  padding: ${pxToRem(10)};
  &:focus,
  &:hover,
  &:visited,
  &:active {
    box-shadow: none !important;
  }
`;

export const EndPolioImageDiv = styled("div")`
  padding-top: 4%;
`;

export const HeadingImage = styled("img")`
  width: 100%;
  height: auto;
  display: block;
`;

export const HeadingImgEndPolio = styled("img")``;

export const CardImgRaiseRotary = styled("img")`
  width: 100%;
  height: auto;
  display: block;
`;

export const CardImgRotaryDirect = styled("img")`
  width: 100%;
  height: auto;
  display: block;
`;

export const CardImgEndPolio = styled("img")``;
