import OverrideCreditOrgGrid from "./components/OverrideCreditOrgGrid";
import "./components/OverrideCreditOrgGrid/styles.css";

const OverrideCreditOrg = () => {
  return (
    <div
      id="override-creditorg-grid"
      className="overridecreditorggrid__container"
    >
      <OverrideCreditOrgGrid />
    </div>
  );
};

export default OverrideCreditOrg;
