import { ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/system";
import TextInput from "components/Form/components/TextInput";
import TextArea from "components/Form/components/TextArea";
import DatePicker from "../DatePicker";
import { RootState } from "shared/state/store";
import RecognitionFields from "../RecognitionFields";
import Anonymous from "../Anonymous";
import OverrideCreditOrgCheckbox from "components/OverrideCreditOrg/components/OverrideCreditOrgCheckbox";
import OverrideCreditOrg from "components/OverrideCreditOrg";
import useActions from "Hooks/useActions";

const OverrideDateContainer = styled("div")({
  width: "48%",
  marginRight: "2%",
  display: "inline-block",
  float: "left",
});

const QuidProQuoContainer = styled("div")({
  width: "calc((100% - 5px)/2)",
  display: "inline-block",
});

const RecognitionFieldsWrapper = styled("div")`
  .field--rcgfSendToName,
  .field--rcgfAddressLine1,
  .field--rcgfCity,
  .field--rcgfPostalCode {
    width: 49%;
    float: left;
    height: 72px;
  }
  .field--rcgfAddressLine2,
  .field--rcgfCountry,
  .field--rcgfStateProvince,
  .field--rcgfPhone {
    width: 49%;
    float: right;
    height: 72px;
  }
  margin-bottom: 1.875rem;
`;

const DPMDonationOnly = () => {
  const {
    setOverrideDate,
    setQpqQuantity,
    setQpqQuantityError,
    setNotes,
    setAnonymous,
    toggelOverrideCreditOrgVisibility,
    setOverrideCreditOrgId,
    setOverrideCreditOrgType,
    setOverrideCreditOrgError,
    setOverrideCreditOrgValidStatus,
  } = useActions();
  const { qpqQuantity, notes, isAnonymous, overrideCreditOrgVisibility } =
    useSelector((state: RootState) => state.staffUseOnly);

  return (
    <div>
      <div>
        <div>
          <OverrideDateContainer>
            <DatePicker
              id="OverrideDate"
              labelText="Override date"
              onDateChange={setOverrideDate}
            />
          </OverrideDateContainer>
          <QuidProQuoContainer>
            <TextInput
              id="qpqQuantity"
              value={qpqQuantity}
              label={"Quid pro quo"}
              onChange={(e) => {
                setQpqQuantity(e.target.value);
                if (/^[0-9]*$/.test(qpqQuantity) === false) {
                  setQpqQuantityError(true, true);
                } else {
                  setQpqQuantityError(false, false);
                }
              }}
              error={/^[0-9]*$/.test(qpqQuantity) === false}
              errorMessages={["Please enter numbers only."]}
            />
          </QuidProQuoContainer>
        </div>
      </div>
      <RecognitionFieldsWrapper>
        <RecognitionFields />
      </RecognitionFieldsWrapper>
      <div>
        <TextArea
          label="Notes"
          id="StaffNotes"
          value={notes}
          characterLimit={250}
          charactersRemaining={notes ? 250 - notes.length : 250}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
          subText=""
          maxLength={250}
        />
        <div className="oco-anonymous">
          <Anonymous
            checked={isAnonymous}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setAnonymous(event.target.checked);
            }}
          />
        </div>
        <div className="oco-overrideCreditorgCheckbox">
          <OverrideCreditOrgCheckbox
            checked={overrideCreditOrgVisibility}
            onChange={(e) => {
              toggelOverrideCreditOrgVisibility(e.target.checked);
              if (!e.target.checked) {
                setOverrideCreditOrgId(null);
                setOverrideCreditOrgType(null);
                setOverrideCreditOrgError(false, false);
                setOverrideCreditOrgValidStatus(false);
              }
            }}
          />
        </div>
        {overrideCreditOrgVisibility && (
          <div>
            <OverrideCreditOrg />
          </div>
        )}
      </div>
    </div>
  );
};

export default DPMDonationOnly;
