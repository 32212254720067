import { useEffect } from "react";
import { styled, css } from "@mui/system";
import { useSelector } from "react-redux";
import { ifProp } from "styled-tools";
import {
  bp,
  color,
  pxToRem,
  media,
  fontSize,
  stepTitle,
  CustomButton,
  iFrameDedicationPreviewWrapper,
  iFrameDedicationPreviewHeader,
  iFrameDedicationPreviewFooter,
} from "shared/styles";
import { inIframe, checkWebAppURLExistInWebpageURL } from "shared/utils";
import { RootState } from "shared/state/store";

interface WrapperProps {
  checkIframeExist: boolean | null;
}

const Wrapper = styled("div")<WrapperProps>`
  background-color: ${color.white};
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  margin-top: ${(props) =>
    props.checkIframeExist ? iFrameDedicationPreviewWrapper() : 0};
  ${bp("sm")`
    background-color: rgba(255, 255, 255, 0.9);
  `}
`;

interface WindowContainerProps {
  checkIframeExist: boolean | null;
}

const WindowContainer = styled("div")<WindowContainerProps>(
  ({ theme, checkIframeExist }) => ({
    height: "100vh",
    margin: "0 auto",
    maxWidth: "770px",
    [theme.breakpoints.up("sm")]: {
      margin: "80px auto 0",
      height: "auto",
    },
  }),
);

interface HeaderProps {
  checkIframeExist: boolean | null;
}

const Header = styled("div")<HeaderProps>`
  background-color: ${color.navy};
  color: ${color.white};
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;

  ${bp("sm")`
  height: 45px;
  position: relative;
`}
  ${(props) => props.checkIframeExist && iFrameDedicationPreviewHeader()};
`;

const CloseBtn = styled("div")(({ theme }) => ({
  alignItems: "center",
  cursor: "pointer",
  display: "flex",
  margin: "0 16px 0 auto",
  [theme.breakpoints.up("sm")]: {
    color: color.navy,
    margin: 0,
    position: "absolute",
    right: 0,
    top: "-32px",
    transition: "all 0.3s ease",
    "&:hover": {
      color: color.red,

      path: {
        fill: color.red,
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: "10px",
  },
}));

const CloseText = styled("div")({
  ...fontSize(20),
});

const CloseGraphic = styled("div")(({ theme }) => ({
  height: "19px",
  margin: "0 0 0 8px",
  width: "19px",

  path: {
    fill: color.white,
    transition: "all 0.3s ease",
  },

  [theme.breakpoints.up("sm")]: {
    height: "20px",
    margin: "2px 0 0 10px",
    width: "20px",

    path: {
      fill: color.navy,
    },
  },
}));

interface MainProps {
  checkIframeExist: boolean | null;
}

const Main = styled("div")<MainProps>(({ theme, checkIframeExist = true }) => ({
  backgroundColor: color.white,
  height: checkIframeExist ? "calc(17vh - 100px)" : "calc(100vh - 230px)",
  margin: "39px 0 0",
  minHeight: "250px",
  overflow: "hidden",
  padding: "0 5%",
  position: "relative",
  "-webkit-overflow-scrolling": "touch",
  // ${(props) => props.checkIframeExist ? iFrameDedicationPreviewMain(props.fullDonateFormVisibility) : 'max-height: calc(100vh - 255px)'};
  [theme.breakpoints.up("sm")]: {
    border: "1px solid",
    boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.42)",
    margin: "0 0 40px",
    padding: 0,
  },
  [theme.breakpoints.down("sm")]: {
    border: "1px solid",
    margin: "0 0 40px",
    padding: 0,
    height: "100%",
  },
}));

const Heading = styled("h1")({
  ...stepTitle(),
});

interface FooterProps {
  checkIframeExist: boolean | null;
}

const Footer = styled("div")<FooterProps>`
  bottom: 0;
  padding: 0 5%;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  ${bp("xs")`
    display: flex;
    padding: 5%;
  `}

  ${bp("sm")`
    justify-content: center;
    padding: 0;
    position: static;
  `}
  ${(props) => props.checkIframeExist && iFrameDedicationPreviewFooter()};
`;

const FooterInner = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    background: "transparent",
    padding: 0,
    width: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    padding: `0 0 ${pxToRem(44)}`,
    width: "100%",
  },
}));

const BtnContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

interface FooterBtnProps {
  primary?: boolean;
}
const FooterBtn = styled(CustomButton)<FooterBtnProps>`
  && {
    margin-bottom: 1rem;

    ${bp("xs")`
      min-width: auto;
      width: 100%;
    `}

    ${bp("xs_landscape_up")`
    width: calc(50% - 2.5%);
  `}

    ${media.xs_landscape_s} {
      &:last-child {
        margin: 0 0 0 5%;
      }
    }

    ${bp("sm")`
      min-width: 16.9rem;
      margin: 0 1rem;
      width: auto;
    `}

    ${media.xs_landscape_down} {
      &:last-child {
        margin-top: ${pxToRem(10)}; // Call pxToRem function here
      }
    }

    &:hover,
    &:focus {
      background-color: ${color.white};
      color: ${color.red};
    }

    ${ifProp(
      "primary",
      css`
        &:hover,
        &:focus {
          background-color: ${color.red};
          color: ${color.white};
        }
      `,
    )}
  }
`;

const DedicationPreviewiframe = styled("iframe")(({ theme }) => ({
  height: "calc(100% - 168px)",
  width: "100%",
  [theme.breakpoints.up("xs_landscape_up")]: {
    height: "calc(100% - 105px)",
  },
  [theme.breakpoints.up("sm")]: {
    height: "100%",
  },
}));

const DedicationPreviewNoteDesktop = styled("div")(({ theme }) => ({
  backgroundColor: "#ebf4f8",
  bottom: 0,
  color: "#39424a",
  display: "none",
  fontSize: "14px",
  left: 0,
  lineHeight: "19px",
  padding: "13px 20px",
  position: "absolute",
  width: "100%",
  boxSizing: "border-box",
  [theme.breakpoints.up("sm")]: {
    display: "block",
  },
}));

const DedicationPreviewNoteMobile = styled("div")(({ theme }) => ({
  backgroundColor: "#ebf4f8",
  color: "#39424a",
  fontSize: "14px",
  left: 0,
  lineHeight: "19px",
  marginBottom: "10px",
  padding: "13px 20px",
  width: "100%",
  boxSizing: "border-box",
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 37.99988 38"
    aria-labelledby="title"
  >
    <title>Close modal</title>
    <path d="M19,0A19,19,0,1,0,37.99988,19,18.99992,18.99992,0,0,0,19,0Zm0,34A15,15,0,1,1,33.99988,19,15.00009,15.00009,0,0,1,19,34Zm2.82837-15,4.6568,4.65686L23.6568,26.48529l-4.65686-4.65686-4.65686,4.65686-2.82843-2.82843L16.17151,19l-4.65686-4.6568,2.82843-2.82849,4.65686,4.65686,4.65686-4.65686,2.82837,2.82849Z" />
  </svg>
);

interface DedicationPreviewProps {
  setDedicationPreviewVisibility: (visibility: boolean) => void;
}

const DedicationPreview = ({
  setDedicationPreviewVisibility,
}: DedicationPreviewProps) => {
  const {
    dedicationType,
    notificationType,
    dedicationName,
    donorName,
    personalMessage,
    selectedDedicationImage,
  } = useSelector((state: RootState) => state.dedication);

  const { config } = useSelector((state: RootState) => state.app);

  interface QueryParams {
    dedication_type: number;
    notification_type: number;
    dedicated_to: string;
    donor_name: string;
    personal_msg: string;
    image_fid: string;
  }

  const queryParams: QueryParams = {
    dedication_type: dedicationType === "memory" ? 1 : 2,
    notification_type: notificationType === "email" ? 1 : 2,
    dedicated_to: dedicationName,
    donor_name: donorName,
    personal_msg: personalMessage.replace(/\r?\n|\r/g, " "),
    // This should be the last query parameter since it won't contain a
    // period. A period as the last character of the url (even the query
    // string) will throw a 404.
    image_fid: selectedDedicationImage?.id ?? "",
  };
  //To do based on mode
  // const api_url = config && config.appConfigs.api_url;
  // let path = null;
  // if (dpmMode === true && dpm.btnMode == 'addDonation') {
  //   path = `${api_url}/${dpm.user.preferred_language}/widgets/donate/e-card-preview`;
  // } else {
  // path = `${api_url}/en/widgets/donate/e-card-preview`; // wont work until APIs are set up
  // }

  const queryString = Object.keys(queryParams)
    .map(
      (name) =>
        `${name}=${encodeURIComponent(queryParams[name as keyof QueryParams])}`,
    )
    .join("&");

  const baseURL = process.env.REACT_APP_BASE_URL;

  const iFrameUrl = `${baseURL + "/en/e-card-preview"}?${queryString}`;

  const onClose = () => {
    setDedicationPreviewVisibility(false);
    if (
      inIframe() === true &&
      config &&
      checkWebAppURLExistInWebpageURL(config.appConfigs.webAppBaseUrl)
    ) {
      const previewButtonId = document.getElementById("preview-button");
      if (previewButtonId) {
        previewButtonId.scrollIntoView({ block: "center" });
      }
    }
  };

  const checkIframeExist =
    inIframe() === true &&
    config &&
    checkWebAppURLExistInWebpageURL(config.appConfigs.webAppBaseUrl);

  useEffect(() => {
    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "scroll";
      }
    };
  }, []);

  return (
    <Wrapper checkIframeExist={checkIframeExist}>
      <WindowContainer
        onClick={(e) => e.stopPropagation()}
        checkIframeExist={checkIframeExist}
      >
        <Header checkIframeExist={checkIframeExist}>
          <Heading>Preview</Heading>
          <CloseBtn onClick={onClose}>
            <CloseText>Close</CloseText>
            <CloseGraphic>
              <CloseIcon />
            </CloseGraphic>
          </CloseBtn>
        </Header>
        <Main checkIframeExist={checkIframeExist}>
          <DedicationPreviewiframe src={iFrameUrl} />
          {notificationType === "email" && (
            <DedicationPreviewNoteDesktop>
              Rotary will send the recipient an email with a link to your
              personalized notification after your donation is completed
            </DedicationPreviewNoteDesktop>
          )}
          {notificationType !== "email" && (
            <DedicationPreviewNoteDesktop>
              You will be able to print and deliver your personalized
              notification after you complete your donation
            </DedicationPreviewNoteDesktop>
          )}
        </Main>
        <Footer checkIframeExist={checkIframeExist}>
          <FooterInner>
            {notificationType === "email" && (
              <DedicationPreviewNoteMobile>
                Rotary will send the recipient an email with a link to your
                personalized notification after your donation is completed
              </DedicationPreviewNoteMobile>
            )}
            {notificationType !== "email" && (
              <DedicationPreviewNoteMobile>
                You will be able to print and deliver your personalized
                notification after you complete your donation
              </DedicationPreviewNoteMobile>
            )}
            <BtnContainer>
              <FooterBtn onClick={onClose}>Edit</FooterBtn>
              <FooterBtn onClick={onClose} primary>
                Continue
              </FooterBtn>
            </BtnContainer>
          </FooterInner>
        </Footer>
      </WindowContainer>
    </Wrapper>
  );
};

export default DedicationPreview;
