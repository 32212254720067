import React from "react";
import { styled } from "@mui/system";
import RecognitionAmount from "components/RecognitionAmount";
import Address from "components/Address";
import SecurityLock from "components/SecurityLock";
import { PageWrapper } from "shared/styles";
import {
  AccountOptionsWrapper,
  CardImgEndPolio,
  CardImgRaiseRotary,
  CardImgRotaryDirect,
  Column,
  ConfirmationPreviewBtn,
  ConfirmationTools,
  ConfirmationWrapper,
  ConfirmationWrapperDPM,
  Content,
  CreateAccount,
  CTADiv,
  CTALink,
  DonateButtonBox,
  DonationGroup,
  DonationParagraphBox,
  EndPolioImageDiv,
  FlexIcons,
  HeadingImage,
  HeadingImgEndPolio,
  HeadingWithLink,
  InfoText,
  LargeTitle,
  LinkDiv,
  MainContentSection,
  MainText,
  OtherwayParagraph,
  OtherWaysContentBox,
  OtherWaysContentInfo,
  OtherWaysHeading,
  OtheyWaySubHeading,
  ParaColumn,
  ParaColumn2,
  ParaContentInfo,
  Print,
  PrintLink,
  RightBoxSubheading,
  RightDonationBox,
  RightDonationBoxText,
  RightDonationBoxUrl,
  RightInnerBox,
  SignOut,
  SocialIconsBox,
  Subheading,
  SubheadingLink,
  ViewNotificationDiv,
} from "./styles";
import iconFacebook from "shared/assets/icon-facebook.png";
import iconLinkedIn from "shared/assets/icon-linkedin.png";
import iconTwitter from "shared/assets/icon-twitter.png";
import { useSelector } from "react-redux";
import { RootState } from "shared/state/store";
import HeaderBlurbText from "components/HeaderBlurbText";
import {
  checkIsInvoiceFlow,
  decodeAmount,
  formatAmountByCurrency,
  formatEmail,
  getCurrencyByCode,
  getFundTitle,
} from "shared/utils";
import { Config, Currencies, Currency, Fund } from "shared/types";
import useActions from "Hooks/useActions";
import DonationPreviewPopUp from "components/DonationPreviewPopUp";

const RightArrow = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="13.000000pt"
    height="13.000000pt"
    viewBox="0 0 20.000000 20.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,20.000000) scale(0.100000,-0.100000)"
      fill="#19519C"
      stroke="none"
    >
      <path
        d="M75 159 c-4 -6 3 -21 16 -35 l22 -24 -23 -25 c-19 -20 -21 -28 -12
-37 10 -10 20 -4 49 25 l37 37 -34 35 c-36 37 -45 41 -55 24z"
      />
    </g>
  </svg>
);

const PrintOnPopup = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.000000pt"
    height="13.000000pt"
    viewBox="0 0 42.000000 32.000000"
    aria-labelledby="title"
    preserveAspectRatio="xMidYMid meet"
  >
    <title id="title">Print</title>
    <g fill="#19519C" stroke="none">
      <path
        d="M28,5V0H7v5H0v15h7v9h21v-9h7V5H28z M9,2h17v3H9V2z M26,27H9V14h17V27z M33,18h-5v-6H7v6H2V7h31V18z M24,17v2
  H11v-2H24z M24,22v2H11v-2H24z"
      />
    </g>
  </svg>
);

const StyledDonationConfirmation = styled(PageWrapper)``;

interface InfoBoxProps {
  title: string;
  children: React.ReactNode;
}

const InfoBox = ({ title, children }: InfoBoxProps) => (
  <DonationGroup>
    <Subheading confirmation>{title}</Subheading>
    <Content confirmation>{children}</Content>
  </DonationGroup>
);

const HeadingLink = () => (
  <SubheadingLink
    dangerouslySetInnerHTML={{
      __html: '<a href="">Learn more about contribution recognition</a>',
    }}
  />
);

interface InfoBoxColumnsProps {
  title: string;
  children: React.ReactNode;
}

const InfoBoxColumns = ({ title, children }: InfoBoxColumnsProps) => (
  <DonationGroup>
    <HeadingWithLink>
      <Subheading confirmation columns>
        {title}
      </Subheading>
      <HeadingLink />
    </HeadingWithLink>
    <Content confirmation columns>
      {children}
    </Content>
  </DonationGroup>
);

const PrinterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 29"
    aria-labelledby="title"
  >
    <title id="title">Print</title>
    <path
      d="M28,5V0H7v5H0v15h7v9h21v-9h7V5H28z M9,2h17v3H9V2z M26,27H9V14h17V27z M33,18h-5v-6H7v6H2V7h31V18z M24,17v2
  H11v-2H24z M24,22v2H11v-2H24z"
    />
  </svg>
);

interface AccountOptionsProps {
  isAuthenticated: boolean;
  accountSignOutShown: boolean;
  lang: string;
}

const AccountOptions = ({
  isAuthenticated,
  accountSignOutShown,
  lang,
}: AccountOptionsProps) => {
  if (isAuthenticated) {
    return (
      <AccountOptionsWrapper>
        {accountSignOutShown && (
          <SignOut
            href={`https://my.rotary.org/${lang}/user/logout?returnURL=https://www.endpolio.org/${lang}`}
          >
            Sign out
          </SignOut>
        )}
        <SecurityLock />
      </AccountOptionsWrapper>
    );
  }
  return (
    <AccountOptionsWrapper>
      <SecurityLock />
    </AccountOptionsWrapper>
  );
};

const ContactFoundation = (lang: string) => {
  return (
    <InfoText
      dangerouslySetInnerHTML={{
        __html: `If you have questions, <a href="https://my.rotary.org/${lang}/contact">contact us</a> or your <a href="https://www.rotary.org/${lang}/about-rotary/our-leaders/international-offices">international office</a>.`,
      }}
    />
  );
};

const FacebookIcon = () => <img src={iconFacebook} alt={"Icon Facebook"}></img>;

const LinkedInIcon = () => <img src={iconLinkedIn} alt={"Icon LinkedIn"}></img>;

const TwitterIcon = () => <img src={iconTwitter} alt={"Icon Twitter"}></img>;

const DonationConfirmation = () => {
  const {
    mode,
    config,
    dpmBtnMode,
    fund,
    namedFundAliasDescription,
    trackedGiftAliasDescription,
  } = useSelector((state: RootState) => state.app);
  const { user } = useSelector((state: RootState) => state.user);
  const { confirmationDetailsVisibility } = useSelector(
    (state: RootState) => state.ui,
  );
  const { namedFundsError, trackedGiftError } = useSelector(
    (state: RootState) => state.error,
  );
  const { setConfirmationDetailsVisibility } = useActions();
  const lang = "en";
  let imgPath = "",
    imgContainer = "";
  if (config?.appConfigs.webAppBaseUrl.includes("uat")) {
    imgPath = "rinuscinpphl01stg";
    imgContainer = "riphilanthropynonprd";
  } else {
    imgPath = "ripuscintphl01stg";
    imgContainer = "riphilanthropyprd";
  }
  let isDedicationNotification = !!(window as any).rnw.tamaro.instance
    .transactionInfo["stored_mth_ecard_url"];
  let notificationType = (window as any).rnw.tamaro.instance.transactionInfo[
    "stored_mth_delivery_method"
  ];
  const email = (window as any).rnw.tamaro.instance.transactionInfo[
    "stored_customer_email"
  ];
  const emailFormatted = formatEmail(
    (window as any).rnw.tamaro.instance.transactionInfo[
      "stored_customer_email"
    ],
  );
  const recepientEmail = (window as any).rnw.tamaro.instance.transactionInfo[
    "stored_mth_recipient_email"
  ];
  const ecardLink =
    (window as any).rnw.tamaro.instance.transactionInfo[
      "stored_mth_delivery_method"
    ] === "print"
      ? `${(window as any).rnw.tamaro.instance.transactionInfo["stored_mth_ecard_url"]}?print=1`
      : (window as any).rnw.tamaro.instance.transactionInfo[
          "stored_mth_ecard_url"
        ];

  const currency = getCurrencyByCode(
    config?.currencies as Currencies,
    (window as any).rnw.tamaro.instance.transactionInfo["currency"],
  );
  const amount =
    (window as any).rnw.tamaro.instance.transactionInfo[
      "stored_amount_superunit"
    ] ||
    decodeAmount(
      (window as any).rnw.tamaro.instance.transactionInfo["amount"],
      currency as Currency,
    );
  const amountFormatted = formatAmountByCurrency(amount, currency as Currency);
  const currencyCode = currency?.code.toUpperCase() as string;
  const isRecurringTransaction =
    (window as any).rnw.tamaro.instance.transactionInfo[
      "stored_donation_type"
    ] == "recurring";
  const frequency = localStorage.getItem("frequency");
  const frequencyDisplay = frequency && JSON.parse(frequency).name["en"];
  const confirmationNumber = (window as any).rnw.tamaro.instance
    .transactionInfo["epp_transaction_id"];
  const transactionFundMatchesState =
    fund?.fundId ===
      (window as any).rnw.tamaro.instance.transactionInfo[
        "stored_campaign_id"
      ] &&
    fund?.fundSubId ===
      (window as any).rnw.tamaro.instance.transactionInfo[
        "stored_campaign_subid"
      ];
  let fundTitle = transactionFundMatchesState
    ? getFundTitle(
        {
          fundTitle: {
            en: fund?.fundTitle?.en || null,
            fr: fund?.fundTitle?.fr || null,
            de: fund?.fundTitle?.de || null,
            es: fund?.fundTitle?.es || null,
            it: fund?.fundTitle?.it || null,
            ja: fund?.fundTitle?.ja || null,
            ko: fund?.fundTitle?.ko || null,
            pt: fund?.fundTitle?.pt || null,
          },
          id: fund?.id as number,
        },
        lang,
      )
    : (window as any).rnw.tamaro.instance.transactionInfo[
        "stored_rnw_purpose_text"
      ];
  if (!namedFundsError.status && namedFundAliasDescription) {
    fundTitle = namedFundAliasDescription;
  }
  if (!trackedGiftError.status && trackedGiftAliasDescription) {
    fundTitle = trackedGiftAliasDescription;
  }
  return (
    <StyledDonationConfirmation>
      {mode !== "dpm" && (
        <ConfirmationWrapper className="confirmation_wrapper">
          <HeaderBlurbText headingType={"Confirmation"}></HeaderBlurbText>
          {String(mode).toLowerCase() === "ep" ? (
            <EndPolioImageDiv>
              <HeadingImgEndPolio
                src={`https://${imgPath}.blob.core.windows.net/${imgContainer}/header-og-endpolio-heroimg.jpg`}
                alt="Endpolio heading image"
              />
            </EndPolioImageDiv>
          ) : (
            <HeadingImage
              src={`https://${imgPath}.blob.core.windows.net/${imgContainer}/header-og-confirmation.jpg`}
              alt="Rotary heading image"
            />
          )}
          <LargeTitle>
            We appreciate your gift to The Rotary Foundation.
          </LargeTitle>
          <DonationParagraphBox>
            <ParaColumn>
              {(!isDedicationNotification ||
                (isDedicationNotification &&
                  notificationType !== "print" &&
                  notificationType !== "email")) && (
                <ParaContentInfo
                  dangerouslySetInnerHTML={{
                    __html: `A confirmation email has been sent to <span>${email}</span>.`,
                  }}
                />
              )}
              {isDedicationNotification && notificationType === "print" && (
                <ParaContentInfo
                  dangerouslySetInnerHTML={{
                    __html: `A confirmation email has been sent to <a>${email}</a> with a link to print your personalized notification. <span>If you would like to print your personalized notification now, click the link below.</span>`,
                  }}
                />
              )}
              {isDedicationNotification && notificationType === "print" && (
                <ViewNotificationDiv>
                  <LinkDiv href={ecardLink} target="_blank">
                    {notificationType === "print" ? (
                      <PrintOnPopup></PrintOnPopup>
                    ) : (
                      ""
                    )}
                    <PrintLink>
                      {notificationType === "print"
                        ? "Print your personalized notification"
                        : "View personalized notification"}
                    </PrintLink>
                    <RightArrow></RightArrow>
                  </LinkDiv>
                </ViewNotificationDiv>
              )}
              {isDedicationNotification && notificationType === "email" && (
                <ParaContentInfo
                  dangerouslySetInnerHTML={{
                    __html: `A confirmation email has been sent to <span>${email}</span> and an email with a link to your personalized notification has been sent to <span>${recepientEmail}</span>.`,
                  }}
                />
              )}
              <ParaContentInfo>
                Your contribution directly supports Rotarian-led service
                projects that provide clean water, promote peace, develop
                communities, save mothers and children — and will one day soon
                eradicate polio.
              </ParaContentInfo>
              <ParaContentInfo>
                We’re grateful for donors like you, who are dedicated to Doing
                Good in the World.
              </ParaContentInfo>
              <ParaContentInfo
                dangerouslySetInnerHTML={{
                  __html: `If you have questions, <a href="${config?.appConfigs.webAppBaseUrl}/${lang}/contact">contact us</a> or the <a href="https://www.rotary.org/${lang}/about-rotary/our-leaders/international-offices">International office</a> near you.`,
                }}
              />
              {user.authenticated && (
                <ParaContentInfo
                  dangerouslySetInnerHTML={{
                    __html:
                      'Review your <a href="${config?.appConfigs.webAppBaseUrl}/${lang}/secure/application/411" target="_blank">Donor History Report</a> and any recurring giving arrangements through <a href="${config?.appConfigs.webAppBaseUrl}/${lang}/user/donations/management" target="_blank">My Donations</a> in My Rotary.',
                  }}
                />
              )}
              {!user.authenticated && (
                <ParaContentInfo
                  dangerouslySetInnerHTML={{
                    __html: `To track your donation history and ensure recognition for you and your club, <a href="${config?.appConfigs.webAppBaseUrl}/${lang}/user/login">sign in</a> or <a href="${config?.appConfigs.webAppBaseUrl}/${lang}/user/register">create a My Rotary account</a>`,
                  }}
                />
              )}
            </ParaColumn>
            <ParaColumn2>
              <RightDonationBox>
                <RightInnerBox>
                  <RightBoxSubheading>Your donation</RightBoxSubheading>
                  <RightDonationBoxText
                    dangerouslySetInnerHTML={{
                      __html: `Amount: <span>${amountFormatted}</span> <span>${currencyCode}</span>`,
                    }}
                  />
                  {isRecurringTransaction && frequencyDisplay && (
                    <RightDonationBoxText
                      dangerouslySetInnerHTML={{
                        __html: `Frequency: <span>${frequencyDisplay}</span>`,
                      }}
                    />
                  )}
                  <RightDonationBoxText
                    dangerouslySetInnerHTML={{
                      __html: `Supporting: <span>${fundTitle}</span>`,
                    }}
                  />
                  <RightDonationBoxText
                    dangerouslySetInnerHTML={{
                      __html: `Confirmation number: <span>${confirmationNumber}</span>`,
                    }}
                  />
                  <ConfirmationPreviewBtn>
                    <RightDonationBoxUrl
                      onClick={() => setConfirmationDetailsVisibility(true)}
                    >
                      View full donation details <span>{"     >"}</span>
                    </RightDonationBoxUrl>
                  </ConfirmationPreviewBtn>
                </RightInnerBox>
              </RightDonationBox>
              <RightDonationBoxText>
                Celebrate your gift with others.
                <FlexIcons>
                  <SocialIconsBox
                    onClick={(e) => {
                      window.open(
                        `https://www.facebook.com/sharer/sharer.php?u=https://www.rotary.org/${lang}/donate`,
                        "_blank",
                        "width=500,height=630,top=432,left=600",
                      );
                      e.preventDefault();
                    }}
                    href="#"
                  >
                    <FacebookIcon></FacebookIcon>
                  </SocialIconsBox>
                  <SocialIconsBox
                    onClick={(e) => {
                      window.open(
                        `https://www.linkedin.com/sharing/share-offsite/?url=https://www.rotary.org/${lang}/donate`,
                        "_blank",
                        "width=500,height=630,top=432,left=600",
                      );
                      e.preventDefault();
                    }}
                    href="#"
                  >
                    <LinkedInIcon></LinkedInIcon>
                  </SocialIconsBox>
                  <SocialIconsBox
                    onClick={(e) => {
                      window.open(
                        `http://twitter.com/share?&url=https://www.rotary.org/${lang}/donate`,
                        "_blank",
                        "width=500,height=630,top=432,left=600",
                      );
                      e.preventDefault();
                    }}
                    href="#"
                  >
                    <TwitterIcon></TwitterIcon>
                  </SocialIconsBox>
                </FlexIcons>
                <DonateButtonBox>Make another gift.</DonateButtonBox>
                <CreateAccount
                  href={`${config?.appConfigs.webAppBaseUrl}/${lang}/donate`}
                  primary
                  target="_blank"
                >
                  DONATE
                </CreateAccount>
              </RightDonationBoxText>
            </ParaColumn2>
          </DonationParagraphBox>
          <OtherWaysHeading>Other ways you can help</OtherWaysHeading>
          <DonationParagraphBox>
            <OtherwayParagraph>
              {String(lang).toLowerCase() === "en" && (
                <Column>
                  <CardImgRaiseRotary
                    src={`https://${imgPath}.blob.core.windows.net/${imgContainer}/card-og-raise-for-rotary.jpg`}
                    alt="Raise for Rotary"
                  />
                  <OtheyWaySubHeading>Raise for Rotary</OtheyWaySubHeading>
                  <OtherWaysContentBox>
                    <OtherWaysContentInfo>
                      Fundraise for The Rotary Foundation to help bring
                      life-changing projects to every corner of the world.
                    </OtherWaysContentInfo>
                  </OtherWaysContentBox>
                  <CTADiv>
                    {config?.appConfigs.webAppBaseUrl ===
                      "https://myuat.rotary.org" && (
                      <CTALink
                        href={`https://raiseuat.rotary.org/`}
                        target="_blank"
                      >
                        GET STARTED
                      </CTALink>
                    )}
                    {config?.appConfigs.webAppBaseUrl ===
                      "https://my.rotary.org" && (
                      <CTALink
                        href={`https://raise.rotary.org/`}
                        target="_blank"
                      >
                        GET STARTED
                      </CTALink>
                    )}
                  </CTADiv>
                </Column>
              )}
              {String(lang).toLowerCase() !== "en" && (
                <Column>
                  <CardImgEndPolio
                    src={`https://${imgPath}.blob.core.windows.net/${imgContainer}/card-og-end-polio.jpg`}
                    alt="End Polio"
                  />
                  <OtheyWaySubHeading>End polio forever</OtheyWaySubHeading>
                  <OtherWaysContentBox>
                    <OtherWaysContentInfo>
                      Every donation helps us obtain the vaccines, transport and
                      materials needed in the fight against polio.
                    </OtherWaysContentInfo>
                  </OtherWaysContentBox>
                  <CTADiv>
                    {config?.appConfigs.webAppBaseUrl ===
                      "https://myuat.rotary.org" && (
                      <CTALink
                        href={`https://wwwuat.endpolio.org/donate`}
                        target="_blank"
                      >
                        DONATE NOW
                      </CTALink>
                    )}
                    {config?.appConfigs.webAppBaseUrl ===
                      "https://my.rotary.org" && (
                      <CTALink
                        href={`https://www.endpolio.org/donate`}
                        target="_blank"
                      >
                        DONATE NOW
                      </CTALink>
                    )}
                  </CTADiv>
                </Column>
              )}
              <Column>
                <CardImgRotaryDirect
                  src={`https://${imgPath}.blob.core.windows.net/${imgContainer}/card-og-rotary-direct.jpg`}
                  alt="Rotary Direct"
                />
                <OtheyWaySubHeading>Do good all year</OtheyWaySubHeading>
                <OtherWaysContentBox>
                  <OtherWaysContentInfo>
                    Rotary Direct allows you to help year-round by automatically
                    giving each month, quarter, or year.
                  </OtherWaysContentInfo>
                </OtherWaysContentBox>
                <CTADiv>
                  <CTALink
                    href={`${config?.appConfigs.webAppBaseUrl}/${lang}/rotary-direct`}
                    target="_blank"
                  >
                    ENROLL NOW
                  </CTALink>
                </CTADiv>
              </Column>
            </OtherwayParagraph>
          </DonationParagraphBox>
        </ConfirmationWrapper>
      )}
      {mode === "dpm" && (
        <ConfirmationWrapperDPM>
          <ConfirmationTools>
            <Print
              href="/"
              onClick={(e) => {
                global.print();
                e.preventDefault();
                return false;
              }}
            >
              <PrinterIcon />
              Print this page
            </Print>
          </ConfirmationTools>
          <MainContentSection>
            {(!checkIsInvoiceFlow(config as Config) ||
              (mode === "dpm" && dpmBtnMode != "addPayment")) && (
              <MainText
                dangerouslySetInnerHTML={{
                  __html: `A confirmation email has been sent to <span>${email}</span>`,
                }}
              />
            )}
            {isDedicationNotification && notificationType === "print" && (
              <MainText>
                Your confirmation email will include a link for you to print
                your personalized notification.
              </MainText>
            )}
            {isDedicationNotification && notificationType === "email" && (
              <MainText
                dangerouslySetInnerHTML={{
                  __html: `An email with a link to your personalized notification has been sent to: <span>${recepientEmail}</span>`,
                }}
              />
            )}
          </MainContentSection>

          <MainContentSection></MainContentSection>
        </ConfirmationWrapperDPM>
      )}
      <SecurityLock />
    </StyledDonationConfirmation>
  );
};

export default DonationConfirmation;
