import { styled } from "@mui/system";
import {
  bp,
  color,
  donationConfirmationSubheading,
  donationTitle,
  pxToRem,
} from "shared/styles";

interface ContentProps {
  confirmation?: boolean;
  columns?: boolean;
}

export const Content = styled("div")<ContentProps>(
  ({ theme, confirmation, columns }) => ({
    marginBottom: `${pxToRem(6)}`,

    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },

    ...(confirmation && {
      [theme.breakpoints.up("s_up_rn")]: {
        width: `calc(100% / 3 * 2)`,
      },
    }),

    ...(columns && {
      [theme.breakpoints.up("xs")]: {
        display: "flex",
        paddingBottom: `${pxToRem(4)}`,
      },
    }),
  }),
);

export const ContentNote = styled("div")`
  font-size: 14px;
  line-height: 20px;
  background-color: ${color.blueLight};
  padding: ${pxToRem(24)} ${pxToRem(32)};

  ${bp("s_up")`
    font-size: 16px;
    line-height: 24px;
  `}
  span {
    color: ${color.navy};
    font-weight: bold;
  }
`;

export const ContentNoteHeaderPopUp = styled("h3")<{ columns?: boolean }>(
  ({ theme, columns }) => ({
    fontWeight: 700,
    lineHeight: 1.5,
    padding: `${pxToRem(11)} 0`,

    [theme.breakpoints.up("xs")]: {
      marginRight: pxToRem(24),
      width: "calc(50% - 12px)",
    },

    [theme.breakpoints.up("sm")]: {
      fontSize: pxToRem(16),
      lineHeight: 1.375, // 22px / 16px = 1.375
    },

    ...(columns && {
      width: "100% !important",

      [theme.breakpoints.up("xs")]: {
        borderBottom: `1px solid ${color.greyCobble}`,
        marginBottom: pxToRem(8),
        padding: `${pxToRem(7)} 0`,
      },
    }),
  }),
);

export const ContentNotePopUp = styled("div")`
  font-size: 14px;
  line-height: 20px;

  ${bp("s_up")`
    font-size: 16px;
    line-height: 24px;
  `}
  span {
    color: ${color.navy};
    font-weight: bold;
  }
`;

interface WrapperProps {
  onClick: () => void;
}

export const Wrapper = styled("div")`
  background-color: rgba(255, 255, 255, 0.9);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  overflow-y: auto;
  width: 80%;
  height: 100vh;
  ${bp("sm")`
    background-color: rgba(255, 255, 255, 0.9); 
  `}
`;
export const WindowContainer = styled("div")`
  height: 100vh;
  margin: 0 auto;
  max-width: 976px;

  ${bp("s_up")`
    margin: 60px auto 100px;
    height: auto;
  `}
`;

export const Header = styled("div")`
  background-color: #ffffff !important;
  display: flex !important;
  top: 0 !important;
  width: 100% !important;

  ${bp("s_up")`
    height: 100px!important;
    position: relative!important;
  `}
`;

export const CloseBtn = styled("div")(({ theme }) => ({
  fontSize: "14px",
  cursor: "pointer",
  display: "flex",
  paddingTop: "5px",
  paddingRight: "5px",
  margin: "0 16px 0 auto",
  marginLeft: "auto",

  [theme.breakpoints.up("sm")]: {
    paddingTop: "30px",
    paddingRight: "20px",
    fontSize: "16px",
    color: color.navy,
    margin: "0",
    right: "0",
    top: "-32px",
    transition: "all 0.3s ease",
    marginLeft: "auto",

    "&:hover": {
      color: color.red,

      path: {
        fill: color.red,
      },
    },
  },
}));

export const Main = styled("div")`
  background-color: ${color.white};
  height: calc(100vh - 10px);
  margin: 10px 0 0;
  padding: 0 5%;

  ${bp("s_up")`
    border: 1px solid #BEC4C9;
    height: auto;
    margin: 0 0 40px;
    max-height: calc(100vh - 255px);
    padding: 0;
  `}
`;

export const Heading = styled("h1")`
  ${donationTitle()};
  display: inline !important;
`;

export const PrintIcon = styled("div")`
  background: #f7f7f7;
  display: flex;
  height: 50px;
  border-top: 1px solid #bec4c9;

  ${bp("s_up")`
    padding-left: 30px;
    border-top: 1px solid #BEC4C9;
    font-size: 36px;
    line-height: 59px;
  `}
`;

export const DonationGroup = styled("div")(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: color.blueLight,
  },
  [theme.breakpoints.up("xs")]: {
    "&:nth-of-type(odd)": {
      backgroundColor: color.blueLight,
    },
  },
  [theme.breakpoints.up("xxs_up")]: {
    "&:nth-of-type(odd)": {
      backgroundColor: color.blueLight,
    },
  },
  [theme.breakpoints.up("sm")]: {
    "&:nth-of-type(odd)": {
      backgroundColor: color.blueLight,
    },
  },
  [theme.breakpoints.up("s_up_rn")]: {
    backgroundColor: color.offWhite,
    borderTop: "1px solid #BEC4C9",
    display: "flex",
    flexWrap: "nowrap",
    padding: `${pxToRem(28)} ${pxToRem(30)}`,
    "&:nth-of-type(odd)": {
      backgroundColor: color.blueLight,
    },
  },
}));

interface SubheadingProps {
  confirmation?: boolean;
  columns?: boolean;
}

export const Subheading = styled("h2")<SubheadingProps>((props) =>
  donationConfirmationSubheading(props),
);

export const Footer = styled("div")`
  bottom: 0;
  position: fixed;
  width: 100%;

  ${bp("xs_up")`
    display: flex;
  `}

  ${bp("s_up")`
    justify-content: center;
    padding: 0;
    position: static;
  `}
`;

export const MainContentSection = styled("div")``;

export const ContentRow = styled("div")<{ table?: boolean }>(
  ({ theme, table }) => ({
    fontSize: "14px",
    lineHeight: "20px",
    borderBottom: `1px solid ${color.greyCobble}`,
    color: color.navy,
    display: table ? "block" : "flex",
    flexDirection: "column",
    padding: `${pxToRem(11)} 0`,

    "&:last-child": {
      borderBottom: "0 none",
    },

    [theme.breakpoints.up("xs")]: {
      alignItems: "flex-start",
      flexDirection: "row",
      padding: `${pxToRem(7)} 0`,
    },

    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
      lineHeight: "22px",
    },
  }),
);

interface ContentHeadingProps {
  columns?: boolean;
}

export const ContentHeading = styled("h3")<ContentHeadingProps>(
  ({ theme, columns }) => ({
    fontWeight: 700,
    lineHeight: "1.5",
    marginRight: `${pxToRem(24)}`,
    marginTop: 0,
    width: columns ? "100%" : `calc(50% - 12px)`,

    [theme.breakpoints.up("xs")]: {
      marginRight: `${pxToRem(24)}`,
      width: columns ? "100%" : `calc(50% - 12px)`,
    },

    ...(columns && {
      width: "100% !important",
      marginRight: `${pxToRem(24)}`,

      [theme.breakpoints.up("xs")]: {
        borderBottom: `1px solid ${color.greyCobble}`,
        marginBottom: `${pxToRem(8)}`,
        padding: `${pxToRem(7)} 0`,
      },
    }),
  }),
);

interface ContentTextProps {
  columns?: boolean;
}

export const ContentText = styled("div")<ContentTextProps>(
  ({ theme, columns }) => ({
    fontWeight: 400,
    color: color.black,
    lineHeight: "1.5",
    wordWrap: "break-word",

    [theme.breakpoints.up("xs")]: {
      width: columns ? "100% !important" : "calc(50% - 12px)",
    },

    ...(columns && {
      width: "100% !important",

      [theme.breakpoints.up("xs")]: {
        margin: `${pxToRem(5)} 0`,
      },
    }),

    img: {
      display: "block",
      padding: `${pxToRem(6)} 0 0`,

      [theme.breakpoints.up("xs")]: {
        padding: `${pxToRem(6)} 0`,
      },
    },
  }),
);

export const Print = styled("a")(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "50px",
  color: "currentColor",
  margin: "0 auto 0 5%",
  height: "50px",
  textDecoration: "none",
  marginLeft: "auto",

  [theme.breakpoints.up("md")]: {
    margin: `0 ${pxToRem(20)} 0 auto`,
  },

  [theme.breakpoints.up("xxl_up")]: {
    margin: `0 ${pxToRem(20)} 0 auto`,
  },

  "&:hover, &:focus, &:active, &:link": {
    color: "inherit",
    textDecoration: "none",
  },

  svg: {
    boxSizing: "content-box",
    fill: "currentColor",
    height: "1em",
    paddingRight: "0.8em",
    width: "1.2em",
    display: "inline-block",
  },

  span: {
    display: "inline-block",
  },
}));

export const DedicationImage = styled("img")`
  margin-bottom: ${pxToRem(7)};
  max-width: 100%;
`;

export const PrintLink = styled("a")`
  text-decoration: underline !important;
  margin-left: 5px !important;
  color: #0050a2 !important;
  &:focus,
  &:hover,
  &:visited,
  &:active {
    pointer: cursor !important;
    text-decoration: none !important;
  }
`;

export const ViewNotificationDiv = styled("div")`
  display: flex !important;
  line-height: 18px !important;
  font-size: 16px !important;
  border-bottom: 1px solid ${color.greyCobble};
  padding-top: 20px !important;
  padding-bottom: 25px !important;
`;

export const LinkDiv = styled("a")`
  display: flex !important;
  text-decoration: none !important;
  outline: none !important;
  pointer: cursor !important;
  &:hover,
  &:focus,
  &:active,
  &:link {
    text-decoration: none;
  }
`;

export const ContentTable = styled("div")`
  border-color: ${color.greyCobble};
  border-style: solid;
  border-width: 0 0 1px;
  margin: 0 0 ${pxToRem(7)};
  padding: 0 0 ${pxToRem(7)};
`;

export const ContentTableRow = styled("div")`
  color: ${color.black};
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: ${pxToRem(4)};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ContentMember = styled("div")`
  margin-right: ${pxToRem(24)};
  width: calc(50% - 12px);
  padding: ${pxToRem(5)};
`;

export const ContentMemberAmt = styled("h3")`
  width: calc(50% - 12px);
  padding: ${pxToRem(5)};
`;

export const ContentMemberAmtTotal = styled("h3")`
  width: calc(50% - 12px);
  padding: ${pxToRem(5)};
  font-weight: 700;
  line-height: 1.5;
  ${bp("xs_up")`
    width: calc(50% - 12px);
  `}

  ${bp("s_up")`
    font-size: 16px;
    line-height: 22px;
    width: calc(50% - 12px);
  `}
`;

export const FooterTotal = styled("h3")`
  margin-right: ${pxToRem(24)};
  width: calc(50% - 12px);
  padding: ${pxToRem(5)};
  font-weight: 700;
  line-height: 1.5;
  ${bp("xs_up")`
    margin-right: 1.5rem;
    width: calc(50% - 12px);
  `}

  ${bp("s_up")`
    font-size: 16px;
    line-height: 22px;
  `}
`;
// Add id: 'table_format'
export const TableFormat = styled("div")`
  display: none !important;
`;

export const HeaderRow = styled("div")`
  border-color: ${color.greyCobble};
  border-style: solid;
  border-width: 0 0 1px;
  color: ${color.black};
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: ${pxToRem(4)};

  &:last-child {
    margin-bottom: 0;
  }
`;

interface ContentYourDonationProps {
  confirmation?: boolean;
  columns?: boolean;
}

export const ContentYourDonation = styled("div")<ContentYourDonationProps>(
  ({ theme, confirmation, columns }) => ({
    marginBottom: pxToRem(6),

    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },

    ...(confirmation && {
      [theme.breakpoints.up("s_up_rn")]: {
        width: "calc(100% / 3 * 2)",
      },
    }),

    ...(columns && {
      [theme.breakpoints.up("xs")]: {
        display: "flex",
        paddingBottom: pxToRem(4),
      },
    }),
  }),
);

interface ContentDedicationProps {
  confirmation?: boolean;
  columns?: boolean;
}

export const ContentDedication = styled("div")<ContentDedicationProps>(
  ({ theme, confirmation, columns }) => ({
    marginBottom: pxToRem(6),

    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },

    ...(confirmation && {
      [theme.breakpoints.up("s_up_rn")]: {
        width: "calc(100% / 3 * 2)",
      },
    }),

    ...(columns && {
      [theme.breakpoints.up("xs")]: {
        display: "flex",
        paddingBottom: pxToRem(4),
      },
    }),
  }),
);
