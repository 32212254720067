import React, { ChangeEvent } from "react";
import FormItem from "./FormItem";
import { styled } from "@mui/system";
import { fontSize, inputStyling } from "shared/styles";

const TextAreaWrapper = styled("div")({
  lineHeight: "24px",
  boxSizing: "border-box",
});
interface StyledTextAreaProps {
  error: boolean;
  maxLength: number;
}

const StyledTextArea = styled("textarea")<StyledTextAreaProps>`
  /* Increase specificity with double class */
  && {
    ${(props) => inputStyling(props.error)};
    display: block;
    height: 175px;
    line-height: 28px;
    resize: none;
    max-length: ${(props) => props.maxLength};
  }
`;

const TextAreaCounter = styled("div")`
  ${fontSize(14, 20)}
  margin-top: -24px;
  text-align: right;
`;

interface TextAreaProps {
  id: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  required?: boolean;
  error?: boolean;
  errorMessages?: string[];
  characterLimit: number | null;
  charactersRemaining: number | null;
  subText: string;
  maxLength: number;
}

const TextArea: React.FC<TextAreaProps> = ({
  id,
  value,
  onChange,
  label,
  required = false,
  error = false,
  errorMessages = [],
  characterLimit = null,
  charactersRemaining = null,
  subText = "",
  maxLength,
  ...otherProps
}) => (
  <TextAreaWrapper>
    <FormItem
      FormElement={StyledTextArea}
      id={id}
      label={label}
      value={value}
      required={required}
      error={error}
      errorMessages={errorMessages}
      onChange={onChange}
      maxLength={maxLength}
      {...otherProps}
    />

    <TextAreaCounter>
      {charactersRemaining! >= 0 && characterLimit && (
        <>
          {charactersRemaining || charactersRemaining === 0
            ? `${charactersRemaining} characters remaining`
            : `${characterLimit} characters remaining`}
        </>
      )}
    </TextAreaCounter>
  </TextAreaWrapper>
);

export default TextArea;
