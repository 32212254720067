import { useState } from "react";
import { useSelector } from "react-redux";
import Select from "components/Form/components/Select";
import { RootState } from "shared/state/store";
import { SelectOptions } from "shared/types";
import useActions from "Hooks/useActions";

const RequestSourceSelector = () => {
  const { setRequestSource, setRequestSourceError, setPrivacyPolicyFlag } =
    useActions();
  const [placeholder, setPlaceholder] = useState("Select");
  const { config } = useSelector((state: RootState) => state.app);
  const { requestSource } = useSelector(
    (state: RootState) => state.staffUseOnly,
  );
  const { requestSourceError } = useSelector((state: RootState) => state.error);

  const onChange = (requestSource: SelectOptions) => {
    setRequestSource(requestSource.value as string);
    setPlaceholder(requestSource.value as string);
    if (requestSource && !requestSource.value) {
      setRequestSourceError(true, true);
    } else {
      setRequestSourceError(false, false);
      setPrivacyPolicyFlag(requestSource.ShowPrivacyPolicy as boolean);
    }
  };

  const requestSourceOptions =
    config &&
    config.request_sources
      .slice()
      .sort((a, b) => a.SourceName.localeCompare(b.SourceName));

  return (
    <div>
      <Select
        id="RequestSource"
        label="Request source"
        placeholder={placeholder}
        value={requestSource}
        options={requestSourceOptions}
        onChange={onChange}
        required
        error={requestSourceError.visibility && requestSourceError.status}
        errorMessages={["This field is required."]}
      />
    </div>
  );
};

export default RequestSourceSelector;
