import { useState, useCallback, useEffect, ChangeEvent } from "react";
import { styled, css } from "@mui/system";
import { useSelector } from "react-redux";
import ModuleContainer from "../ModuleContainer";
import CalloutBtn from "components/Form/components/CalloutBtn";
import TextInput from "components/Form/components/TextInput";
import Checkbox from "components/Form/components/Checkbox";
import TextArea from "components/Form/components/TextArea";
import ImageSelector from "components/Form/components/ImageSelector";
import DedicationPreview from "components/Dedication/DedicationPreview/index";
import { color, fieldLabel, CustomButton } from "shared/styles";
import { validateEmail } from "shared/utils/inputValidation";
import { getSelectors, scrollTo, setValueFromLocalStorage } from "shared/utils";
import { DedicationImages, SelectOptions } from "shared/types";
import { dedicationImages } from "shared/state/dedication/index";
import { RootState } from "shared/state/store";
import CountrySelector from "components/StaffUseOnly/RecognitionFields/CountrySelector";
import StateProvinceSelector from "components/StaffUseOnly/RecognitionFields/StateProvinceSelector";
import useActions from "Hooks/useActions";

const Row = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "10px",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const NotificationHeading = styled("div")({
  borderBottom: `1px solid ${color.navy}`,
  color: color.navy,
  fontSize: "24px",
  fontWeight: 300,
  lineHeight: "90%",
  marginBottom: "30px",
  marginTop: "40px",
  paddingBottom: "15px",
});

const Label = styled("label")({ ...fieldLabel() });

const DedicationImagesWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  alignItems: "flex-start",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const ImageRow = styled("div")({
  flexBasis: "47%",
  marginBottom: "30px",
});

const dynamicStyle = (props: { color: string }) => css`
  color: ${props.color};
`;

interface PreviewButtonProps {
  primary?: boolean;
}

const PreviewButton = styled(CustomButton)<PreviewButtonProps>(({ theme }) => ({
  width: "50%",
  marginTop: "30px",
  marginBottom: "30px",
  ...dynamicStyle,
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

interface DedicationProps {
  allDedicationImages: DedicationImages;
}

const Dedication = ({ allDedicationImages }: DedicationProps) => {
  const [subtitleVisibleId, setSubtitleVisibleId] = useState<string | null>(
    null,
  );
  const {
    dedicationName,
    dedicationType,
    dedicationNotificationStatus,
    dedicationImages,
    notificationType,
    personalMessageMaxLength,
    recipientEmail,
    donorName,
    selectedDedicationImage,
    dedicationVisibility,
    personalMessage,
    charactersRemaining,
    dedicationPreviewVisibility,
  } = useSelector((state: RootState) => state.dedication);
  const {
    dedicationNameError,
    recipientEmailError,
    donorNameError,
    countryError,
    stateProvinceError,
  } = useSelector((state: RootState) => state.error);
  const {
    occasion,
    recipientName,
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    province,
    country,
    postalCode,
  } = useSelector((state: RootState) => state.staffUseOnly);
  const { dedicationStateProvinceList, mode, dpmBtnMode } = useSelector(
    (state: RootState) => state.app,
  );
  const {
    setDedicationType,
    setDonorName,
    setDonorNameError,
    setDedicationName,
    setDedicationNameError,
    setRecipientEmail,
    setRecipientEmailError,
    setNotificationType,
    handleToggleDedicationNotificationStatus,
    setSelectedDedicationImage,
    setCountryError,
    setPersonalMessage,
    setOccasion,
    setRecipientName,
    setAddressLine1,
    setAddressLine2,
    setAddressLine3,
    setCity,
    setProvince,
    setMailLetterCountry,
    setPostalCode,
    setStateProvinceError,
    setDedicationStateProvinceList,
    setDedicationErrorsVisibility,
    setDedicationPreviewVisibility,
  } = useActions();

  const allDedicationImagesForDedication = allDedicationImages;

  const onNameInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDedicationName(event.target.value);
    const hasError = !event.target.value;
    setDedicationNameError(hasError, false);
  };

  const onNameInputBlur = () => {
    if (dedicationName.length === 0) {
      setDedicationNameError(true, true);
    }
  };

  const onRecipientEmailInputChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setRecipientEmail(event.target.value);
    setRecipientEmailError(!validateEmail(event.target.value), false);
  };

  const onRecipientEmailInputBlur = () => {
    if (recipientEmail.length === 0 || !validateEmail(recipientEmail)) {
      setRecipientEmailError(true, true);
    }
    setRecipientEmailError(false, true);
  };

  const onDonorNameInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDonorName(event.target.value);
    const hasError = !event.target.value;
    setDonorNameError(hasError, false);
  };

  const onDonorNameInputBlur = () => {
    if (donorName.length === 0) {
      setDonorNameError(true, true);
    }
    setDonorNameError(false, true);
  };

  const onPersonalMessageInputChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setPersonalMessage(
      event.target.value,
      personalMessageMaxLength - event.target.value.length,
    );
  };

  const setToDefaultImg = () => {
    const defaultImg =
      dedicationType === "honor"
        ? allDedicationImagesForDedication[0]
        : allDedicationImagesForDedication[1];
    setSelectedDedicationImage(allDedicationImagesForDedication, defaultImg);
  };

  const onNotificationCheckboxChange = () => {
    if (dedicationNotificationStatus) {
      // remove errors
      setDonorNameError(false, false);
      setRecipientEmailError(false, false);
      // reset to email
      setNotificationType("email");
    } else {
      // register errors, but don't make them visible
      setDonorNameError(true, false);
      if (notificationType === "email") {
        setRecipientEmailError(true, false);
      }
    }
    handleToggleDedicationNotificationStatus(allDedicationImagesForDedication);
    setToDefaultImg();
  };

  const emailCardBtnOnClick = () => {
    if (notificationType !== "email") {
      setRecipientEmail("");
      setToDefaultImg();
    }
    setNotificationType("email");
    setRecipientEmailError(!validateEmail(recipientEmail), false);
  };

  const printCardBtnOnClick = () => {
    if (notificationType !== "print") {
      setToDefaultImg();
    }
    setNotificationType("print");
    setRecipientEmailError(false, false);
  };

  const mailCardBtnOnClick = () => {
    if (notificationType !== "letter") {
      setOccasion(null);
      setRecipientName(null);
      setAddressLine1(null);
      setAddressLine2(null);
      setAddressLine3(null);
      setCity(null);
      setProvince(null);
      setPostalCode(null);
      setMailLetterCountry(null);
    }
    setNotificationType("letter");
    setRecipientEmailError(false, false);
    setCountryError(true, false);
  };

  const onClickSubtitleHandler = useCallback(
    (subtitleId: string, event: React.MouseEvent<HTMLInputElement>) => {
      if (event) {
        event.stopPropagation();
      }
      const isOpeningSubtitle = subtitleVisibleId !== subtitleId;
      setSubtitleVisibleId(isOpeningSubtitle ? subtitleId : null);
    },
    [subtitleVisibleId],
  );

  useEffect(() => {
    setDedicationNameError(false, false);
    setRecipientEmailError(false, false);
    setDonorNameError(false, false);

    if (dedicationNotificationStatus) {
      setToDefaultImg();
      if (dedicationName.length === 0) {
        setDedicationNameError(true, false);
      }
      if (donorName.length === 0) {
        setDonorNameError(true, false);
      }
      if (notificationType === "email" && recipientEmail.length === 0) {
        setRecipientEmailError(true, false);
      }
    }
  }, [dedicationNotificationStatus, dedicationVisibility, notificationType]);

  const onPreviewCard = () => {
    if (dedicationName.length === 0) {
      setDedicationNameError(true, true);
    }
    if (donorName.length === 0) {
      setDonorNameError(true, true);
    }
    if (notificationType === "email" && recipientEmail.length === 0) {
      setRecipientEmailError(true, true);
    }
    const dedicationError =
      dedicationNameError.status === true
        ? "dedicationNameError"
        : recipientEmailError.status === true
          ? "recipientEmailError"
          : donorNameError.status === true
            ? "donorNameError"
            : false;
    if (dedicationError) {
      setDedicationErrorsVisibility(true);
      const cssSelector = getSelectors(dedicationError).toString();
      scrollTo(cssSelector);
    } else {
      setDedicationPreviewVisibility(true);
    }
  };

  const dedicationImageComponents =
    dedicationImages &&
    dedicationImages.map((dedicationImage: dedicationImages) => (
      <ImageRow>
        <ImageSelector
          key={dedicationImage.id}
          isSelected={
            selectedDedicationImage &&
            dedicationImage.id === selectedDedicationImage.id
          }
          onClickImageSelector={() =>
            setSelectedDedicationImage(
              allDedicationImagesForDedication,
              dedicationImage,
            )
          }
          imageUrl={dedicationImage.url}
          imageAlt={dedicationImage.alt}
        />
      </ImageRow>
    ));

  return (
    <ModuleContainer headerText={"Dedication"}>
      <Row>
        <CalloutBtn
          title="In memory of"
          isSelected={dedicationType === "memory"}
          onClickCalloutBtn={() =>
            setDedicationType(allDedicationImagesForDedication, "memory")
          }
          isSubtitleVisible={false}
          isMiddleBottom={false}
          minHeight="130px"
        />
        <CalloutBtn
          title="In honor of"
          isSelected={dedicationType === "honor"}
          onClickCalloutBtn={() =>
            setDedicationType(allDedicationImagesForDedication, "honor")
          }
          isSubtitleVisible={false}
          isMiddleBottom={false}
          minHeight="130px"
        />
      </Row>
      <TextInput
        label={
          dedicationType === "memory" ? "Name of deceased" : "Name of honoree"
        }
        id="DedicationName"
        value={dedicationName}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          onNameInputChange(event);
        }}
        onBlur={onNameInputBlur}
        maxLength="50"
        type="text"
        error={dedicationNameError.status && dedicationNameError.visibility}
        errorMessages={["Please enter a name"]}
      />
      <Checkbox
        label="Yes, I would like to notify someone of this dedication"
        checked={dedicationNotificationStatus}
        onChange={onNotificationCheckboxChange}
        id="NotifyCheckbox"
      />
      {dedicationNotificationStatus && (
        <>
          <NotificationHeading>Notification</NotificationHeading>
          <Row>
            <CalloutBtn
              title="Email"
              isSelected={
                setValueFromLocalStorage(
                  "notificationType",
                  "string",
                  notificationType,
                ) === "email"
              }
              onClickCalloutBtn={emailCardBtnOnClick}
              isSubtitleVisible={subtitleVisibleId === "email"}
              onClickSubtitle={(event) =>
                onClickSubtitleHandler("email", event)
              }
              subtitle="Rotary will send the recipient an email with a link to your personalized notification after your donation is completed"
              isMiddleBottom={false}
            />
            <CalloutBtn
              title="Print and deliver it myself"
              isSelected={
                setValueFromLocalStorage(
                  "notificationType",
                  "string",
                  notificationType,
                ) === "print"
              }
              onClickCalloutBtn={printCardBtnOnClick}
              isSubtitleVisible={subtitleVisibleId === "print"}
              onClickSubtitle={(event) =>
                onClickSubtitleHandler("print", event)
              }
              subtitle="You will be able to print and deliver your personalized notification after you complete your donation"
              isMiddleBottom={false}
            />
          </Row>
          {mode === "dpm" && dpmBtnMode === "addDonation" && (
            <Row>
              <CalloutBtn
                title="Mail letter"
                isSelected={
                  setValueFromLocalStorage(
                    "notificationType",
                    "string",
                    notificationType,
                  ) === "letter"
                }
                onClickCalloutBtn={mailCardBtnOnClick}
              />
            </Row>
          )}
          {notificationType === "email" && (
            <TextInput
              label={"Recipient's email address"}
              helpText={
                "Recipient's email address will be used only to notify them of this gift. It will not be shared."
              }
              id="RecipientEmail"
              value={recipientEmail}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onRecipientEmailInputChange(event)
              }
              onBlur={onRecipientEmailInputBlur}
              maxLength="80"
              type="email"
              error={
                recipientEmailError.status && recipientEmailError.visibility
              }
              errorMessages={["Please enter a valid email address"]}
            />
          )}
          <TextInput
            label="Name of donor"
            subText="as you would like it to appear on the notification"
            id="DonorName"
            value={donorName}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onDonorNameInputChange(event)
            }
            onBlur={onDonorNameInputBlur}
            maxLength="50"
            type="text"
            error={donorNameError.status && donorNameError.visibility}
            errorMessages={["Please enter a name"]}
          />
          {notificationType === "letter" && (
            <>
              <TextInput
                label="Occasion"
                id="Occasion"
                value={occasion}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setOccasion(event.target.value)
                }
              />
              <TextInput
                label="Recipient's name"
                id="RecipientName"
                value={recipientName}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setRecipientName(event.target.value)
                }
              />
              <TextInput
                label="Address line 1"
                id="AddressLine1"
                value={addressLine1}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setAddressLine1(event.target.value)
                }
              />
              <TextInput
                label="Address line 2"
                id="AddressLine2"
                value={addressLine2}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setAddressLine2(event.target.value)
                }
              />
              <TextInput
                label="Address line 3"
                id="AddressLine3"
                value={addressLine3}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setAddressLine3(event.target.value)
                }
              />
              <TextInput
                label="City"
                id="City"
                value={city}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setCity(event.target.value)
                }
              />
              <TextInput
                label="Postal Code"
                id="PostalCode"
                value={postalCode}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setPostalCode(event.target.value)
                }
              />
              <CountrySelector
                label="Country"
                id="Country"
                countryError={countryError}
                setDpmCountryError={setCountryError}
                setStateError={setStateProvinceError}
                setStateValue={setProvince}
                setStateList={setDedicationStateProvinceList}
                setDpmCountry={setMailLetterCountry}
              />
              {(country && country.toLowerCase() === "us") ||
              (country && country.toLowerCase() === "ca") ? (
                <StateProvinceSelector
                  label="State/Province"
                  id="Province"
                  stateProvinceValue={province}
                  selectOptions={dedicationStateProvinceList as SelectOptions[]}
                  setValue={setProvince}
                  setStateError={setStateProvinceError}
                  stateError={stateProvinceError}
                />
              ) : (
                <TextInput
                  label="State/Province"
                  id="Province"
                  value={province}
                  onChange={(e) => {
                    setProvince(e.target.value);
                  }}
                  required
                  error={
                    stateProvinceError.visibility && stateProvinceError.status
                  }
                  errorMessages={["This field is required."]}
                />
              )}
            </>
          )}
          {notificationType !== "letter" && (
            <>
              <Label>Choose a design</Label>
              <DedicationImagesWrapper>
                {dedicationImageComponents}
              </DedicationImagesWrapper>

              <TextArea
                label="Add a personal message"
                subText="optional"
                id="PersonalMessage"
                value={personalMessage}
                characterLimit={personalMessageMaxLength}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  onPersonalMessageInputChange(event)
                }
                maxLength={personalMessageMaxLength}
                charactersRemaining={charactersRemaining}
              />
              <PreviewButton
                onClick={onPreviewCard}
                id="preview-button"
                primary
              >
                Preview
              </PreviewButton>
            </>
          )}
        </>
      )}
      {dedicationPreviewVisibility && (
        <DedicationPreview
          setDedicationPreviewVisibility={setDedicationPreviewVisibility}
          // fullDonateFormVisibility={user.authenticated || user.guestContinue}  //To be added once user data is hardcoded
        />
      )}
    </ModuleContainer>
  );
};

export default Dedication;
