import { createAsyncThunk } from "@reduxjs/toolkit";
import { createFetch } from "shared/utils/api";

export const fetchConfig = createAsyncThunk("app/fetchConfig", (mode: string) =>
  createFetch({
    endpoint: "/getConfig",
    params: { mode: mode },
    method: "GET",
  }),
);

export const fetchPaymentProfile = createAsyncThunk(
  "app/fetchPaymentProfile",
  (params: {
    CountryISO2: string;
    CurrencyCode: string;
    TransactionTypeAlias: string;
    CustomerType: string;
    TransactionFlowName: string;
  }) =>
    createFetch({
      endpoint: "/payment-profile",
      params: params,
      method: "GET",
    }),
);

export const fetchPanStatus = createAsyncThunk(
  "app/fetchPanStatus",
  (params: { memberid: string }) =>
    createFetch({
      endpoint: "/pan-status",
      params: params,
      method: "GET",
    }),
);

export const fetchUserInfo = createAsyncThunk(
  "app/fetchUserInfo",
  (params: { memberid: string; role: string }) =>
    createFetch({
      endpoint: `/user-info/${params.memberid}`,
      params: params,
      method: "GET",
    }),
);
