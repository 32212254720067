import { ChangeEvent } from "react";
import { FormControlLabel } from "@mui/material";
import CustomCheckbox from "components/StaffUseOnly/Checkbox";

interface OverrideCreditOrgCheckboxProps {
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const styles = {
  ".MuiFormControlLabel-root": {
    marginTop: "15px",
  },

  ".MuiFormControlLabel-label": {
    fontWeight: "700",
    fontSize: "1rem !important",
    color: "#263b4c",
  },
};

const OverrideCreditOrgCheckbox = (props: OverrideCreditOrgCheckboxProps) => {
  return (
    <FormControlLabel
      sx={styles}
      control={
        <CustomCheckbox
          id="OverrideCreditOrgCheckbox"
          checked={props.checked}
          onChange={props.onChange}
        />
      }
      label="Override credit org"
    />
  );
};

export default OverrideCreditOrgCheckbox;
