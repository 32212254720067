import { createSlice } from "@reduxjs/toolkit";
import {
  getDedicationImage,
  getDedicationImagesByType,
  removeKeysFromLocalStorage,
  setItemsInLocalStorage,
  setValueFromLocalStorage,
  updateErrorInLocalStorage,
} from "shared/utils";
import { DedicationImage, DedicationImages } from "shared/types";

const defaultDedicationType = "memory";
export interface dedicationImages {
  id: string;
  type: string;
  url: string;
  alt: string;
}

export interface DedicationState {
  dedicationVisibility: boolean;
  dedicationType: string;
  dedicationName: string;
  dedicationNotificationStatus: boolean;
  notificationType: string;
  recipientEmail: string;
  donorName: string;
  personalMessage: string;
  charactersRemaining: number | null;
  dedicationImages: DedicationImage[];
  selectedDedicationImage: DedicationImage;
  ecardLink: string;
  dedicationPreviewVisibility: boolean;
  personalMessageMaxLength: number;
}

const initialStateDedication = {
  dedicationVisibility: setValueFromLocalStorage(
    "dedicationVisibility",
    "json",
    false,
  ),
  dedicationType: setValueFromLocalStorage(
    "dedicationType",
    "string",
    defaultDedicationType,
  ),
  dedicationName: setValueFromLocalStorage("dedicationName", "string", ""),
  dedicationNotificationStatus: setValueFromLocalStorage(
    "dedicationNotificationStatus",
    "json",
    false,
  ),
  notificationType: setValueFromLocalStorage(
    "notificationType",
    "string",
    "email",
  ), // choices are email or print
  recipientEmail: setValueFromLocalStorage("recipientEmail", "string", ""),
  donorName: setValueFromLocalStorage("donorName", "string", ""),
  personalMessage: setValueFromLocalStorage("personalMessage", "string", ""),
  charactersRemaining: setValueFromLocalStorage(
    "charactersRemaining",
    "string",
    null,
  ),
  dedicationImages: [{ id: "", type: "", url: "", alt: "" }],
  selectedDedicationImage: { id: "", type: "", url: "", alt: "" },
  ecardLink: "",
  dedicationPreviewVisibility: false,
  personalMessageMaxLength: 150,
};

const dedicationSlice = createSlice({
  name: "dedication",
  initialState: initialStateDedication,
  reducers: {
    setDefaultDedicationImages: (state, action) => {
      const { dedicationImages } = action.payload;
      const defaultDedicationType = "memory";
      const defaultDedicationImages = getDedicationImagesByType(
        dedicationImages,
        defaultDedicationType,
      );
      state.dedicationImages = defaultDedicationImages;
      state.selectedDedicationImage = defaultDedicationImages[0];
    },
    setDedicationType: (state, action) => {
      const { dedicationType, dedicationImages } = action.payload;
      // if (dedicationType === state.dedicationType) {
      //   return state;
      // }
      const dedicationImagesByType = getDedicationImagesByType(
        dedicationImages,
        dedicationType,
      );
      setItemsInLocalStorage(
        "dedicationImages",
        dedicationImagesByType,
        "json",
      );
      setItemsInLocalStorage(
        "selectedDedicationImage",
        dedicationImagesByType[0],
        "json",
      );
      global.localStorage.setItem(
        "dedicationImage",
        JSON.stringify(dedicationImagesByType[0]),
      );
      state.dedicationType = dedicationType;
      state.dedicationImages = dedicationImagesByType;
      state.selectedDedicationImage = dedicationImagesByType[0];
    },
    setDedicationName: (state, action) => {
      setItemsInLocalStorage(
        "dedicationName",
        action.payload.dedicationName,
        "string",
      );
      updateErrorInLocalStorage("dedicationNameError", false, false);

      state.dedicationName = action.payload.dedicationName;
    },
    setNotificationType: (state, action) => {
      setItemsInLocalStorage(
        "notificationType",
        action.payload.notificationType,
        "string",
      );
      state.notificationType = action.payload.notificationType;
    },
    setRecipientEmail: (state, action) => {
      setItemsInLocalStorage(
        "recipientEmail",
        action.payload.recipientEmail,
        "string",
      );
      state.recipientEmail = action.payload.recipientEmail;
    },
    setDonorName: (state, action) => {
      setItemsInLocalStorage("donorName", action.payload.donorName, "string");
      updateErrorInLocalStorage("donorNameError", false, false);

      state.donorName = action.payload.donorName;
    },
    setPersonalMessage: (state, action) => {
      setItemsInLocalStorage(
        "personalMessage",
        action.payload.personalMessage,
        "string",
      );
      setItemsInLocalStorage(
        "charactersRemaining",
        action.payload.charactersRemaining,
        "string",
      );

      state.personalMessage = action.payload.personalMessage;
      state.charactersRemaining = action.payload.charactersRemaining;
    },
    toggleDedicationNotificationStatus: (state, action) => {
      const {
        dedicationNotificationStatus = !state.dedicationNotificationStatus,
      } = action.payload;
      setItemsInLocalStorage(
        "dedicationNotificationStatus",
        dedicationNotificationStatus,
        "json",
      );
      state.dedicationNotificationStatus = dedicationNotificationStatus;

      if (!dedicationNotificationStatus) {
        updateErrorInLocalStorage("recipientEmailError", false, false);
        updateErrorInLocalStorage("donorNameError", false, false);
        removeKeysFromLocalStorage(["recipientEmail", "donorName"]);

        state.recipientEmail = "";
        state.donorName = "";
        state.personalMessage = "";
        state.charactersRemaining = null;
      }
    },
    toggleDedicationVisibility: (state, action) => {
      const {
        dedicationVisibility = !state.dedicationVisibility,
        dedicationImages,
      } = action.payload;
      state.dedicationVisibility = dedicationVisibility;
      const {
        dedicationNotificationStatus = !state.dedicationNotificationStatus,
      } = action.payload;
      setItemsInLocalStorage(
        "dedicationVisibility",
        dedicationVisibility,
        "json",
      );
      setItemsInLocalStorage(
        "dedicationNotificationStatus",
        dedicationNotificationStatus,
        "json",
      );
      if (!dedicationVisibility) {
        updateErrorInLocalStorage("dedicationNameError", false, false);
        updateErrorInLocalStorage("donorNameError", false, false);
        updateErrorInLocalStorage("recipientEmailError", false, false);
        removeKeysFromLocalStorage([
          "dedicationName",
          "dedicationNotificationStatus",
          "recipientEmail",
          "donorName",
        ]);

        Object.assign(state, initialStateDedication); // Reset to initial state
      } else {
        state.dedicationNotificationStatus = dedicationNotificationStatus;
        const defaultDedicationImages = getDedicationImagesByType(
          dedicationImages,
          defaultDedicationType,
        );
        state.dedicationImages = defaultDedicationImages;
      }
    },
    setSelectedDedicationImage: (state, action) => {
      const { dedicationImages, selectedDedicationImage } = action.payload;
      setItemsInLocalStorage(
        "selectedDedicationImage",
        selectedDedicationImage,
        "json",
      );
      const dedicationImage = getDedicationImage(
        dedicationImages,
        selectedDedicationImage.id,
      );
      state.selectedDedicationImage = selectedDedicationImage;
    },
    setEcardLink: (state, action) => {
      state.ecardLink = action.payload.ecardLink;
    },
    setDedicationPreviewVisibility: (state, action) => {
      const {
        dedicationPreviewVisibility = !state.dedicationPreviewVisibility,
      } = action.payload;
      state.dedicationPreviewVisibility = dedicationPreviewVisibility;
    },
  },
});

export const { toggleDedicationNotificationStatus } = dedicationSlice.actions;

export const createSetDefaultDedicationImages = (
  dedicationImages: DedicationImages,
) => ({
  type: "dedication/setDefaultDedicationImages",
  payload: { dedicationImages },
});

export const createSetDedicationVisibility = (
  dedicationImages: DedicationImages,
) => ({
  type: "dedication/toggleDedicationVisibility",
  payload: { dedicationImages },
});

export const createSetToggleNotificationStatus = (
  dedicationImages: DedicationImages,
) => ({
  type: "dedication/toggleDedicationNotificationStatus",
  payload: { dedicationImages },
});

export const createSetDedicationType = (
  dedicationImages: DedicationImages,
  dedicationType: string,
) => ({
  type: "dedication/setDedicationType",
  payload: { dedicationType, dedicationImages },
});

export const createSetNotificationType = (notificationType: string) => ({
  type: "dedication/setNotificationType",
  payload: { notificationType },
});

export const createSetRecipientEmail = (recipientEmail: string) => ({
  type: "dedication/setRecipientEmail",
  payload: { recipientEmail },
});

export const createSetSelectedDedicationImage = (
  dedicationImages: DedicationImages,
  selectedDedicationImage: dedicationImages,
) => ({
  type: "dedication/setSelectedDedicationImage",
  payload: { dedicationImages, selectedDedicationImage },
});

export const createSetPersonalMessage = (
  personalMessage: string,
  charactersRemaining: number,
) => ({
  type: "dedication/setPersonalMessage",
  payload: { personalMessage, charactersRemaining },
});

export const createSetDedicationName = (dedicationName: string) => ({
  type: "dedication/setDedicationName",
  payload: { dedicationName },
});

export const createSetDonorName = (donorName: string) => ({
  type: "dedication/setDonorName",
  payload: { donorName },
});

export const createSetDedicationPreviewVisibility = (visibility: boolean) => ({
  type: "dedication/setDedicationPreviewVisibility",
  payload: { visibility },
});

export const createSetEcardLink = (eCardLink: string) => ({
  type: "dedication/setECardLink",
  payload: { eCardLink },
});

export default dedicationSlice.reducer;
