import React from "react";
import { styled } from "@mui/system";
import { pxToRem, bp, fieldLabel, inputStyling } from "shared/styles";

const MultipleDonorGridFooterContainer = styled("div")`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-bottom: ${pxToRem(30)};
`;

const AmountTotalContainer = styled("div")`
  cursor: default;
  width: ${pxToRem(105)};
  ${bp("xs")`
    width: 4.063rem;
  `};
  ${bp("sm")`
    width: 7rem;
  `};
`;

const AmountTotalLabel = styled("div")`
  ${fieldLabel()}
`;

const CurrencyCode = styled("span")`
  font-weight: 400;
`;

interface AmountTotalProps {
  error: boolean;
}

const AmountTotal = styled("div")<AmountTotalProps>`
  ${(props) => inputStyling(props.error)};
  display: block;
  line-height: 28px;
  padding: 0.2rem 0.295rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface Row {
  isAutoInserted: boolean;
  errorMessage: { err_amount?: string };
  amount: string;
}

interface MultipleDonorGridFooterProps {
  currencyCode: string;
  amount: string;
  rows: Row[];
}

const MultipleDonorGridFooter: React.FC<MultipleDonorGridFooterProps> = ({
  currencyCode,
  rows,
}) => {
  let amountTotalText: string = "";
  let amountTotalNum: number = 0;
  let nonAutoInsertedRows = rows.filter((row) => !row.isAutoInserted);
  let noError = nonAutoInsertedRows.every(
    (value) => value.errorMessage.err_amount === undefined,
  );
  if (noError) {
    amountTotalNum = nonAutoInsertedRows.reduce((acc, cur) => {
      return acc + Number(cur.amount);
    }, 0);
  }

  if (amountTotalNum > 0) {
    amountTotalText = amountTotalNum.toString();
  } else {
    amountTotalText = "";
  }
  return (
    <MultipleDonorGridFooterContainer>
      <AmountTotalContainer>
        <AmountTotalLabel>
          Total <CurrencyCode>({currencyCode})</CurrencyCode>
        </AmountTotalLabel>
        <AmountTotal title={amountTotalText} error={false}>
          {amountTotalText}
        </AmountTotal>
      </AmountTotalContainer>
    </MultipleDonorGridFooterContainer>
  );
};

export default MultipleDonorGridFooter;
