import { ChangeEvent } from "react";
import { FormControlLabel } from "@mui/material";
import Checkbox from "./Checkbox";

const styles = {
  ".MuiFormControlLabel-root": {
    marginTop: "15px",
  },

  ".MuiFormControlLabel-label": {
    fontWeight: "700",
    fontSize: "1rem !important",
    color: "#263b4c",
  },
};

interface AnonymousProps {
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Anonymous = (props: AnonymousProps) => {
  return (
    <FormControlLabel
      label="Anonymous"
      sx={styles}
      control={<Checkbox checked={props.checked} onChange={props.onChange} />}
    />
  );
};

export default Anonymous;
