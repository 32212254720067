import { updateErrorInLocalStorage } from ".";

/**
 * Validate email address according to Rotary standards.
 *
 * @param {string} email - The email to be validated.
 * @returns {boolean}
 */
export const validateEmail = (email = "", maxlength = 80) => {
  if (typeof email != "string") {
    return false;
  }
  const emailTrimmed = email.trim();

  //contains spaces?
  if (emailTrimmed.indexOf(" ") != -1) {
    return false;
  }

  //length exceeds maxlength
  if (emailTrimmed.length > maxlength) {
    return false;
  }

  const parts = emailTrimmed.split("@");

  if (parts.length != 2) {
    return false;
  }

  const localPart = parts[0];
  const domainSubdomainTld2TldPart = parts[1];
  if (localPart.length == 0 || domainSubdomainTld2TldPart.length == 0) {
    return false;
  }

  const regex1 = /^[^\W_]((?!.*?\.\.)[!#$%&'*+\-\/=?^`{|}~.\w]*[^\W_])?$/g;
  const regex2 =
    /^[^\W_]((?!.*?\-\-)[A-Za-z0-9\-]{0,60}[^\W_])?(\.[^\W_]((?!.*?\-\-)[A-Za-z0-9\-]{0,60}[^\W_])?)*\.((?=.*?[a-zA-Z])[^\W_]{2,})$/g;
  if (
    regex1.test(localPart) == false ||
    regex2.test(domainSubdomainTld2TldPart) == false
  ) {
    return false;
  }
  updateErrorInLocalStorage("recipientEmailError", false, false);
  return true;
};

/**
 * Checks if input contains any non-digit characters.
 *
 * @param {string} input - The input to check.
 * @returns {boolean}
 */
const containsNonDigitCharacters = (input: string) => /\D/.test(input);
const containNoZeroAtBeginning = (input: string) =>
  /^[1-9\s][0-9\s]*$/.test(input);

/**
 * Validation function for Grant Number field.
 *
 * @param {string} number - The number to be validated.
 * @returns {boolean}
 */
export const validateGrantNumber = (number: string) => {
  return !containsNonDigitCharacters(number) && number.length === 7;
};

export const validateTrackedGiftId = (trackedGiftId: string) => {
  return (
    trackedGiftId.length > 0 &&
    trackedGiftId.length < 31 &&
    /^[A-Z]+$/.test(trackedGiftId[0]) === true &&
    /^[0-9]+$/.test(trackedGiftId.slice(1)) === true &&
    /^[A-Z][0-9_-]{1,30}$/.test(trackedGiftId.trim())
  );
};

/**
 * Validation function for Custom Amount field.
 *
 * @param {string} amount - The amount to be validated.
 * @returns {boolean}
 */
export const validateCustomAmount = (amount: string) => {
  return containNoZeroAtBeginning(amount) && !!Number(amount);
};
