import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import { bp, pxToRem } from "shared/styles";
import { useSelector } from "react-redux";
import { RootState } from "shared/state/store";

const HeadingContainer = styled("div")`
  margin-left: 0;
  margin-right: 0;
  ${bp("sm")`
    margin-left:3.43rem;
    margin-right:3.43rem;
  `}
`;

const Heading = styled("div")`
  font-weight: 300;
  font-size: 2rem;
  line-height: 2rem;
  color: #263b4c;
  ${bp("sm")`
    font-size:4.125rem;
    line-height:4.125rem;
    font-weight: 300;
  `};
`;

const ConfirmationHeading = styled("div")`
  font-weight: 300;
  font-size: 2.25rem;
  line-height: 2.25rem;
  padding-bottom: 1rem;
  ${bp("sm")`
    font-size:4.125rem;
    line-height:4.125rem;
    font-weight: 300;
  `}
`;

const BlurbText = styled("div")`
  color: black;
  padding-top: 1rem;
  padding-left: 4px;
  font-size: 1.2em;
  line-height: 1.43em;
  ${bp("sm")`    
    padding-top:1rem;
    padding-left:4px;
  `}
  span {
    font-weight: 700;
  }
`;

const InfoText = styled("div")``;

interface HeadingDivProps {
  headingType: string;
}

const HeadingDiv = styled("div")<HeadingDivProps>(({ theme, headingType }) => ({
  [theme.breakpoints.down("sm")]: {
    marginLeft: "1.875rem",
    marginRight: "1.875rem",
  },
  [theme.breakpoints.up("sm")]: {
    marginLeft: headingType === "Confirmation" ? 0 : "6rem",
    marginRight: headingType === "Confirmation" ? 0 : "6rem",
  },
}));

const InfoTextItalic = styled("div")`
  font-style: italic;
`;

const ConfirmationSubTitle = styled("div")`
  color: black;
  padding-top: 0.2rem;
  font-size: 1rem;
  ${bp("sm")`    
  font-size:1.5em;
  line-height:1.5em;
    padding-top:0.2rem;
  `}
  span {
    font-weight: 700;
  }
`;

const ConfirmationLink = styled("div")`
  color: black;
  padding-top: 1.2rem;
  font-size: 0.9rem;
  ${bp("sm")`    
  font-size:1.2rem;
  line-height:1.3em;
    padding-top:1.3em;
  `}
  span {
    font-weight: 700;
  }
`;

const ConfirmationHeadingEndpolio = styled("div")``;

interface HeaderBlurbTextProps {
  headingType: string;
}

const HeaderBlurbText = ({ headingType }: HeaderBlurbTextProps) => {
  const { mode, config } = useSelector((state: RootState) => state.app);
  const [modeType, setModeType] = useState("");
  const [lastPart, setLastPart] = useState("");

  useEffect(() => {
    const currentLastPart = global.location.href.split("/").pop();
    currentLastPart && setLastPart(currentLastPart);

    let currentModeType = "";
    //   const mode = String(mode).toLowerCase();

    if (mode === "giv") {
      if (currentLastPart === "giving-tuesday") {
        currentModeType = "Giving Tuesday";
      } else if (currentLastPart === "give-gift-rotary") {
        currentModeType = "Give the gift of Rotary";
      } else {
        currentModeType = "Donate";
      }
    } else if (mode === "drf") {
      currentModeType = "Disaster Response Fund";
    } else if (mode === "rotary-direct") {
      currentModeType = "Rotary Direct";
    } else if (mode === "ep") {
      currentModeType = "Donate to End Polio";
    }

    setModeType(currentModeType);
  }, [mode]);
  const lang = "en";
  return (
    <HeadingDiv headingType={headingType}>
      <HeadingContainer>
        {headingType === "Donate" && <Heading>{modeType}</Heading>}
        {headingType === "Donate" && lastPart === "giving-tuesday" && (
          <BlurbText>
            <InfoText
              dangerouslySetInnerHTML={{
                __html: `Giving Tuesday happens once a year, but your gift of Rotary will make a difference for a lifetime. This Giving Tuesday, consider making a special gift to <a href="https://www.rotary.org/${lang}/about-rotary/rotary-foundation">The Rotary Foundation</a> and together we can continue Doing Good in the World.`,
              }}
            />
          </BlurbText>
        )}
        {headingType === "Donate" && lastPart === "give-gift-rotary" && (
          <BlurbText>
            <InfoText
              dangerouslySetInnerHTML={{
                __html: `You can make a difference in this world by helping others in need — from supplying a vaccine to protect a child from polio to training peacebuilders in conflict resolution to help build a more peaceful future. With your help, <a href="https://www.rotary.org/${lang}/about-rotary/rotary-foundation">The Rotary Foundation</a> can make lives better in your community and around the world.`,
              }}
            />
          </BlurbText>
        )}
        {headingType === "Donate" && String(mode).toLowerCase() === "drf" && (
          <BlurbText>
            <InfoTextItalic
              dangerouslySetInnerHTML={{
                __html: `Your gift helps Rotary clubs provide disaster recovery and support rebuilding efforts. Contributions are made available through <a href="${config?.appConfigs.webAppBaseUrl}/${lang}/knowledge-and-resources/resources-and-reference/disaster-response-grants">Rotary Disaster Response Grants</a>. Contributions to the General Disaster Response Fund cannot be directed to a specific disaster.`,
              }}
            />
          </BlurbText>
        )}
        {headingType === "Donate" &&
          String(mode).toLowerCase() === "rotary-direct" && (
            <BlurbText>
              <InfoText
                dangerouslySetInnerHTML={{
                  __html:
                    "Enroll in Rotary’s recurring giving program, Rotary Direct, which makes giving easy, fast, and secure. You can schedule a monthly, quarterly, or annual donation that empowers Rotarians to change lives in communities around the world.",
                }}
              />
            </BlurbText>
          )}
        {headingType === "Donate" && mode === "ep" && (
          <BlurbText>
            <InfoText>
              {
                "Your donation helps Rotary and its partners reach every child with the polio vaccine. Thanks to the Bill & Melinda Gates Foundation, your contribution will be tripled. Together, we end polio."
              }
            </InfoText>
          </BlurbText>
        )}
      </HeadingContainer>
      {headingType === "Confirmation" && mode !== "ep" && (
        <ConfirmationHeading>Thank You</ConfirmationHeading>
      )}
      {headingType === "Confirmation" && mode === "ep" && (
        <ConfirmationHeadingEndpolio>
          <ConfirmationHeading>Thank You</ConfirmationHeading>
          <ConfirmationSubTitle>
            Your donation will help protect children around the world from polio
            until we eradicate it forever.
          </ConfirmationSubTitle>
          <ConfirmationLink
            dangerouslySetInnerHTML={{
              __html: `Return to <a href="${
                config?.appConfigs.webAppBaseUrl === "https://myuat.rotary.org"
                  ? "https://wwwuat.endpolio.org/"
                  : "https://www.endpolio.org/"
              }">endpolio.org</a>.`,
            }}
          />
        </ConfirmationHeadingEndpolio>
      )}
    </HeadingDiv>
  );
};

export default HeaderBlurbText;
