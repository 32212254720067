import { createTheme } from "@mui/material/styles";
import { css, styled } from "@mui/system";
import { ifProp } from "styled-tools";
import { Button } from "@mui/material";
import { resetButton, resetInput } from "shared/normalize";
import close from "shared/assets/close.svg";
import errorIcon from "shared/assets/error.svg";
import closeBlue from "shared/assets/closeBlue.svg";
import infoIcon from "shared/assets/information.svg";
import questionIcon from "shared/assets/question.svg";
import infoIconHover from "shared/assets/informationHover.svg";
import questionIconHover from "shared/assets/questionHover.svg";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xs_landscape_up: true;
    s_up_rn: true;
    xxs_up: true;
    xs_up: true;
    s_up: true;
    m_up: true;
    l_up: true;
    xl_up: true;
    xxl_up: true;
    rn_error_icon: true;
  }
}

const htmlNode = document.getElementsByTagName("html")[0];
const baseFontSize = parseInt(
  getComputedStyle(htmlNode).getPropertyValue("font-size"),
  10,
);

export const color = {
  white: "#ffffff",
  offWhite: "#f8f9fa",
  blue: "#0050a2",
  blueSky: "#019fcb",
  blueLight: "#eaf4f8",
  blueSkyLight: "#ebf4f8",
  blueSkyMid: "#e6f0f5",
  blueOverlay: "#0c3c7c",
  darkBlueOverlay: "#0a3163",
  navy: "#263b4c",
  red: "#c10042",
  rotaryGold: "#ffc000",
  errorField: "#f9e5ec",
  black: "#000000",
  darkGrey: "#aaaaaa",
  greyCobble: "#c1c4c7",
  greyTab: "#929496",
  placeholder: "#5e7170",
  lightGrey: "#f3f4f6",
  midGrey: "#e6e7e8",
  lightBlueGrey: "#d1dae1",
  darkBlue: "#004894",
};

const fontWeight = {
  "Open Sans": {
    light: 300,
    regular: 400,
    bold: 700,
  },
  KO: {
    light: 300,
    regular: 400,
    bold: 700,
  },
  JA: {
    light: 300,
    regular: 400,
    bold: 700,
  },
};

interface FontStack {
  "Open Sans": string;
  JA: string;
  KO: string;
}

export const fontStack: FontStack = {
  "Open Sans": "'Open Sans', Arial, sans-serif",
  JA: "Meiryo, メイリオ, 'Hiragino Kaku Gothic Pro', 'MS PGothic', 'Arial Unicode MS', sans-serif",
  KO: "'Nanum Gothic', 'Malgun Gothic', Gulim, Dotum, 'Arial Unicode MS', sans-serif",
};

type BreakpointsValues = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xs_landscape_up: number;
  s_up_rn: number;
  xxs_up: number;
  xs_up: number;
  s_up: number;
  m_up: number;
  l_up: number;
  xl_up: number;
  xxl_up: number;
  rn_error_icon: number;
};

export const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 480,
      xs_landscape_up: 667,
      sm: 750,
      s_up_rn: 768,
      md: 960,
      lg: 1160,
      xl: 1200,
      xxs_up: 250,
      xs_up: 480,
      s_up: 750,
      m_up: 960,
      l_up: 1160,
      xl_up: 1200,
      xxl_up: 1440,
      rn_error_icon: 698,
    },
  },
  palette: {
    primary: {
      main: color.navy,
    },
    secondary: {
      main: color.blue,
    },
    background: {
      default: color.offWhite,
    },
    error: {
      main: color.red,
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: color.red,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
              borderColor: color.errorField,
            },
          },
        },
      },
    },
  },
});

// Functions
export const pxToRem = (pxValue: number) => `${pxValue / baseFontSize}rem`;

export const bp =
  (name: keyof BreakpointsValues) => (styles: TemplateStringsArray) =>
    `
  @media (min-width: ${defaultTheme.breakpoints.values[name]}px) {
    ${styles}
  }
`;

export const fontSize = (
  sizeInPx: number,
  lineHeight: number | boolean = false,
) => {
  const baseStyles = css`
    font-size: ${sizeInPx}px;
    font-size: ${pxToRem(sizeInPx)};
  `;

  if (lineHeight === true) {
    return css`
      ${baseStyles};
      line-height: ${sizeInPx * 1.66666667}px;
      line-height: ${pxToRem(sizeInPx * 1.66666667)};
    `;
  }

  if (typeof lineHeight === "number") {
    return css`
      ${baseStyles};
      line-height: ${lineHeight}px;
      line-height: ${pxToRem(lineHeight)};
    `;
  }

  return baseStyles;
};

export const universalEmphasis = () => css`
  &:not(:lang(ko)):not(:lang(ja)) {
    font-style: italic;
  }
`;

/**
 * For the specified media query, returns a tag function that can be used to
 * automatically wrap the tagged template literal in its media query.
 *
 * @param {string} query The string or template literal containing the media
 *   query features.
 */
const mediaQuery =
  (query: string) =>
  (...rules: string[]) => css`
    @media ${query} {
      ${css(...rules)}
    }
  `;

// Custom media queries
export const media = {
  xs_landscape_down: mediaQuery("(max-width: 666px)")(),
  xs_landscape_s: mediaQuery("(min-width: 667px) and (max-width: 749px)")(),
  s_down: mediaQuery("(max-width: 749px)")(),
  m_down: mediaQuery("(max-width: 959px)")(),
};

// Custom grid system
export const flexGridUnit = (multiplier = 1) => `${7 * multiplier}vw`;
export const fixGridUnit = (multiplier = 1) => `${100 * multiplier}px`;

// Mixins
interface FontWeight {
  light: number;
  regular: number;
  bold: number;
}

export const errorIconStandalone = css`
  background: url(${errorIcon}) top right no-repeat;
  height: 18px;
  width: 18px;
`;

export const fontFamily = (
  family: keyof FontStack,
  weight: keyof FontWeight = "regular",
) => css`
  font-family: ${fontStack[family]};
  font-weight: ${fontWeight[family][weight] || "normal"};
`;

interface CustomButtonProps {
  shouldReset?: boolean;
  textTransform?: string;
}

export const stepTitle = () => css`
  background-color: ${color.navy};
  color: ${color.white};
  font-weight: 700;
  ${fontSize(18, 24)}
  margin: 0;
  padding: 6px 16px 9px;
  ${bp("sm")`
    font-size: 28px;
    line-height: 30px;
  `}
`;

export const CustomButton = styled(Button)`
  ${(props: CustomButtonProps) => (props.shouldReset ? resetButton() : "")}
  ${fontFamily("Open Sans", "bold")}
  background-color: ${color.white} !important;
  color: ${color.red} !important;
  border: 1px solid ${color.red} !important;
  border-radius: 0;
  box-sizing: border-box;
  display: inline-block;
  padding: 0.875em 2.5em 0.875em;
  text-align: center;
  text-decoration: none;
  text-transform: ${(props: CustomButtonProps) => props.textTransform};
  transition: all 0.3s ease;
  width: 100%;

  ${bp("xs")`
  min-width: 16.9rem;
  width: auto;
`}

  &:active,
&:hover,
&:focus {
    background-color: ${color.red} !important;
    color: ${color.white} !important;
    text-decoration: inherit;
  }

  ${ifProp(
    "primary",
    css`
      background-color: ${color.red} !important;
      color: ${color.white} !important;

      &:active,
      &:hover,
      &:focus {
        background-color: ${color.white} !important;
        border: 1px solid ${color.red} !important;
        color: ${color.red} !important;
      }

      &:disabled {
        background-color: ${color.greyCobble};
        border-color: ${color.greyCobble};
        color: ${color.white} !important;
        cursor: default;
      }
    `,
  )}
`;

export const visuallyHidden = () => css`
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  position: absolute;
`;

export const inputStyling = (error: boolean) => css`
  ${resetInput()}
  background-color: ${color.white};
  border: 1px solid ${color.navy};
  border-radius: 0;
  color: ${color.navy};
  ${fontSize(16)}
  font-weight: 400;
  display: inline;
  height: ${pxToRem(40)};
  max-width: 100%;
  padding: ${pxToRem(8)} ${pxToRem(14)};
  transition: border 0.3s ease;
  width: 100%;
  &:focus {
    background-color: ${color.white};
    border-color: ${color.blueSky};
  }
  &::-webkit-input-placeholder {
    color: ${color.placeholder};
    ${fontSize(16)}
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    opacity: 1;
  }
  &:disabled {
    background: transparent;
    border: 0 none;
    color: ${color.black};
    height: auto;
    padding: 0;
  }
  &,
  &:focus {
    ${error &&
    css`
      border-color: ${color.red};
      background-color: ${color.errorField};
    `}
  }
`;

// Info/Question Icon & Popup
export const popup = () => css`
  ${fontSize(14)}
  background-color: ${color.white};
  border: 1px solid ${color.blueSky};
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  font-style: normal;
  line-height: 1.5;
  margin-top: 10px;
  padding: 1em 1.4em;
  width: 290px;
  max-width: 70vw;
  &::after {
    background-color: inherit;
    border: inherit;
    border-left: 0;
    border-top: 0;
    bottom: -7px;
    content: "";
    left: 26px;
    padding: 6px;
    position: absolute;
    transform: rotate(45deg);
  }
`;

export const popupCloseButton = (iconColor = "black") => css`
  ${resetButton()}
  ${iconColor === "black" &&
  css`
    background: url(${close}) top right no-repeat;
    &:active,
    &:hover {
      background: url(${close}) top right no-repeat;
    }
  `}
  ${iconColor === "blue" &&
  css`
    background: url(${closeBlue}) top right no-repeat;
    &:active,
    &:hover {
      background: url(${closeBlue}) top right no-repeat;
    }
  `}
  cursor: pointer;
  height: 0.8em;
  position: absolute;
  right: 8px;
  top: 8px;
  width: 0.8em;
`;

export const iconButton = (type = "info") => css`
  ${resetButton()}
  background: url(${infoIcon}) no-repeat;
  cursor: pointer;
  height: 18px;
  width: 18px;
  ${type === "question" &&
  css`
    background: url(${questionIcon}) no-repeat;
  `}
`;

export const iconButtonActive = (type = "info") => css`
  background: url(${infoIconHover}) no-repeat;
  ${type === "question" &&
  css`
    background: url(${questionIconHover}) no-repeat;
  `}
`;

export const fieldWrapper = (marginBottom = 30) => css`
  margin-bottom: ${marginBottom}px;
  padding: 0;
`;

export const fieldLabel = () => css`
  font-weight: 700;
  ${fontSize(14)}
  color: ${color.navy};
  display: block;
  line-height: 1.4;
  margin-bottom: 12px;
  ${bp("xs")`
  font-size:1rem;
  `}
`;

export const CloseGraphic = styled("div")`
  height: 19px;
  margin: 0 0 0 8px;
  width: 19px;
  path {
    fill: ${color.red} !important;
    transition: all 0.3s ease;
  }

  ${bp("sm")`
    height: 20px;
    margin: 2px 0 0 0px;
    width: 20px;

    path {
      fill: #263b4c;
    }
  `}
`;

export const ButtonWrapper = styled("button")`
  background: none;
  border: none;
`;

interface DonationSubheadingProps {
  confirmation?: boolean;
  columns?: boolean;
}

export const donationSubheading = (props?: DonationSubheadingProps) => css`
  font-size: 18px;
  border-bottom: 1px solid ${color.navy};
  color: ${color.navy};
  line-height
  margin: 0;
  padding: ${pxToRem(28)} 0 ${pxToRem(6)};

  ${bp("sm")`
    font-size: 24px;
    padding: 2.625rem 0 0.625;
  `}

  ${props?.confirmation && css``};

  ${
    props?.columns &&
    css`
      margin-bottom: ${pxToRem(5)};
      width: 100% !important;
    `
  };
`;

// To do: post base work, create styled components for rest of the donate widget instances

export const iFrameDedicationPreviewWrapper = () => css`
  ${global.window.location.pathname.indexOf("disaster-response-fund") !== -1
    ? "12vw"
    : 0}
`;

export const iFrameDedicationPreviewFooter = () => css`
  ${bp("xs")`
    position: static;
    margin-top: 20px;
  `};
`;
export const iFrameDedicationPreviewHeader = () => css`
  ${bp("xs")`
    position: relative;
  `};
`;
export const stepHelpText = () => css`
  ${fontSize(14)}
  background-color: transparent;
  color: ${color.black};
  line-height: 1.6;
  margin-bottom: 3.125rem;
  padding: 0 1.5em !important;
  text-align: center;
`;

export const button = (
  shouldReset = false,
  textTransform = "uppercase",
  namedFunds = false,
) => css`
  ${shouldReset ? resetButton() : ""}
  ${fontFamily("Open Sans", "bold")}
  ${fontSize(16)}
  background: ${color.white}!important;
  color: ${color.red}!important;
  border: 1px solid ${color.red}!important;
  border-radius: 0;
  box-sizing: border-box;
  display: inline-block;
  padding: 0.875em 2.5em 0.875em;
  text-align: center;
  text-decoration: none;
  text-transform: ${textTransform};
  transition: all 0.3s ease;
  width: 100%;
  &:active,
  &:hover,
  &:focus {
    background: ${color.red}!important;
    color: ${color.white}!important;
    text-decoration: inherit;
  }
  }
`;

export const donationStep = () => css`
  background-color: ${"theme.pageBackgroundColor"};
  border-bottom: 1px solid ${"theme.baseColor"} !important;
  margin-bottom: ${pxToRem(50)};

  ${bp("xs")`
    border: 1px solid !important;
  `}
`;

export const customSelectStyles = () => `css
    backgroundColor: red
    border: 1px solid blue
    borderRadius: 5px

`;

interface ValidationMessageProps {
  type: string;
}
export const validationMessage = (props: ValidationMessageProps) => css`
  ${fontSize(14, 21)}
  border: 1px solid currentColor;
  margin-bottom: 1rem;
  padding: 1em 1.5em;
  ${props.type === "error" &&
  css`
    background-color: ${color.errorField};
    color: ${color.red};
  `}
`;

export const userExperienceMessage = () => css`
  ${fontSize(14, 21)}
  border: 1px solid currentColor;
  border-color: #915d00;
  margin-bottom: 1rem;
  padding: 1em 1.5em;
  background-color: #fff7e8;
  color: #915d00;
`;

export const PageWrapper = styled("div")(({ theme }) => ({
  // Box sizing, color, and font size
  boxSizing: "border-box",
  color: color.navy,
  fontSize: `${baseFontSize}px`,
  margin: "0 auto",
  maxWidth: "1370px",

  // Media queries for padding
  [theme.breakpoints.up("md")]: {
    padding: `0 ${flexGridUnit()} ${flexGridUnit()}`,
  },

  [theme.breakpoints.up("xxl_up")]: {
    padding: `0 ${fixGridUnit()} ${fixGridUnit()}`,
  },
}));

export const MainWrapper = styled("div")(({ theme }) => ({
  background: color.white,

  [theme.breakpoints.up("xs")]: {
    padding: "5%",
  },

  [theme.breakpoints.up("md")]: {
    padding: `${pxToRem(60)} ${flexGridUnit()} ${pxToRem(96)}`,
  },

  [theme.breakpoints.up("xxl_up")]: {
    paddingLeft: fixGridUnit(),
    paddingRight: fixGridUnit(),
  },
}));

export const ctaLink = (shouldReset?: boolean) => css`
  ${shouldReset ? resetButton() : ""}
  ${fontSize(14, 19)}
  color: ${color.blue};
  display: inline-block;
  text-decoration: underline;
  &:hover,
  &:focus {
    text-decoration: none !important;
    color: ${color.black};
  }
  &:disabled,
  &:disabled:hover {
    background-color: transparent;
    color: transparent;
    cursor: default;
    text-decoration: none !important;
  }
`;
export const rightboxSubheading = () => css`
  color: #39424a !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 26px !important;

  ${bp("s_up")`
    font-size: 18px !important;
    line-height: 39px !important;
  `}
`;

export const donationTitle = () => css`
  background-color: #ffffff !important;
  color: #39424a !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  line-height: 28px !important;

  margin: 0 !important;
  ${bp("s_up")`
    padding-left: 30px!important;
    padding-top: 15px!important;
    padding-bottom: 15px!important;
    font-size: 36px!important;
    line-height: 59px!important;
    
  `}
`;

export const confirmationTitle = () => css`
  color: #39424a !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  padding: 20px 0 !important;

  ${bp("s_up")`
    padding: 35px 0 !important;
    font-size: 36px !important;
    line-height: 59px !important;
  `}
`;

interface BtnConfirmationProps {
  shouldReset?: boolean;
  textTransform?: string;
  primary?: boolean;
}

export const btnconfirmation = styled("button")<BtnConfirmationProps>`
  ${(props) => (props.shouldReset ? resetButton() : "")}
  ${fontFamily("Open Sans", "bold")}
  background-color: ${color.white} !important;
  color: ${color.red} !important;
  border: 1px solid ${color.red} !important;
  border-radius: 0;
  box-sizing: border-box;
  display: inline-block;
  padding: 0.875em 1em;
  text-align: center;
  text-decoration: none;
  text-transform: ${(props) => props.textTransform || "uppercase"};
  transition: all 0.3s ease;
  width: 100%;

  ${bp("xs_up")`
    min-width: 15rem;
    width: auto;
  `}

  &:active,
  &:hover,
  &:focus {
    background-color: ${color.red} !important;
    color: ${color.white} !important;
    text-decoration: inherit;
  }

  ${(props) =>
    props.primary &&
    css`
      background-color: ${color.red} !important;
      color: ${color.white} !important;

      &:active,
      &:hover,
      &:focus {
        background-color: ${color.white} !important;
        border: 1px solid ${color.red} !important;
        color: ${color.red} !important;
      }

      &:disabled {
        background-color: ${color.greyCobble};
        border-color: ${color.greyCobble};
        color: ${color.white} !important;
        cursor: default;
      }
    `}
`;

export const otherWaysHeading = () => css`
  color: #39424a !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  border-bottom: 1px solid ${color.greyCobble};
  margin-bottom: 1.2em;

  ${bp("s_up")`
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 52px !important;
    margin-bottom:0;
  `}
`;

export const otherWaysSubheading = () => css`
  color: #39424a !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 26px !important;
  margin-top: 20px !important;
  ${bp("s_up")`
    font-size: 18px !important;
    line-height: 29px !important;
  `}
`;

export const donationConfirmationSubheading = (props: {
  confirmation?: boolean;
  columns?: boolean;
}) => css`
  ${fontSize(18, 24)}
  border-bottom: 1px solid ${color.navy};
  color: ${color.navy};
  margin: 0;

  ${bp("s_up")`
    font-size: 24px;
    line-height: 32px;
    padding: 1.25rem 0 0.313rem;
  `}

  ${bp("s_up_rn")`
    margin: 0 0 1rem;
  `}

  ${props.confirmation &&
  css`
    ${bp("s_up_rn")`
      border-bottom: 0 none;
      margin: 0;
      padding: 0 1.5rem 0 0;
      width: calc(100% / 3);
    `}
  `}

  ${props.columns &&
  css`
    margin-bottom: 0.313rem;
    width: 100% !important;

    ${bp("s_up_rn")`
      padding: 0 !important;
      width: 100% !important;
    `}
  `}
`;
