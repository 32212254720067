import { visuallyHidden } from "shared/styles";
import { styled } from "@mui/system";
import throbber from "shared/assets/throbber.gif";

const StyledLoadingThrobber = styled("div")`
  text-align: center;
`;

const LoadingVisual = styled("div")`
  background: url(${throbber}) no-repeat;
  height: 32px;
  margin: 0 auto 1rem;
  width: 32px;
`;

const LoadingSearchVisual = styled("div")`
  background: url(${throbber}) no-repeat;
  height: 32px;
  margin-left: 5px;
  width: 32px;
`;

const LoadingText = styled("p")`
  ${visuallyHidden()}
`;

interface LoadingThrobberProps {
  forPurpose?: string;
}

const LoadingThrobber = ({ forPurpose }: LoadingThrobberProps) => (
  <StyledLoadingThrobber id="loading-throbber">
    <LoadingText>Loading...</LoadingText>
    {forPurpose === "Search" ? <LoadingSearchVisual /> : <LoadingVisual />}
  </StyledLoadingThrobber>
);

export default LoadingThrobber;
