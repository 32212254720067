import React from "react";
import "./styles.css";

interface HeaderProps {
  currencyCode: string;
}

const Header: React.FC<HeaderProps> = ({ currencyCode }) => (
  <div className="inv-header">
    <div className="inv-col"></div>
    <div className="inv-col">
      <label className="col-heading">{"Date"}</label>
    </div>
    <div className="inv-col">
      <label className="col-heading">{"Item number"}</label>
    </div>
    <div className="inv-col">
      <label className="col-heading">{"Description"}</label>
    </div>
    <div className="inv-col">
      <label className="col-heading">
        {"Amount"}
        <span className="currency-code">({currencyCode})</span>
      </label>
    </div>
  </div>
);

export default Header;
