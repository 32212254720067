// id must always be a number

export default [
  {
    id: "1013374",
    type: "honor",
    url: "https://ripuscintphl01stg.blob.core.windows.net/riphilanthropyprd/20110222_HT_095.jpg",
    alt: "In honor of - student",
  },
  {
    id: "1013370",
    type: "memory",
    url: "https://ripuscintphl01stg.blob.core.windows.net/riphilanthropyprd/20080323_KR_080.jpg",
    alt: "In memory of - cherry blossoms",
  },
  {
    id: "1013376",
    type: "honor",
    url: "https://ripuscintphl01stg.blob.core.windows.net/riphilanthropyprd/20130427_CI_159_0.jpg",
    alt: "In honor of - purple pinkies",
  },
  {
    id: "1059013",
    type: "memory",
    url: "https://ripuscintphl01stg.blob.core.windows.net/riphilanthropyprd/20090318_DO_139.jpg",
    alt: "In memory of - flowers",
  },
  {
    id: "1013377",
    type: "honor",
    url: "https://ripuscintphl01stg.blob.core.windows.net/riphilanthropyprd/20080706_MN_045.jpg",
    alt: "In honor of - girl and boy",
  },
  {
    id: "1013369",
    type: "memory",
    url: "https://ripuscintphl01stg.blob.core.windows.net/riphilanthropyprd/20050126_TH_073.jpg",
    alt: "In memory of - red poppy",
  },
  {
    id: "1059014",
    type: "honor",
    url: "https://ripuscintphl01stg.blob.core.windows.net/riphilanthropyprd/20130515_GT_126.jpg",
    alt: "In honor of - smiling girl",
  },
  {
    id: "1013373",
    type: "memory",
    url: "https://ripuscintphl01stg.blob.core.windows.net/riphilanthropyprd/20101204_US_046.jpg",
    alt: "In memory of - sunset",
  },
];
