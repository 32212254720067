import React from "react";
import { styled } from "@mui/system";

const AddressParagraph = styled("p")`
  line-height: 1.5;
  margin: 0;
`;

interface AddressProps {
  widgetUUID: string;
  street: string | null;
  street2?: string | null;
  street3?: string | null;
  state: string | null;
  zip: string | null;
  city: string | null;
  internationalProvince?: string | null;
  country: string | null;
}

const Address = ({
  widgetUUID,
  street,
  street2,
  street3,
  state,
  zip,
  city,
  internationalProvince,
  country,
}: AddressProps) => {
  switch (widgetUUID) {
    case "rotary-canada":
    case "rotary-canada-usd":
      return (
        <div>
          <AddressParagraph>{street}</AddressParagraph>
          <AddressParagraph>{street2}</AddressParagraph>
          <AddressParagraph>{street3}</AddressParagraph>
          <AddressParagraph>{zip}</AddressParagraph>
          <AddressParagraph>{state}</AddressParagraph>
          <AddressParagraph>{city}</AddressParagraph>
          <AddressParagraph>{country}</AddressParagraph>
        </div>
      );
    case "rotary-uk":
    case "rotary-nz":
    case "rotary-brl-zar":
    case "rotary-island":
    case "rotary-standard-world":
      return (
        <div>
          <AddressParagraph>{street}</AddressParagraph>
          <AddressParagraph>{street2}</AddressParagraph>
          <AddressParagraph>{street3}</AddressParagraph>
          <AddressParagraph>
            {city} {internationalProvince}
          </AddressParagraph>
          <AddressParagraph>{zip}</AddressParagraph>
          <AddressParagraph>{country}</AddressParagraph>
        </div>
      );
    case "rotary-usa":
      return (
        <div>
          <AddressParagraph>{street}</AddressParagraph>
          <AddressParagraph>{street2}</AddressParagraph>
          <AddressParagraph>{street3}</AddressParagraph>
          <AddressParagraph>
            {city} {state} {zip}
          </AddressParagraph>
          <AddressParagraph>{country}</AddressParagraph>
        </div>
      );
    case "rotary-reverse-standard":
      return (
        <div>
          <AddressParagraph>{country}</AddressParagraph>
          <AddressParagraph>{zip}</AddressParagraph>
          <AddressParagraph>{internationalProvince}</AddressParagraph>
          <AddressParagraph>{city}</AddressParagraph>
          <AddressParagraph>{street}</AddressParagraph>
          <AddressParagraph>{street2}</AddressParagraph>
          <AddressParagraph>{street3}</AddressParagraph>
        </div>
      );
    case "rotary-japan":
      return (
        <div>
          <AddressParagraph>{country}</AddressParagraph>
          <AddressParagraph>{zip}</AddressParagraph>
          <AddressParagraph>{internationalProvince}</AddressParagraph>
          <AddressParagraph>{city}</AddressParagraph>
          <AddressParagraph>{street}</AddressParagraph>
          <AddressParagraph>{street2}</AddressParagraph>
          <AddressParagraph>{street3}</AddressParagraph>
        </div>
      );
    default:
      return (
        <div>
          <AddressParagraph>{street}</AddressParagraph>
          <AddressParagraph>{street2}</AddressParagraph>
          <AddressParagraph>{street3}</AddressParagraph>
          <AddressParagraph>
            {zip} {city}
          </AddressParagraph>
          <AddressParagraph>{internationalProvince}</AddressParagraph>
          <AddressParagraph>{country}</AddressParagraph>
        </div>
      );
  }
};

export default Address;
