import { createSlice } from "@reduxjs/toolkit";
import { DPMTransactionFlowKeys, getTransactionFlow } from "shared/utils";

export interface TransactionState {
  transactionData: any;
  transactionFlowData: any;
  customerType: any;
}

const initialStateTransaction = {
  transactionData: null,
  transactionFlowData: null,
  customerType: null,
};

const transactionSlice = createSlice({
  name: "transaction",
  initialState: initialStateTransaction,
  reducers: {
    setTransactionData: (state, action) => {
      const { transactionData } = action.payload;
      state.transactionData = transactionData;
    },
    setTransactionFlow: (state, action) => {
      const { transactionFlow } = action.payload;
      state.transactionFlowData = transactionFlow;
    },
    setCustomerType: (state, action) => {
      const { customerType } = action.payload;
      state.customerType = customerType;
    },
  },
});

export const createSetTransactionData = (transactionData = {}) => ({
  type: "transaction/setTransactionData",
  payload: { transactionData },
});

export const createSetTransactionFlow = (transactionFlowData: {
  mode: string;
  btnMode: DPMTransactionFlowKeys;
  donationType: string;
  module?: string;
}) => {
  const { mode, btnMode, donationType, module = "" } = transactionFlowData;
  const transactionFlow = getTransactionFlow(
    mode,
    btnMode,
    donationType,
    module,
  );
  return {
    type: "transaction/setTransactionFlow",
    payload: { transactionFlow },
  };
};

export const createSetCustomerType = (customerType: any) => {
  return {
    type: "transaction/setCustomerType",
    payload: { customerType },
  };
};

export default transactionSlice.reducer;
