// @flow
import React from "react";
import { styled } from "@mui/system";
import { color } from "shared/styles";
import selected from "shared/assets/selected.svg";
import unselected from "shared/assets/unselected.svg";

interface IsSelected {
  isSelected: boolean | null;
}
const ImageSelectorWrapper = styled("div")(({ isSelected }: IsSelected) => ({
  alignSelf: "center",
  borderColor: isSelected ? color.blue : color.lightBlueGrey,
  borderStyle: "solid",
  borderWidth: "1px",
  cursor: "pointer",
  margin: "0",
  overflow: "hidden",
  position: "relative",
  width: "100%",

  "& svg": {
    height: "20px",
    path: {
      fill: isSelected ? color.white : color.navy,
      transition: "fill 0.3s ease",
    },
  },
}));

const ImageSelectorButton = styled("div")(({ isSelected }: IsSelected) => ({
  backgroundColor: isSelected ? color.blue : color.white,
  backgroundSize: "auto 20px",
  backgroundImage: isSelected ? `url(${selected})` : `url(${unselected})`,
  backgroundPosition: isSelected ? "8px center" : "center",
  backgroundRepeat: "no-repeat",
  bottom: 0,
  height: "35px",
  position: "absolute",
  right: 0,
  width: "35px",
  zIndex: 1,
}));

const ImageSelectorImage = styled("img")({
  display: "block",
  maxWidth: "100%",
});

type ImageSelectorProps = {
  isSelected: boolean | null;
  onClickImageSelector: (event: React.MouseEvent<HTMLInputElement>) => void;
  imageUrl: string;
  imageAlt: string;
};
const ImageSelector = ({
  isSelected,
  onClickImageSelector,
  imageUrl,
  imageAlt,
}: ImageSelectorProps) => {
  return (
    <ImageSelectorWrapper
      isSelected={isSelected}
      onClick={onClickImageSelector}
    >
      <ImageSelectorButton isSelected={isSelected} />
      <ImageSelectorImage src={imageUrl} alt={imageAlt} />
    </ImageSelectorWrapper>
  );
};

export default ImageSelector;
