import React, { ChangeEvent } from "react";
import PayInvoiceCheckbox from "../PayInvoiceCheckbox";
import "./styles.css";

interface GridRowData {
  checked: boolean;
  amount: string;
  date: string;
  itemNumber: string;
  description: string;
}

interface PayInvoiceGridRowProps {
  gridRowData: GridRowData;
  onChange: (params: { checked: boolean }) => void;
  mode: string;
}

const PayInvoiceGridRow: React.FC<PayInvoiceGridRowProps> = ({
  gridRowData,
  onChange,
  mode,
}) => {
  let amount = gridRowData.amount.replace(/,/g, ".");
  return (
    <div className="inv-row">
      <div className="inv-col">
        <PayInvoiceCheckbox
          checked={gridRowData.checked}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            onChange({ checked: e.target.checked });
          }}
          mode={mode}
        />
      </div>
      <div className="inv-col">
        <label>{gridRowData.date}</label>
      </div>
      <div className="inv-col">
        <label>{gridRowData.itemNumber}</label>
      </div>
      <div className="inv-col">
        <label>{gridRowData.description}</label>
      </div>
      <div className="inv-col">
        <label>
          {parseFloat(amount) < 0
            ? "(" + gridRowData.amount.toString().replace(/-/g, "") + ")"
            : gridRowData.amount}
        </label>
      </div>
    </div>
  );
};

export default PayInvoiceGridRow;
