/* we should be able to delete this */
export const setNestedValue = function (
  obj: Record<string, any>,
  address: string,
  value: any,
): void {
  const addressNames = address.split(".");
  let currentObj: Record<string, any> = obj;

  while (addressNames.length > 1) {
    const prop = addressNames.shift() as string;
    if (typeof currentObj[prop] !== "object" || currentObj[prop] === null) {
      currentObj[prop] = {};
    }
    currentObj = currentObj[prop];
  }

  currentObj[addressNames.shift() as string] = value;
};

export const setDdoValue = function (address: string, value: any): void {
  if (typeof (globalThis as any).rotaryDDO === "undefined") {
    return;
  }

  setNestedValue((globalThis as any).rotaryDDO, address, value);
};

export const setDdoMemberId = function (value: any): void {
  setDdoValue("userData.userProfileID", value);
};
