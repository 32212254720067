import AMOUNT_CONFIG from "shared/data/amounts";
import { dedicationImages } from "shared/state/dedication";
import {
  Amounts,
  CategoriesByLanguage,
  Config,
  Currencies,
  DedicationImages,
  Fund,
  Funds,
  Translations,
} from "shared/types";

const getDedicationImage = (
  allDedicationImages: dedicationImages[],
  dedicationImageId: string,
  dedicationType: string,
) => {
  if (!dedicationImageId) {
    return allDedicationImages.find(
      (di: dedicationImages) => di.type == dedicationType,
    );
  } else {
    const match = allDedicationImages.filter(
      (di) => di.id == dedicationImageId,
    );
    if (match.length == 1) {
      return match[0];
    } else {
      return null;
    }
  }
};

const getFund = (fundId: string, fundSubId: string, funds: Funds) => {
  const match = funds.filter(
    (f) => f.fundId == fundId && f.fundSubId == fundSubId,
  );
  if (match.length == 1) {
    return match[0];
  } else {
    return null;
  }
};

interface FundType extends Fund {
  isGrant: boolean;
}

const getFundTab = (fund: FundType, categories: CategoriesByLanguage) => {
  if (fund.isGrant === true) {
    return categories["en"].find((c) => c.content[0].component === "Grants");
  } else {
    if (categories == null) {
      return null;
    } else {
      const match = categories["en"].filter(
        (c) =>
          c.content[0].config &&
          c.content[0].config.funds.indexOf(fund.id) != -1,
      );
      if (match.length == 1) {
        return match[0];
      } else {
        return null;
      }
    }
  }
};

const getAmountDetails = (
  currencyCode: string,
  amount: string,
  currencies: Currencies,
  amounts: Amounts,
) => {
  //lets first find currency object
  let currency = currencies.find((c) => c.code == currencyCode);
  if (currency) {
    //now lets find corresponding amount Config
    let amountConfigKey = Object.keys(amounts).find(
      (amountConfigKey) => amountConfigKey.toLowerCase() == currency?.id,
    );
    if (amountConfigKey) {
      let amountConfig = amounts[amountConfigKey];
      if (amountConfig.oneTime.options.indexOf(Number(amount)) == -1) {
        return { isCustomAmount: true, amount };
      } else {
        return { isCustomAmount: false, amount: +amount };
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const getDedicationDetails = (obj: any) => obj;

const getBehalfDetails = (payment: any) => {
  const ret: any = {};
  if (payment.stored_list_id != "" && payment.stored_list_id != null) {
    ret.behalfVisibility = true;
    ret.behalfType = "member";
    const clubMemberAmountsData = localStorage.getItem("clubMembersWithAmount");
    ret.clubMemberAmounts =
      clubMemberAmountsData && JSON.parse(clubMemberAmountsData);
  } else if (
    payment.stored_rotary_club != null &&
    payment.stored_rotary_club != "" &&
    payment.stored_rotary_credorg_id != null &&
    payment.stored_rotary_credorg_id != "" &&
    payment.stored_rotary_credorg_type != null &&
    payment.stored_rotary_credorg_type != ""
  ) {
    ret.behalfVisibility = true;
    ret.behalfType = "club";
  } else {
    ret.behalfVisibility = false;
  }
  return ret;
};

export const presetUtils = {
  bootstrapWidgetPreset: (payment: any, config: Config) => {
    const widgetData: any = {};
    if (
      payment.stored_campaign_id === "GRNT_GG" &&
      payment.stored_campaign_subid === "GG_FLOWTHR"
    ) {
      widgetData.selectedFund = {
        isGrant: true,
        grantId: payment.stored_grant_id,
      };
    } else {
      widgetData.selectedFund = getFund(
        payment.stored_customer_fund_id,
        payment.stored_customer_subfund_id,
        config.funds,
      );
    }

    widgetData.selectedFundTab = getFundTab(
      widgetData.selectedFund,
      config.categories,
    );

    widgetData.amountDetails = getAmountDetails(
      payment.currency,
      payment.default_selected_amount,
      config.currencies,
      AMOUNT_CONFIG,
    );

    widgetData.dedicationDetails = getDedicationDetails({
      dedicationType: payment.stored_mth_recipient_occasion,
      notificationType: payment.stored_mth_delivery_method,
      ecardLink: payment.stored_mth_ecard_url,
      recipientEmail: payment.stored_mth_recipient_email,
      personalMessage: payment.stored_mth_recipient_message,
      dedicationName: payment.stored_mth_recipient_name,
      donorName: payment.stored_mth_sender_name,
      selectedDedicationImage: getDedicationImage(
        config.dedicationImages,
        payment.stored_dedication_image_id,
        payment.stored_mth_recipient_occasion,
      ),
    });

    widgetData.behalfDetails = getBehalfDetails(payment);

    return widgetData;
  },

  getAllPurpose: (funds: Funds) => {
    const translations: Translations = {
      en: { purposes: {} },
      de: { purposes: {} },
      es: { purposes: {} },
      fr: { purposes: {} },
      it: { purposes: {} },
      ja: { purposes: {} },
      ko: { purposes: {} },
      pt: { purposes: {} },
    };

    let purposeId = 1;
    for (let fund of funds) {
      for (const [key, value] of Object.entries(translations)) {
        translations[key as keyof Translations].purposes[`p${purposeId}`] =
          fund.fundTitle?.[key as keyof Translations] || "";
      }
      purposeId++;
    }

    /*added last customized purpose for grants/named fund/ tracked gift , 
      when user select any of these, it will set here purpose text here
    */
    for (const [key, value] of Object.entries(translations)) {
      translations[key as keyof Translations].purposes[`p${purposeId}`] =
        `customized-purpose-text`;
    }

    return translations;
  },
  getPurposeByText: (
    translations: Translations,
    language: string,
    value: any,
  ): string | undefined => {
    const translation = translations[language as keyof Translations];

    if (!translation || !translation.purposes) {
      return undefined;
    }

    return Object.keys(translation.purposes).find(
      (key) => translation.purposes[key] === value,
    );
  },
};

export default presetUtils;
