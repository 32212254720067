import { useEffect, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { pxToRem, CustomButton } from "shared/styles";
import FundCategory from "components/FundCategory";
import LoadingThrobber from "components/LoadingThrobber";
import Donation from "components/Donation";
import { Config, DedicationImages } from "shared/types";
import Dedication from "components/Dedication";
import Checkbox from "components/Form/components/Checkbox";
import { useSelector } from "react-redux";
import { RootState } from "shared/state/store";
import ClubInfoClubCredit from "components/ClubInfoClubCredit";
import SecurityLock from "components/SecurityLock";
import useActions from "Hooks/useActions";
import dedicationImagesNonPrd from "components/Dedication/dedicationImagesNonPrd";
import dedicationImagesPrd from "components/Dedication/dedicationImagesPrd";
import StaffUseOnly from "components/StaffUseOnly";
import PayInvoice from "components/PayInvoice";
import {
  checkIsInvoiceFlow,
  checkIsRotaryDirectPage,
  removeKeysFromLocalStorage,
  setValueFromLocalStorage,
} from "../src/shared/utils/index";
import MultipleDonorCheckbox from "components/MultipleDonor/components/MultipleDonorCheckbox";
import {
  fetchEcardLinkAndStoreData,
  fetchEncryptedPAN,
} from "shared/utils/api";
import DonationDetails from "components/DonationDetails";
import HeaderBlurbText from "components/HeaderBlurbText";
import { Trans, useTranslation } from "react-i18next";
import LanguageSwitcher from "components/LanguageSwitcher";
import RaiseNowWidgetTamaro from "components/RaiseNowTamaroWidget";
import { RaiseNowPreData } from "RaisenowData";
import UserActions from "components/UserActions";
import { setUser } from "shared/state/user";
import DonationConfirmation from "components/DonationConfirmation";
import DonationPreviewPopUp from "components/DonationPreviewPopUp";

const AppWrapper = styled(Box)(({ theme }) => ({
  border: "1px solid grey",
  width: pxToRem(1150),
  paddingBottom: "3rem",
  boxSizing: "border-box",
  margin: "auto",
  marginBottom: "1rem",
  backgroundColor: "white",
  [theme.breakpoints.down("md")]: {
    width: pxToRem(960),
    margin: "auto",
    padding: pxToRem(10),
  },
  [theme.breakpoints.down("sm")]: {
    width: pxToRem(750),
    margin: "auto",
    padding: pxToRem(10),
  },
  [theme.breakpoints.down("xs")]: {
    width: pxToRem(400),
    margin: "auto",
    padding: pxToRem(10),
  },
}));

const DonateText = styled(Typography)(({ theme }) => ({
  paddingLeft: "7.5rem",
  fontWeight: 300,
  fontSize: 70,
  boxSizing: "border-box",
  [theme.breakpoints.down("sm")]: {
    fontSize: 35,
    paddingLeft: 0,
  },
}));

const ConditionsText = styled(Typography)(({ theme }) => ({
  paddingRight: pxToRem(150),
  fontSize: 16,
  marginTop: pxToRem(40),
  fontWeight: "100",
  boxSizing: "border-box",
  textAlign: "right",
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
    marginTop: pxToRem(20),
    marginLeft: "1.875rem",
    marginBottom: pxToRem(20),
    textAlign: "left",
    paddingRight: 0,
  },
}));

const DonationStep = styled("div")(({ theme }) => ({
  borderBottom: `1px solid #263b4c' !important`,
  marginBottom: pxToRem(50),
  [theme.breakpoints.down("sm")]: {
    border: `1px solid #263b4c' !important`,
  },
  minHeight: "10%",
}));

const WidgetFooter = styled("div")`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

interface DonationDetailsBtnProps {
  shouldReset: boolean;
  primary: boolean;
  onClick: () => void;
}
const DonationDetailsBtn = styled(CustomButton)<DonationDetailsBtnProps>`
  margin: 0 1rem 1rem;
`;
interface SubmitBtnProps {
  onClick: () => void;
}
const SubmitBtn = styled(CustomButton)<SubmitBtnProps>`
  display: block;
  margin: 0 1rem 1rem;
  width: 100%;
`;

const Overlay = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark, semi-transparent O */
  z-index: 100;
`;

function App() {
  const { dedicationVisibilityError } = useSelector(
    (state: RootState) => state.error,
  );
  const { donationType, config, loading, country, currency, mode, dpmBtnMode } =
    useSelector((state: RootState) => state.app);
  const {
    dedicationVisibility,
    ecardLink,
    dedicationType,
    dedicationName,
    donorName,
    personalMessage,
    notificationType,
    selectedDedicationImage,
    dedicationNotificationStatus,
  } = useSelector((state: RootState) => state.dedication);
  const { donationDetailsVisibility, confirmationDetailsVisibility } =
    useSelector((state: RootState) => state.ui);
  const { widgetIsLoaded, widgetIsLoading } = useSelector(
    (state: RootState) => state.raiseNow,
  );
  const { transactionData } = useSelector(
    (state: RootState) => state.transaction,
  );
  const { guestContinue, user } = useSelector((state: RootState) => state.user);
  const {
    setDedicationVisibility,
    setDedicationVisibilityError,
    setDedicationType,
    fetchConfig,
    fetchPaymentProfile,
    fetchPanStatus,
    fetchUserInfo,
    setDonationDetailsVisibility,
    setDonationType,
    setDedicationNameError,
    setDonorNameError,
    setRecipientEmailError,
    setCountryError,
    setStateProvinceError,
    setNotificationType,
    setUser,
    setEcardLink,
    setConfirmationDetailsVisibility,
  } = useActions();
  const setVisibility = (dedicationImages: DedicationImages) => {
    if (!dedicationVisibility) {
      removeKeysFromLocalStorage([
        "dedicationName",
        "dedicationType",
        "dedicationNotificationStatus",
        "notificationType",
        "recipientEmail",
        "donorName",
        "personalMessage",
        "selectedDedicationImage",
        "dedicationImages",
        "currencyRecurringEnabled",
        "frequency",
        "interval",
        "ruccuringStartDate",
      ]);
      // if we are unchecking the checkbox (hiding dedication)
      setDedicationNameError(false, false); // no dedication errors
      setDonorNameError(false, false);
      setRecipientEmailError(false, false);
      setCountryError(false, false);
      setStateProvinceError(false, false);
      // reset to email
      setNotificationType(
        setValueFromLocalStorage("notificationType", "string", "email"),
      );
    } else {
      // if we are checking the checkbox
      setDedicationNameError(true, false); // register error, but don't make it visible
      // register errors, but don't make them visible
      setDonorNameError(true, false);
      setRecipientEmailError(true, false);
    }
    setDedicationVisibility(dedicationImages);
  };
  const dedicationImages = useMemo(() => {
    if (config?.appConfigs.webAppBaseUrl.includes("uat")) {
      return dedicationImagesNonPrd;
    } else {
      return dedicationImagesPrd;
    }
  }, [config]);

  useEffect(() => {
    fetchConfig(mode);
  }, []);

  useEffect(() => {
    if (config) {
      if (!config.appConfigs.oneTimeEnabled) {
        setDonationType(config.currencies, "recurring");
      }
      fetchPaymentProfile({
        CountryISO2: country?.iso2 as string,
        CurrencyCode: currency?.code as string,
        TransactionTypeAlias: "AKSEVENT",
        CustomerType: "Business",
        TransactionFlowName: "DPM-Payment",
      });
      fetchPanStatus({
        memberid: "5693420", // to do - will get MemberID from the logged in user details
      });
      //fetch encrypted PAN
      const plainPan = "ANUPC2993I";
      let result = fetchEncryptedPAN(plainPan);
      //fetch logged in user info
      fetchUserInfo({
        memberid: "16303",
        role: "Member",
      });
      // if (!user.authenticated) {
      //   let user = {
      //     authenticated: false,
      //     roles: {
      //       1: "anonymous user",
      //     },
      //   };
      //   setUser(user, false);
      // }
      const queryString = window.location.search;
      const isPaymentRedirected = new RegExp(/epp_transaction_id/);

      if (!isPaymentRedirected.test(queryString)) {
        return;
      } else {
        let user = {
          authenticated: false,
          roles: {
            1: "anonymous user",
          },
        };
        setUser(user, true);
      }
    }
  }, [config]);

  const { t } = useTranslation();

  const submitForm = async () => {
    if ((window as any).rnw) {
      try {
        const apiPromises: Promise<any>[] = [];
        if (dedicationNotificationStatus) {
          const ecardPromise = fetchEcardLinkAndStoreData(
            dedicationType === "memory" ? 1 : 2,
            notificationType === "email" ? 1 : 2,
            dedicationName,
            donorName,
            Number(selectedDedicationImage.id),
          ).then((response) => {
            const baseURL = process.env.REACT_APP_BASE_URL;
            let ecardUrl = `${baseURL}/en/e-card/${response.data.ecardId}`;
            localStorage.setItem("eCardUrl", ecardUrl);
            setEcardLink(ecardUrl);
          });
          apiPromises.push(ecardPromise);
        }

        await Promise.all(apiPromises);

        return (
          window as any
        ).rnw.tamaro.instance.paymentForm.validateAndSend();
      } catch (error) {
        console.error("Error during form submission:", error);
      }
    }
  };

  let raisnowWidgetId = "rotary-italy";

  return !!transactionData ? (
    <>
      <AppWrapper>
        {confirmationDetailsVisibility && (
          <>
            <DonationPreviewPopUp />
            <Overlay
              onClick={() => setConfirmationDetailsVisibility(false)}
            ></Overlay>
          </>
        )}
        <DonationConfirmation />
      </AppWrapper>
    </>
  ) : (
    <AppWrapper className="widget__container">
      <LanguageSwitcher></LanguageSwitcher>
      {loading ? (
        <LoadingThrobber />
      ) : (
        <>
          {donationDetailsVisibility && <DonationDetails />}
          <HeaderBlurbText headingType="Donate"></HeaderBlurbText>
          <ConditionsText variant="subtitle1">
            All fields are required unless marked <i>optional</i>.
          </ConditionsText>
          {mode === "dpm" && (
            <>
              <StaffUseOnly />
              <DonationStep>
                {checkIsInvoiceFlow(config as Config) && <PayInvoice />}
              </DonationStep>
            </>
          )}
          {!checkIsInvoiceFlow(config as Config) &&
            config?.appConfigs.fundSelectable && <FundCategory />}
          {config?.appConfigs.dedicationEnabled && (
            <Checkbox
              label="I would like to make this donation in honor or in memory of someone"
              outSideContainer={true}
              isDisabled={donationType === "recurring"}
              onChange={() => setVisibility(dedicationImagesNonPrd)}
              checked={dedicationVisibility}
              errorMsg={
                dedicationVisibilityError.status
                  ? "This option is only available with a one-time donation"
                  : null
              }
            />
          )}
          {mode === "dpm" && <MultipleDonorCheckbox />}
          {dedicationVisibility && (
            <Dedication allDedicationImages={dedicationImages} />
          )}
          {!checkIsInvoiceFlow(config as Config) && <Donation />}
          {!guestContinue && <UserActions />}
          {/* RN props need to be made dynamic */}
          {!widgetIsLoaded && widgetIsLoading && <LoadingThrobber />}
          {(guestContinue ||
            user.authenticated ||
            mode === "dpm" ||
            mode === "invoice") &&
            raisnowWidgetId !== null && (
              <>
                <RaiseNowWidgetTamaro props={RaiseNowPreData} />
                {mode !== "dpm" &&
                  donationType === "one-time" &&
                  !checkIsInvoiceFlow(config as Config) &&
                  widgetIsLoaded && <ClubInfoClubCredit />}
              </>
            )}
          <WidgetFooter>
            {widgetIsLoaded && (
              <>
                <DonationDetailsBtn
                  shouldReset
                  primary
                  onClick={() => setDonationDetailsVisibility(true)}
                >
                  {checkIsRotaryDirectPage(config as Config) === true
                    ? "REVIEW MY ENROLLMENT"
                    : config &&
                        ((mode == "dpm" && dpmBtnMode == "addPayment") ||
                          checkIsInvoiceFlow(config))
                      ? "REVIEW MY TRANSACTION"
                      : "Review my donation"}
                </DonationDetailsBtn>
                <SubmitBtn
                  onClick={() => {
                    submitForm();
                  }}
                >
                  Submit
                </SubmitBtn>
              </>
            )}
            <SecurityLock />
          </WidgetFooter>
        </>
      )}
    </AppWrapper>
  );
}

export default App;
