import { styled } from "@mui/system";
import { Box } from "@mui/material";
import { pxToRem, color } from "shared/styles";
import RequestSourceSelector from "./RequestSourceSelector";
import DPMDonationOnly from "./DPMDonationOnly";
import DpmPaymentComponent from "./PaymentTypeSelector";
import DPMInvoiceOnly from "./InvoiceOnlyFields";
import { useSelector } from "react-redux";
import { RootState } from "shared/state/store";

const StaffOnlyUseContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#EDF4F8",
  padding: "1.875rem 7vw",
  border: "1px solid #ccc",
  fontColor: "#263b4c",
  marginLeft: pxToRem(150),
  marginRight: pxToRem(150),
  marginTop: pxToRem(20),
  marginBottom: pxToRem(70),
  [theme.breakpoints.down("sm")]: {
    marginLeft: pxToRem(30),
    marginRight: pxToRem(30),
  },
  [theme.breakpoints.down("xs")]: {
    border: "none",
    borderBottom: `1px solid ${color.midGrey}`,
  },
}));

const StaffOnlyUseTitle = styled("div")`
  font-size: 1.5rem;
  padding-bottom: 1rem;
  font-weight: 300;
  margin-bottom: 1rem;
  border-bottom: 1px solid;
`;

const StaffUseOnly = () => {
  const { dpmBtnMode, userInfo } = useSelector((state: RootState) => state.app);

  return (
    <StaffOnlyUseContainer>
      <StaffOnlyUseTitle>Staff Use Only</StaffOnlyUseTitle>
      <RequestSourceSelector />
      {dpmBtnMode === "addDonation" && <DPMDonationOnly />}
      {dpmBtnMode === "addPayment" && <DpmPaymentComponent />}
      {dpmBtnMode === "payInvoice" && <DPMInvoiceOnly />}
    </StaffOnlyUseContainer>
  );
};

export default StaffUseOnly;
