import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

type Params = { [key: string]: string };

interface CreateFetch {
  endpoint: string;
  params?: Params;
  config?: AxiosRequestConfig;
  method: "GET" | "POST";
  data?: { [key: string]: string | number };
}

export const createFetch = async ({
  endpoint,
  params = {},
  config,
  method,
  data,
}: CreateFetch) => {
  try {
    const url = `${baseURL}${endpoint}`;
    let axiosConfig: AxiosRequestConfig = {
      params: method === "GET" ? params : undefined,
      auth: {
        username: process.env.REACT_APP_BASICAUTH_USERNAME as string,
        password: process.env.REACT_APP_BASICAUTH_PASSWORD as string,
      },
      headers: {
        Accept: "*/*",
        ...(config?.headers || {}),
      },
      ...config,
    };
    if (method === "POST") {
      axiosConfig.data = data;
    }
    const response = await axios.request({
      url,
      method,
      ...axiosConfig,
    });
    const { status, data: responseData } = response;
    return { status, data: responseData };
  } catch (error) {
    console.warn(error);
    throw error;
  }
};

export const fetchTrackedGiftInfo = (trackedGiftIdInputTrimmed: string) =>
  createFetch({
    endpoint: "/tracked-gift/search",
    params: {
      TRACKED_GIFT_ID: trackedGiftIdInputTrimmed,
      DONOR_TYPE: "Individual",
      DONOR_ID: "6270842",
      LANGUAGE_CODE: "en",
    },
    method: "GET",
  });

export const fetchGlobalGrantInfo = (grantIdWithPrefix: string) =>
  createFetch({
    endpoint: "/grants",
    params: { grant_id: grantIdWithPrefix },
    method: "GET",
  });

export const fetchStatesByCountryId = (countryId: string) =>
  createFetch({
    endpoint: "/statelist",
    params: { CountryId: countryId },
    method: "GET",
  });

export const fetchClubName = (clubId: string, clubType: string) =>
  createFetch({
    endpoint: "/club-search",
    params: { club_id: clubId, club_type: clubType },
    method: "GET",
  });

export const fetchDonorNameByTypeAndMemberId = (type: string, id: string) =>
  createFetch({
    endpoint: "/get-donor-name",
    params: {
      type: type === "Individual" ? "Individuals" : encodeURIComponent(type),
      id: id,
    },
    method: "GET",
  });

//In old donate widget 2 separate calls existed to multiple donor(fetchMemberListById) and club officer(fetchClubMembersByClubId) but in background it called the same api endpoint
//Here we are only writing one and using it in both places.
export const fetchClubMembersByClubIdandType = (type: string, id: string) =>
  createFetch({
    endpoint: "/club/members",
    params: {
      club_type: type,
      club_id: id,
    },
    method: "GET",
  });

export const fetchInvoiceListByClubTypeAndClubId = (type: string, id: string) =>
  createFetch({
    endpoint: "/invoice-list",
    params: {
      clubType: type,
      clubId: id,
    },
    method: "GET",
  });

export const fetchEncryptedPAN = (plainPan: string) =>
  createFetch({
    endpoint: "/encrypt-pan",
    params: {},
    method: "POST",
    data: { pan: plainPan },
  });

export const getMemberSubscription = (memberId: string) =>
  createFetch({
    endpoint: `/subscriptions/${memberId}`,
    params: {},
    method: "GET",
  });

export const fetchEcardLinkAndStoreData = (
  dedicationType: number,
  notificationType: number,
  dedicatedTo: string,
  donorName: string,
  selectedDedicationImage: number,
  personalMessage: string = "",
): Promise<{ status: number; data: any }> =>
  createFetch({
    endpoint: "/ecard",
    params: {},
    method: "POST",
    data: {
      DedicationType: dedicationType,
      NotificationType: notificationType,
      DedicatedTo: dedicatedTo,
      DonorName: donorName,
      ImageFid: selectedDedicationImage,
      PersonalMsg: personalMessage,
    },
  });
