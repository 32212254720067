import React from "react";
import {
  Amounts,
  Country,
  Countries,
  CountryCurrencies,
  DedicationImages,
  Fund,
  Funds,
  Currencies,
  Currency,
  Rates,
  Frequency,
  Config,
  CategoriesByLanguage,
} from "shared/types";
import amounts from "shared/data/amounts";
import recurringFrequencies from "shared/data/recurringFrequencies";
import recurringDisabledCurrencies from "shared/data/recurringDisabledCurrencies";
import moment from "moment";

export const getDedicationImage = (
  dedicationImages: DedicationImages,
  id: string,
) => {
  const filtered = dedicationImages.filter(
    (image: { id: string }) => image.id === id,
  );
  return filtered.length ? filtered[0] : {};
};
export const getDedicationImagesByType = (
  dedicationImages: DedicationImages,
  dedicationType: string,
) => {
  const dedicationImagesByType = dedicationImages.filter(
    (image: { type: string }) => image.type === dedicationType,
  );
  return dedicationImagesByType;
};

export const staffOnlyFieldsUtils = {
  isValidDate: (d: string[]) => {
    const date = d[0];
    return date && !Number.isNaN(Date.parse(date));
  },
  getReadableDate: (d: string[]) => {
    const date = d[0];
    let readableDate = moment(date).format("DD-MMM-YYYY");

    return readableDate;
  },
};
/** **********************************
 ****** Formatting Functions ********
 *********************************** */

export const formatAmount = (
  amount: string,
  delimiter: string,
  decimalMark: string,
) => {
  const hasDecimals = /[.,]\d{1,2}$/g.test(amount) === true;
  const re = `\\d(?=(\\d{${3}})+${hasDecimals ? "\\D" : "$"})`;
  const num = hasDecimals
    ? parseFloat(amount.toString().replace(",", ".")).toFixed(2)
    : amount.toString();
  return (decimalMark ? num.replace(".", decimalMark) : num).replace(
    new RegExp(re, "g"),
    `$&${delimiter || " "}`,
  );
};

export const formatAmountByCurrency = (amount: number, currency: Currency) => {
  const spaceDelimiterDecimalComma = ["eur", "dkk", "nok", "sek", "zar"];
  const spaceDelimiterDecimalPoint = ["chf"];
  const pointDelimiterDecimalComma = ["brl", "ars"];
  const commaDelimiterDecimalPoint = [
    "aud",
    "nzd",
    "cad",
    "usd",
    "gbp",
    "jpy",
    "inr",
    "krw",
  ];
  const commaDelimiterNoDecimal: string[] = [];

  const amountString = amount.toString();

  let updatedAmount;

  if (spaceDelimiterDecimalComma.includes(currency.code)) {
    updatedAmount = formatAmount(amountString, " ", ",");
  } else if (spaceDelimiterDecimalPoint.includes(currency.code)) {
    updatedAmount = formatAmount(amountString, " ", ".");
  } else if (pointDelimiterDecimalComma.includes(currency.code)) {
    updatedAmount = formatAmount(amountString, ".", ",");
  } else if (commaDelimiterDecimalPoint.includes(currency.code)) {
    updatedAmount = formatAmount(amountString, ",", ".");
  } else if (commaDelimiterNoDecimal.includes(currency.code)) {
    updatedAmount = formatAmount(Math.floor(amount).toString(), ",", ".");
  } else {
    updatedAmount = formatAmount(amountString, " ", ",");
  }

  return updatedAmount;
};

export const decodeAmount = (amount: any, currency: Currency) => {
  if (currency.code === "jpy") {
    return amount;
  }
  return amount / 100;
};

export const formatEmail = (email: string) => {
  const [name, domain] = email.split("@");
  return `${name}<wbr>@${domain}`;
};

/** **********************************
 ****** Country Data Functions ******
 *********************************** */

// Only return countries from DIS that also have currency+widget mapping
export const getCountries = (
  countries: Countries,
  countryCurrency: CountryCurrencies,
) =>
  countries.filter((country) =>
    Object.prototype.hasOwnProperty.call(countryCurrency, country.id),
  );

export const getCountryById = (
  countries: Countries,
  id: string,
  countryCurrency: CountryCurrencies,
) => {
  const filtered = getCountries(countries, countryCurrency).filter(
    (country) => country.id === id,
  );

  return filtered[0];
};

// ISO2 country codes are not unique in our data set!
export const getCountriesByISO2 = (
  countries: Countries,
  iso2: string,
  countryCurrency: CountryCurrencies,
) => {
  const filtered = getCountries(countries, countryCurrency).filter(
    (country) => country.iso2 === iso2.toLowerCase(),
  );

  return filtered.length ? filtered : [];
};

export const getCountryByEnglishName = (
  countries: Countries,
  en: string,
  countryCurrency: CountryCurrencies,
) => {
  const filtered = getCountries(countries, countryCurrency).filter(
    (country) => {
      if (
        country.name.en.localeCompare(en, undefined, {
          sensitivity: "base",
        }) === 0 ||
        country.name.und.localeCompare(en, undefined, {
          sensitivity: "base",
        }) === 0
      ) {
        return true;
      }
    },
  );
  return filtered.length ? filtered[0] : null;
};

export const getCountryName = (country: Country, lang: string) =>
  country.name[lang as keyof TitleByLanguage] ||
  country.name.en ||
  country.name.und;

export const getCountriesForDropdown = (countries: Countries) => {
  const filtered = countries.map(function (country) {
    return {
      label: country.name.en,
      value: country.iso2,
      countryID: country.id,
    };
  });
  const sortedCountries = filtered.sort((a, b) =>
    a?.label?.localeCompare(b.label),
  );
  return sortedCountries;
};
/** **********************************
 ****** Currency Data Functions *****
 *********************************** */

export const getCurrencyById = (currencies: Currencies, id: string) => {
  const filtered = currencies.filter(
    (currency) => currency.id.toLowerCase() === id.toLowerCase(),
  );

  // return filtered.length ? filtered[0] : null;
  return filtered[0];
};

export const getCurrencyByCode = (currencies: Currencies, code: string) => {
  const filtered = currencies.filter(
    (currency) => currency.code === code.toLowerCase(),
  );

  return filtered.length ? filtered[0] : null;
};

interface CurrencyNameByLanguage {
  en: string;
  sv?: string;
  es?: string;
  de?: string;
  ko?: string;
  it?: string;
  pt?: string;
  fr?: string;
  ja?: string;
}

export const getCurrencyName = (currency: Currency, lang: string) =>
  currency.name[lang as keyof CurrencyNameByLanguage] || currency.id;

/** ******************************************
 ****** Fund Data & User Functions *********
 ********************************************* */
interface TitleByLanguage {
  en: string | null;
  fr: string | null;
  de: string | null;
  es: string | null;
  it: string | null;
  ja: string | null;
  ko: string | null;
  pt: string | null;
}

interface GetFundTitleProps {
  fundTitle: TitleByLanguage;
  id: number | null;
}

export const getFundTitle = (
  { fundTitle, id }: GetFundTitleProps,
  lang: string,
) => {
  const langKeys = ["fr", "de", "es", "it", "ja", "ko", "pt"];

  if (langKeys.includes(lang) && fundTitle[lang as keyof TitleByLanguage]) {
    return fundTitle[lang as keyof TitleByLanguage];
  }

  return fundTitle.en || id?.toString();
};

interface GetFundSubTitle {
  fundSubTitle?: {
    en?: string;
    fr?: string;
    de?: string;
    es?: string;
    it?: string;
    ja?: string;
    ko?: string;
    pt?: string;
  } | null;
  id: number;
}

export const getFundSubtitle = (
  { fundSubTitle, id }: GetFundSubTitle,
  lang: string,
) => {
  if (fundSubTitle) {
    const langKeys = ["fr", "de", "es", "it", "ja", "ko", "pt"];

    if (
      langKeys.includes(lang) &&
      fundSubTitle[lang as keyof TitleByLanguage]
    ) {
      return fundSubTitle[lang as keyof TitleByLanguage];
    }

    return fundSubTitle.en || id.toString();
  }

  return null;
};

/**
 * Determines if the given roles pass the allow/deny rules.
 *
 * @param {Object} rules
 * @param {Array} rules.allow
 * @param {Array} rules.deny
 * @param {Object} roles
 * @returns {boolean}
 */

export const getDpmCategories = (
  categories: CategoriesByLanguage,
  dpmBtnMode: string,
  mode: string,
) => {
  let categoriesArray = categories.en.filter((cat) =>
    cat.content.filter((obj) => obj.mode === mode),
  );
  // Remove Global grants for addsubscription option
  if (
    mode === "dpm" &&
    (dpmBtnMode === "addSubscription" || dpmBtnMode === "editSubscription")
  ) {
    categoriesArray = categoriesArray.filter(
      (category) => category.title !== "Global Grants",
    );
  }
  // Remove TrackedGift category.
  if (mode === "dpm" && dpmBtnMode != "addDonation") {
    categoriesArray = categoriesArray.filter(
      (category) => category.title !== "Tracked Gifts",
    );
  }
  return categoriesArray;
};

export const getFundById = (funds: Funds | null, id: number) => {
  const filtered =
    funds &&
    funds.filter((fund) => {
      return fund.id === id;
    });
  return filtered?.length ? filtered[0] : null;
};

export const getFundByKey = (
  funds: Funds,
  keyName: keyof Fund,
  keyValue: string,
) => {
  const filtered = funds.filter(
    (fund) =>
      Object.keys(fund).find((fundKey) => fundKey == keyName) &&
      fund[keyName] == keyValue,
  );
  return filtered.length ? filtered[0] : null;
};

/**
 * Given a list of funds, return only ones that are visible given the current "oneTimeEnabled" setting.
 *  - If one-time donations are enabled, all funds are returned.
 *  - If not, only funds with the "recurring" flag set to true as returned.
 */
export const getVisibleFunds = (
  allFunds: Funds | null,
  listedFunds: number[] | null,
  oneTimeEnabled: boolean | null,
) => {
  if (oneTimeEnabled) {
    return listedFunds || [];
  }
  return (
    listedFunds?.filter((fundId: number) => {
      const fundData: Fund | null = getFundById(allFunds, fundId);
      return fundData?.recurring || false;
    }) || []
  );
};

/** **********************************
 ****** Frequency Functions **********
 *********************************** */
interface FrequencyTitleByLanguage {
  en: string;
  es: string;
  de: string;
  fr: string;
  it: string;
  ja: string;
  ko: string;
  pt: string;
  und: string;
}

export const getFrequencyById = (id: string) => {
  const filtered = recurringFrequencies.filter(
    (frequency) => frequency.id === id,
  );
  return filtered.length ? filtered[0] : null;
};

export const getFrequencyName = (frequency: Frequency, lang: string) => {
  if (frequency.name[lang as keyof FrequencyTitleByLanguage]) {
    return frequency.name[lang as keyof FrequencyTitleByLanguage];
  }

  // Attempt to return english string
  if (frequency.name.en) {
    return frequency.name.en;
  }

  return frequency.id;
};

/** ************************************
 ****** MISC functions ******
 ************************************* */

export const amountInDollars = (
  rateToUsd: string,
  amount: number,
  currency: Currency,
) =>
  amount
    ? formatAmountByCurrency(
        Math.round(Number(rateToUsd) * amount * 100) / 100,
        currency,
      )
    : 0;

export const getExchangeRate = (
  currencies: Currencies | null,
  exchangeRates: Rates | null,
  fromCurrency?: string | { code: string } | null,
  toCurrency?: Currency | null,
) => {
  if (!toCurrency) {
    toCurrency = currencies && getCurrencyByCode(currencies, "usd");
  }
  const filtered =
    exchangeRates &&
    exchangeRates.filter(
      (rate) =>
        fromCurrency &&
        (typeof fromCurrency === "string"
          ? fromCurrency.toLowerCase() === rate.FromCurrency.toLowerCase()
          : fromCurrency.code.toLowerCase() ===
            rate.FromCurrency.toLowerCase()) &&
        toCurrency &&
        toCurrency.code.toLowerCase() === rate.ToCurrency.toLowerCase(),
    );

  return filtered && filtered.length ? filtered[0].RateMultiplier : "";
};

export const sortTranslatedByKey = (
  lang: string,
  collection: Countries | Currencies,
  key: string = "label",
) => {
  if (key !== "label" && key !== lang && !(key in collection[0]?.name)) {
    throw new Error(`Invalid key: ${key}`);
  }

  return collection.sort((a, b) => {
    const aName = a.name as Record<string, string>;
    const bName = b.name as Record<string, string>;
    return (aName[lang] || aName.en).localeCompare(bName[lang] || bName.en);
  });
};

export const getCurrencyRecurringEnabled = (
  country: Country,
  currency: Currency,
  countryCurrency: CountryCurrencies,
) => {
  const currenciesAndWidgets = countryCurrency[country.id].list;
  const currencyAndWidget = currenciesAndWidgets.filter(
    (data) => data.currencyId.toLowerCase() === currency.id.toLowerCase(),
  );

  const countryCurrencyConfig = currencyAndWidget[0];

  if (
    countryCurrencyConfig &&
    recurringDisabledCurrencies.includes(countryCurrencyConfig.currencyId)
  ) {
    return false;
  }
  return true;
};

export const getDedicationErrorKeys = () => [
  "dedicationNameError",
  "donorNameError",
  "recipientEmailError",
];

export const scrollTo = (cssSelector: string) => {
  const element = global.document.querySelector(cssSelector);
  if (element) {
    global.scrollBy({
      behavior: "smooth",
      top: element.getBoundingClientRect().top,
    });
  }
};

export const getSelectors = (selector: string) => {
  const selectors: { [key: string]: string } = {
    requestSourceError: '[for="RequestSource"]',
    trackedGiftError: '[for="TrackedGift"]',
    openItemsError: '[for="InvoiceOpenItems"]',
    paymentTypeError: '[for="PaymentType"]',
    dedicationNameError: '[for="DedicationName"]',
    recipientEmailError: '[for="RecipientEmail"]',
    donorNameError: '[for="DonorName"]',
    grantsError: '[for="Grants"]',
    amountError: '[for="CustomAmount"]',
    frequencyError: '[for="DonationFrequency"]',
    clubMembersNoAmountError: '[For="MemberFormNoAmount"]',
    clubMembersInputError: '[for="MemberForm"]',
    qpqQuantityError: '[for="qpqQuantity"]',
    paymentAmountError: '[for="paymentAmount"]',
    overrideCreditOrgError: '[id="override-creditorg-grid"]',
    multipleDonorError: '[id="multiple-donor-grid"]',
    privacyPolicyError: '[for="PrivacyPolicyCheckbox"]',
    clubIDError: '[for="ClubIDErrorDiv"]',
  };

  return selectors[selector] || selectors;
};

export const inIframe = () => {
  try {
    return global.window.self !== global.window.top;
  } catch (e) {
    // permission error that window.top is not accessible
    // occurs when parent domain <> iframe domain
    return true;
  }
};

export const checkWebAppURLExistInWebpageURL = (webAppBaseUrl: string) => {
  return global.window.document.referrer.indexOf(webAppBaseUrl) !== -1;
};

export const getAmountsForCurrency = (currencyId: string) => {
  const currencyIdTrimmed = currencyId.toLowerCase().trim();
  const amountsLowercaseCurrency: Amounts = Object.keys(amounts).reduce(
    (newAmounts, key) => ({
      ...newAmounts,
      [key.toLowerCase()]: amounts[key],
    }),
    {},
  );

  return amountsLowercaseCurrency[currencyIdTrimmed];
};

export const getAmountPlaceholder = (currency: Currency) => {
  const currencyCode = currency.code.toUpperCase();
  const currencyForComma = ["ARS", "BRL", "DKK", "EUR", "NOK", "SEK", "ZAR"];
  const currencyForDot = ["AUD", "CAD", "CHF", "GBP", "NZD", "USD"];
  const currencyForZero = ["JPY"];

  if (currencyForComma.indexOf(currencyCode) !== -1) {
    return "0,00";
  } else if (currencyForDot.indexOf(currencyCode) !== -1) {
    return "0.00";
  } else if (currencyForZero.indexOf(currencyCode) !== -1) {
    return "0";
  } else {
    return "";
  }
};

export const validatorsForAmount = {
  regexZeroWithDecimalNum: (amount: string) => {
    return /^0{1}[.,]([1-9]|\d[1-9]|[1-9]\d)$/g.test(amount) === true;
  },
  regexFloatingNum: (amount: string) => {
    return /^[1-9]\d{0,}([.,]\d{0,2})?%?$/g.test(amount) === true;
  },
  regexTillTwoDecimalDigit: (amount: string) => {
    return amount.match(/\d{0,}(\.\d{0,2}|\,\d{0,2})?/)?.[0] || "";
  },
  regexToCheckAmountHavingComma: (amount: string) => {
    return /[,]$|[,]\d/g.test(amount) === true;
  },
};

export const blockInvalidChar = (e: React.KeyboardEvent<HTMLInputElement>) =>
  ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();

export const checkIsInvoiceFlow = (config: Config) => {
  let mode = "dpm";
  let btnMode = "payInvoice";
  const isInvoiceFlow =
    config &&
    ((mode === "dpm" && btnMode === "payInvoice") ||
      config.appConfigs.mode === "invoice")
      ? true
      : false;
  // return isInvoiceFlow;
  return false;
};

export const getExchangeRateForPayInvoice = (
  currencies: Currencies | null,
  exchangeRates: Rates,
  fromCurrency?: string | { code: string } | null | undefined,
  payInvoiceBillingCurrency?: string,
) => {
  const fromCurrencyObj =
    typeof fromCurrency === "object" ? fromCurrency : { code: fromCurrency };
  const fromCurrencyCode = fromCurrencyObj?.code;
  const filtered = exchangeRates.filter(
    (rate) =>
      fromCurrencyCode &&
      rate.FromCurrency.toLowerCase() === fromCurrencyCode.toLowerCase() &&
      payInvoiceBillingCurrency &&
      payInvoiceBillingCurrency.toLowerCase() === rate.ToCurrency.toLowerCase(),
  );
  return filtered[0].RateMultiplier;
};

export const checkIsRotaryDirectPage = (config: Config) => {
  const isRotaryDirectPage =
    typeof config.appConfigs.mode != "undefined" &&
    config.appConfigs.mode === "rotary-direct"
      ? true
      : false;
  return isRotaryDirectPage;
};

export type DPMTransactionFlowKeys =
  | "addSubscription"
  | "addDonation"
  | "addPayment"
  | "payInvoice"
  | "editSubscription";

export const getTransactionFlow = (
  mode: string,
  btnMode: DPMTransactionFlowKeys,
  donationType: string,
  module = "",
) => {
  let transactionFlow = "";
  const DPM_TRANSACTION_FLOW = {
    addSubscription: "DPM-Subscription",
    addDonation: "DPM-Donation",
    addPayment: "DPM-Payment",
    payInvoice: "DPM-Invoice",
    editSubscription: "DPM-Subscription",
  };
  if (mode) {
    switch (mode.toLowerCase()) {
      case "dpm":
        transactionFlow = DPM_TRANSACTION_FLOW[btnMode];
        if (module === "globalgrants" || module === "trackedgift") {
          transactionFlow = DPM_TRANSACTION_FLOW[btnMode];
        }
        break;
      case "giv":
        transactionFlow =
          donationType == "recurring"
            ? "MyRotary-Subscription"
            : "MyRotary-Donation";
        if (module === "globalgrants" || module === "trackedgift") {
          transactionFlow = "MyRotary-Donation";
        }
        break;
      case "drf":
        transactionFlow =
          donationType == "recurring" ? "DRF-Subscription" : "DRF-Donation";
        break;
      case "ep":
        transactionFlow =
          donationType == "recurring"
            ? "Endpolio-Subscription"
            : "Endpolio-Donation";
        break;
      case "rotary-direct":
        transactionFlow = "RotaryDirect-Subscription";
        break;
      case "ss":
        transactionFlow = "SelfService-Subscription";
        break;
      case "invoice":
        transactionFlow = "MyRotary-Invoice";
        break;
      default:
        transactionFlow = "MyRotary-Donation";
        break;
    }
  }

  return transactionFlow;
};

export const setItemsInLocalStorage = (
  key: string,
  value: any,
  type: "json" | "string",
): void => {
  const path = window.location.pathname;
  localStorage.setItem("setLocalStorageBy", path);

  if (type === "json") {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.setItem(key, value as string);
  }
};

export const getItemsFromLocalStorage = (key: string, type: string) => {
  const retain = JSON.parse(localStorage.getItem("retain") as string);

  if (!retain) {
    return false;
  } else if (type === "json") {
    const item = localStorage.getItem(key);
    const value = item ? JSON.parse(item) : null;
    return value;
  } else {
    const value = localStorage.getItem(key);
    return value;
  }
};

export const removeKeysFromLocalStorage = (
  localStorageKeysToRemove: string[],
): void => {
  const paymentWorkflowRedirect = /epp_transaction_id/.test(
    window.location.search,
  );
  const ginPresentPAN = localStorage.getItem("customerGinPresentPAN");
  const ginPresentFiscalCode = localStorage.getItem(
    "customerGinPresentFiscalCode",
  );

  localStorageKeysToRemove.forEach((key) => {
    if (paymentWorkflowRedirect) {
      if (key === "behalfType") {
        localStorage.removeItem(key);
      } else if (
        key === "customerGinPresent" &&
        localStorage.getItem("fetchUserLoader") &&
        (ginPresentPAN === "false" || ginPresentFiscalCode === "false")
      ) {
        localStorage.removeItem(key);
      }
    } else {
      localStorage.removeItem(key);
    }
  });
};

export const validatingSamePageRefresh = (): void => {
  const redirectFrom = localStorage.getItem("redirectFrom");
  const allLocalStorageKeys: string[] = [
    "activeFundTab",
    "behalfType",
    "selectedFund",
    "dedicationType",
    "dedicationImages",
    "dedicationName",
    "notificationType",
    "recipientEmail",
    "donorName",
    "personalMessage",
    "dedicationVisibility",
    "dedicationNotificationStatus",
    "selectedDedicationImage",
    "api_url",
    "paymentProfileData",
    "setPaymentProfile",
    "setFoundation",
    "calledBy",
    "grantIdInput",
    "grantName",
    "grantStatus",
    "apiUrl",
    "amountRemaining",
    "lang",
    "grantsErrorMessage",
    "frequency",
    "currencyRecurringEnabled",
    "customAmount",
    "customAmountShown",
    "donationType",
    "interval",
    "ruccuringStartDate",
    "fixedAmount",
    "errorObj",
    "customerGinPresentPAN",
    "customerGinPresentFiscalCode",
  ];

  const path = /[^/]*$/.exec(window.location.pathname)?.[0];
  const setLocalStorageBy = /[^/]*$/.exec(
    localStorage.getItem("setLocalStorageBy") ?? "",
  )?.[0];

  if (path !== setLocalStorageBy) {
    removeKeysFromLocalStorage(allLocalStorageKeys);
    setItemsInLocalStorage("retain", false, "json");
    setItemsInLocalStorage("setLocalStorageBy", path || "", "string");
  }

  if (redirectFrom) {
    localStorage.removeItem("redirectFrom");
    localStorage.setItem("retain", "true");
  } else {
    removeKeysFromLocalStorage(allLocalStorageKeys);
    localStorage.setItem("retain", "false");
  }
};

export const setValueFromLocalStorage = (
  key: string,
  type: string,
  defaultValue?: any,
) => {
  return getItemsFromLocalStorage(key, "string")
    ? getItemsFromLocalStorage(key, type)
    : defaultValue;
};

type ErrorItem = {
  status: boolean;
  visibility: boolean;
};

type ErrorObject = {
  [key: string]: ErrorItem;
};

export const updateErrorInLocalStorage = (
  errorKey: string,
  status: boolean,
  visibility: boolean,
): void => {
  const errorObj = getItemsFromLocalStorage("errorObj", "json");

  if (errorObj && typeof errorObj === "object" && errorObj[errorKey]) {
    errorObj[errorKey].status = status;
    errorObj[errorKey].visibility = visibility;
    setItemsInLocalStorage("errorObj", errorObj, "json");
  }
};

export const imgSize = (elem: HTMLImageElement): void => {
  elem.height = 200;
  elem.width = 300;
};

export const displayDonorList = (
  clubMembers: any,
  amountFormatted: string,
  currencyCode: string,
  currency: Currency,
) => {
  let table = '<table cellpadding="8" cellspacing="0" border="1" >';
  table += "<h2>Donation from</h2>";
  table +=
    '<tr><th align="center">Donor</th><th align="center">Amount</th></tr>';

  clubMembers.forEach((member: any) => {
    table +=
      "<tr>" +
      "<td>" +
      `${member.first_name} ${member.last_name}` +
      "</td>" +
      "<td>" +
      `${formatAmountByCurrency(member.amount, currency)} ${currencyCode}` +
      "</td>" +
      "</tr>";
  });

  table +=
    "<tr>" +
    "<th>Total</th>" +
    "<th>" +
    `${amountFormatted} ${currencyCode}` +
    "</th>" +
    "</tr>";
  table += "</table>";

  const element = document.getElementById("table_format");
  if (element) {
    element.innerHTML = table;
  }
};
export const printElement = (
  elem: HTMLElement | null,
  heading: string,
  clubMembers: HTMLElement | null,
  isDonatingOnBehalfOfClubMembers: boolean,
  myDonationDiv: HTMLElement | null,
  dedicationDiv: HTMLElement | null,
  isDedication: boolean,
): boolean => {
  if (
    !elem ||
    !clubMembers ||
    (isDonatingOnBehalfOfClubMembers && !myDonationDiv) ||
    (isDedication && !dedicationDiv)
  ) {
    console.error("One or more required elements are null.");
    return false;
  }

  let contents: string;
  let dedicationContent: string | undefined;

  if (isDonatingOnBehalfOfClubMembers) {
    if (myDonationDiv) {
      contents = myDonationDiv.innerHTML;
    } else {
      console.error("myDonationDiv is null.");
      return false;
    }
  } else {
    contents = elem.innerHTML;
  }

  const table = clubMembers.innerHTML;
  const frame1 = document.createElement("iframe");
  frame1.name = "frame1";
  frame1.style.position = "absolute";
  frame1.style.top = "-1000000px";
  document.body.appendChild(frame1);

  const title = "";
  const frameDoc = frame1.contentWindow?.document || frame1.contentDocument;

  if (frameDoc) {
    frameDoc.open();
    frameDoc.write(`<html><head><title>${title}</title>`);
    frameDoc.write("</head><body>");
    frameDoc.write(`<h1>${heading}</h1>`);
    frameDoc.write(contents);

    if (isDonatingOnBehalfOfClubMembers) {
      if (isDedication) {
        if (dedicationDiv) {
          dedicationContent = dedicationDiv.innerHTML;
          frameDoc.write(dedicationContent);
        } else {
          console.error("dedicationDiv is null.");
          return false;
        }
      }
      frameDoc.write(table);
    }

    frameDoc.write("</body></html>");
    frameDoc.close();

    setTimeout(() => {
      const iframe = Array.from(window.frames).find(
        (frame) => frame.name === "frame1",
      );
      if (iframe) {
        iframe.focus();
        iframe.print();
      } else {
        console.error('Iframe with name "frame1" not found.');
      }
      document.body.removeChild(frame1);
    }, 500);
  } else {
    console.error("Failed to access the document of the iframe.");
    document.body.removeChild(frame1);
  }

  return false;
};
