import React, { useEffect, useState } from "react";
import "./styles.css";
import { styled } from "@mui/system";
import { MultipleDonorMemberListGridDataObject } from "shared/types";
import Select from "components/Form/components/Select";
import {
  getTypeOptionsLabelByValue,
  typeOptions,
} from "components/MultipleDonor/utils/utils.mulitpleDonor";
import TextInput from "components/Form/components/TextInput";
import throbber from "shared/assets/throbber.gif";
import { ButtonWrapper, CloseGraphic } from "shared/styles";
import { CloseIcon } from "components/CloseIcon";

const LoadingVisual = styled("div")`
  background: url(${throbber}) no-repeat;
  height: 30px;
  margin: 0rem auto -1.2rem;
  width: 30px;
`;

interface MultipleDonorGridRowProps {
  gridRowData: MultipleDonorMemberListGridDataObject;
  onChange: (params: {
    selectedType?: string;
    id?: string;
    amount?: string;
  }) => void; // Update the type definition
  onBlur: (params: { donorId: string }) => void;
  onClear: () => void;
  onDelete: () => void;
  rowsCount: number;
  multipleDonorConfig: { minRows: number };
  rowIndex: number;
}

const MultipleDonorGridRow = ({
  gridRowData,
  onChange,
  onBlur,
  onClear,
  onDelete,
  rowsCount,
  multipleDonorConfig,
  rowIndex,
}: MultipleDonorGridRowProps) => {
  let topErrorBorder = "";
  let atLeastOneErr =
    gridRowData.errorMessage.err_id || gridRowData.errorMessage.err_amount;
  const [placeholder, setPlaceholder] = useState("Select");

  useEffect(() => {
    const lastIndex = rowsCount - 1;
    if (rowIndex === lastIndex) {
      setPlaceholder("Select");
    } else {
      setPlaceholder(
        getTypeOptionsLabelByValue(gridRowData.selectedType)[0].label,
      );
    }
  }, [rowsCount]);

  if (atLeastOneErr) {
    topErrorBorder = " top-error-border";
  }
  return (
    <div
      className={
        gridRowData.errorMessage.err_amount !== "" && atLeastOneErr
          ? "no-top-border"
          : "row-container"
      }
    >
      <div className={"mdg-row flex-host" + topErrorBorder}>
        <div className="mdg-col data">
          <Select
            id="type"
            options={typeOptions}
            value={gridRowData.selectedType}
            placeholder={placeholder}
            onChange={(event) => {
              setPlaceholder(event.label);
              onChange({ selectedType: event.value.toString() });
            }}
          />
        </div>
        <div className="mdg-col data">
          {gridRowData.selectedType ? (
            <div
              className={
                gridRowData.errorMessage.err_id ? "mdg-error-border-id" : ""
              }
            >
              <TextInput
                maxLength="12"
                value={gridRowData.id}
                type="text"
                onChange={(event) => {
                  onChange({ id: event.target.value });
                }}
                onBlur={(event) => {
                  onBlur({ donorId: event.target.value.trim() });
                }}
              />
            </div>
          ) : (
            <label></label>
          )}
        </div>
        <div
          className="mdg-col data"
          title={
            typeof (gridRowData.first_name || gridRowData.last_name) !=
            "undefined"
              ? `${gridRowData.first_name} ${gridRowData.last_name}`
              : ""
          }
        >
          <label>
            {gridRowData.isLoading ? (
              <LoadingVisual />
            ) : typeof (gridRowData.first_name || gridRowData.last_name) !=
              "undefined" ? (
              `${gridRowData.first_name} ${gridRowData.last_name}`
            ) : (
              ""
            )}
          </label>
        </div>
        <div className="mdg-col data">
          {gridRowData.selectedType &&
          gridRowData.id &&
          (gridRowData.first_name || gridRowData.last_name) ? (
            <div
              className={
                gridRowData.amount !== "" && gridRowData.errorMessage.err_amount
                  ? "mdg-error-border-id"
                  : ""
              }
            >
              <TextInput
                value={gridRowData.amount}
                type="text"
                onChange={(event) => {
                  onChange({ amount: event.target.value });
                }}
              />
            </div>
          ) : (
            <label className="mdg-label-readonly">{gridRowData.amount}</label>
          )}
        </div>
        {!gridRowData.isAutoInserted && (
          <div className="mdg-actions">
            <ButtonWrapper
              onClick={() => {
                if (rowsCount === multipleDonorConfig.minRows) {
                  onClear();
                } else {
                  onDelete();
                }
              }}
            >
              <CloseGraphic>
                <CloseIcon />
              </CloseGraphic>
            </ButtonWrapper>
          </div>
        )}
      </div>
      {Object.entries(gridRowData.errorMessage).map(([err, value]) => {
        return (
          <div className="mdg-error" key={err}>
            {value}
          </div>
        );
      })}
    </div>
  );
};

export default MultipleDonorGridRow;
