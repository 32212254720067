import Select from "components/Form/components/Select";
import { useSelector } from "react-redux";
import { RootState } from "shared/state/store";
import { SelectOptions } from "shared/types";
import { getCountriesForDropdown } from "shared/utils";
import { fetchStatesByCountryId } from "shared/utils/api";
interface CountrySelectorProps {
  id: string;
  label: string;
  setDpmCountryError: (status: boolean, visibility: boolean) => void;
  setStateError: (status: boolean, visibility: boolean) => void;
  setDpmCountry: (country: string) => void;
  setStateValue: (state: string) => void;
  setStateList: (list: SelectOptions[] | []) => void;
  countryError: { status: boolean; visibility: boolean };
}

interface State {
  StateAbbreviation: string;
}

const CountrySelector = ({
  id,
  label,
  setDpmCountryError,
  setDpmCountry,
  setStateError,
  setStateValue,
  setStateList,
  countryError,
}: CountrySelectorProps) => {
  const { config } = useSelector((state: RootState) => state.app);

  return (
    <div>
      <Select
        id={id}
        label={label}
        options={config && getCountriesForDropdown(config?.countries)}
        onChange={async (item) => {
          setDpmCountry(item.value as string);
          setDpmCountryError(false, false);
          setStateError(false, false);
          setStateValue("");
          setStateList([]);
          if (["us", "ca"].indexOf(item.value as string) !== -1) {
            setStateError(true, true);
            fetchStatesByCountryId(
              item.countryID ? item.countryID.toString() : "",
            ).then((response) => {
              const stateListResponse = response?.data.payload; // Access the 'data' property
              const filtered = stateListResponse.map(function (state: State) {
                return {
                  label: state.StateAbbreviation,
                  value: state.StateAbbreviation,
                };
              });
              const sortedStates = filtered.sort(
                (a: SelectOptions, b: SelectOptions) =>
                  a.label > b.label ? 1 : b.label > a.label ? -1 : 0,
              );
              setStateList(sortedStates);
            });
          }
        }}
        required
        error={countryError.visibility && countryError.status}
        errorMessages={["This field is required."]}
      />
    </div>
  );
};

export default CountrySelector;
