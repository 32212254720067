import { useSelector } from "react-redux";
import Select from "components/Form/components/Select";
import useActions from "Hooks/useActions";
import { getCountries, getCountryName } from "shared/utils";
import { RootState } from "shared/state/store";
import { SelectOptions } from "shared/types";

const Country = () => {
  const { setCountryId } = useActions();
  const { countryId, countryName, donationType, config } = useSelector(
    (state: RootState) => state.app,
  );
  const setCountry = (event: SelectOptions) => {
    if (typeof event.value === "string") {
      config &&
        setCountryId(
          donationType, //hard coded for the timebeing
          config.appConfigs.recurringEnabled,
          config.currencies,
          config.countries,
          event.value,
          config.countryCurrency,
        );
    }
  };
  const options: SelectOptions[] | null =
    config &&
    getCountries(config.countries, config.countryCurrency).map((country) => ({
      label: getCountryName(country, "en"),
      value: country.id,
      country,
    }));
  const sortedOptions =
    options && options.sort((a, b) => a?.label?.localeCompare(b.label));
  return (
    <Select
      id={"Country"}
      label={"Select your country"}
      value={countryId}
      options={sortedOptions}
      onChange={(event: SelectOptions) => setCountry(event)}
      required
      placeholder={countryName}
    />
  );
};

export default Country;
