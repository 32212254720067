import React from "react";
import { styled } from "@mui/system";
import {
  bp,
  CustomButton,
  fontSize,
  stepHelpText,
  userExperienceMessage,
} from "shared/styles";
import { useSelector } from "react-redux";
import { RootState } from "shared/state/store";
import useActions from "Hooks/useActions";
import { getDpmCategories } from "shared/utils";

const UserActionsWrapper = styled("div")`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

const UserActionWrapper = styled("div")`
  flex: 1 1 100%;
  margin: 0 1rem 1rem;
  ${bp("sm")`
    display: flex;
    flex: 1 1 10em;
    flex-flow: row wrap;
  `} &:first-child {
    ${bp("sm")`
      justify-content: flex-end;
    `};
  }
`;

const UserAction = styled("div")`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
`;

interface UserActionButtonProps {
  primary?: boolean;
}

const UserActionButton = styled(CustomButton)<UserActionButtonProps>`
  &:not(:only-child) {
    margin-bottom: 0.25em;
  }
`;

const UserActionHelpText = styled("p")`
  ${fontSize(14)}
  line-height: 1.4;
  margin-bottom: 1em;
  max-width: 14rem;
  text-align: center;
`;

const StepHelpText = styled("div")`
  ${stepHelpText()}
`;

const Message = styled("div")`
  ${userExperienceMessage()}

  b {
    font-weight: bold;
  }
  a {
    color: #915d00;
  }

  a[href] {
    text-decoration: underline;
  }
`;

const UserActions = () => {
  const { setUser } = useActions();
  const { activeFundTab, mode, config, dpmBtnMode } = useSelector(
    (state: RootState) => state.app,
  );
  const lang = "en";
  let user = {
    authenticated: false,
    roles: {
      1: "anonymous user",
    },
  };
  const setContinueAsGuest = () => {
    setUser(user, true);
  };
  let tabs =
    mode === "dpm"
      ? config && getDpmCategories(config.categories, dpmBtnMode, mode)
      : config &&
        config.categories[lang].filter((cat) =>
          cat.content.filter((obj) => obj.mode === mode),
        );

  let activeTab =
    tabs?.find((tab) => tab.id === activeFundTab) || (tabs && tabs[0]);
  return (
    <UserActionsWrapper>
      <UserActionWrapper>
        <UserAction>
          <UserActionButton id="sign-in-initial" primary>
            Sign In
          </UserActionButton>
          <UserActionHelpText>
            Sign in to My Rotary to track your donations and receive recognition
          </UserActionHelpText>
        </UserAction>
      </UserActionWrapper>
      <UserActionWrapper>
        <UserAction>
          <UserActionButton
            id="guestContinue"
            onClick={
              activeTab && activeTab.content[0].component === "NamedFunds"
                ? (e) => {
                    const element = document.querySelector('[id="namedFunds"]');
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                    const errorMessageElement =
                      document.getElementById("errorMessage");
                    if (errorMessageElement) {
                      errorMessageElement.style.display = "block";
                    }
                  }
                : setContinueAsGuest
            }
          >
            Continue as a guest
          </UserActionButton>
          <UserActionHelpText>
            You can create an account after you donate
          </UserActionHelpText>
        </UserAction>
      </UserActionWrapper>
    </UserActionsWrapper>
  );
};

export default UserActions;
