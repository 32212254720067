import React, { ChangeEvent, useState, MouseEvent, useEffect } from "react";
import { useSelector } from "react-redux";
import { styled, css } from "@mui/system";
import { Box, Typography } from "@mui/material";
import useActions from "Hooks/useActions";
import { validateGrantNumber } from "shared/utils/inputValidation";
import {
  formatAmount,
  getItemsFromLocalStorage,
  removeKeysFromLocalStorage,
  setItemsInLocalStorage,
  setValueFromLocalStorage,
  updateErrorInLocalStorage,
} from "shared/utils";
import { color, CustomButton } from "shared/styles";
import TextInput from "components/Form/components/TextInput";
import { RootState } from "shared/state/store";
import { Config } from "shared/types";
import { fetchGlobalGrantInfo } from "shared/utils/api";
import LoadingThrobber from "components/LoadingThrobber";

interface SearchButtonProps {
  color: string;
  onClick: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  primary: boolean;
  shouldReset: boolean;
}

const dynamicStyle = (props: SearchButtonProps) => css`
  color: ${props.color};
`;

const GrantContainer = styled(Box)(({ theme }) => ({
  boxSizing: "border-box",
  marginLeft: "18px",
  marginRight: "18px",
}));

const GrantMsgText = styled(Box)({
  fontSize: 16,
  color: "#000000",
  fontFamily: "Open Sans",
  "& a": {
    color: "#005DAA",
    textDecoration: "underline",
    textUnderlineOffset: "1px",
    textDecorationThickness: "1px",
    "&:hover": {
      textDecorationColor: "#005DAA",
    },
  },
});

const SearchButton = styled(CustomButton)<SearchButtonProps>(({ theme }) => ({
  marginBottom: "40px",
  [theme.breakpoints.up("xs")]: {
    minWidth: "170px",
  },
  ...dynamicStyle,
}));

const GrantDetails = styled("div")({
  marginBottom: "30px",
});

const GrantName = styled(Typography)({
  borderBottom: `1px solid ${color.navy}`,
  color: color.navy,
  fontSize: "25px",
  fontWeight: "100",
  paddingBottom: "10px",
  display: "block",
});

const AmountNeeded = styled(Box)({
  display: "inline",
});

const AmountNeededContainer = styled(Box)({
  display: "block",
  marginBottom: "15px",
  marginTop: "15px",
  "& label": {
    fontWeight: 700,
    display: "inline",
  },
});

const HelpText = styled("div")(() => ({
  fontSize: 14,
}));

const GrantStatusMessageContainer = styled("div")({
  left: "0px",
  marginTop: "24px",
  marginBottom: "24px",
  background: "#fae1de",
});

const GrantStatusMessageBox = styled("div")({
  paddingTop: "25px",
  paddingLeft: "24px",
  paddingRight: "8px",
  paddingBottom: "25px",
});

const GrantStatusMsg = styled("div")(() => ({
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  color: "#bd0039",
}));

interface GrantsProps {
  config: Config;
}

const Grants = ({ config }: GrantsProps) => {
  const { setGrantsError, setGrantId, setDedicationVisibilityError } =
    useActions();
  const [grantIdInput, setGrantIdInput] = useState<string>("");
  const [grantName, setGrantName] = useState<string>("");
  const [grantStatus, setGrantStatus] = useState<string>("");
  const [amountRemaining, setAmountRemaining] = useState<string | null>(null);
  const [grantsErrorMessage, setGrantsErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const { grantsError } = useSelector((state: RootState) => state.error);
  const { grant } = useSelector((state: RootState) => state.app);

  const apiUrl = config.appConfigs.api_url;

  const setLocalStorageValueForGrants = (
    grantIdInputTrimmed: string,
    grantName: string,
    grantStatus: string,
    api_url: string,
    lang: string,
    amountRemaining: string | null,
    grantsErrorMessage: string,
  ) => {
    setItemsInLocalStorage("grantIdInput", grantIdInputTrimmed, "string");
    setItemsInLocalStorage("grantName", grantName, "string");
    setItemsInLocalStorage("grantStatus", grantStatus, "string");
    setItemsInLocalStorage("apiUrl", api_url, "string");
    setItemsInLocalStorage("amountRemaining", amountRemaining, "string");
    setItemsInLocalStorage("lang", lang, "string");
    setItemsInLocalStorage("grantsErrorMessage", grantsErrorMessage, "string");
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setItemsInLocalStorage("grantIdInput", event.target.value, "string");
    removeKeysFromLocalStorage(["grantsErrorMessage"]);
    setGrantIdInput(event.target.value);
  };

  const onSearchGrants = (grantIdInput: string) => {
    setLoading(true);
    const grantIdInputTrimmed = grantIdInput.trim();
    if (validateGrantNumber(grantIdInputTrimmed)) {
      const grantIdWithPrefix = `GG${grantIdInputTrimmed}`;
      fetchGlobalGrantInfo(grantIdWithPrefix)
        .then((response) => {
          setLoading(false);
          const grant = response.data.payload;
          if (
            grant.Type === "GG" &&
            (grant.Status === "Approved" ||
              grant.Status === "Draft" ||
              grant.Status === "Authorizations Required" ||
              grant.Status === "Submitted") &&
            !grant.Paid
          ) {
            const formattedAmountRemaining = formatAmount(
              grant.AmountRemaining.toString(),
              ",",
              ".",
            );
            setGrantId(grant.Id);
            setGrantName(grant.Name);
            setGrantStatus(grant.Status);
            setAmountRemaining(`${formattedAmountRemaining} USD`);
            setGrantsErrorMessage("");
            setGrantsError(false, false);
            updateErrorInLocalStorage("grantsError", false, false);
            setLocalStorageValueForGrants(
              grantIdInputTrimmed,
              grant.Name,
              grant.Status,
              apiUrl,
              // lang,
              "en",
              `${formattedAmountRemaining} USD`,
              "",
            );
          } else {
            setGrantId(grant.Id);
            setGrantName("");
            setGrantStatus("");
            setAmountRemaining(null);
            if (grant.Paid) {
              let error_message =
                "This grant project is fully funded and cannot accept donations. We encourage you to donate to another cause. ";

              setGrantsErrorMessage(error_message);
              setLocalStorageValueForGrants(
                grantIdInputTrimmed,
                "",
                "",
                "",
                "",
                null,
                error_message,
              );
            } else {
              let error_message =
                "This grant number is not recognized or the grant is not yet approved.";
              setGrantsErrorMessage(error_message);
              setLocalStorageValueForGrants(
                grantIdInputTrimmed,
                "",
                "",
                "",
                "",
                null,
                error_message,
              );
            }
            setGrantsError(true, true);
          }
        })
        .catch(() => {
          let error_message =
            "This grant number is not recognized or the grant is not yet approved.";
          setGrantId(grantIdInputTrimmed);
          setGrantName("");
          setGrantStatus("");
          setAmountRemaining(null);
          setGrantsErrorMessage(error_message);
          setLocalStorageValueForGrants(
            grantIdInputTrimmed,
            "",
            "",
            "",
            "",
            null,
            error_message,
          );
          setGrantsError(true, true);
          setLoading(false);
        });
    } else {
      let error_message = "Please enter a valid grant number";
      setGrantId(grantIdInputTrimmed);
      setGrantName("");
      setGrantStatus("");
      setAmountRemaining(null);
      setGrantsErrorMessage(error_message);
      setLocalStorageValueForGrants(
        grantIdInputTrimmed,
        "",
        "",
        "",
        "",
        null,
        error_message,
      );
      setGrantsError(true, true);
      setLoading(false);
    }
  };

  useEffect(() => {
    setDedicationVisibilityError(false, false);
    const grantIdInput = setValueFromLocalStorage("grantIdInput", "string", "");
    const grantName = getItemsFromLocalStorage("grantName", "string");
    if (grantIdInput.length > 0 && grantName) {
      onSearchGrants(grantIdInput);
    }

    return () => {
      setGrantsError(false, false);
      updateErrorInLocalStorage("grantsError", false, false);
      setItemsInLocalStorage("grantIdInput", "", "string");
      setGrantId(null);
    };
  }, []);
  return (
    <GrantContainer>
      <TextInput
        id="Grants"
        label="Seven-digit grant number"
        onChange={onInputChange}
        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            onSearchGrants(grantIdInput);
          }
        }}
        value={grantIdInput}
        error={grantsError.visibility && grantsError.status}
        errorMessages={[grantsErrorMessage]}
      />
      <SearchButton
        id="SearchButton"
        onClick={(e) => {
          e.preventDefault();
          onSearchGrants(grantIdInput);
        }}
        primary
        color="primary"
        shouldReset
      >
        Search
      </SearchButton>
      {loading && <LoadingThrobber forPurpose={"Search"}></LoadingThrobber>}
      {grantName && (
        <GrantDetails>
          <GrantName variant="inherit">{grantName}</GrantName>
          {(grantStatus === "Draft" ||
            grantStatus === "Authorizations Required" ||
            grantStatus === "Submitted") && (
            <GrantStatusMessageContainer>
              <GrantStatusMessageBox>
                <GrantStatusMsg>
                  {"This global grant application hasn’t yet been approved."}
                </GrantStatusMsg>
                <GrantMsgText
                  dangerouslySetInnerHTML={{
                    __html: `To ensure that your contribution will be credited to the correct project, please wait to donate until the application is approved. Refer to the <a href="${apiUrl}/en/document/terms-and-conditions-rotary-foundation-district-grants-and-global-grants">Terms and Conditions</a> for more information.`,
                  }}
                />
              </GrantStatusMessageBox>
            </GrantStatusMessageContainer>
          )}
          <AmountNeededContainer>
            <label htmlFor="AmountNeeded">{"Amount needed"}: </label>
            <AmountNeeded id="AmountNeeded">{amountRemaining}</AmountNeeded>
          </AmountNeededContainer>
          <HelpText>
            {
              "Note: The Rotary Foundation uses 5% of your contribution to help cover the cost of processing these funds. If you want your full pledged amount to be applied to the global grant, please add 5% to your contribution."
            }
          </HelpText>
        </GrantDetails>
      )}
    </GrantContainer>
  );
};
export default Grants;
