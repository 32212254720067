import { createSlice } from "@reduxjs/toolkit";
import {
  getFundById,
  getCountryById,
  getCurrencyById,
  getAmountsForCurrency,
  getFrequencyById,
  getCurrencyRecurringEnabled,
  setValueFromLocalStorage,
  setItemsInLocalStorage,
  removeKeysFromLocalStorage,
  updateErrorInLocalStorage,
  getItemsFromLocalStorage,
} from "shared/utils";
import {
  CategoriesByLanguage,
  Funds,
  Fund,
  Countries,
  CountryCurrencies,
  Currencies,
  Currency,
  Frequency,
  CurrencyAmounts,
  Config,
  SelectOptions,
  Country,
  Grant,
  UserInfo,
} from "../../types";
import recurringDisabledCurrencies from "shared/data/recurringDisabledCurrencies";
import {
  fetchConfig,
  fetchPaymentProfile,
  fetchPanStatus,
  fetchUserInfo,
} from "./thunks";

const getDefaultFund = (
  funds: Funds,
  categories: CategoriesByLanguage | undefined,
  mode?: string,
): Fund | null => {
  if (categories) {
    const defaultFundId =
      mode === "ep"
        ? categories["en"][0]?.content[1].defaultFund
        : categories["en"][0]?.content[0].defaultFund;
    return defaultFundId
      ? funds.find((f) => f.id === defaultFundId) || null
      : null;
  } else {
    return null;
  }
};

export const getDefaultCountry = (
  countries: Countries,
  countryCurrency: CountryCurrencies,
) => {
  const countryId = setValueFromLocalStorage(
    "countryId",
    "string",
    "1eedd8d4-41b6-449d-a00a-90928d4484d9",
  );
  return getCountryById(countries, countryId, countryCurrency);
};

export const getDefaultCurrency = (
  currencies: Currencies,
  countryId: string,
  recurringEnabled: boolean,
  donationType: string,
  countryCurrency: CountryCurrencies,
) => {
  const defaultMarkedCurrencyId = countryCurrency[countryId].default;
  const defaultMarkedCurrency = countryCurrency[countryId].list.find(
    (cur) => cur.currencyId === defaultMarkedCurrencyId,
  );
  const isCurrencyRecurringDisabled =
    defaultMarkedCurrency &&
    recurringDisabledCurrencies.includes(defaultMarkedCurrency.currencyId);
  let defaultCurrencyId = "";
  if (!defaultMarkedCurrencyId) {
    if (recurringEnabled && donationType === "recurring") {
      defaultCurrencyId = countryCurrency[countryId].list.filter(
        (c) => !recurringDisabledCurrencies.includes(c.currencyId),
      )[0].currencyId;
    } else {
      defaultCurrencyId = countryCurrency[countryId].list[0].currencyId;
    }
  } else {
    if (recurringEnabled && donationType === "recurring") {
      if (isCurrencyRecurringDisabled) {
        defaultCurrencyId = countryCurrency[countryId].list.filter(
          (c) => !recurringDisabledCurrencies.includes(c.currencyId),
        )[0].currencyId;
      } else {
        defaultCurrencyId = defaultMarkedCurrencyId;
      }
    } else {
      defaultCurrencyId = defaultMarkedCurrencyId;
    }
  }
  return getCurrencyById(currencies, defaultCurrencyId);
};

interface FrequencyTitle {
  annual: string;
  monthly: string;
  quarterly: string;
}

export const getDefaultAmount = (
  currency: Currency,
  frequency?: Frequency | null,
) => {
  const amountsForCurrency: CurrencyAmounts = getAmountsForCurrency(
    currency.id,
  );
  const seletedAmount = setValueFromLocalStorage("fixedAmount", "string", null);
  if (
    setValueFromLocalStorage("customAmountShown", "string", false) &&
    setValueFromLocalStorage("customAmount", "string", null)
  ) {
    return setValueFromLocalStorage("customAmount", "string", null);
  }
  if (!amountsForCurrency) {
    return 0;
  }

  if (!frequency) {
    if (
      amountsForCurrency.oneTime.options.some(
        (option) => option === seletedAmount,
      )
    ) {
      return seletedAmount;
    }
    return amountsForCurrency.oneTime.default;
  }

  const amountsForFrequency =
    amountsForCurrency.recurring[frequency.id as keyof FrequencyTitle];

  if (amountsForFrequency.options.some((option) => option === seletedAmount)) {
    return seletedAmount;
  }

  return amountsForFrequency.default;
};

export interface AppState {
  mode: string;
  dpmBtnMode: string;
  config: Config | null;
  loading: boolean;
  selectedFundUniqueId: number | null;
  fund: Fund | null;
  endowmentName: string;
  amount: number | null;
  customAmount: number | null;
  customAmountShown: boolean;
  donationType: string;
  country: Country | null;
  countryId: string;
  countryName: string;
  currency: Currency | null;
  currencyId: string;
  currencyName: string;
  countryOverridable: boolean;
  recurringFrequencyId: string | null;
  currencyRecurringEnabled: boolean;
  recurringInterval: string | null;
  fixedAmount: number | null;
  recurringStartDateDetails: {
    month: string | null;
    description: string | null;
  };
  clubCreditClubIDNumber: string;
  clubCreditClubType: string;
  grant: Grant | null;
  grantId: string | null;
  namedFundId: string | null;
  namedFundAliasDescription: string;
  trackedGiftId: string | null;
  trackedGiftFundId: string;
  trackedGiftSubFundId: string;
  trackedGiftAlias: string;
  trackedGiftAliasDescription: string;
  trackedGiftName: string;
  recognitionStateProvinceList: SelectOptions[] | [] | null;
  dedicationStateProvinceList: SelectOptions[] | [] | null;
  activeFundTab: string;
  paymentProfile: string;
  panStatus: boolean;
  userInfo: UserInfo | null;
  paymentWorkflowRedirect: boolean;
}

const getMode = () => {
  const mode = new URLSearchParams(window.location.search).get(
    "mode",
  ) as string;
  const index = [
    "giv",
    "ep",
    "dpm",
    "rotary-direct",
    "giving-tuesday",
    "drf",
    "ss",
  ].indexOf(mode);
  if (index < 0) {
    console.log("mode not exist");
    return "giv";
  }
  console.log(mode);
  return mode;
};

const initialStateApp: AppState = {
  mode: getMode(),
  dpmBtnMode: "", //To do: get dynamically
  config: null,
  loading: true,
  selectedFundUniqueId: null,
  fund: null,
  endowmentName: "Select",
  amount: null,
  fixedAmount: null,
  customAmount: null,
  customAmountShown: false,
  donationType: "one-time",
  country: null,
  countryId: "",
  countryName: "",
  currency: null,
  currencyId: "",
  currencyName: "",
  countryOverridable: true,
  recurringFrequencyId: null,
  currencyRecurringEnabled: true,
  recurringInterval: null,
  recurringStartDateDetails: {
    month: null,
    description: null,
  },
  clubCreditClubIDNumber: "",
  clubCreditClubType: "None",
  grant: null,
  grantId: null,
  namedFundId: null,
  namedFundAliasDescription: "",
  trackedGiftId: null,
  trackedGiftFundId: "",
  trackedGiftSubFundId: "",
  trackedGiftAlias: "",
  trackedGiftAliasDescription: "",
  trackedGiftName: "",
  recognitionStateProvinceList: null,
  dedicationStateProvinceList: null,
  activeFundTab: "",
  paymentProfile: "",
  panStatus: false,
  userInfo: null,
  paymentWorkflowRedirect: false,
};

const appSlice = createSlice({
  name: "app",
  initialState: initialStateApp,
  reducers: {
    setConfig: (state, action) => {
      const { config } = action.payload;
      const fund: Fund | null = getDefaultFund(
        config.funds,
        config.categories,
        config.appConfigs.mode,
      );
      const country = getDefaultCountry(
        config.countries,
        config.countryCurrency,
      );
      const currency = getDefaultCurrency(
        config.currencies,
        country.id,
        config.appConfigs.recurringEnabled,
        "one-time",
        config.countryCurrency,
      );
      const amount = getDefaultAmount(currency);
      state.config = config;
      state.selectedFundUniqueId = fund && fund.id;
      state.amount = amount;
      state.fixedAmount = amount;
      state.countryId = setValueFromLocalStorage(
        "countryId",
        "string",
        country.id,
      );
      state.countryName = country?.name.en || "";
      state.currency = currency;
      state.currencyId = setValueFromLocalStorage(
        "currencyId",
        "string",
        currency.id,
      );
      state.currencyName = currency?.name.en || "";
      state.fund = fund;
    },
    setActiveFundTab: (state, action) => {
      const { activeFundTab } = action.payload;
      setItemsInLocalStorage("activeFundTab", activeFundTab, "string");
      state.activeFundTab = activeFundTab;
    },
    setLoading: (state, action) => {
      const { loading } = action.payload;
      state.loading = loading;
    },
    setGrantId: (state, action) => {
      const { grantId } = action.payload;
      state.grantId = grantId;
    },
    setTrackedGiftId: (state, action) => {
      const { trackedGiftId } = action.payload;
      state.trackedGiftId = trackedGiftId;
    },
    setNamedFundId: (state, action) => {
      const { namedFundId } = action.payload;
      state.namedFundId = namedFundId;
    },
    setTrackedGiftFundId: (state, action) => {
      const { trackedGiftFundId } = action.payload;
      state.trackedGiftFundId = trackedGiftFundId;
    },
    setTrackedGiftSubFundId: (state, action) => {
      const { trackedGiftSubFundId } = action.payload;
      state.trackedGiftSubFundId = trackedGiftSubFundId;
    },
    setTrackedGiftAlias: (state, action) => {
      const { trackedGiftAlias } = action.payload;
      state.trackedGiftAlias = trackedGiftAlias;
    },
    setTrackedGiftAliasDescription: (state, action) => {
      const { trackedGiftAliasDescription } = action.payload;
      state.trackedGiftAliasDescription = trackedGiftAliasDescription;
    },
    setNamedFundAliasDescription: (state, action) => {
      const { namedFundAliasDescription } = action.payload;
      state.namedFundAliasDescription = namedFundAliasDescription;
    },
    setAmountToDefault: (state, action) => {
      const { currencies } = action.payload;
      const currency = getCurrencyById(currencies, state.currencyId);
      const frequency =
        state.recurringFrequencyId &&
        getFrequencyById(state.recurringFrequencyId);
      const defaultAmount = getDefaultAmount(currency, frequency as Frequency);
      state.amount = defaultAmount;
      state.customAmount = null;
      state.customAmountShown = false;
      state.fixedAmount = defaultAmount;
    },
    setAmount: (state, action) => {
      const { amount } = action.payload;
      state.amount = amount;
    },
    setFixedAmount: (state, action) => {
      const { fixedAmount } = action.payload;
      setItemsInLocalStorage("fixedAmount", fixedAmount, "string");
      removeKeysFromLocalStorage(["customAmount", "customAmountShown"]);
      state.amount = fixedAmount;
      state.customAmount = null;
      state.customAmountShown = false;
      state.fixedAmount = fixedAmount;
    },
    setCustomAmount: (state, action) => {
      const { customAmount } = action.payload;
      setItemsInLocalStorage("customAmount", customAmount, "string");
      if (!customAmount.length) {
        updateErrorInLocalStorage("amountError", true, true);
      }
      removeKeysFromLocalStorage(["fixedAmount"]);
      state.customAmount = customAmount;
      state.amount = isNaN(customAmount) ? 0 : customAmount || null;
      state.customAmountShown = true;
      state.fixedAmount = null;
    },
    showCustomAmount: (state) => {
      setItemsInLocalStorage("customAmountShown", true, "json");
      setItemsInLocalStorage("fixedAmount", "0", "string");
      removeKeysFromLocalStorage(["customAmount", "amount"]);
      state.amount = null;
      state.customAmount = null;
      state.customAmountShown = true;
      state.fixedAmount = null;
    },
    setSelectedFundUniqueId: (state, action) => {
      const { selectedFundUniqueId, funds } = action.payload;
      const fund =
        funds !== null ? getFundById(funds, selectedFundUniqueId) : null;
      setItemsInLocalStorage("selectedFund", fund, "json");
      state.selectedFundUniqueId = selectedFundUniqueId;
      state.donationType = fund?.recurring ? state.donationType : "one-time";
      state.fund = fund;
      state.grantId = null;
    },
    setCountryId: (state, action) => {
      const {
        countryId,
        donationType,
        recurringEnabled,
        currencies,
        countryCurrency,
        countries,
      } = action.payload;
      const frequency =
        state.recurringFrequencyId &&
        getFrequencyById(state.recurringFrequencyId);
      const currency = getDefaultCurrency(
        currencies,
        countryId,
        recurringEnabled,
        donationType,
        countryCurrency,
      );
      const defaultAmount = getDefaultAmount(currency, frequency as Frequency);
      const country = getCountryById(countries, countryId, countryCurrency);
      const currencyRecurringEnabled = getCurrencyRecurringEnabled(
        country,
        currency,
        countryCurrency,
      );
      if (getItemsFromLocalStorage("retain", "json")) {
        const activeFund = getItemsFromLocalStorage("activeFundTab", "string");
        if (
          activeFund !== "3" &&
          document.getElementById("fixed-amount-label")
        ) {
          const label = document.getElementById("fixed-amount-label");
          label && label.scrollIntoView();
        }
      }
      localStorage.setItem("countryId", countryId);
      localStorage.setItem("currencyId", currency.id);
      setItemsInLocalStorage("fixedAmount", defaultAmount, "string");
      setItemsInLocalStorage(
        "currencyRecurringEnabled",
        currencyRecurringEnabled,
        "json",
      );

      state.countryId = countryId;
      state.country = country;
      state.currency = currency; // is this needed?
      state.countryName = country.name.en;
      state.currencyId = currency.id;
      state.currencyName = currency.name.en;
      state.fixedAmount = defaultAmount;
      state.amount = defaultAmount;
      state.customAmount = null;
      state.customAmountShown = false;
      state.currencyRecurringEnabled = currencyRecurringEnabled;
    },
    setCurrencyId: (state, action) => {
      const { currencyId, countries, countryCurrency, currencies } =
        action.payload;
      const currency = getCurrencyById(currencies, currencyId);
      const country = getCountryById(
        countries,
        state.countryId,
        countryCurrency,
      );
      const currencyRecurringEnabled = getCurrencyRecurringEnabled(
        country,
        currency,
        countryCurrency,
      );
      const frequency =
        state.recurringFrequencyId &&
        getFrequencyById(state.recurringFrequencyId);
      const defaultAmount = getDefaultAmount(currency, frequency as Frequency);
      localStorage.setItem("currencyId", currencyId);

      setItemsInLocalStorage("fixedAmount", defaultAmount, "string");
      setItemsInLocalStorage("amount", defaultAmount, "string");
      setItemsInLocalStorage(
        "currencyRecurringEnabled",
        currencyRecurringEnabled,
        "json",
      );

      state.currency = currency;
      state.currencyId = currencyId;
      state.currencyName = currency.name.en;
      state.fixedAmount = defaultAmount;
      state.amount = defaultAmount;
      state.customAmount = null;
      state.customAmountShown = false;
      state.currencyRecurringEnabled = currencyRecurringEnabled;
    },
    setDonationType: (state, action) => {
      const { donationType, currencies } = action.payload;
      setItemsInLocalStorage("fixedAmount", "", "string");
      const currency = getCurrencyById(currencies, state.currencyId);
      const defaultAmount = getDefaultAmount(currency);
      state.donationType = donationType;
      state.recurringFrequencyId = "";
      state.amount = defaultAmount;
      state.fixedAmount = defaultAmount;
      state.customAmount = null;
      state.customAmountShown = false;
    },
    setRecurringFrequencyId: (state, action) => {
      const { frequencyId } = action.payload || state.recurringFrequencyId;
      const { currencies } = action.payload;
      const frequency = frequencyId && getFrequencyById(frequencyId);
      const currency = getCurrencyById(currencies, state.currencyId);
      const defaultAmount = getDefaultAmount(currency, frequency);

      setItemsInLocalStorage("frequency", frequency, "json");
      setItemsInLocalStorage("fixedAmount", defaultAmount, "string");
      removeKeysFromLocalStorage(["customAmount"]);
      setItemsInLocalStorage("customAmountShown", false, "json");
      updateErrorInLocalStorage("frequencyError", false, false);
      updateErrorInLocalStorage("amountError", false, false);

      state.recurringFrequencyId = frequencyId;
      state.amount = defaultAmount;
      state.fixedAmount = defaultAmount;
      state.customAmount = null;
      state.customAmountShown = false;
    },
    setRecurringInterval: (state, action) => {
      const { interval } = action.payload;
      setItemsInLocalStorage("interval", interval, "string");
      state.recurringInterval = interval;
    },
    setRecurringStartDate: (state, action) => {
      const { month, description } = action.payload;
      const recurringStartDateDetails = setValueFromLocalStorage(
        "recurringStartDateDetails",
        "json",
        {
          month: month,
          description: action.payload.description,
        },
      );
      setItemsInLocalStorage(
        "recurringStartDateDetails",
        recurringStartDateDetails,
        "json",
      );

      state.recurringStartDateDetails = {
        month: month,
        description: description,
      };
    },
    setClubCreditClubIDNumber: (state, action) => {
      const { clubCreditClubIDNumber } = action.payload;
      state.clubCreditClubIDNumber = clubCreditClubIDNumber;
    },
    setClubCreditClubType: (state, action) => {
      const { clubCreditClubType } = action.payload;
      state.clubCreditClubType = clubCreditClubType;
    },
    setEndowmentName: (state, action) => {
      const { endowmentName } = action.payload;
      state.endowmentName = endowmentName;
    },
    setRecognitionStateProvinceList: (state, action) => {
      const { recognitionStateProvinceList } = action.payload;
      state.recognitionStateProvinceList = recognitionStateProvinceList;
    },
    setDedicationStateProvinceList: (state, action) => {
      const { dedicationStateProvinceList } = action.payload;
      state.dedicationStateProvinceList = dedicationStateProvinceList;
    },
    setPaymentWorkflowRedirect: (state, action) => {
      const { paymentWorkflowRedirect } = action.payload;
      state.paymentWorkflowRedirect = paymentWorkflowRedirect;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfig.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchConfig.fulfilled, (state, action) => {
        const config = action.payload?.data;
        const fund: Fund | null = getDefaultFund(
          config.funds,
          config.categories,
          config.appConfigs.mode,
        );
        const country = getDefaultCountry(
          config.countries,
          config.countryCurrency,
        );
        const currency = getDefaultCurrency(
          config.currencies,
          country.id,
          config.appConfigs.recurringEnabled,
          "one-time",
          config.countryCurrency,
        );
        const amount = getDefaultAmount(currency);

        state.config = config;
        state.activeFundTab = config.categories.en[0].id;
        state.selectedFundUniqueId = fund && fund.id;
        state.fund = fund;
        state.amount = amount;
        state.fixedAmount = amount;
        state.country = country;
        state.countryId = country?.id || "";
        state.countryName = country?.name.en || "";
        state.currency = currency;
        state.currencyId = currency?.id || "";
        state.currencyName = currency?.name.en || "";
        state.loading = false;
      })
      .addCase(fetchPaymentProfile.pending, (state, action) => {})
      .addCase(fetchPaymentProfile.fulfilled, (state, action) => {
        const PaymentProfileName = action.payload?.data;
        global.localStorage.setItem("paymentProfile", PaymentProfileName);
        global.localStorage.setItem("paymentProfileName", PaymentProfileName);
        state.paymentProfile = PaymentProfileName;
      })
      .addCase(fetchPanStatus.pending, (state, action) => {})
      .addCase(fetchPanStatus.fulfilled, (state, action) => {
        const PanStatus = action.payload?.data;
        state.panStatus = PanStatus;
      })
      .addCase(fetchUserInfo.pending, (state, action) => {})
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        const UserInfo = action.payload?.data;
        state.userInfo = UserInfo;
      });
  },
});

export const { setSelectedFundUniqueId } = appSlice.actions;

export const createSetConfig = (config: Config) => ({
  type: "app/setConfig",
  payload: { config },
});

export const createSetActiveFundTab = (activeFundTab: string) => {
  return {
    type: "app/setActiveFundTab",
    payload: {
      activeFundTab,
    },
  };
};

export const createSetLoading = (loading: boolean) => ({
  type: "app/setLoading",
  payload: { loading },
});

export const createSetSelectedFundUniqueId = (
  id: number | null,
  funds: Funds | null,
) => ({
  type: "app/setSelectedFundUniqueId",
  payload: { selectedFundUniqueId: id, funds },
});

export const createSetGrantId = (grantId: string | null) => ({
  type: "app/setGrantId",
  payload: { grantId },
});

export const createSetTrackedGiftId = (trackedGiftId: string) => ({
  type: "app/setTrackedGiftId",
  payload: { trackedGiftId },
});

export const createSetNamedFundId = (namedFundId: string | null) => ({
  type: "app/setNamedFundId",
  payload: { namedFundId },
});

export const createSetTrackedGiftFundId = (trackedGiftFundId: string) => ({
  type: "app/setTrackedGiftFundId",
  payload: { trackedGiftFundId },
});

export const createSetTrackedGiftSubFundId = (
  trackedGiftSubFundId: string,
) => ({
  type: "app/setTrackedGiftSubFundId",
  payload: { trackedGiftSubFundId },
});

export const createSetTrackedGiftAlias = (trackedGiftAlias: string) => ({
  type: "app/setTrackedGiftAlias",
  payload: { trackedGiftAlias },
});

export const createSetTrackedGiftAliasDescription = (
  trackedGiftAliasDescription: string,
) => ({
  type: "app/setTrackedGiftAliasDescription",
  payload: { trackedGiftAliasDescription },
});
export const createSetNamedFundAliasDescription = (
  namedFundAliasDescription: string,
) => ({
  type: "app/setNamedFundAliasDescription",
  payload: { namedFundAliasDescription },
});

export const createSetTrackedGiftName = (trackedGiftName: string) => ({
  type: "app/setTrackedGiftName",
  payload: { trackedGiftName },
});

export const createSetAmount = (amount: number) => ({
  type: "app/setAmount",
  payload: { amount: amount },
});

export const createSetAmountToDefault = (currencies: Currencies) => ({
  type: "app/setAmountToDefault",
  payload: { currencies },
});

export const createSetFixedAmount = (amount: number) => ({
  type: "app/setFixedAmount",
  payload: { fixedAmount: amount },
});

export const createSetCustomAmount = (amount: number) => ({
  type: "app/setCustomAmount",
  payload: { customAmount: amount },
});

export const createSetShowCustomAmount = () => ({
  type: "app/showCustomAmount",
});

export const createSetCountryId = (
  donationType: string,
  recurringEnabled: boolean,
  currencies: Currencies,
  countries: Countries,
  id: string,
  countryCurrency: CountryCurrencies,
) => {
  return {
    type: "app/setCountryId",
    payload: {
      currencies,
      countries,
      countryId: id,
      recurringEnabled,
      donationType,
      countryCurrency,
    },
  };
};

export const createSetCurrencyId = (
  currencies: Currencies,
  countries: Countries,
  id: string,
  countryCurrency: CountryCurrencies,
) => {
  return {
    type: "app/setCurrencyId",
    payload: { currencies, countries, currencyId: id, countryCurrency },
  };
};

export const createSetDonationType = (
  currencies: Currencies | null,
  donationType: string,
) => ({
  type: "app/setDonationType",
  payload: { currencies, donationType: donationType },
});

export const createSetRecurringFrequency = (
  currencies: Currencies,
  frequency?: Frequency,
) => ({
  type: "app/setRecurringFrequencyId",
  payload: { currencies, frequencyId: frequency?.id },
});

export const createSetRecurringInterval = (interval?: string | null) => ({
  type: "app/setRecurringInterval",
  payload: { interval },
});

export const createSetRecurringStartDateDetails = (
  month: string | null,
  description: string | null,
) => ({ type: "app/setRecurringStartDate", payload: { month, description } });

export const createSetClubIDNumber = (clubCreditClubIDNumber: string) => ({
  type: "app/setClubCreditClubIDNumber",
  payload: { clubCreditClubIDNumber },
});

export const createSetClubCreditClubType = (clubCreditClubType: string) => ({
  type: "app/setClubCreditClubType",
  payload: { clubCreditClubType },
});

export const createSetEndowmentName = (endowmentName: string) => ({
  type: "app/setEndowmentName",
  payload: { endowmentName },
});

export const createSetRecognitionStateProvinceList = (
  recognitionStateProvinceList: SelectOptions[] | [],
) => ({
  type: "app/setRecognitionStateProvinceList",
  payload: { recognitionStateProvinceList },
});

export const createSetDedicationStateProvinceList = (
  dedicationStateProvinceList: SelectOptions[] | [],
) => ({
  type: "app/setDedicationStateProvinceList",
  payload: { dedicationStateProvinceList },
});

export const createSetPaymentWorkflowRedirectAction = (
  isRedirect: boolean,
) => ({
  type: "app/setPaymentWorkflowRedirect",
  payload: { paymentWorkflowRedirect: isRedirect },
});

export default appSlice.reducer;
