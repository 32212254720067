export const typeOptions = [
  { label: "District", value: "District" },
  { label: "RI", value: "RI" },
  { label: "Rotary Club", value: "Rotary Club" },
  { label: "Rotaract Club", value: "Rotaract Club" },
];

export const validators = {
  isValidId: (id: string) => {
    return /^[1-9][0-9]{0,11}$/g.test(id) == true;
  },
};

export const typeMappingWithId: { [key: string]: number } = {
  District: 7,
  RI: 9,
  "Rotary Club": 3,
  "Rotaract Club": 4,
};
