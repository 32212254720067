import { styled } from "@mui/system";
import { Typography } from "@mui/material";
import { color } from "shared/styles";

const HeaderTextContainer = styled(Typography)(({ theme }) => ({
  fontSize: 28,
  color: color.white,
  fontWeight: 700,
  backgroundColor: color.navy,
  lineHeight: "1.875rem",
  padding: "6px 16px 9px 16px",
  [theme.breakpoints.down("sm")]: {
    fontSize: 20,
    lineHeight: "1.5rem",
  },
}));

interface ModuleHeaderProps {
  headerText: string;
}

const ModuleHeader = ({ headerText }: ModuleHeaderProps) => {
  return <HeaderTextContainer>{headerText}</HeaderTextContainer>;
};

export default ModuleHeader;
