import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import { getMemberSubscription } from "shared/utils/api";
import { useSelector } from "react-redux";
import { RootState } from "shared/state/store";

const MyDonationsInfoContainer = styled("div")`
  margin: 2em 0;

  border: 1px solid #d1dae1;
  background-color: #ebf4f8;
  box-sizing: border-box;
  div {
    a {
      color: #005daa;
      text-decoration: underline !important;
      text-underline-offset: 3px;
      text-decoration-thickness: 1px;
    }
  }
`;
const DonationsInfoText = styled("div")`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #19519c;
  padding: 20px;
`;

const MyDonationLink = () => {
  const [isUserActiveSubscription, setIsUserActiveSubscription] =
    useState(false);
  const { donationType, config } = useSelector((state: RootState) => state.app);
  const isAuthenticated = true;
  const subscriptionEdit = false;
  const getActiveSubscription = (id: string) => {
    getMemberSubscription(id).then((data) => {
      if (data.data.length > 0) {
        setIsUserActiveSubscription(true);
      } else {
        setIsUserActiveSubscription(false);
      }
    });
  };

  useEffect(() => {
    getActiveSubscription("6638991");
  }, []);

  return (
    <div>
      {isAuthenticated &&
        isUserActiveSubscription &&
        donationType === "recurring" &&
        !subscriptionEdit && (
          <MyDonationsInfoContainer>
            <DonationsInfoText role="main">
              If you have an existing recurring donation and would like to
              update it, please{" "}
              <a
                href={`${
                  config && config.appConfigs.webAppBaseUrl
                }/en/user/donations/management`}
                target="_blank"
              >
                sign in and edit in My Donations
              </a>
              .
            </DonationsInfoText>
          </MyDonationsInfoContainer>
        )}
      {!isAuthenticated && donationType === "recurring" && (
        <DonationsInfoText role="main">
          If you have an existing recurring donation and would like to update
          it, please{" "}
          <a
            href={`${config && config.appConfigs.webAppBaseUrl}/en/user/donations/management`}
            target="_blank"
          >
            sign in and edit in My Donations
          </a>
          .
        </DonationsInfoText>
      )}
    </div>
  );
};

export default MyDonationLink;
