import {
  Currencies,
  Currency,
  PayInvoiceListObject,
  Rate,
  Rates,
} from "shared/types";
import { getExchangeRate } from "shared/utils";

interface InvoiceData {
  amount: string;
}

interface OpenItem {
  checked: boolean;
  amount: string;
  itemNumber: string;
}

interface ItemNumberObject {
  "Item number": string;
  [key: string]: string;
}

interface OpenItemNumberObjectResult {
  listArray: ItemNumberObject[];
  sum: number;
}

export function calculateBalanceAmount(gridData: InvoiceData[]) {
  let balance = 0;
  gridData.forEach((invoice: InvoiceData) => {
    let amount = invoice.amount.replace(/,/g, ".");
    balance += parseFloat(amount);
  });
  return balance.toFixed(2);
}

export function openItemNumberObject(
  openItemsArray: OpenItem[],
): OpenItemNumberObjectResult {
  let sum = 0;
  const listArray: ItemNumberObject[] = openItemsArray.reduce(
    (filtered: ItemNumberObject[], openItem) => {
      if (openItem.checked) {
        sum += parseFloat(openItem.amount);
        const itemNumberObject: ItemNumberObject = {
          "Item number": openItem.itemNumber,
        };
        filtered.push(itemNumberObject);
      }
      return filtered;
    },
    [],
  );
  return { listArray, sum };
}

export function exchangedAmount(
  currencies: Currencies,
  invoiceExchangeRate: Rates,
  fromCurrency: string,
  toCurrency: Currency,
  sumAmount: number,
) {
  if (sumAmount <= 0) return "";
  else if (fromCurrency.toLowerCase() === toCurrency.code.toLowerCase()) {
    return sumAmount.toString();
  } else {
    let fromCurrencyCode =
      typeof fromCurrency === "string" ? { code: fromCurrency } : fromCurrency;
    const rateTo = getExchangeRate(
      currencies,
      invoiceExchangeRate,
      fromCurrencyCode,
      toCurrency,
    );
    return (Math.round(Number(rateTo) * sumAmount * 100) / 100).toString();
  }
}

export function createInvoiceExchangeRateObject(
  billingToUsd: string,
  usdToBilling: string,
  billingCurrency: string,
) {
  const rates: Rate[] = [
    {
      FromCurrency: billingCurrency,
      ToCurrency: "USD",
      RateMultiplier: billingToUsd,
      RateDivisor: "1",
    },
    {
      FromCurrency: "USD",
      ToCurrency: billingCurrency,
      RateMultiplier: usdToBilling,
      RateDivisor: "1",
    },
  ];
  return rates;
}

export function getPayInvoiceOfficialLicenseeList(
  list: PayInvoiceListObject[],
  billingToUsdRate: string,
) {
  const OlList = list.map((item: PayInvoiceListObject) => {
    const amount = {
      amount: String(
        Math.round(
          parseFloat(billingToUsdRate) * parseFloat(item.amount) * 100,
        ) / 100,
      ),
    };
    return { ...item, ...amount };
  });
  return OlList;
}
