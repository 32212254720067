import Checkbox from "components/Form/components/Checkbox";
import useActions from "Hooks/useActions";
import { useSelector } from "react-redux";
import React from "react";
import { RootState } from "shared/state/store";

const MultipleDonorCheckbox = () => {
  const { multipleDonorVisibility } = useSelector(
    (state: RootState) => state.staffUseOnly,
  );
  const { multipleDonorError } = useSelector((state: RootState) => state.error);
  const { toggleMultipleDonorVisibility, setMultipleDonorError } = useActions();
  return (
    <Checkbox
      id="MultipleDonor"
      label="This donation is from multiple donors"
      checked={multipleDonorVisibility}
      onChange={() => {
        if (multipleDonorVisibility) {
          setMultipleDonorError(false, false);
        }
        toggleMultipleDonorVisibility();
      }}
      errorMsg={
        multipleDonorError.status && multipleDonorError.visibility
          ? "Error for multiple donors"
          : undefined
      }
      outSideContainer={true}
    />
  );
};

export default MultipleDonorCheckbox;
