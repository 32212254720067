import { createSlice } from "@reduxjs/toolkit";

export interface RaiseNowState {
  customerToken: string | null;
  scriptErrorStatus: boolean;
  subscriptionId: string | null;
  validationErrors: string[] | null;
  widgetIsLoaded: boolean;
  widgetIsLoading: boolean;
}

const initialStateRaiseNow: RaiseNowState = {
  customerToken: null,
  scriptErrorStatus: false,
  subscriptionId: null,
  validationErrors: null,
  widgetIsLoaded: false,
  widgetIsLoading: false,
};

const raiseNowSlice = createSlice({
  name: "raiseNow",
  initialState: initialStateRaiseNow,
  reducers: {
    setValidationErrors: (state, action) => {
      state.validationErrors = action.payload.validationErrors;
    },
    setWidgetIsLoaded: (state, action) => {
      const { widgetIsLoaded } = action.payload;
      state.widgetIsLoading = widgetIsLoaded ? false : state.widgetIsLoading;
      state.widgetIsLoaded = widgetIsLoaded;
    },
    setWidgetIsLoading: (state, action) => {
      const { widgetIsLoading } = action.payload;
      state.widgetIsLoaded = widgetIsLoading ? false : state.widgetIsLoaded;
      state.widgetIsLoading = widgetIsLoading;
    },
    setScriptErrorStatus: (state, action) => {
      state.scriptErrorStatus = action.payload.scriptErrorStatus;
    },
    setSubscriptionId: (state, action) => {
      state.subscriptionId = action.payload.subscriptionId;
    },
    setCustomerToken: (state, action) => {
      state.customerToken = action.payload.customerToken;
    },
  },
});

export const {
  setValidationErrors,
  setWidgetIsLoaded,
  setWidgetIsLoading,
  setScriptErrorStatus,
  setSubscriptionId,
  setCustomerToken,
} = raiseNowSlice.actions;

export const createSetRaiseNowValidationErrors = (
  validationErrors: string[] = [],
) => ({
  type: "raiseNow/setValidationErrors",
  payload: { validationErrors },
});

export const createSetRaiseNowWidgetIsLoaded = (widgetIsLoaded: boolean) => ({
  type: "raiseNow/setWidgetIsLoaded",
  payload: { widgetIsLoaded },
});

export const createSetRaiseNowWidgetIsLoading = (
  widgetIsLoading: boolean = false,
) => ({
  type: "raiseNow/setWidgetIsLoading",
  payload: { widgetIsLoading },
});

export const createSetRaiseNowScriptErrorStatus = (
  scriptErrorStatus: boolean = false,
) => ({
  type: "raiseNow/setScriptErrorStatus",
  payload: { scriptErrorStatus },
});

export const createSetRaiseNowSubscriptionId = (
  subscriptionId: string | null = null,
) => ({
  type: "raiseNow/setSubscriptionId",
  payload: { subscriptionId },
});

export const createSetRaiseNowCustomerToken = (
  customerToken: string | null = null,
) => ({
  type: "raiseNow/setCustomerToken",
  payload: { customerToken },
});

export default raiseNowSlice.reducer;
