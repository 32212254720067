import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { color, popupCloseButton, pxToRem } from "shared/styles";
import selected from "shared/assets/selected.svg";
import unselected from "shared/assets/unselected.svg";

interface CalloutBtnWrapperProps {
  isSelected?: boolean;
  isLastItem?: boolean;
  isMiddleBottom?: boolean;
  minHeight: string;
  isSubtitleVisible?: boolean;
}

interface CalloutBtnProps {
  isSelected?: boolean;
  isSubtitleVisible?: boolean;
  onClickCalloutBtn?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onClickSubtitle?: (event: React.MouseEvent<HTMLInputElement>) => void;
  subtitle?: string | null;
  subtitleLabel?: string;
  title?: string | null;
  minHeight?: string;
  isLastItem?: boolean;
  isMiddleBottom?: boolean;
}

interface CalloutBtnPrimaryProps {
  isSubtitleVisible?: boolean;
}

interface CalloutBtnTitleProps {
  isSelected?: boolean;
}

interface CalloutBtnSubtitleBtnProps {
  isSelected?: boolean;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  isSubtitleVisible?: boolean;
}

interface CalloutSubtitleModalProps {
  isSubtitleVisible?: boolean;
  isLastItem?: boolean;
  isMiddleBottom?: boolean;
  minHeight: string;
}

const CalloutBtnWrapper = styled(Box)<CalloutBtnWrapperProps>(
  ({
    isSelected,
    isLastItem,
    isMiddleBottom,
    minHeight,
    isSubtitleVisible,
    theme,
  }) => ({
    border: isSubtitleVisible ? "none" : `1px solid ${color.navy}`,
    cursor: "pointer",
    display: "flex",
    fontSize: 14,
    overflow: "hidden",
    position: "relative",
    textAlign: "center",
    boxSizing: "border-box",
    width: isMiddleBottom && isLastItem ? "45%" : "100%",
    minHeight: minHeight,
    backgroundSize: "auto 20px",
    backgroundImage: isSelected ? `url(${selected})` : `url(${unselected})`,
    backgroundPosition: isSelected ? "calc(50% + 2px) 34px" : "center 34px",
    backgroundRepeat: "no-repeat",
    backgroundColor: isSelected ? color.blue : color.white,
    margin: "0 0.9rem 1.875rem 0.9rem",
    marginLeft: isMiddleBottom && isLastItem ? "auto" : "0.9rem",
    marginRight: isMiddleBottom && isLastItem ? "auto" : "0.9rem",
    [theme.breakpoints.down("sm")]: {
      boxSizing: "border-box",
      width: "100%",
      margin: "0 0 1.875rem 0",
    },
  }),
);

const CalloutBtnPrimary = styled(Box)<CalloutBtnPrimaryProps>(
  ({ isSubtitleVisible, theme }) => ({
    display: !isSubtitleVisible ? "block" : "none",
    height: "100%",
    maxHeight: `158px`,
    padding: pxToRem(20),
    boxSizing: "border-box",
    margin: "auto",

    [theme.breakpoints.down("sm")]: {
      padding: pxToRem(50),
    },
  }),
);

const CalloutBtnTitle = styled(Box)<CalloutBtnTitleProps>(
  ({ isSelected, theme }) => ({
    color: isSelected ? color.white : color.black,
    fontWeight: 700,
    fontSize: 16,
    marginTop: pxToRem(50),
    [theme.breakpoints.down("sm")]: {
      marginTop: pxToRem(10),
    },
  }),
);

const CalloutBtnSubtitleBtn = styled(Box)<CalloutBtnSubtitleBtnProps>(
  ({ isSelected, isSubtitleVisible }) => ({
    backgroundColor: isSelected ? color.blueOverlay : color.lightGrey,
    bottom: 0,
    left: 0,
    color: isSelected ? color.white : color.navy,
    padding: pxToRem(5),
    position: "absolute",
    display: isSubtitleVisible ? "none" : "block",
    width: "100%",
    "&:hover": {
      backgroundColor: isSelected ? color.darkBlueOverlay : color.midGrey,
    },
  }),
);

const CalloutSubtitleModal = styled(Box)<CalloutSubtitleModalProps>(
  ({ isSubtitleVisible, isLastItem, isMiddleBottom, minHeight, theme }) => ({
    display: isSubtitleVisible ? "block" : "none",
    backfaceVisibility: "hidden",
    backgroundSize: "0.8em",
    fontSize: 14,
    width: "100%",
    color: color.black,
    backgroundColor: color.white,
    height: "100%",
    left: "0",
    lineHeight: "160%",
    minHeight: "100%",
    overflow: "hidden",
    padding: pxToRem(30),
    position: isSubtitleVisible ? "relative" : "absolute",
    textAlign: "left",
    transition: "all 0.3s ease-in-out",
    boxSizing: "border-box",
    "&:active, &:hover": {
      backgroundPosition: "top 8px right 8px",
      backgroundSize: "0.8em",
    },
    ...(isLastItem &&
      isMiddleBottom && {
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
      }),
    [theme.breakpoints.down("sm")]: {
      boxSizing: "border-box",
      minHeight: minHeight,
    },
  }),
);

const CalloutSubtitleModalClose = styled(Box)({
  ...popupCloseButton("blue"),
});

const CalloutBtn = ({
  isSelected,
  isSubtitleVisible,
  onClickCalloutBtn,
  subtitle,
  subtitleLabel = "Learn more",
  title,
  onClickSubtitle,
  isLastItem,
  isMiddleBottom,
  minHeight = "160px",
}: CalloutBtnProps) => {
  return (
    <CalloutBtnWrapper
      isSelected={isSelected}
      onClick={onClickCalloutBtn}
      isLastItem={isLastItem}
      isMiddleBottom={isMiddleBottom}
      minHeight={minHeight}
      isSubtitleVisible={isSubtitleVisible}
      className="callout__button"
    >
      <CalloutBtnPrimary isSubtitleVisible={isSubtitleVisible}>
        <CalloutBtnTitle isSelected={isSelected}>{title}</CalloutBtnTitle>
      </CalloutBtnPrimary>
      {!!subtitle && (
        <CalloutBtnSubtitleBtn
          isSelected={isSelected}
          onClick={onClickSubtitle}
          isSubtitleVisible={isSubtitleVisible}
        >
          {subtitleLabel}
        </CalloutBtnSubtitleBtn>
      )}
      {!!subtitle && (
        <CalloutSubtitleModal
          onClick={onClickSubtitle}
          isSubtitleVisible={isSubtitleVisible}
          isLastItem={isLastItem}
          isMiddleBottom={isMiddleBottom}
          minHeight={minHeight}
        >
          <CalloutSubtitleModalClose />
          {subtitle}
        </CalloutSubtitleModal>
      )}
    </CalloutBtnWrapper>
  );
};

export default CalloutBtn;
