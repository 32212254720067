import React from "react";
import { useSelector } from "react-redux";
import { Tab, Tabs, Box } from "@mui/material";
import { color } from "shared/styles";
import useActions from "Hooks/useActions";
import { Category } from "shared/types";
import { RootState } from "shared/state/store";
import { getItemsFromLocalStorage } from "shared/utils";

interface TabBarProps {
  tabs: Category[] | null;
}

interface TabsProp {
  id: string;
  title: string;
}

const TabBar = ({ tabs }: TabBarProps) => {
  const { activeFundTab } = useSelector((state: RootState) => state.app);
  const { setActiveFundTab } = useActions();

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    setActiveFundTab(newValue);
  };

  return (
    <Box
      sx={{
        justifyContent: "center",
        marginTop: "0.5rem",
        boxSizing: "border-box",
      }}
    >
      <Tabs
        value={
          getItemsFromLocalStorage("activeFundTab", "json") || activeFundTab
        }
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons={true}
        allowScrollButtonsMobile
        sx={{ boxSizing: "border-box" }}
      >
        {tabs?.map(({ id, title }: TabsProp) => (
          <Tab
            key={id}
            label={title}
            value={id}
            sx={{
              fontWeight: 700,
              boxSizing: "border-box",
              color: color.greyTab,
              height: "auto",
              maxWidth: "auto",
              minWidth: "auto",
              opacity: 1,
              textTransform: "none",
              transition: "color 0.3s ease",
              borderBottom: `1px solid ${color.greyCobble}`,
              "&:hover": {
                color: color.navy,
              },
              "&:hover, &:focus": {
                backgroundColor: "transparent",
              },
              "&.selected": {
                color: color.blue,
              },
            }}
            disableRipple
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default TabBar;
