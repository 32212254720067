import { createSlice } from "@reduxjs/toolkit";

export interface UiState {
  activeFundTab: string;
  donationDetailsVisibility: boolean;
  alert: string;
  showAlertButton: boolean;
  confirmationDetailsVisibility: boolean;
}

const initialStateUi: UiState = {
  activeFundTab: "",
  donationDetailsVisibility: false,
  alert: "",
  showAlertButton: true,
  confirmationDetailsVisibility: false,
};

const uiSlice = createSlice({
  name: "ui",
  initialState: initialStateUi,
  reducers: {
    setDonationDetailsVisibility: (state, action) => {
      const { donationDetailsVisibility } = action.payload;
      state.donationDetailsVisibility = donationDetailsVisibility;
    },
    setAlert: (state, action) => {
      const { alert = "", showAlertButton } = action.payload;
      state.alert = alert;
      state.showAlertButton = showAlertButton;
    },
    setConfirmationDetailsVisibility: (state, action) => {
      const { confirmationDetailsVisibility } = action.payload;

      state.confirmationDetailsVisibility = confirmationDetailsVisibility;
    },
  },
});

export const {} = uiSlice.actions;

export const createSetDonationDetailsVisibility = (
  donationDetailsVisibility: boolean,
) => ({
  type: "ui/setDonationDetailsVisibility",
  payload: { donationDetailsVisibility },
});

export const createSetAlert = (alert: string, showAlertButton = true) => ({
  type: "ui/setAlert", // if alert is empty string, its hidden
  payload: { alert, showAlertButton },
});

export const createSetConfirmationDetailsVisibility = (
  confirmationDetailsVisibility: boolean,
) => ({
  type: "ui/setConfirmationDetailsVisibility",
  payload: { confirmationDetailsVisibility },
});

export default uiSlice.reducer;
