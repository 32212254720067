export const RaiseNowPreData = {
  hasError: false,
  createdBy: null,
  behalf: {
    behalfVisibility: false,
    behalfType: "",
    club: {
      club_id: "",
      club_name: "",
      club_sec_group: "",
      club_type: "",
    },
    clubMembers: [],
    clubMembersTotal: 0,
    clubMembersInputError: {
      status: false,
      visibility: false,
    },
    clubMembersNoAmountError: {
      status: false,
      visibility: false,
    },
    clubs: [],
  },
  paymentWorkflowRedirect: false,
  merchantApiKey: "ri-ddd5a0e",
  raiseNowCustomerToken: null,
  raiseNowScriptErrorStatus: false,
  raiseNowSubscriptionId: null,
  raiseNowWidgetId: "rotary-italy",
  raiseNowWidgetIsLoaded: false,
  raiseNowWidgetIsLoading: false,
  selectedGrantId: null,
  user: {
    authenticated: false,
    roles: {
      "1": "anonymous user",
    },
    guestContinue: true,
  },
  foundation: {
    id: "Fondazione Rotary Italia (FRI)",
    name: {
      ko: "로타리재단(인도)",
      de: "Rotary Foundation (Indien)",
      pt: "Fundação Rotária (Índia)",
      ja: "Rotary Foundation (India)",
      fr: "La Fondation Rotary (Inde)",
      en: "Rotary Foundation (India)",
      it: "Rotary Foundation (India)",
      es: "Rotary Foundation (India)",
    },
    taxId: "97-952320154",
    message: [],
    bankAltName: "TRF ITA CC Clearing EUR",
    bankCode: "TRF_CCITA",
  },
};
