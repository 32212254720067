import { useMemo } from "react";
import { useSelector } from "react-redux";
import useActions from "Hooks/useActions";
import Select from "components/Form/components/Select";
import {
  AmountConfig,
  SelectOptions,
  CountryCurrencies,
  Currencies,
} from "shared/types";
import {
  getCountryById,
  getCurrencyById,
  getCurrencyName,
  getCurrencyRecurringEnabled,
} from "shared/utils";
import { RootState } from "shared/state/store";

const Currency = () => {
  const { setCurrencyId, setAmountToDefault } = useActions();
  const { countryId, currencyId, currencyName, donationType, config } =
    useSelector((state: RootState) => state.app);
  const setCurrency = (event: SelectOptions) => {
    if (typeof event.value === "string")
      config &&
        setCurrencyId(
          config.currencies,
          config.countries,
          event.value,
          config.countryCurrency,
        );
    config && setAmountToDefault(config.currencies);
  };
  const getCurrenciesForCountry = (
    allCurrencies: Currencies,
    lang: string,
    countryCurrency: CountryCurrencies,
  ) => {
    const selectedCurrencies: SelectOptions[] = countryCurrency[
      countryId
    ].list.reduce((currencies: SelectOptions[], amountConfig: AmountConfig) => {
      const currency = getCurrencyById(allCurrencies, amountConfig.currencyId);
      const country =
        config && getCountryById(config.countries, countryId, countryCurrency);
      const isCurrencyRecurringEnabled =
        country &&
        getCurrencyRecurringEnabled(country, currency, countryCurrency);
      // Don't add duplicate currencies
      if (currencies.find((option) => option.value === currency.id)) {
        return currencies;
      }

      //if recurringEnabled is true
      //and currently recurring is selected DonationType
      if (config?.appConfigs.recurringEnabled && donationType === "recurring") {
        //do not include currencies for country that are not meant for recurring.
        if (!isCurrencyRecurringEnabled) {
          return currencies;
        }
      }

      return [
        ...currencies,
        {
          value: currency.id,
          label: getCurrencyName(currency, lang),
          currency,
        },
      ];
    }, []);
    return selectedCurrencies;
  };

  const CurrencyOptions = useMemo(
    () =>
      config &&
      getCurrenciesForCountry(config.currencies, "en", config.countryCurrency),
    [countryId, donationType],
  );
  const sortedOptions =
    CurrencyOptions &&
    CurrencyOptions.sort((a, b) => a?.label?.localeCompare(b.label));

  return (
    <Select
      id={"Currency"}
      label={"Select your Currency"}
      value={currencyId}
      options={sortedOptions}
      onChange={(event: SelectOptions) => setCurrency(event)}
      required
      placeholder={currencyName}
    />
  );
};

export default Currency;
