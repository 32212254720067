import { useSelector } from "react-redux";
import { styled } from "@mui/system";
import useActions from "Hooks/useActions";
import CalloutBtn from "components/Form/components/CalloutBtn";
import { fieldLabel } from "shared/styles";
import { RootState } from "shared/state/store";
import { Currencies } from "shared/types";
import {
  setItemsInLocalStorage,
  updateErrorInLocalStorage,
} from "shared/utils";

const CalloutBtnWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const TypeField = styled("div")``;

const TypeLabel = styled("legend")`
  ${fieldLabel()}
  background: none;
`;

const DonationType = () => {
  const {
    setDonationType,
    setDedicationVisibilityError,
    setRecurringInterval,
    setRecurringStartDateDetails,
  } = useActions();
  const { donationType, config } = useSelector((state: RootState) => state.app);

  const setType = (currencies: Currencies, type: string) => {
    setDonationType(currencies, type);
    setItemsInLocalStorage("donationType", type, "string");
    if (type === "one-time") {
      setDedicationVisibilityError(false, false);
    }
  };
  //To do make hardcoded values dynamic.
  return (
    <TypeField>
      <TypeLabel>Select a donation type</TypeLabel>
      <CalloutBtnWrapper>
        <CalloutBtn
          title="One-time donation"
          isSelected={donationType === "one-time"}
          isSubtitleVisible={false}
          onClickCalloutBtn={() => {
            config && setType(config.currencies, "one-time");
            setRecurringInterval(null);
            setRecurringStartDateDetails(null, null);
            localStorage.removeItem("ruccuringStartDate");
            localStorage.removeItem("recurringStartDateDetails");
            updateErrorInLocalStorage("frequencyError", false, false);
          }}
          minHeight="130px"
        />
        <CalloutBtn
          title="Recurring donation"
          isSelected={donationType === "recurring"}
          isSubtitleVisible={false}
          onClickCalloutBtn={() =>
            config && setType(config.currencies, "recurring")
          }
          minHeight="130px"
        />
      </CalloutBtnWrapper>
    </TypeField>
  );
};

export default DonationType;
