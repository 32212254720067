// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

// Import translation files
import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationDE from "./locales/de/translation.json";
import translationIT from "./locales/it/translation.json";
import translationKO from "./locales/ko/translation.json";
import translationJA from "./locales/ja/translation.json";
import translationES from "./locales/es/translation.json";
import translationPT from "./locales/pt/translation.json";

// Define the resources
const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  es: {
    translation: translationES,
  },
  it: {
    translation: translationIT,
  },
  ko: {
    translation: translationKO,
  },
  pt: {
    translation: translationPT,
  },
  ja: {
    translation: translationJA,
  },
  de: {
    translation: translationDE,
  },
};

i18n
  .use(initReactI18next) // Bind react-i18next to the instance
  .use(LanguageDetector) // Detect user language
  .use(HttpApi) // Load translations using http
  .init({
    resources,
    fallbackLng: "en", // Default language
    debug: true, // Enable debugging for development
    interpolation: {
      escapeValue: false, // React already escapes values to prevent XSS
    },
    saveMissing: true, // Enable saving of missing keys
  });

i18n.on("missingKey", (lng, namespace, key, fallbackValue) => {
  console.log(lng, namespace, key, fallbackValue);
});

export default i18n;
