import { MouseEvent, ChangeEvent, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { styled, css } from "@mui/system";
import useActions from "Hooks/useActions";
import { color, CustomButton, pxToRem } from "shared/styles";
import TextInput from "components/Form/components/TextInput";
import LoadingThrobber from "components/LoadingThrobber";
import errorIcon from "shared/assets/error.svg";
import { RootState } from "shared/state/store";
import { validateTrackedGiftId } from "shared/utils/inputValidation";
import { fetchTrackedGiftInfo } from "shared/utils/api";
import { setItemsInLocalStorage } from "shared/utils";

interface UserActionButtonProps {
  primary: boolean;
}
const UserActionButton = styled(CustomButton)<UserActionButtonProps>(
  ({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
      minWidth: "170px",
    },
  }),
);

const NamedFundsContainer = styled(Box)(({ theme }) => ({
  boxSizing: "border-box",
  marginLeft: pxToRem(18),
  marginRight: pxToRem(18),
}));

const SignInMessage = styled("div")`
  display: none;
`;

const NamedFundsMessage = styled("div")`
  line-height: 1.2em;
  padding-top: 10px;
  position: relative;
  left: 27px;
  bottom: 50%;
  color: #b82216;
`;
const ElementWrapper = styled("div")`
  position: relative;
  top: 27px;
  background: url(${errorIcon}) top right 100% no-repeat;
  height: 18px;
`;
const LoggedInMsg = styled("div")`
  font-weight: 400;
  font-size: 15px;
  margin-top: ${pxToRem(35)};
  margin-bottom: ${pxToRem(15)};
`;

interface SearchButtonProps {
  color?: string;
  onClick: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  primary: boolean;
}

const dynamicStyle = (props: SearchButtonProps) => css`
  color: ${props.color};
`;

const SearchButton = styled(CustomButton)<SearchButtonProps>(({ theme }) => ({
  marginBottom: "40px",
  marginTop: "20px",
  [theme.breakpoints.up("xs")]: {
    minWidth: "170px",
  },
  ...dynamicStyle,
}));

const FundsContainer = styled("div")`
  .field--NamedFunds {
    position: relative;
    top: 20px;
  }
`;

const NamedFundGiftName = styled("div")`
  color: ${color.navy};
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-top: 15px;
  display: inline;
`;

const NamedFundDetails = styled("div")`
  display: block;
  label {
    font-weight: 700;
    display: inline;
    font-size: 20px;
  }
`;

const NamedFunds = () => {
  const {
    setNamedFundId,
    setTrackedGiftFundId,
    setTrackedGiftName,
    setTrackedGiftAliasDescription,
    setNamedFundsError,
    setTrackedGiftSubFundId,
    setNamedFundAliasDescription,
    setSelectedFundUniqueId,
    setDedicationVisibilityError,
  } = useActions();
  const [namedFundInput, setNamedFundInput] = useState("");
  const [namedFundGiftname, setNamedFundGiftName] = useState("");
  const [namedFundErrorMessage, setNamedFundErrorMessage] =
    useState("Enter gift ID");
  const [namedFundsLoading, setNamedFundsLoading] = useState(false);
  const { config } = useSelector((state: RootState) => state.app);
  const { namedFundsError } = useSelector((state: RootState) => state.error);
  const redirectToLogin = () => {
    const lang = "en";
    const webAppBaseUrl = config?.appConfigs.webAppBaseUrl;
    setItemsInLocalStorage(
      "redirectFrom",
      `${config?.appConfigs.webAppBaseUrl}/${lang}/login`,
      "string",
    );
    window.location.href = `${webAppBaseUrl}/en/login`;
  };
  const user = { authenticated: true };
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNamedFundInput(event.target.value);
  };

  const onSearchNamedFund = (namedFundInput: string) => {
    setNamedFundsLoading(true);
    const setCommonStateForNamedFund = (
      id = "",
      fund = "",
      subFund = "",
      giftName = "",
      aliasDescription = "",
    ) => {
      setNamedFundId(id);
      setTrackedGiftFundId(fund);
      setTrackedGiftSubFundId(subFund);
      setTrackedGiftName(giftName);
      setTrackedGiftAliasDescription(aliasDescription);
    };
    const namedFundIdTrimmed = namedFundInput.trim().toUpperCase();
    if (validateTrackedGiftId(namedFundIdTrimmed)) {
      //To do get user details for API
      fetchTrackedGiftInfo(namedFundIdTrimmed)
        .then((response) => {
          setNamedFundsLoading(false);
          const namedFundData = response?.data.payload;
          switch (namedFundData.status) {
            case "ACTIVE":
              setNamedFundId(namedFundData.Id);
              setNamedFundErrorMessage("");
              setNamedFundGiftName(namedFundData.GiftName);
              setNamedFundsError(false, false);
              setNamedFundAliasDescription(namedFundData.AliasDescription);
              setCommonStateForNamedFund(
                namedFundData.Id,
                namedFundData.Fund,
                namedFundData.SubFund,
                namedFundData.GiftName,
                namedFundData.AliasDescription,
              );
              setNamedFundErrorMessage("");
              // const paymentProfileData = {
              //     country: country,
              //     currency: currency,
              //     transactionTypeAlias: namedFundData[0].Alias,
              //     transactionFlow,
              //     customerType,
              //     api_url,
              // };
              // setTransactionTypeAlias(namedFundData[0].Alias);
              // setPaymentProfileState(api_url, paymentProfileData, setPaymentProfile, setFoundation, 'Namedfund');
              break;

            case "INELIGIBLE":
              setNamedFundAliasDescription("");
              setNamedFundGiftName("");
              setNamedFundsError(true, true);
              setCommonStateForNamedFund();
              setNamedFundErrorMessage(
                "This gift ID is limited to certain donors. Please verify your ID or contact your local office.",
              );
              break;

            case "PENDING":
              setNamedFundAliasDescription("");
              setNamedFundGiftName("");
              setNamedFundsError(true, true);
              setCommonStateForNamedFund();
              setNamedFundErrorMessage(
                "This gift ID is not ready to accept funds.",
              );
              break;

            case "CLOSED":
              setNamedFundAliasDescription("");
              setNamedFundGiftName("");
              setNamedFundsError(true, true);
              setCommonStateForNamedFund();
              setNamedFundErrorMessage(
                "This gift ID is no longer accepting funds.",
              );
              break;

            case "INVALID":
              setNamedFundAliasDescription("");
              setNamedFundGiftName("");
              setNamedFundsError(true, true);
              setCommonStateForNamedFund();
              setNamedFundErrorMessage(
                "This gift ID was not found. Please enter a valid gift ID.",
              );
              break;
            default:
              setNamedFundAliasDescription("");
              setNamedFundGiftName("");
              setNamedFundsError(true, true);
              setCommonStateForNamedFund();
              setNamedFundErrorMessage(
                "There was an issue with your request. Please try again or contact your local office.",
              );
          }
        })
        .catch((error) => {
          setNamedFundAliasDescription("");
          setNamedFundGiftName("");
          setNamedFundsError(true, true);
          setCommonStateForNamedFund();
          setNamedFundErrorMessage("Please enter a valid  gift ID.");
          setNamedFundsLoading(false);
        });
    } else {
      setNamedFundAliasDescription("");
      setNamedFundGiftName("");
      setNamedFundsError(true, true);
      if (!namedFundInput) {
        setNamedFundErrorMessage("Enter gift ID.");
      } else {
        setNamedFundErrorMessage("Please enter a valid  gift ID.");
      }
      setNamedFundsLoading(false);
    }
  };

  useEffect(() => {
    setSelectedFundUniqueId(null, null);
    setDedicationVisibilityError(false, false);
  }, []);

  return (
    // To do
    // Add local storage for authenticated usage
    <NamedFundsContainer>
      {!user.authenticated && (
        <div>
          <UserActionButton
            id="SignInButton"
            onClick={() => redirectToLogin()}
            primary
          >
            Sign in
          </UserActionButton>
          <SignInMessage id="errorMessage">
            <ElementWrapper>
              <NamedFundsMessage>
                Please sign in or choose another fund to proceed.
              </NamedFundsMessage>
            </ElementWrapper>
          </SignInMessage>
        </div>
      )}
      {user.authenticated && (
        <div>
          <LoggedInMsg
            dangerouslySetInnerHTML={{
              __html: `To donate to an existing fund, please enter the six digit gift ID. If you do not know the gift ID, please contact <a href="mailto:gift.administration@rotary.org">gift.administration@rotary.org</a> or your <a href="https://www.rotary.org/en/about-rotary/our-leaders/international-offices">local office</a>.`,
            }}
          />
          <FundsContainer>
            <TextInput
              id="NamedFunds"
              label="Enter gift ID"
              onChange={onInputChange}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  onSearchNamedFund(namedFundInput);
                }
              }}
              value={namedFundInput}
              error={namedFundsError.visibility && namedFundsError.status}
              errorMessages={[namedFundErrorMessage]}
            />
            <SearchButton
              id="SearchButton"
              onClick={(e) => {
                e.preventDefault();
                onSearchNamedFund(namedFundInput);
              }}
              primary
            >
              Search
            </SearchButton>
            {namedFundsLoading && (
              <LoadingThrobber
              // forPurpose={'Search'}
              ></LoadingThrobber>
            )}
            {namedFundGiftname && (
              <NamedFundDetails>
                <label htmlFor="Designation">Fund: </label>
                <NamedFundGiftName>{namedFundGiftname}</NamedFundGiftName>
              </NamedFundDetails>
            )}
          </FundsContainer>
        </div>
      )}
    </NamedFundsContainer>
  );
};

export default NamedFunds;
