import React from "react";
import { ChangeEvent } from "react";
import { FormControlLabel } from "@mui/material";
import Checkbox from "components/StaffUseOnly/Checkbox";
import { styled } from "@mui/system";

interface PayInvoiceCheckboxProps {
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  mode: string;
}

const CheckBoxWrapper = styled("div")<{ mode: string }>`
  label {
    ${({ mode }) => mode === "dpm" && "margin-top: -24%;"}
  }
`;

const PayInvoiceCheckbox = (props: PayInvoiceCheckboxProps) => {
  return (
    <CheckBoxWrapper mode={props.mode}>
      <FormControlLabel
        label=""
        control={
          <Checkbox
            id="PayInvoiceCheckbox"
            checked={props.checked}
            onChange={props.onChange}
          />
        }
      />
    </CheckBoxWrapper>
  );
};

export default PayInvoiceCheckbox;
