export const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 37.99988 38"
    aria-labelledby="title"
  >
    <title>Delete</title>
    <path d="M19,0A19,19,0,1,0,37.99988,19,18.99992,18.99992,0,0,0,19,0Zm0,34A15,15,0,1,1,33.99988,19,15.00009,15.00009,0,0,1,19,34Zm2.82837-15,4.6568,4.65686L23.6568,26.48529l-4.65686-4.65686-4.65686,4.65686-2.82843-2.82843L16.17151,19l-4.65686-4.6568,2.82843-2.82849,4.65686,4.65686,4.65686-4.65686,2.82837,2.82849Z" />
  </svg>
);
